import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Button,
  Grid,
  Box,
  styled,
  Card,
  Stack,
  TextField,
  Autocomplete,
  CircularProgress
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { collection, getDocs, setDoc, doc, query, where } from 'firebase/firestore';
import { db } from '../../../App';
import SortByTitle from '../event/SortByTitle';

const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------

const UpdateRoom = async (name, roomId, facility, facilityName, image) => {
  let heading = name
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), '')
    .toUpperCase();
  heading = heading.substring(0, 2);

  const roomsRef = query(collection(db, 'residents'), where('roomId', '==', roomId));
  getDocs(roomsRef).then((res) => {
    res.forEach((result) => {
      setDoc(
        doc(db, 'residents', result.data().id),
        {
          roomName: name
        },
        { merge: true }
      );
    });
  });
  let avatarUrl;

  if (image) {
    const storage = getStorage();
    const storageRef = ref(storage, `rooms/${roomId}/roomImage`);
    const metadata = {
      contentType: 'image/jpeg'
    };
    const uploadTask = await uploadBytesResumable(storageRef, image, metadata);

    avatarUrl = await getDownloadURL(uploadTask.ref);

    return setDoc(
      doc(db, 'rooms', roomId),
      {
        name,
        heading,
        facilityId: facility,
        facilityName,
        avatarUrl
      },
      { merge: true }
    );
  }
  return setDoc(
    doc(db, 'rooms', roomId),
    {
      name,
      heading,
      facilityId: facility,
      facilityName
    },
    { merge: true }
  );
};

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function UpdateRoomForm({ roomId, name, facilityName, facilityId, avatarUrl }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [image, setImage] = useState(avatarUrl);
  const [uploadImage, setUploadImage] = useState();
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        facilitesCollection = collection(db, 'facilites');
      }
      // const facilitesCollection = collection(db, 'facilites');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  });

  const formik = useFormik({
    initialValues: {
      name,
      facility: facilityId,
      facilityName
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      // if (!uploadImage && !image) {
      //   return NotificationManager.error('Image is required');
      // }
      setIsLoading(true);
      const { name, facility, facilityName } = values;

      UpdateRoom(name, roomId, facility, facilityName, uploadImage)
        .then((res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Location Updated Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          navigate('/home/locations', { replace: true });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          return NotificationManager.error(err.message);
        });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <Autocomplete
            fullWidth
            id="asynchronous-demo"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            defaultValue={{ title: facilityName ?? '', value: facilityId ?? '' }}
            onChange={(e, value) => {
              setFieldValue('facilityName', value?.title || '');
              setFieldValue('facility', value?.value || '');
            }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            options={options}
            loading={loading}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label="Select Group"
                {...getFieldProps('facility')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
                error={Boolean(touched.facility && errors.facility)}
                helperText={touched.facility && errors.facility}
              />
            )}
          />

          <Grid container spacing={2}>
            {image && (
              <Grid item xs={4} sm={4} md={2}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle alt="Image" src={image} />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label">
              Upload Image
              <input
                fullWidth
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => {
                  setImage(URL.createObjectURL(e.target.files[0]));
                  setUploadImage(e.target.files[0]);
                }}
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Update Location
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
