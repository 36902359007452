import { styled } from '@mui/material/styles';
import { Box, Card, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import MoreInfo from '../../components/_dashboard/organization/MoreInfo';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
  //   padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function MoreInfoPageOrganization() {
  return (
    <RootStyle title="Facility Details | Rel8">
      <Container>
        <ContentStyle>
          <Box sx={{ marginLeft: '3.2%' }}>
            <Typography variant="h4" gutterBottom>
              Organisation Details
            </Typography>
          </Box>

          <MoreInfo />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
