import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  Card,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  CircularProgress,
  Popper,
  ClickAwayListener,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import filePNG from '../../../assest/file.png';
import AviThumbnail from '../../../assest/aviThumbnail.png';
import HEICThumbnail from '../../../assest/heicThumbnail.png';
import MPEGThumbnail from '../../../assest/mpegThumbnail.png';
import AudioThumbnail from '../../../assest/audioThumbnail.png';
import Textthumbnail from '../../../assest/textThumbnail.png';
import PdfThumbnail from '../../../assest/pdfThumbnail.webp';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ShopProductCard from '../../../components/_dashboard/products/ProductCard';
import BackButtonImage from "../../../assest/backButton.svg"

import {
  collection,
  doc,
  updateDoc,
  getDocs,
  query,
  where,
  setDoc,
  deleteDoc
} from 'firebase/firestore';
import { Box } from '@mui/system';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { db } from '../../../App';
import { uploadFile } from '../user/UploadFile';
import { deleteFolder } from '../user/deleteFiles';
import EventPreview from '../event/EventPreview';
import SortByTitle from '../event/SortByTitle';
import { set } from 'lodash';

const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`
}));

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});



export default function UpdateStoryForm({ props, selectOption, setSelectOption }) {
  const { id, title, description, residentsId, roomsId, facilitiesId } = props;

  // console.log(props);

  const type = props?.type;
  const medias = props?.medias;

  // console.log(medias);

  const published = props.isPublished;
  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFile] = useState(medias);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [defaultOptions, setDefaultopt] = useState([]);
  const [defaultOptionsRooms, setDefaultoptRooms] = useState([]);
  const [draft, setDraft] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [openRoom, setOpenRoom] = useState(false);
  const [openFacility, setOpenFacility] = useState(false);
  const [IsGroupContent, setIsGroupContent] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewError, setpreviewError] = useState(false);
  const [residentIDs, setresidentIDs] = useState([]);

  const loadingRooms = openRoom && roomOptions.length === 0;
  const loadingFacility = openFacility && facilityOptions.length === 0;

  // Facility
  const [facilityID, setFacilityID] = useState(facilitiesId);
  const [roomId, setRoomIds] = useState(roomsId);
  const [checkAll, setCheckAll] = useState(false);

  const [checkAllResi, setCheckAllResi] = useState(false);

  const loading = open && options.length === 0;
  const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');


  const images =
    files.length != 0
      ? files.filter((image) => {
          // console.log('image :: ', image);
          const file =
            (image.type != 'image' && image.type != 'video') ||
            (image.name && image.name.endsWith('.avi')) ||
            (image.name && image.name.endsWith('.mpeg')) ||
            (image.name && image.name.endsWith('.heic'));
          const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
          if (type != 'file') {
            return image;
          }
        })
      : [];

  // for rooms Id array for resident
  const IDROOM = [];
  roomId?.forEach((e) => {
    IDROOM.push(e.value);
  });

  useEffect(() => {
    setIsGroupContent(getFieldProps('isPublished').value);
  }, []);

  // // --------------------------------------start for residents ---------------------
  // useEffect(async () => {
  //   const residentsCollection = query(collection(db, 'residents'), where('id', 'in', residentsId));
  //   const residentsPromise = await getDocs(residentsCollection);
  //   const opt = [];
  //   residentsPromise.forEach((result) => {
  //     opt.push({
  //       value: result.data().id,
  //       title: `${result.data().firstName} ${result.data().lastName}`
  //     });
  //   });
  //   setDefaultopt(opt);
  // }, []);
  // // --------------------------------------end for residents ---------------------

  // // --------------------------------------start for Rooms ---------------------
  // useEffect(async () => {
  //   const roomCollection = query(collection(db, 'rooms'), where('id', 'in', roomsId));
  //   const roomsPromise = await getDocs(roomCollection);
  //   const opt = [];
  //   roomsPromise?.forEach((result) => {
  //     opt.push({
  //       value: result.data().id,
  //       title: result.data().name
  //     });
  //   });
  //   setDefaultoptRooms(opt);
  // }, []);
  // // --------------------------------------End for residents ---------------------

  useEffect(async () => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const residentsCollection = query(collection(db, 'residents'), where('roomId', 'in', IDROOM));
      const residentsPromise = await getDocs(residentsCollection);
      const opt = [];
      residentsPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: `${result.data().firstName} ${result.data().lastName}`,
          isAllow: result.data().includedInAdminStories,
          deletedAt: result.data().deletedAt
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e, downloadUrl, thumbnail) => {
    const s = files.filter((item, index) => index !== e);
    if (thumbnail && RgExp.test(thumbnail)) {
      setDeleteFiles([...deleteFiles, thumbnail, downloadUrl]);
    } else if (RgExp.test(downloadUrl)) setDeleteFiles([...deleteFiles, downloadUrl]);
    setFile(s);
  };

  const handleFileUpload = async (e) => {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      arr.push(e.target.files[i]);
    }
    if (arr.length > 30) {
      return NotificationManager.error('maximum 30 files to upload');
    }
    const obj = [];

    for (let i = 0; i < arr.length; i += 1) {
      let thumbnail;
      const ImagesArray = arr[i];
      const { size } = arr[i];
      const downloadUrl = URL.createObjectURL(arr[i]);
      const mediaTypeLocal = arr[i].type.split('/')[0];
      if (mediaTypeLocal !== 'application' && !arr[i].name.endsWith('.mpeg')) {
        if (mediaTypeLocal === 'video' && !arr[i].name.endsWith('.avi')) {
          thumbnail = await generateVideoThumbnail(arr[i]);
        }
        if (arr[i].name.endsWith('.heic')) {
          thumbnail = HEICThumbnail;
        }
        if (arr[i].name.endsWith('.avi')) {
          thumbnail = AviThumbnail;
        }
        if (mediaTypeLocal === 'audio') {
          thumbnail = AudioThumbnail;
        }
        if (mediaTypeLocal === 'text') {
          thumbnail = Textthumbnail;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          name: arr[i]?.name,
          size,
          type: mediaTypeLocal
        });
      } else {
        if (arr[i].name.endsWith('.pdf')) {
          thumbnail = PdfThumbnail;
        } else if (arr[i].name.endsWith('.mpeg')) {
          thumbnail = MPEGThumbnail;
        } else {
          thumbnail = filePNG;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          size,
          name: arr[i]?.name,
          type: 'file'
        });
      }
      
    }
    setFile([...files, ...obj]);
  };

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('title is required'),
    description: Yup.string().required('description is required.').nullable(),
    facilitiesId: Yup.string().required('Group is required').nullable(),
    roomsId: Yup.array().min(1, 'at least 1 location is required').required('required'),
    residentsId: Yup.array().min(1, 'at least 1 client is required').required('required'),
    date: Yup.date().required('Date is required')
  });
  const DraftRegisterSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    description: Yup.string().nullable(),
    facility: Yup.string().nullable(),
    facilityName: Yup.string().nullable(),
    roomsId: Yup.array(),
    residentsId: Yup.array(),
    date: Yup.date()
  });
  const formik = useFormik({
    initialValues: {
      title,
      description,
      residentsId: defaultOptions,
      isPublished: published,
      facilitiesId: props.facilitiesId,
      facilityName: props.facilityName,
      roomsId: defaultOptionsRooms,
      date: new Date(props?.createdAt?.seconds * 1000)
    },
    validationSchema: draft ? DraftRegisterSchema : RegisterSchema,
    onSubmit: async (values) => {
      if (previewError) {
        return setPreview(true);
      }
      AddEventFunc(draft ? 1 : 0);
    }
  });
  const AddEventFunc = async (drft) => {
    // console.log(drft ?? false);
    const draft = drft ?? false;
    // console.log(draft);
    if (files.length > 30) {
      return NotificationManager.error('Maximum 30 files to upload');
    }
    if (draft) {
      setDraftLoading(true);
    } else {
      setIsLoading(true);
    }
    const title = getFieldProps('title').value;
    const description = getFieldProps('description').value;
    const isPublished = getFieldProps('isPublished').value;
    const residents = getFieldProps('residentsId').value;
    const eventsRef = doc(collection(db, 'stories'));
    const residentsId = [];
    const roomsId = [];
    const mediaId = uuidv4();
    const facilityName = getFieldProps('facilityName').value;
    const facilitiesId = getFieldProps('facilitiesId').value;
    const roomId = getFieldProps('roomsId').value;
    const date = getFieldProps('date').value;
    defaultOptions.forEach((element) => {
      residentsId.push(element.value);
    });

    defaultOptionsRooms.forEach((element) => {
      roomsId.push(element.value);
    });

    setTimeout(() => {
      NotificationManager.warning('Please wait! File is being upload');
    }, 10000);

    const medias = [];
    let itemsProcessed = 0;
    deleteFiles?.forEach((element) => {
      deleteFolder(element);
      const newFiles = files.filter((item) => item.downloadUrl === element);
      setFile(newFiles);
    });
    if (files.length > 0) {
      files.forEach(async (element, index, array) => {
        console.log('element ...', element);
        element.id = id;
        if (!RgExp.test(element.downloadUrl)) {
          console.log('element ...222', element);
          if (element.type === 'video') {
            const downloadUrl = await uploadFile(
              'stories',
              element,
              element.file,
              element.type,
              mediaId
            );
            const thumbnailId = uuidv4();
            const downloadUrlThumbnail = await uploadFile(
              'stories',
              element,
              element.thumbnail,
              'thumbnail',
              thumbnailId
            );
            medias.push({
              downloadUrl,
              thumbnailId: thumbnailId ?? null,
              thumbnail: downloadUrlThumbnail ?? null,
              // name: null,
              name: element.file.name ?? 'abc.mp4',
              type: element.type,
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
          if (element.type === 'image') {
            const downloadUrl = await uploadFile(
              'stories',
              element,
              element.file,
              element.type,
              mediaId
            );
            medias.push({
              downloadUrl,
              thumbnailId: null,
              thumbnail: null,
              name: element.file.name,
              type: element.type,
              size: null,
              id: mediaId
            });
          }
          if (element.type === 'application') {
            const downloadUrl = await uploadFile(
              'stories',
              element,
              element.file,
              element.type,
              mediaId
            );
            medias.push({
              downloadUrl,
              thumbnailId: null,
              thumbnail: null,
              name: image.name,
              type: 'file',
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
        } else {
          medias.push(element);
        }
        itemsProcessed += 1;
        if (itemsProcessed === array.length) {
          if (draft) {
            console.log(id);
            const draftsRef = doc(db, 'drafts', id);
            // check type for update draft or new add
            if (type) {
              updateDoc(draftsRef, {
                title,
                createdAt: date,
                object: {
                  title,
                  description,
                  isPublished,
                  medias,
                  residentsId,
                  postedById: 'admin',
                  createdAt: date,
                  facilitiesId,
                  facilityName,
                  roomsId
                  // roomName
                }
              })
                .then((res) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Draft Updated Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  return navigate('/home/drafts', { replace: true });
                })
                .catch((err) => console.log(err));
            }
            if (!type) {
              // check type for update draft or new add
              setDoc(draftsRef, {
                title,
                createdAt: date,
                type: 'stories',
                id,
                object: {
                  title,
                  description,
                  isPublished,
                  medias,
                  residentsId,
                  postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                  createdAt: date,
                  facilitiesId,
                  facilityName,
                  roomsId,
                  id
                }
              })
                .then((res) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Draft Updated Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  return navigate('/home/drafts', { replace: true });
                })
                .catch((err) => console.log(err));
            }
          } else {
            // *************** for add new story from draft
            if (type) {
              const eventsRef = doc(db, 'stories', id);
              console.log(date);
              return setDoc(eventsRef, {
                id,
                title,
                description,
                isPublished,
                medias,
                residentsId,
                postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                  JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                }`,
                createdAt: date,
                // roomName,
                roomsId,
                facilitiesId,
                facilityName,
                commentsCount: 0
              })
                .then(async (res) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Story Added Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  deleteDoc(doc(db, 'drafts', id));

                  // -----------------------------------------Comment Notifications------------------------------------
                  const object = {
                    id,
                    title,
                    description,
                    isPublished,
                    medias,
                    residentsId,
                    postedById: JSON.parse(localStorage.getItem('Rel8User')).id,
                    createdAt: date,
                    // roomName,
                    roomsId,
                    facilitiesId,
                    facilityName,
                    commentsCount: 0
                  };
                  const commentNotifiRef = doc(collection(db, 'notifications'));
                  await setDoc(commentNotifiRef, {
                    id: commentNotifiRef.id,
                    body: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                      JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                    } has posted a new story`,
                    commentId: id,
                    createdAt: date,
                    profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
                    title: 'A new story',
                    topic: `${facilitiesId}_story`,
                    viewedBy: [],
                    senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
                    receiverId: '',
                    object,
                    senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                      JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                    }`,
                    type: 'stories'
                  });
                  // -----------------------------------------Comment Notifications------------------------------------

                  navigate('/home/stories', { replace: true });
                })
                .catch((err) => console.log(err));
            }

            const eventsRef = doc(db, 'stories', id);
            updateDoc(eventsRef, {
              id: eventsRef.id,
              title,
              description,
              isPublished,
              medias,
              residentsId,
              createdAt: date,
              // roomName,
              roomsId,
              facilitiesId,
              facilityName
            })
              .then((res) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Story Updated Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/stories', { replace: true });
              })
              .catch((err) => console.log(err));
          }
        }
      });

      // ****************************************** for with out image***********************************************
    } else {
      if (draft) {
        console.log(id);
        const draftsRef = doc(db, 'drafts', id);
        // check type for update draft or new add
        if (type) {
          return updateDoc(draftsRef, {
            title,
            createdAt: date,
            object: {
              title,
              description,
              isPublished,
              medias,
              residentsId,
              postedById: 'admin',
              createdAt: date,
              facilitiesId,
              facilityName,
              roomsId
              // roomName
            }
          })
            .then((res) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Draft Updated Successfully',
                showConfirmButton: false,
                timer: 2000
              });
              return navigate('/home/drafts', { replace: true });
            })
            .catch((err) => console.log(err));
        }
        // check type for update draft or new add
        if (!type) {
          return setDoc(draftsRef, {
            title,
            createdAt: date,
            type: 'stories',
            id,
            object: {
              title,
              description,
              isPublished,
              medias,
              residentsId,
              postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
              createdAt: date,
              facilitiesId,
              facilityName,
              roomsId,
              id
              // roomName
            }
          })
            .then((res) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Draft Updated Successfully',
                showConfirmButton: false,
                timer: 2000
              });
              return navigate('/home/drafts', { replace: true });
            })
            .catch((err) => console.log(err));
        }
      }
      //  else {
      // *************** for add new story from draft
      if (type) {
        const eventsRef = doc(db, 'stories', id);
        return setDoc(eventsRef, {
          id,
          title,
          description,
          isPublished,
          medias,
          residentsId,
          postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
          authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
            JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          }`,
          createdAt: new Date(),
          // roomName,
          roomsId,
          facilitiesId,
          facilityName,
          commentsCount: 0
        })
          .then(async (res) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Story Added Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            deleteDoc(doc(db, 'drafts', id));

            // -----------------------------------------Comment Notifications------------------------------------
            const object = {
              id,
              title,
              description,
              isPublished,
              medias,
              residentsId,
              postedById: JSON.parse(localStorage.getItem('Rel8User')).id,
              createdAt: new Date(),
              // roomName,
              roomsId,
              facilitiesId,
              facilityName,
              commentsCount: 0
            };
            const commentNotifiRef = doc(collection(db, 'notifications'));
            await setDoc(commentNotifiRef, {
              id: commentNotifiRef.id,
              body: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              } has posted a new story`,
              commentId: id,
              createdAt: new Date(),
              profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
              title: 'A new story',
              topic: `${facilitiesId}_story`,
              viewedBy: [],
              senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
              receiverId: '',
              object,
              senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              }`,
              type: 'stories'
            });
            // -----------------------------------------Comment Notifications------------------------------------

            navigate('/home/stories', { replace: true });
          })
          .catch((err) => console.log(err));
      }

      const eventsRef = doc(db, 'stories', id);
      updateDoc(eventsRef, {
        id: eventsRef.id,
        title,
        description,
        isPublished,
        medias,
        residentsId,
        postedById: 'admin',
        // createdAt: new Date(),
        // roomName,
        roomsId,
        facilitiesId,
        facilityName
      })
        .then((res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Story Updated Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          navigate('/home/stories', { replace: true });
        })
        .catch((err) => console.log(err));
      // }
    }
  };

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched
  } = formik;

  // -------------Rooms Autocomplete--------------------------//
  useEffect(() => {
    let active = true;

    if (!loadingRooms) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const roomsCollection = query(collection(db, 'rooms'), where('facilityId', '==', facilityID));
      const roomsPromise = await getDocs(roomsCollection);
      const opt = [];
      roomsPromise?.forEach((result) => {
        opt.push({
          value: result.data()?.id,
          title: result.data()?.name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setRoomOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingRooms]);

  useEffect(() => {
    if (!openRoom) {
      setRoomOptions([]);
    }
  }, [openRoom]);

  // -----------------------------get facility--------------------------
  useEffect(() => {
    let active = true;

    if (!loadingFacility) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        facilitesCollection = collection(db, 'facilites');
      }
      // const facilitesCollection = collection(db, 'facilites');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise?.forEach((result) => {
        opt.push({
          value: result.data()?.id,
          title: result.data()?.name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setFacilityOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingFacility]);

  useEffect(() => {
    if (!openFacility) {
      setFacilityOptions([]);
    }
  }, [openFacility]);

  // ************************************************** Default Options ********************************
  // ************************************************** Default Options ********************************
  // --------------------------------------start for Rooms ---------------------
  useEffect(async () => {
    const roomCollection = query(collection(db, 'rooms'), where('id', 'in', roomsId));
    const roomsPromise = await getDocs(roomCollection);
    const opt = [];
    roomsPromise?.forEach((result) => {
      opt.push({
        value: result.data().id,
        title: result.data().name
      });
    });
    setFieldValue('roomsId', opt);
    setDefaultoptRooms(opt);
  }, []);
  // --------------------------------------start for residents ---------------------
  useEffect(async () => {
    const residentsCollection = query(collection(db, 'residents'), where('id', 'in', residentsId));
    const residentsPromise = await getDocs(residentsCollection);
    const opt = [];
    residentsPromise.forEach((result) => {
      opt.push({
        value: result.data().id,
        title: `${result.data().firstName} ${result.data().lastName}`
      });
    });
    console.log('options ', opt);
    setFieldValue('residentsId', opt);
    setDefaultopt(opt);
  }, []);
  // --------------------------------------end for residents ---------------------

  // -----------------------------------------
  // for room
  const handleClickAway = (e) => {
    setOpenRoom(false);
  };
  // for resident
  const handleClickAwayResi = (e) => {
    setOpen(false);
  };

  // -------for room
  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      setDefaultoptRooms(roomOptions);
      setRoomIds(roomOptions);
      setFieldValue('roomsId', roomOptions);
    } else {
      setDefaultoptRooms([]);
      setRoomIds([]);
    }
    setFieldValue('residentsId', []);
    setDefaultopt([]);
    setCheckAllResi(false);
  };

  // -----For resident
  const checkAllChangeResi = (event) => {
    if (!IsGroupContent) {
      return;
    }
    setCheckAllResi(event.target.checked);
    if (event.target.checked) {
      const arry = [];
      options.map((item) => {
        if (item.isAllow && item.deletedAt == null) {
          arry.push(item);
        }
      });
      console.log('array : ', arry);
      setDefaultopt(arry);
      setFieldValue('residentsId', arry);
    } else {
      setFieldValue('residentsId', []);
      setDefaultopt([]);
    }
  };
  // -----------------------------------------

  return (
    <Box sx={{ width: '100%', minWidth: '100%' }}>
      {selectOption == 'preview' ? (
        <EventPreview
          file={files}
          title={{ ...getFieldProps('title') } ?? ''}
          description={{ ...getFieldProps('description') }}
          setPreview={setPreview}
          AddSubmitFunc={AddEventFunc}
          draftLoading={draftLoading}
          isLoading={isLoading}
          head1="Update Story"
          head2={type ? 'Add Story' : 'Update Story'}
          section='Story'
          date={getFieldProps('date').value}
          handleSubmit={handleSubmit}
          setDraft={setDraft}
          setSelectOption={setSelectOption}
        />
      ) : (
        <Card
        sx={{
          marginTop: '',
          width: '100%',
          minWidth: '100%',
          paddingX: '20px',
          paddingY: '20px'
        }}
      >
         {selectOption != 'add' &&  <div style={{marginBottom:"12px"}} onClick={()=>{
            if(selectOption=='audience'){
              setSelectOption('add')
            }else if(selectOption=='schedule'){
              setSelectOption('audience')
            }
           }}><img src={BackButtonImage} style={{width:"35px", cursor:"pointer"}}/></div>}
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <NotificationContainer />
            <Stack spacing={3}>
              {selectOption == 'add' && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Title
                    </div>

                    <TextField
                      fullWidth
                      // label="Title"
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Box>
                </Stack>
              )}
              {selectOption == 'schedule' && (
                <Stack sx={{ width: '100%', '& > *': { flex: 1 } }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      width: '100%'
                    }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Date
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        format="dd/MM/yyyy"
                        inputFormat="dd/MM/yyyy"
                        label="Date"
                        {...getFieldProps('date')}
                        onChange={(e, value) => setFieldValue('date', e || '')}
                        onChangeRaw={(e) => {
                          setFieldTouched('date', true, true);
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            error={Boolean(touched.date && errors.date)}
                            helperText={touched.date && errors.date}
                          />
                        )}
                        maxDate={new Date()}
                      />
                    </LocalizationProvider>
                  </div>
                </Stack>
              )}
              {/* {selectOption == 'schedule' && (
                  <Box
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#9A76F5',
                      paddingY: '25px',
                      textAlign: 'center'
                    }}
                  >
                    Schedule your content in advance - coming soon!
                  </Box>
                )} */}
              {selectOption == 'audience' && (
                <FormControlLabel
                control={<Checkbox checked={IsGroupContent} />}
                // label="Published"
                label="Is this a group story?"
                {...getFieldProps('isPublished')}
                onChange={(e, value) => {
                  setFieldValue('residentsId', []);
                  setresidentIDs([]);
                  setOptions([]);
                  setIsGroupContent(!IsGroupContent);
                  setFieldValue('isPublished', value);
                }}
              />
              )}
              {selectOption == 'audience' && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      width: '100%'
                    }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Group
                    </div>
                    <Autocomplete
                      fullWidth
                      id="asynchronous-demo"
                      open={openFacility}
                      onOpen={() => {
                        setOpenFacility(true);
                      }}
                      onClose={() => {
                        setOpenFacility(false);
                      }}
                      onChange={(e, value) => {
                        setFieldValue('facilityName', value?.title || '');
                        setFieldValue('facilitiesId', value?.value || '');
                        setFacilityID(value?.value);

                        // for empty
                        setDefaultoptRooms([]);
                        setFieldValue('roomsId', []);
                        setDefaultopt([]);
                        setFieldValue('residentsId', []);
                        // for check all empty
                        setCheckAll(false);
                        setCheckAllResi(false);
                        setDefaultopt([]);
                      }}
                      defaultValue={{ title: props.facilityName, value: props.facilitiesId }}
                      isOptionEqualToValue={(option, value) => option.title === value.title}
                      getOptionLabel={(option) => option.title}
                      options={facilityOptions}
                      loading={loadingFacility}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          // label="Select Group"
                          {...getFieldProps('facility')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingFacility ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                          error={Boolean(touched.facilitiesId && errors.facilitiesId)}
                          helperText={touched.facilitiesId && errors.facilitiesId}
                        />
                      )}
                    />
                  </div>
                </Stack>
              )}
              {selectOption == 'audience' && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      width: '100%'
                    }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Location
                    </div>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <div style={{ width: '100%' }}>
                        <Autocomplete
                          fullWidth
                          multiple
                          limitTags={3}
                          disabled={!facilityID ?? false}
                          disableCloseOnSelect
                          id="asynchronous-demo"
                          open={openRoom}
                          onOpen={() => {
                            setOpenRoom(true);
                          }}
                          onChange={(event, newValue, reason) => {
                            if (reason === 'selectOption') {
                              setDefaultoptRooms(newValue);
                              setFieldValue('roomsId', newValue ?? '');
                              setRoomIds(newValue);
                            } else if (reason === 'removeOption') {
                              setDefaultoptRooms(newValue);
                              setFieldValue('roomsId', newValue ?? '');
                              setRoomIds(newValue);
                            } else if (reason === 'clear') {
                              setRoomIds([]);
                              setFieldValue('roomsId', []);
                              setDefaultoptRooms([]);
                            }
                            setCheckAll(false);
                            setFieldValue('residentsId', []);
                            setCheckAllResi(false);
                            setDefaultopt([]);
                          }}
                          isOptionEqualToValue={(option, value) => option.title === value.title}
                          getOptionSelected={(option, value) => value.value === option.value}
                          options={roomOptions}
                          value={defaultOptionsRooms}
                          loading={loadingRooms}
                          PopperComponent={(param) => (
                            <PopperStyledComponent {...param}>
                              <Box {...param} />
                              <Divider />
                              <Box
                                sx={{
                                  backgroundColor: 'white',
                                  height: '45px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                <Checkbox
                                  checked={checkAll}
                                  onChange={checkAllChange}
                                  id="check-all"
                                  sx={{ marginRight: '8px' }}
                                  onMouseDown={(e) => e.preventDefault()}
                                />
                                Select All
                              </Box>
                            </PopperStyledComponent>
                          )}
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Select Location"
                              {...getFieldProps('room')}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingRooms ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                              error={Boolean(touched.roomsId && errors.roomsId)}
                              helperText={touched.roomsId && errors.roomsId}
                            />
                          )}
                        />
                      </div>
                    </ClickAwayListener>
                  </div>
                </Stack>
              )}
              {selectOption == 'audience' && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      width: '100%'
                    }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Clients
                    </div>
                    <ClickAwayListener onClickAway={handleClickAwayResi}>
                      <div style={{ width: '100%' }}>
                        <Autocomplete
                          fullWidth
                          disableCloseOnSelect
                          multiple
                          limitTags={3}
                          disabled={IDROOM.length === 0 ?? false}
                          id="asynchronous-demo"
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onChange={(event, newValue, reason) => {
                            if (reason === 'selectOption') {
                              if (IsGroupContent) {
                                console.log("group content")
                                setFieldValue('residentsId', newValue ?? '');
                                setDefaultopt(newValue);
                              } else {
                                console.log("not group content ",[newValue[newValue.length - 1]])
                                setFieldValue('residentsId', [newValue[newValue.length - 1]]);
                                setDefaultopt([newValue[newValue.length - 1]]);
                              }
                            } else if (reason === 'removeOption') {
                              setCheckAllResi(false);
                              setFieldValue('residentsId', newValue ?? '');
                              setDefaultopt(newValue);
                            } else if (reason === 'clear') {
                              setDefaultopt([]);
                              setCheckAllResi(false);
                              setFieldValue('residentsId', []);
                            }
                            setCheckAllResi(false);
                          }}
                          isOptionEqualToValue={(option, value) => option.title === value.title}
                          getOptionSelected={(option, value) => value.value === option.value}
                          options={options}
                          value={defaultOptions}
                          loading={loading}
                          getOptionDisabled={
                            IsGroupContent
                              ? (option) => option.isAllow === false || option.deletedAt != null
                              : (option) => (option.deletedAt != null ? true : false)
                          }
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{
                                opacity: IsGroupContent
                                  ? option.isAllow && option.deletedAt == null
                                    ? 1
                                    : 0.5
                                  : option.deletedAt != null
                                  ? 0.5
                                  : 1
                              }}
                            >
                              {option.title}
                            </li>
                          )}
                          PopperComponent={(param) => (
                            <PopperStyledComponent {...param}>
                              <Box {...param} />
                              <Divider />
                              <Box
                                sx={{
                                  backgroundColor: 'white',
                                  height: '45px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                <Checkbox
                                  checked={checkAllResi}
                                  onChange={checkAllChangeResi}
                                  id="check-all"
                                  sx={{ marginRight: '8px' }}
                                  onMouseDown={(e) => e.preventDefault()}
                                />
                                Select All
                              </Box>
                            </PopperStyledComponent>
                          )}
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              // label="Select Clients"
                              {...getFieldProps('resident')}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                              error={Boolean(touched.residentsId && errors.residentsId)}
                              helperText={touched.residentsId && errors.residentsId}
                            />
                          )}
                        />
                      </div>
                    </ClickAwayListener>
                  </div>
                </Stack>
              )}
              {selectOption == 'add' && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                  <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                    Description
                  </div>
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={20}
                    // label="Description"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Box>
              )}
              
              {selectOption == 'add' && (
                <>
                  {true && (
                    <div className="grid">
                      {files.length > 0 &&
                        files.map((item, index) => {
                          return (
                            <>
                              {!(
                                (item.type != 'image' && item.type != 'video') ||
                              item.name &&  item.name.endsWith('.avi') ||
                              item.name &&  item.name.endsWith('.mpeg')
                              ) && (
                                <div
                                  key={item.id}
                                  style={{ position: 'relative' }}
                                  className={
                                    item === images[0] && images.length > 7
                                      ? 'firstIndex'
                                      : 'allIndex'
                                  }
                                >
                                  {' '}
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '-3px',
                                      right: '-3px',
                                      zIndex: 100
                                    }}
                                  >
                                    <Icon
                                      icon="ci:close-big"
                                      onClick={() => deleteFile(index)}
                                      style={{
                                        cursor: 'pointer',
                                        backgroundColor: 'white',
                                        borderRadius: '100%',
                                        outline: '1px',
                                        outlineColor: '#9A76F5'
                                      }}
                                    />
                                  </div>
                                  <Card>
                                  <ShopProductCard img={item} Index={index} images={images} />
                                  </Card>
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  )}
                  {true && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                      {files.length > 0 &&
                        files.map((item, index) => {
                          return (
                            <>
                              {((item.type != 'image' && item.type != 'video') ||
                              item.name &&  item.name.endsWith('.avi') ||
                              item.name &&  item.name.endsWith('.mpeg')) && (
                                <Box
                                  sx={{
                                    maxWidth: '250px',
                                    width: 'full',
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Icon
                                    icon="ci:close-big"
                                    onClick={() => deleteFile(index)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <Box
                                    sx={{
                                      maxWidth: '50px',
                                      maxHeight: '60px',
                                      minWidth: '50px',
                                      minHeight: '60px',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    <img
                                      src={item.thumbnail}
                                      style={{ width: '100%', object: 'contain', height: '100%' }}
                                    />
                                  </Box>
                                  <div style={{ fontSize: '12px', fontWeight: 500 }}>
                                    {item.name && item.name.length > 25
                                      ? item.name.slice(0, 22) + '...'
                                      : item.name}
                                    {' ('}
                                    {item.size ?? ''}
                                    {' KB)'}
                                  </div>
                                </Box>
                              )}
                            </>
                          );
                        })}
                    </Box>
                  )}
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button variant="contained" component="label">
                      Add Photos
                      <input
                        type="file"
                        hidden
                        multiple
                        fullWidth
                         accept="image/*, video/*,  .heic"
                        onChange={handleFileUpload}
                        error={Boolean(touched.photo && errors.photo)}
                        helperText={touched.photo && errors.photo}
                      />
                    </Button>
                  </Stack>
                </>
              )}
              {/* {selectOption == 'schedule' && (
                <Box
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#9A76F5',
                    paddingY: '25px',
                    textAlign: 'center'
                  }}
                >
                  Schedule your content in advance - coming soon!
                </Box>
              )} */}

              {/* ************************************************************************************************************************* */}

              {/* ------------------------------------------------------ */}

              {/* <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={() => setpreviewError(true)}
              >
                Preview
              </LoadingButton> */}

              {/* {type ? (
                <>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="outlined"
                      loading={draftLoading}
                      onClick={() => setDraft(true)}
                    >
                      Save Drafts
                    </LoadingButton>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                    >
                      Add Notice
                    </LoadingButton>
                  </Stack>
                </>
              ) : (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="outlined"
                    loading={draftLoading}
                    onClick={() => setDraft(true)}
                  >
                    Save Drafts
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                  >
                    Update Notice
                  </LoadingButton>
                </Stack>
              )} */}
              {(selectOption == 'add' ||
                selectOption == 'audience' ||
                selectOption == 'schedule') && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="outlined"
                    loading={draftLoading}
                    onClick={() => setDraft(true)}
                  >
                    Save drafts
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    size="large"
                    // type="submit"
                    onClick={() => {
                      if (selectOption == 'add') {
                        if (!getFieldProps('title').value) {
                          setFieldTouched('title');
                        }
                        if (!getFieldProps('description').value) {
                          setFieldTouched('description');
                        }
                        if (getFieldProps('title').value && getFieldProps('description').value) {
                          setSelectOption('audience');
                        }
                      } else if (selectOption == 'audience') {
                        if (!getFieldProps('facilityName').value) {
                          setFieldTouched('facilityName');
                        }
                        if (!getFieldProps('roomsId').value) {
                          setFieldTouched('roomsId');
                        }
                        if (!getFieldProps('residentsId').value) {
                          setFieldTouched('residentsId');
                        }
                        if (
                          getFieldProps('facilityName').value &&
                          getFieldProps('roomsId').value &&
                          getFieldProps('residentsId').value
                        ) {
                          setSelectOption('schedule');
                        }
                      } else if (selectOption == 'schedule') {
                        setSelectOption('preview');
                      }
                    }}
                    variant="contained"
                    loading={isLoading}
                  >
                    {selectOption == 'add' ? 'Choose audience' : selectOption == 'audience'? 'Schedule':"Preview"}
                  </LoadingButton>
                </Stack>
              )}
            </Stack>
          </Form>
        </FormikProvider>
      </Card>
      )}
    </Box>
  );
}
