import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import UpdateForm from '../components/authentication/register/UpdateForm';
import UpdatePassword from '../components/authentication/forgot/UpdatePassword';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------
export default function ChangePassword() {
  const location = useLocation();
  const id = location?.state;
  return (
    <RootStyle title="Change Password | Minimal-UI">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {id ? 'Update Password' : 'Change Password'}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {id ? (
                <span>
                  Please type new password for <b>{id?.email}</b>
                </span>
              ) : (
                'Please type new password below'
              )}
            </Typography>
          </Box>

          <UpdatePassword id={id?.id} type={id?.type} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
