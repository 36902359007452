import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import RoomForm from '../../components/_dashboard/room/RoomForm';
import FacilityMembersForm from '../../components/_dashboard/FaciltiyMembers/FacilityMembersForm';
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { RegisterForm } from '../../components/authentication/register';
import ResidentForm from '../../components/_dashboard/resident/ResidentForm';
import EventForm from '../../components/_dashboard/event/EventForm';
import React, {useState} from 'react';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px',
  padding: theme.spacing(0, 0)
}));

// --------------------------  --------------------------------------------

export default function AddEvent() {
  const [selectOption, setSelectOption] = useState('add');

  return (
    <RootStyle title="Add Event | Rel8">
     
        <ContentStyle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%'
          }}
        >
          <div
            onClick={() => {
              
                setSelectOption('add');
              
            }}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'add' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'add' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
            Add Event
          </div>
          <div
            onClick={() => {
              if (selectOption == 'schedule' || selectOption == 'preview' ) {
                setSelectOption('audience');
              }
            }}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'audience' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'audience' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
            Audience
          </div>
          <div
             onClick={() => {
              if (selectOption == 'preview') {
                setSelectOption('schedule');
              }
            }}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'schedule' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'schedule' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
           Schedule
          </div>
          <div
            onClick={() => {}}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'preview' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'preview' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
            Preview
          </div>
        </Box>

          <EventForm selectOption={selectOption} setSelectOption={setSelectOption}/>

         
        </ContentStyle>
     
    </RootStyle>
  );
}
