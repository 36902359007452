import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  uploadString
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

export const uploadFile = async (collectionName, eventsRef, image, mediaType, mediaId) => {
  const storage = getStorage();
  mediaId = uuidv4();
  console.log("type: ", mediaType)

  let storageRef = ref(storage, `${collectionName}/${eventsRef.id}/file/${mediaId}`);
  let metadata = {
    contentType: image.name && image.name.endsWith('.heic')? "image/heic" :image.type
  };
  if (mediaType === 'video') {
    console.log("mpeg ")
    storageRef = ref(storage, `${collectionName}/${eventsRef.id}/${mediaType}/${mediaId}`);
    metadata = {
      contentType: image.type
    };
  }
  if (mediaType === 'thumbnail') {
    storageRef = ref(storage, `${collectionName}/${eventsRef.id}/${mediaType}/${mediaId}`);
    metadata = {
      contentType: image.type
    };
    const uploadTask = await uploadString(storageRef, image, 'data_url');
    const url = await getDownloadURL(uploadTask.ref);
    return url;
  }
  if (mediaType === 'image') {
    console.log("image ", image)
    storageRef = ref(storage, `${collectionName}/${eventsRef.id}/${mediaType}/${mediaId}`);
    metadata = {
      contentType: image.type
    };
  }
  const uploadTask = await uploadBytesResumable(storageRef, image, metadata);
  const url = await getDownloadURL(uploadTask.ref);
  return url;
};
