import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
import duplicate from '@iconify/icons-eva/file-add-outline';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  where,
  query,
  deleteField,
  updateDoc
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import Swal from 'sweetalert2';
import axios from 'axios';
import { db } from '../../../App';
import { deleteUser } from '../../../pages/facilityMember/User';
import { cloudFuncBaseUrl } from 'src/utils/config';
// ----------------------------------------------------------------------

export default function WellnessMoreMenu({props, dots}) {
  const refs = useRef(null);
  
  // console.log(id);
  const user = JSON.parse(localStorage.getItem('Rel8User')).role;

  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <>
      <IconButton ref={refs} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={refs.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: '1', marginTop: dots ? '30px' : '0px' }}
      >
        {user !== 'Family Member' && (
          <>
            <MenuItem
              sx={{ color: 'text.secondary' }}
              component={RouterLink}
              to="/home/profile/update"
              state={{ props }}
            >
              <ListItemIcon>
                <Icon icon={editFill} width={24} height={24} />
              </ListItemIcon>
              
              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
           
            <MenuItem
              sx={{ color: 'text.secondary' }}
              component={RouterLink}
              to="/home/change-password"
              state={{ props }}
            >
              <ListItemIcon>
                <Icon icon={duplicate} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Update Password" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </>
        )}
       

        
      </Menu>
    </>
  );
}
