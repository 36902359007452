import { filter, forEach, result } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// components

import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  where,
  query,
  deleteField,
  updateDoc,
  onSnapshot
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
//
import { db } from '../../App';
import { LightBox } from '../../components/_dashboard/user/LightBox';
import DeletedTimeFamily from '../../components/_dashboard/FamilyMember/DeletedTimeFamily';
import { cloudFuncBaseUrl } from 'src/utils/config';

import repeatOutline from '@iconify/icons-eva/repeat-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { deleteUserPermanently } from 'src/components/_dashboard/user/FamilyMoreMenu';
import { localUser } from '../../App';
// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'facilityIds', label: 'Groups', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'createdAt', label: 'Date Added', alignRight: false },
  { id: 'restrictAt', label: 'Archive Period', alignRight: false }
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//* **************Add Users From Firebase**********************
export const addUser = (name, email, contact, location, facilityName) => {
  const data = {
    name,
    email,
    contact,
    location,
    facilityName
  };
  return axios.post('https://rel8-cloud-functions.herokuapp.com/addFacility', data);
};

//* **************Delete Users From Firebase**********************
export const deleteUser = (id, avatarUrl) =>
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteDoc(doc(db, 'facilites', id))
        .then((res) => window.location.reload(false))
        .catch((err) => console.log(err.message));

      const membersRef = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
      const residentsRef = query(collection(db, 'residents'), where('facilityId', '==', id));
      getDocs(membersRef).then((res) => {
        res.forEach((result) => {
          const facilityMemberRef = doc(db, 'facilityMembers', result.data().id);
          updateDoc(facilityMemberRef, {
            facilityId: deleteField(),
            facilityName: deleteField()
          });
        });
      });
      getDocs(residentsRef).then((res) => {
        res.forEach((result) => {
          const residentRef = doc(db, 'residents', result.data().id);
          updateDoc(residentRef, {
            facilityId: deleteField(),
            facilityName: deleteField()
          });
        });
      });
      const storage = getStorage();

      // Create a reference to the file to delete
      const desertRef = ref(storage, avatarUrl);

      // Delete the file
      deleteObject(desertRef)
        .then(() => {
          Swal.fire('Deleted!', 'Facility has been deleted.', 'success');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

//* **************Delete Users From Firebase**********************
export const listUserDelete = (ids) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      for (let i = 0; i < ids.length; i += 1) {
        deleteDoc(doc(db, 'facilityMembers', ids[i]))
          .then((res) => {
            if (i === ids.length - 1) {
              window.location.reload(false);
            }
          })
          .catch((err) => console.log(err.message));
      }
      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });
};

//* **************Update Users From Firebase**********************
export const updateUser = (id, name, contact, location, capacity, image) => {
  const storage = getStorage();
  const storageRef = ref(storage, `facilities/${id}/facilityImgUrl`);
  const metadata = {
    contentType: 'image/jpeg'
  };
  const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');
  if (RgExp.test(image)) {
    return setDoc(
      doc(db, 'facilites', id),
      {
        id,
        name,
        contact,
        location,
        capacity
      },
      { merge: true }
    ).then(() => {
      const membersRef = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
      const residentsRef = query(collection(db, 'residents'), where('facilityId', '==', id));
      getDocs(membersRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'facilityMembers', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      getDocs(residentsRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'residents', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Facility has been updated.',
        showConfirmButton: false,
        timer: 1500
      });
    });
  }
  const uploadTask = uploadBytesResumable(storageRef, image, metadata);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(`Upload is ${progress}% done`);
    },
    (error) => NotificationManager.error(error.message),
    async () => {
      const avatarUrl = await getDownloadURL(uploadTask.snapshot.ref);
      setDoc(
        doc(db, 'facilites', id),
        {
          id,
          name,
          contact,
          location,
          capacity,
          avatarUrl
        },
        { merge: true }
      ).then(() => {
        const membersRef = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
        const residentsRef = query(collection(db, 'residents'), where('facilityId', '==', id));
        getDocs(membersRef).then((res) => {
          res.forEach((result) => {
            setDoc(
              doc(db, 'facilityMembers', result.data().id),
              {
                facilityName: name
              },
              { merge: true }
            );
          });
        });
        getDocs(residentsRef).then((res) => {
          res.forEach((result) => {
            setDoc(
              doc(db, 'residents', result.data().id),
              {
                facilityName: name
              },
              { merge: true }
            );
          });
        });
        Swal.fire('Upated!', 'Facility has been updated.', 'success');
      });
    }
  );
};

//* **************Delete Users From Firebase**********************
//* **************Delete Users From Firebase**********************
//* **************Delete Users From Firebase**********************
export const restoreEvent = (data) => {
  console.log(data.id);
  console.log(data);
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to be restore this User?',
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, restore it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      // ----------------------------------------------Loading-------------
      let timerInterval;
      Swal.fire({
        title: 'Processing...',
        html: 'I will close in few<b></b> Moments.',
        // timer: 2000,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer');
        }
      });
      // ----------------------------------------------Loading---------------------
      await updateDoc(doc(db, 'users', data?.id), {
        restrictAt: 0,
        deletedAt: null
      });
      // ***************************************************
      //           Chat user restrict update in chat
      // ***************************************************
      const getChatUser = query(
        collection(db, 'chat'),
        where('isGroup', '==', false),
        where('userIds', 'array-contains', data?.id)
      );

      const chatU = await getDocs(getChatUser);

      chatU?.forEach(async (chat) => {
        await updateDoc(doc(db, 'chat', chat.data()?.threadId), {
          restricted: false
        });
      });

      // ------------------------
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User has been restored.',
        showConfirmButton: false,
        timer: 1500
      });
      // window.location.reload(false);
    }
  });
};

// ---------------------------------------------------------------------
//                       restoreDeletedUser
// ---------------------------------------------------------------------
//* **************Delete Users From Firebase**********************
export const restoreDeletedUser = (data) => {
  console.log(data.id);
  console.log(data);
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to be restore this User?',
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, restore it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      // ----------------------------------------------Loading-------------
      let timerInterval;
      Swal.fire({
        title: 'Processing...',
        html: 'I will close in few<b></b> Moments.',
        // timer: 2000,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer');
        }
      });
      // ----------------------------------------------Loading---------------------
      try {
        const res = await axios({
          method: 'post',
          url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteUserAfter3Restriction`,
          data: {
            id: data?.id,
            action: 'restore'
          }
        });
        // ***************************************************
        //           Chat user restrict update in chat
        // ***************************************************
        const getChatUser = query(
          collection(db, 'chat'),
          where('isGroup', '==', false),
          where('userIds', 'array-contains', data?.id)
        );

        const chatU = await getDocs(getChatUser);

        chatU?.forEach(async (chat) => {
          await updateDoc(doc(db, 'chat', chat.data()?.threadId), {
            restricted: false
          });
        });
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.message
        });
      }
      // ------------------------
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User has been restored.',
        showConfirmButton: false,
        timer: 1500
      });
      window.location.reload(false);
    }
  });
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function DeletedUsers() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  //* **************Get deleted Users From Firebase**********************

   let userCollection;
  if (localUser?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'users'),
      where('deletedAt', '!=', null),
      where('restrictAt', '==', 4),
      where('facilityIds', 'array-contains-any', localUser?.facilityIds)
    );
  } else if (localUser?.role === 'admin') {
    userCollection = query(
      collection(db, 'users'),
      where('deletedAt', '!=', null),
      where('restrictAt', '==', 4)
    );
  }

  // const userCollection = query(
  //   collection(db, 'users'),
  //   where('deletedAt', '!=', null),
  //   where('restrictAt', '==', 4)
  // );
  // const userCollection = collection(db, 'recycleBin', 'users', 'users');
  const users = [];
  const getUsers = async () => {
    // getDocs(userCollection).then((res) => {
    onSnapshot(userCollection, (res) => {
      res.forEach((result) => {
        if (result.data().email !== 'admin@admin.com') {
          // if (result.data()?.restrictAt === 3) {
          users.push({
            id: result.data().id,
            avatarUrl: result.data().avatarUrl,
            email: result.data().email,
            facilityName: result.data().facilityName ?? null,
            facilityId: result.data().facilityIds ?? null,
            createdAt: result.data().createdAt,
            firstname: result.data().firstname,
            lastname: result.data().lastname,
            role: result.data().role,
            status: result.data().status ?? null,
            residentIds: result.data()?.residentIds ?? [],
            restrictAt: result.data()?.restrictAt ?? 0,
            restrictTime: result.data()?.restrictTime ?? 0,
            deletedAt: result.data()?.deletedAt
          });
          // }
        }
      });
      if (!users.length) {
        return setNotFound(true);
      }
      setUSERLIST(users);
    });
  };

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    return setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );

  // -------------------------------------------------------------
  async function deleteUserPermanently(id) {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to be delete this user!',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // ----------------------------------------------Loading-------------
          let timerInterval;
          Swal.fire({
            title: 'Processing...',
            html: 'I will close in few<b></b> Moments.',
            // timer: 2000,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });
          // ----------------------------------------------Loading---------------------
          const res = await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteUserWithAuth`,
            data: {
              userId: id
            }
          });
          console.log(res);
          console.log(res?.data);

          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User delete successfully.',
            showConfirmButton: false,
            timer: 1500
          });
          window.location.reload(false);
        }
      });
    } catch (err) {
      return Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.message
      });
    }
  }
  // -------------------------------------------------------------

  return (
    <Page title="Facility Members | Rel8">
      <Container>
        <Typography variant="h4" gutterBottom>
          Deleted users
        </Typography>
        <Card>
          <UserListToolbar
          USERLIST={[]}
          localuser={{}}
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  deletedTab={true}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        firstname,
                        facilityId,
                        createdAt,
                        avatarUrl,
                        facilityName,
                        email,
                        lastname,
                        role,
                        status,
                        residentIds,
                        restrictAt,
                        restrictTime,
                        deletedAt
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          location="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            /> */}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="2px" width="25%">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {avatarUrl ? (
                                <LightBox src={avatarUrl} alt={firstname}>
                                  <Avatar
                                    alt={firstname}
                                    src={avatarUrl}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </LightBox>
                              ) : (
                                <Avatar alt={firstname} src={avatarUrl} />
                              )}
                              <Typography variant="subtitle2">{firstname?.slice(0, 13)}</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{lastname?.slice(0, 13)}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell>
                            {facilityId?.length}
                            {/* {facilityName || (
                              <Label variant="ghost" color="error">
                                Unassigned
                              </Label>
                            )} */}
                          </TableCell>
                          <TableCell align="left">{role}</TableCell>
                          {/* <TableCell align="left">{createdAt.toDate().toDateString()}</TableCell> */}
                          <TableCell align="left">
                            {moment(createdAt?.toDate()).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color="error">
                              {/* {DeletedTimeFamily(deletedAt, restrictAt)}-remaining */}
                              {DeletedTimeFamily(deletedAt, 2)}-remaining
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <Stack spacing={2} direction={{ xs: 'row', sm: 'row' }}>
                              <Tooltip title="Restore User" placement="top">
                                <LoadingButton
                                  size="small"
                                  variant="contained"
                                  //   loading={isLoading}
                                  onClick={() => {
                                    restoreDeletedUser(row);
                                  }}
                                  disabled={
                                    JSON.parse(localStorage.getItem('Rel8User')).role ===
                                      'Family Member' ?? false
                                  }
                                >
                                  {/* Restore */}
                                  <Icon icon={repeatOutline} style={{ fontSize: '20px' }} />
                                </LoadingButton>
                              </Tooltip>
                              <Tooltip title="Delete User" placement="top">
                                <LoadingButton
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    deleteUserPermanently(row?.id);
                                  }}
                                  disabled={
                                    JSON.parse(localStorage.getItem('Rel8User')).role ===
                                      'Family Member' ?? false
                                  }
                                >
                                  <Icon icon={trash2Outline} style={{ fontSize: '20px' }} />
                                </LoadingButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>

                          {/* <TableCell align="right">
                            <MemberMoreMenu
                              id={id}
                              facilityName={facilityName}
                              facilityId={facilityId}
                              email={email}
                              firstname={firstname}
                              lastname={lastname}
                              createdAt={date}
                              avatarUrl={avatarUrl ?? null}
                              role={role}
                              status={status ?? null}
                            />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound &&
                  (NotFound ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <UserNotFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
