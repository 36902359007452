import moment from 'moment';
import React from 'react';

function SingleLineDateFunc(messageDate, oldDate) {
  const date = new Date();
  date.setDate(date.getDate() - 1);

  if (moment(messageDate.toLocaleDateString()).isSame(oldDate.toLocaleDateString()) === false) {
    if (moment(new Date().toLocaleDateString()).diff(messageDate.toLocaleDateString()) === 0) {
      return 'Today';
    }

    if (moment(date.toLocaleDateString()).diff(messageDate.toLocaleDateString()) === 0) {
      return 'Yesterday';
    }

    return moment(messageDate).format('DD MMMM YYYY');
  }
}

export default SingleLineDateFunc;
