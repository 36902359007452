import { filter, forEach, result } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// components

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDocm,
  where,
  query,
  onSnapshot
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import copyOutline from '@iconify/icons-eva/copy-outline';

import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
// import { LightBox } from '../../components/_dashboard/user/LightBox';
//
import { db, localUser } from '../../App';
import RoomMoreMenu from '../../components/_dashboard/user/RoomMoreMenu';

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'heading', label: 'Heading', alignRight: false },
  //   { id: 'email', label: 'Email', alignRight: false },
  // { id: 'location', label: 'Location', alignRight: false },
  // { id: 'capacity', label: 'Resident Capacity', alignRight: false },
  { id: 'residents', label: 'Clients', alignRight: false },
  { id: 'facilityName', label: 'Group', alignRight: false },
  { id: 'id', label: 'Location ID', alignRight: false },
  { id: 'createdAt', label: 'Date', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//* **************Add Users From Firebase**********************
export const addFacility = (name, heading, location, capacity, avatarUrl) => {
  console.log(capacity);
  const facilityRef = doc(collection(db, 'facilites'));
  return setDoc(facilityRef, {
    id: facilityRef.id,
    name,
    heading,
    location,
    capacity,
    avatarUrl,
    createdAt: new Date()
  });
};

//* **************Delete Users From Firebase**********************
export const deleteUser = (id, email) =>
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      // deleteDoc(doc(db, 'facilityMembers', id))
      //   .then((res) => window.location.reload(false))
      //   .catch((err) => console.log(err.message));
      axios
        .post('https://rel8-cloud-functions.herokuapp.com/deleteUser', { uid: id, email })
        .then((res) => window.location.reload(false))
        .catch((err) => console.log(err.message));
      axios.post('https://rel8-cloud-functions.herokuapp.com/deleteUser', { uid: id, email });

      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });

//* **************Delete Users From Firebase**********************
export const listUserDelete = (ids) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      for (let i = 0; i < ids.length; i += 1) {
        deleteDoc(doc(db, 'facilityMembers', ids[i]))
          .then((res) => {
            if (i === ids.length - 1) {
              window.location.reload(false);
            }
          })
          .catch((err) => console.log(err.message));
      }
      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });
};

//* **************Update Users From Firebase**********************
export const updateUser = (id, name, heading, location, capacity, email) => {
  console.log(location);
  setDoc(
    doc(db, 'facilityMembers', id),
    {
      id,
      name,
      heading,
      location,
      capacity,
      email
    },
    { merge: true }
  )
    .then(() => Swal.fire('Upated!', 'Facility member has been updated.', 'success'))
    .catch((err) => console.log(err.message));
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Rooms() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);

  const [getResident, setgetResident] = useState([]);

  const [copied, setcopied] = useState(false);

  // ------for lightbox
  const [image, setImage] = useState('');

  const navigate = useNavigate();
  // const getMembersSize = (id) => {
  //   let sizes;
  //   const date = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
  //   return getDocs(date).then((res) => res.size);
  // };

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Facilites From Firebase**********************

  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(collection(db, 'rooms'), where('facilityId', 'in', user?.facilityIds));
  } else if (user?.role === 'admin') {
    userCollection = collection(db, 'rooms');
  } else if (user?.role === 'Family Member') {
    userCollection = query(collection(db, 'rooms'), where('facilityId', 'in', user?.facilityIds));
  }

  // const userCollection = collection(db, 'rooms');

  let users = [];
  function getUsers() {
    onSnapshot(userCollection, (snapshot) => {
      users = [];
      snapshot.docs.forEach((result) => {
        if (result.data().email !== 'admin@admin.com') {
          users.push({
            id: result.data().id,
            avatarUrl: result.data().avatarUrl,
            name: result.data().name,
            heading: result.data().heading,
            createdAt: result.data().createdAt,
            facilityName: result.data().facilityName,
            facilityId: result.data().facilityId
          });
        }
      });
      if (!users.length) {
        return setNotFound(true);
      }
      setUSERLIST(users);
    });
  }

  // -----------------------------Get all residents-------------------------
  function getResidentFunc() {
    const getResidentRef = collection(db, 'residents');
    onSnapshot(getResidentRef, (snapshot) => {
      const residentArr = [];
      snapshot.docs?.forEach((res) => {
        residentArr.push({ id: res.data()?.id, roomId: res.data()?.roomId });
      });
      setgetResident(residentArr);
    });
  }
  // -----------------------------End Get all residents-------------------------

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    getResidentFunc();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );

  // ---------------------Copy text to clip board------------------------------------
  function copyToClipboard(str) {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setcopied(true);
      return navigator.clipboard.writeText(str);
    }
  }

  // ---------------------------------------------------------

  // -------------------filter resident-----------
  function roomResidentFunc(id) {
    const residentIDs = getResident?.filter((e) => e?.roomId === id);
    return residentIDs?.length ?? 0;
  }
  // -------------------filter resident-----------

  return (
    <Page title="Rooms | Rel8">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Locations
          </Typography>
          {localUser.role !== 'Family Member' && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/organisation/locations/add-room"
              startIcon={<Icon icon={plusFill} />}
            >
              Add Location
            </Button>
          )}
        </Stack>

        <Card>
          <UserListToolbar
           USERLIST={[]}
           localuser={{}}
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            collect="rooms"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  deletedTab={localUser.role === 'Family Member' && true}
                  // deletedTab={true}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, avatarUrl, createdAt, heading, facilityId, facilityName } =
                        row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          location="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {localUser.role !== 'Family Member' && (
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="2px">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {avatarUrl ? (
                                // <LightBox src={avatarUrl} alt={heading}>
                                <Avatar
                                  alt={heading}
                                  src={avatarUrl}
                                  style={{ cursor: 'pointer', backgroundColor: '#9A76F5' }}
                                  onClick={() => {
                                    setImage(avatarUrl);
                                  }}
                                />
                              ) : (
                                // </LightBox>
                                <Avatar
                                  alt={name}
                                  src={avatarUrl}
                                  style={{ backgroundColor: '#9A76F5' }}
                                />
                              )}
                              <Typography
                                variant="subtitle2"
                                component={RouterLink}
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                  color: '#212B36',
                                  fontWeight: 'bold'
                                }}
                                to="/administration/clients"
                                state={{
                                  roomId: id,
                                  name,
                                  facilityId: null
                                }}
                              >
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">{roomResidentFunc(id)}</TableCell>
                          <TableCell>
                            {facilityName || (
                              <Label variant="ghost" color="error">
                                Unassigned
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                              }}
                            >
                              <div style={{ marginRight: 5, fontSize: 11 }}>{id?.slice(0, 20)}</div>
                              <Tooltip title={copied ? 'copied' : 'copy'} placement="top">
                                <Icon
                                  icon={copyOutline}
                                  style={{ fontSize: 22, cursor: 'pointer', color: '#9a76f5' }}
                                  onClick={() => copyToClipboard(id)}
                                  onMouseLeave={() => setcopied(false)}
                                />
                              </Tooltip>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            {moment(createdAt.toDate()).format('DD/MM/YYYY')}
                          </TableCell>

                          <TableCell align="left">
                            {selected.length === 0 && (
                              <RoomMoreMenu
                                id={id}
                                name={name}
                                heading={heading}
                                facilityName={facilityName}
                                facilityId={facilityId}
                                avatarUrl={avatarUrl}
                                createdAt={createdAt}
                                type="room"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound &&
                  (NotFound ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <UserNotFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Page>
  );
}
