import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  Autocomplete,
  CircularProgress,
  Button,
  Grid,
  Card,
  Box,
  styled,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { Icon } from '@iconify/react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { collection, getDocs, query, where, doc, updateDoc, setDoc } from 'firebase/firestore';
import axios from 'axios';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { db } from '../../../App';
import { subRoleFamily } from '../../subRole/SubRole';
import { deleteFolder } from '../user/deleteFiles';
import SortByTitle from '../event/SortByTitle';
import { cloudFuncBaseUrl } from 'src/utils/config';

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');

// ----------------------------------------------------------------------
const addFamilyMemberAuth = (email, residentIds, firstname, lastname) => {
  const data = {
    email,
    residentIds,
    firstname,
    lastname
  };
  return axios.post(`${cloudFuncBaseUrl}.cloudfunctions.net/addFamilyMember`, data);
};

export default function UpdateFamilyMemberForm({ props }) {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location?.state);
  // console.log(props);
  // const { id, firstname, lastname, residentIds, email, avatarUrl, subRole } = props;
  const { id, firstname, lastname, residentIds, email, avatarUrl, subRole } = location?.state;

  const oldEmail = email;

  const residentIdsOld = residentIds;

  const [deleteImage, setDeleteImage] = useState('');

  const [image, setImage] = useState(avatarUrl);
  const [imageState, setImageState] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [Defaultoptions, setDefaultOptions] = useState([]);
  const loading = open && options.length === 0;
  // useEffect(async () => {
  //   const facilitesCollection = query(collection(db, 'residents'), where('id', 'in', residentIds));
  //   const facilityPromise = await getDocs(facilitesCollection);
  //   const opt = [];
  //   facilityPromise?.forEach((result) => {
  //     opt.push({
  //       value: result.data().id,
  //       title: `${result.data().firstname} ${result.data().lastname}`
  //     });
  //   });
  //   setDefaultOptions(opt);s
  // }, []);
  // --------------------------------------start for residents ---------------------
  useEffect(async () => {
    const residentsCollection = query(collection(db, 'residents'), where('id', 'in', residentIds));
    const residentsPromise = await getDocs(residentsCollection);
    const opt = [];
    residentsPromise.forEach((result) => {
      opt.push({
        value: result.data().id,
        title: `${result.data().firstName} ${result.data().lastName}`,
        facility: result.data()?.facilityId
      });
    });
    setDefaultOptions(opt);
  }, []);
  // --------------------------------------end for residents ---------------------

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const facilitesCollection = collection(db, 'residents');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise.forEach((result) => {
        console.log(result.data());
        opt.push({
          value: result.data().id,
          title: `${result.data().firstName} ${result.data().lastName}`,
          facility: result.data()?.facilityId
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    residentIds: Yup.array().required('Resident is required'),
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    subRole: Yup.number().required('Role is required')
  });

  const formik = useFormik({
    initialValues: {
      email,
      firstname,
      lastname,
      residentIds: Defaultoptions,
      subRole
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const email = getFieldProps('email').value;
        const residents = getFieldProps('residentIds').value;
        // const facilityName = getFieldProps('facilityName').value;
        const firstname = getFieldProps('firstname').value;
        const lastname = getFieldProps('lastname').value;
        const subRole = getFieldProps('subRole').value;

        // const residentIds = Defaultoptions.map((item) => item.value);
        const residentIds = [];
        const facilityId = [];

        Defaultoptions.forEach((element) => {
          residentIds.push(element.value);
          facilityId.push(element.facility);
        });
        // const facilityId = residentId.map((item) => item.facility);

        const facilityIds = [...new Set(facilityId)];

        let NewAvatarUrl;
        if (imageState) {
          const storage = getStorage();
          const storageRef = ref(storage, `users/${id}/profile`);
          const metadata = {
            contentType: 'image/jpeg'
          };
          const uploadTask = await uploadBytesResumable(storageRef, image, metadata);
          NewAvatarUrl = await getDownloadURL(uploadTask.ref);
        }

        if (email !== oldEmail) {
          const updatedEmail = await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateUsersPassword`,
            data: {
              id,
              email,
              type: 'email'
            }
          });
        }

        if (deleteImage) {
          await deleteFolder(deleteImage);
        }

        let deletedImg;
        if (deleteImage) {
          deletedImg = null;
        } else {
          deletedImg = avatarUrl;
        }

        const updatedUser = await axios({
          method: 'post',
          url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateUserEveryWhere`,
          data: {
            id,
            email,
            residentIds,
            facilityIds,
            firstname,
            lastname,
            avatarUrl: imageState ? NewAvatarUrl : deletedImg,
            subRole,
            jobTitle: '',
            role: 'Family Member'
          }
        });

        // check 2 arrays are same or not
        function checkArrays(arrA, arrB) {
          if (arrA.length !== arrB.length) return false;
          var cA = arrA.slice().sort().join(',');
          var cB = arrB.slice().sort().join(',');
          return cA === cB;
        }

        // console.log(checkArrays(residentIds, residentIdsOld));
        if (checkArrays(residentIds, residentIdsOld) === false) {
          setDoc(doc(db, 'users', id), { updatedAt: new Date() }, { merge: true });
        }

        console.log('response !! update user', updatedUser);

        // const eventsRef = doc(db, 'users', id);
        // updateDoc(eventsRef, {
        //   email,
        //   residentIds,
        //   facilityIds,
        //   firstname,
        //   lastname,
        //   // avatarUrl: imageState ? NewAvatarUrl : deleteImage ?? avatarUrl,
        //   avatarUrl: imageState ? NewAvatarUrl : deletedImg,
        //   subRole,
        //   updatedAt: new Date()
        //   // facilityIds
        // })
        //   .then((res) => {
        // // --------------update name
        // const commentRef = query(collection(db, 'comments'), where('writerId', '==', id));
        // const chatRef = query(collection(db, 'chat'), where('userIds', 'array-contains', id));

        // // for comments
        // getDocs(commentRef).then(async function (commentData) {
        //   commentData.forEach(async (comment) => {
        //     await updateDoc(doc(db, 'comments', comment.data()?.id), {
        //       writerName: `${firstname} ${lastname}`
        //     });
        //   });
        // });
        // // for chat
        // getDocs(chatRef).then(async function (chatData) {
        //   chatData?.forEach(async function (chat) {
        //     const user = chat
        //       .data()
        //       ?.users?.map((e) =>
        //         e.id === id ? { ...e, name: `${firstname} ${lastname}` } : e
        //       );
        //     await updateDoc(doc(db, 'chat', chat.data()?.threadId), {
        //       users: user
        //     });
        //   });
        // });

        // --------------update name

        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Family Member Updated Successfully',
          showConfirmButton: false,
          timer: 2000
        });
        navigate('/home/family-members', { replace: true });
        // })
        // .catch((err) => {
        //   setIsLoading(false);
        //   return NotificationManager.error(err.message);
        // });
      } catch (err) {
        console.log(err.message);
        return NotificationManager.error(err.message);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              label="First Name"
              {...getFieldProps('firstname')}
              error={Boolean(touched.firstname && errors.firstname)}
              helperText={touched.firstname && errors.firstname}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Last Name"
              {...getFieldProps('lastname')}
              error={Boolean(touched.lastname && errors.lastname)}
              helperText={touched.lastname && errors.lastname}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              // disabled
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Autocomplete
              fullWidth
              disableCloseOnSelect
              multiple
              limitTags={3}
              id="asynchronous-demo"
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(e, value) => {
                setDefaultOptions(value);
                setFieldValue('residentIds', value ?? '');
              }}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              getOptionSelected={(option, value) => value.value === option.value}
              getOptionLabel={(option) => option.title}
              options={options}
              value={Defaultoptions}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Select Clients"
                  {...getFieldProps('resident')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={Boolean(touched.residentIds && errors.residentIds)}
                  helperText={touched.residentIds && errors.residentIds}
                />
              )}
            />

            {/* -------------- for Sub Role -------------- */}
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              onChange={(e, value) => {
                console.log(value?.value);
                setFieldValue('subRole', value?.value);
              }}
              defaultValue={{ title: subRoleFamily[subRole]?.title, value: subRole }}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option.title}
              options={subRoleFamily}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Select Role"
                  {...getFieldProps('subRole')}
                  InputProps={{
                    ...params.InputProps
                  }}
                  error={Boolean(touched.subRole && errors.subRole)}
                  helperText={touched.subRole && errors.subRole}
                />
              )}
            />
          </Stack>
          {/* ----------------For photo ------------------- */}

          {/* ---------------- for profile ----------- */}
          <Grid container spacing={2}>
            {image && (
              <Grid item xs={4} sm={4} md={2}>
                <Icon
                  icon="ci:close-big"
                  onClick={() => {
                    setDeleteImage(image);
                    setImage('');
                    setImageState('');
                  }}
                  style={{ cursor: 'pointer' }}
                />
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle
                      alt="Image"
                      // src={image && RgExp.test(image) ? image : URL.createObjectURL(image)}
                      src={image && RgExp.test(image) ? image : URL.createObjectURL(imageState)}
                    />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label">
              Upload Profile
              <input
                fullWidth
                type="file"
                hidden
                onChange={(e) => {
                  console.log(e.target.files);
                  setImage(e.target.files[0]);
                  setImageState(e.target.files[0]);
                }}
                accept="image/*"
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>
          {/* ---------------- for profile ----------- */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Update Family
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
