import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
import duplicate from '@iconify/icons-eva/file-add-outline';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  where,
  query,
  deleteField,
  updateDoc
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL,
  listAll
} from 'firebase/storage';
import Swal from 'sweetalert2';
import axios from 'axios';
import { db } from '../../../App';
import { deleteUser } from '../../../pages/facilityMember/User';
import { deleteFolder } from './deleteFiles';
import { cloudFuncBaseUrl } from 'src/utils/config';
// ----------------------------------------------------------------------

export default function EventMoreMenu(props) {
  const refs = useRef(null);
  // const { id, medias } = props;
  const [isOpen, setIsOpen] = useState(false);

  const {
    id,
    title,
    location,
    description,
    medias,
    startDate,
    startTime,
    endTime,
    endDate,
    createdAt,
    likes,
    commentsCount,
    startTimePeriod,
    endTimePeriod,
    residentsId,
    roomsId,
    facilitiesId,
    facilityName,
    object,
    dots
  } = props;

  // console.log(id);

  // console.log(props);
  const user = JSON.parse(localStorage.getItem('Rel8User')).role;

  // -----------------------------------------
  //              Delete Resident
  // -----------------------------------------
  const userDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      // allowOutsideClick: false,
      showDenyButton: true,
      denyButtonText: `Delete forever`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: 'gray',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      // Delete event to recycle bin
      if (result.isConfirmed) {
        // ----------------------------------------------Loading-------------
        let timerInterval;
        Swal.fire({
          title: 'Deleting...',
          html: 'I will close in few<b></b> Moments.',
          // timer: 2000,
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector('b');
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer');
          }
        });
        // ----------------------------------------------Loading---------------------
        try {
          await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
            data: {
              id,
              collectionName: 'events',
              role: user
            }
          });

          // ----------------------------------

          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Event has been deleted.',
            showConfirmButton: false,
            timer: 1500
          });
          window.location.replace('/content/events');
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message
          });
        }
        // Delete event permanently
      } else if (result.isDenied) {
        try {
          // ----------------------------------------------Loading-------------
          let timerInterval;
          Swal.fire({
            title: 'Deleting...',
            html: 'I will close in few<b></b> Moments.',
            // timer: 2000,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });
          // ----------------------------------------------Loading---------------------
          await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/DeleteAllDataPermanently`,
            data: {
              id,
              collection: 'events'
            }
          });
          // ----------------------------------
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Event has been deleted.',
            showConfirmButton: false,
            timer: 1500
          });
          window.location.replace('/content/events');
        } catch (err) {
          return Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.message
          });
        }
      }
    });
  };

  // ------------------------------------------
  //             Archive Resident
  // ------------------------------------------

  return (
    <>
      <IconButton ref={refs} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={refs.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: '1', marginTop:dots?"30px":"0px" }}
      >
        {user !== 'Family Member' && (
          <>
          

            <MenuItem
              sx={{ color: 'text.secondary' }}
              component={RouterLink}
              to="/content/events/update-event"
              state={{ props }}
            >
              <ListItemIcon>
                <Icon icon={editFill} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
           
            <MenuItem sx={{ color: 'text.secondary' }} onClick={() => userDelete()}>
              <ListItemIcon>
                <Icon icon={trash2Outline} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
            <MenuItem
              sx={{ color: 'text.secondary' }}
              component={RouterLink}
              to="/content/events/add-event"
              state={{ props }}
            >
              <ListItemIcon>
                <Icon icon={duplicate} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </>
        )}

    {/* {!dots &&    <MenuItem
          sx={{ color: 'text.secondary' }}
          component={RouterLink}
          to="/content/events/event-detail"
          // state={{ props }}
          state={{
            id,
            title,
            location,
            description,
            medias,
            startDate,
            startTime,
            endTime,
            endDate,
            createdAt,
            likes,
            commentsCount,
            startTimePeriod,
            endTimePeriod,
            residentsId,
            roomsId,
            facilitiesId,
            facilityName,
            object
          }}
        >
          <ListItemIcon>
            <Icon icon={moreHorizontalFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="More" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        
        } */}
      </Menu>
    </>
  );
}
