import { Icon } from '@iconify/react';
import menuFill from '@iconify/icons-eva/menu-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, CircularProgress , Box} from '@mui/material';
// utils
import { useEffect, useState } from 'react';
import {
  collection,
  getCountFromServer,
  getDocs,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import { fShortenNumber } from '../../../utils/formatNumber';
import { db } from '../../../App';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: '#FED1DF'
  // backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppWeeklySales() {
  const [TOTAL, setTOTAL] = useState();
  // const facilites = collection(db, 'facilites');

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Facilites From Firebase**********************
  // let facilites;
  // if (user?.role === 'Facility Member') {
  //   facilites = query(collection(db, 'facilites'), where('id', 'in', user?.facilityIds));
  // } else if (user?.role === 'admin') {
  //   facilites = collection(db, 'facilites');
  // } else if (user?.role === 'Family Member') {
  //   facilites = query(collection(db, 'facilites'), where('id', 'in', user?.facilityIds));
  // }


  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'users'),
      where('role', '==', 'Family Member'),
      where('restrictAt', '!=', 4),
      where('facilityIds', 'array-contains-any', user?.facilityIds)
    );
  } else if (user?.role === 'admin') {
    userCollection = query(
      collection(db, 'users'),
      where('role', '==', 'Family Member'),
      where('restrictAt', '!=', 4)
    );
  } 

  useEffect(async () => {
    // getDocs(facilites).then((res) => {

    const totalCount = await getCountFromServer(userCollection);

    if (totalCount.data()?.count > 0) {
      return setTOTAL(totalCount.data()?.count);
    }
    setTOTAL('Not found');
    // onSnapshot(facilites, (res) => {
    //   if (res.size) {
    //     return setTOTAL(res.size);
    //   }
    // setTOTAL('Not found');
    // });
  }, []);
  return (
    <Box sx={{border:"4px solid #32D18C", minWidth:"120px", height:"120px", borderRadius:"20px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
      {/* <IconWrapperStyle>
        <Icon icon={menuFill} width={24} height={24} />
      </IconWrapperStyle> */}
      <Typography variant="h2" sx={{textAlign:"center",color: '#32D18C' }}>
        {(() => {
          if (!TOTAL) return <CircularProgress style={{ color: '#32D18C' }} />;
          if (TOTAL === 'Not found') return fShortenNumber(0);
          return fShortenNumber(TOTAL);
        })()}
      </Typography>
      <p style={{fontSize:"20px", fontWeight:600, textAlign:"center", color:"black" }}>
        Family
      </p>
    </Box>
  );
}
