import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function UserNotFound({ searchQuery = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1" sx={{ color: '#9A76F5' }}>
        Not Found
      </Typography>
    </Paper>
  );
}
