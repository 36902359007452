import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import BackButtonImage from '../../../assest/backButton.svg';
import ShopProductCard from '../../../components/_dashboard/products/ProductCard';
// material
import {
  Stack,
  TextField,
  Card,
  Grid,
  Box,
  styled,
  Button,
  CircularProgress,
  Autocomplete,
  Popper,
  Divider,
  Checkbox,
  ClickAwayListener,
  Tooltip
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { DateTimePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  collection,
  doc,
  arrayUnion,
  arrayRemove,
  updateDoc,
  getDocs,
  query,
  where,
  setDoc,
  deleteDoc
} from 'firebase/firestore';
// import {DateTimePicker} from '@mui/lab/DateTimePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import { db, localUser } from '../../../App';
import { uploadFile } from '../user/UploadFile';
import { deleteFolder } from '../user/deleteFiles';
import LocationGoogle from './LocationGoogle';
import EventPreview from './EventPreview';
import FileView from './FileView';
import SortByTitle from './SortByTitle';
import GooglePlacesAutocomplete, { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_PLACES_API_KEY } from 'src/utils/config';
import filePNG from '../../../assest/file.png';
import AviThumbnail from '../../../assest/aviThumbnail.png';
import HEICThumbnail from '../../../assest/heicThumbnail.png';
import MPEGThumbnail from '../../../assest/mpegThumbnail.png';
import AudioThumbnail from '../../../assest/audioThumbnail.png';
import Textthumbnail from '../../../assest/textThumbnail.png';
import PdfThumbnail from '../../../assest/pdfThumbnail.webp';
// ----------------------------------------------------------------------
// const localUser = JSON.parse(localStorage.getItem('Rel8User'));

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`
}));

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function UpdateEventForm({ props, setSelectOption, selectOption }) {
  // console.log(props);

  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [mediaType, setMediaType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(new Date());
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [draft, setDraft] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);

  const [preview, setPreview] = useState(false);
  const [previewError, setpreviewError] = useState(false);

  const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');

  const {
    id,
    title,
    description,
    location,
    residentsId,
    roomsId,
    createdAt,
    facilitiesId,
    facilityName
  } = props;
  // console.log(props);
  const file = props.medias;
  const type = props?.type;
  const start = props?.type
    ? new Date(props.startDate.seconds * 1000)
    : new Date(props.startDate.seconds * 1000); // props.startDate
  const end = props?.type
    ? new Date(props.endDate.seconds * 1000)
    : new Date(props.endDate.seconds * 1000); // props.startDate

  const [startHours, startMinutes] = props.startTime.split(':').map(Number);
  const adjustedStartHours =
    props.startTimePeriod === 'pm' ? (startHours % 12) + 12 : startHours % 12;
  start.setHours(adjustedStartHours, startMinutes);

  const [endHours, endMinutes] = props.endTime.split(':').map(Number);
  const adjustedEndHours = props.endTimePeriod === 'pm' ? (endHours % 12) + 12 : endHours % 12;
  end.setHours(adjustedEndHours, endMinutes);

  const startTime = props?.type ? `${props.startTime} ${props.startTimePeriod}` : props.startTime;
  // const startTime = props?.type ? `${props.startTime} ${props.startTimePeriod}` : props.startTime;
  const endTime = props?.type ? `${props.endTime} ${props.endTimePeriod}` : props.endTime;

  // let s = startTime.split(' ')[0];
  // let e = endTime.split(' ')[0];
  // s = moment(s, ['h:mm A']).format('HH:mm');
  // e = moment(e, ['h:mm A']).format('HH:mm');
  // const start = new Date(`${startDate} ${s}`);
  // const end = new Date(`${endDate} ${e}`);

  const [files, setFile] = useState(file);

  // -------------------------------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------Facility room resident----------------------------------------
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [defaultOptions, setDefaultopt] = useState([]);
  const [defaultOptionsRooms, setDefaultoptRooms] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [openRoom, setOpenRoom] = useState(false);
  const [openFacility, setOpenFacility] = useState(false);

  // Facility
  const [facilityID, setFacilityID] = useState(facilitiesId);
  const [roomId, setRoomIds] = useState(roomsId);
  const [checkAll, setCheckAll] = useState(false);

  // for empty change facility
  const [residentIDs, setresidentIDs] = useState([]);
  const [checkAllResi, setCheckAllResi] = useState(false);

  const loadingRooms = openRoom && roomOptions.length === 0;
  const loadingFacility = openFacility && facilityOptions.length === 0;

  const loading = open && options.length === 0;

  // for rooms Id array for resident
  const IDROOM = [];
  roomId?.forEach((e) => {
    IDROOM.push(e.value);
  });

  const images =
    files.length != 0
      ? files.filter((image) => {
          // console.log('image :: ', image);
          const file =
            (image.type != 'image' && image.type != 'video') ||
            (image.name && image.name.endsWith('.avi')) ||
            (image.name && image.name.endsWith('.mpeg')) ||
            (image.name && image.name.endsWith('.heic'));
          const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
          if (type != 'file') {
            return image;
          }
        })
      : [];
  // --------------------------------------End for residents ---------------------

  useEffect(async () => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const residentsCollection = query(collection(db, 'residents'), where('roomId', 'in', IDROOM));
      const residentsPromise = await getDocs(residentsCollection);
      const opt = [];
      residentsPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: `${result.data().firstName} ${result.data().lastName}`
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // -------------Rooms Autocomplete--------------------------//
  useEffect(() => {
    let active = true;

    if (!loadingRooms) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const roomsCollection = query(collection(db, 'rooms'), where('facilityId', '==', facilityID));
      const roomsPromise = await getDocs(roomsCollection);
      const opt = [];
      roomsPromise?.forEach((result) => {
        opt.push({
          value: result.data()?.id,
          title: result.data()?.name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setRoomOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingRooms]);

  useEffect(() => {
    if (!openRoom) {
      setRoomOptions([]);
    }
  }, [openRoom]);

  // -----------------------------get facility--------------------------
  useEffect(() => {
    let active = true;

    if (!loadingFacility) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        facilitesCollection = collection(db, 'facilites');
      }
      // const facilitesCollection = collection(db, 'facilites');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise?.forEach((result) => {
        opt.push({
          value: result.data()?.id,
          title: result.data()?.name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setFacilityOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingFacility]);

  useEffect(() => {
    if (!openFacility) {
      setFacilityOptions([]);
    }
  }, [openFacility]);

  // ------------------------------------------Facility room resident----------------------------------------

  // -------------------------------------------------------------------------------------------------------------------------------------------

  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e, downloadUrl, thumbnail) => {
    const s = files.filter((item, index) => index !== e);

    if (thumbnail && RgExp.test(thumbnail)) {
      setDeleteFiles([...deleteFiles, thumbnail, downloadUrl]);
    } else if (RgExp.test(downloadUrl)) setDeleteFiles([...deleteFiles, downloadUrl]);
    setFile(s);
  };
  const handleFileUpload = async (e) => {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      arr.push(e.target.files[i]);
    }
    const obj = [];
    console.log('arr ', arr);
    for (let i = 0; i < arr.length; i += 1) {
      let thumbnail;
      const ImagesArray = arr[i];
      const { size } = arr[i];
      if (size / 1024 > 20480) {
        return NotificationManager.error('File size is greater than 20 mb');
      }
      if (arr.length + file.length > 30) {
        return NotificationManager.error('maximum 30 files to upload');
      }
      // const downloadUrl = Object.entries(arr).map((ei) => URL.createObjectURL(ei[1]));
      const downloadUrl = URL.createObjectURL(arr[i]);
      const mediaTypeLocal = arr[i].type.split('/')[0];
      if (mediaTypeLocal !== 'application' && !arr[i].name.endsWith('.mpeg')) {
        if (mediaTypeLocal === 'video' && !arr[i].name.endsWith('.avi')) {
          thumbnail = await generateVideoThumbnail(arr[i]);
        }
        if (arr[i].name.endsWith('.heic')) {
          thumbnail = HEICThumbnail;
        }
        if (arr[i].name.endsWith('.avi')) {
          thumbnail = AviThumbnail;
        }
        if (mediaTypeLocal === 'audio') {
          thumbnail = AudioThumbnail;
        }
        if (mediaTypeLocal === 'text') {
          thumbnail = Textthumbnail;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl: downloadUrl,
          thumbnail: thumbnail,
          name: arr[i]?.name,
          size: size,
          type: mediaTypeLocal
        });
      } else {
        if (arr[i].name.endsWith('.pdf')) {
          thumbnail = PdfThumbnail;
        } else if (arr[i].name.endsWith('.mpeg')) {
          thumbnail = MPEGThumbnail;
        } else {
          thumbnail = filePNG;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl: downloadUrl,
          thumbnail: thumbnail,
          size: size,
          name: arr[i]?.name,
          type: 'file'
        });
      }
    }
    setFile([...file, ...obj]);
    // setFile(obj);
  };

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('title is required'),
    // location: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('location required'),
    description: Yup.string().required('description is required.').nullable(),
    start: Yup.date().required('start event is required'),
    end: Yup.date()
      .min(Yup.ref('start'), "End event can't be before Start date")
      .required('end event is required'),
    facilitiesId: Yup.string().required('Group is required').nullable(),
    Location: Yup.string().required('Address is required').nullable(),
    roomsId: Yup.array().min(1, 'at least 1 location is required').required('required'),
    residentsId: Yup.array().min(1, 'at least 1 client is required').required('required'),
    date: Yup.date().required('Date is required')
  });

  
  const RegisterSchemaDraft = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    // location: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Location required'),
    description: Yup.string().nullable(),
    start: Yup.date().nullable(),
    end: Yup.date().nullable(),
    facility: Yup.string().nullable(),
    Location: Yup.string().nullable(),
    roomsId: Yup.array().nullable(),
    residentsId: Yup.array().nullable(),
    date: Yup.date().nullable()
  });

  const formik = useFormik({
    initialValues: {
      title,
      Location: location,
      start,
      end,
      description,
      photo: '',
      residentsId: defaultOptions,
      facilitiesId: props.facilitiesId,
      facilityName: props.facilityName,
      roomsId: defaultOptionsRooms,
      date: new Date(createdAt?.seconds * 1000)
    },
    validationSchema: draft ? RegisterSchemaDraft :RegisterSchema,
    onSubmit: async (values) => {
      if (previewError) {
        return setPreview(true);
      }
      AddEventFunc(draft ? 1 : 0);
    }
  });
  const AddEventFunc = (drft) => {
    // console.log(drft ?? false);
    const draft = drft ?? false;
    // console.log(draft);

    if (files.length > 30) {
      return NotificationManager.error('Maximum 30 files to upload');
    }
    // if (files.length === 0) {
    //   return NotificationManager.error("You didn't select file");
    // }
    if (draft) {
      setDraftLoading(true);
    } else {
      setIsLoading(true);
    }

    setTimeout(() => {
      NotificationManager.warning('Please wait! File is being upload');
    }, 6000);
    const title = getFieldProps('title').value;
    // const location = getFieldProps('location').value;
    const description = getFieldProps('description').value;
    const SDate = getFieldProps('start').value ?? new Date();
    const EDate = getFieldProps('end').value ?? new Date();
    const mediaId = uuidv4();
    const residentsId = [];
    const roomsId = [];
    const facilityName = getFieldProps('facilityName').value;
    const facilitiesId = getFieldProps('facilitiesId').value;
    const roomId = getFieldProps('roomsId').value;
    const date = getFieldProps('date').value;
    const locations = getFieldProps('Location').value;
    const delimiter = ':';
    // const startTime = SDate.toLocaleTimeString().split(delimiter).slice(0, 2).join(delimiter);
    // const endTime = EDate.toLocaleTimeString().split(delimiter).slice(0, 2).join(delimiter);
    const startTime = moment(SDate).format('hh:mm');
    const endTime = moment(EDate).format('hh:mm');
    // const startTimePeriod = SDate.toLocaleTimeString()
    //   .split(delimiter)
    //   .slice(2)
    //   .join(delimiter)
    //   .slice(-2)
    //   .toLowerCase();
    // const endTimePeriod = EDate.toLocaleTimeString()
    //   .split(delimiter)
    //   .slice(2)
    //   .join(delimiter)
    //   .slice(-2)
    //   .toLowerCase();
    const startTimePeriod = moment(SDate).format('a');
    const endTimePeriod = moment(EDate).format('a');
    setTimeout(() => {
      NotificationManager.warning('Please wait! File is being upload');
    }, 10000);

    defaultOptions.forEach((element) => {
      residentsId.push(element.value);
    });

    defaultOptionsRooms.forEach((element) => {
      roomsId.push(element.value);
    });

    // console.log(facilitiesId);
    // console.log(facilityName);
    // console.log(roomsId);
    // console.log(residentsId);

    let startDate = moment(SDate).set({ hour: 0, minute: 0, second: 0 });
    let endDate = moment(EDate).set({ hour: 0, minute: 0, second: 0 });
    startDate = startDate.toDate();
    endDate = endDate.toDate();
    const medias = [];
    let itemsProcessed = 0;
    deleteFiles?.forEach((element) => {
      deleteFolder(element);
      const newFiles = files.filter((item) => item.downloadUrl === element);
      setFile(newFiles);
    });

    if (files.length > 0) {
      files?.forEach(async (element, index, array) => {
        element.id = id;
        if (!RgExp.test(element.downloadUrl)) {
          if (element.type === 'video') {
            const downloadUrl = await uploadFile(
              'events',
              element,
              element.file,
              element.type,
              mediaId
            );
            const thumbnailId = uuidv4();
            const downloadUrlThumbnail = await uploadFile(
              'events',
              element,
              element.thumbnail,
              'thumbnail',
              thumbnailId
            );
            medias.push({
              downloadUrl: downloadUrl ?? null,
              thumbnailId: thumbnailId ?? null,
              thumbnail: downloadUrlThumbnail ?? null,
              name: element.file.name,
              type: element.type,
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
          if (element.type === 'image') {
            const downloadUrl = await uploadFile(
              'events',
              element,
              element.file,
              element.type,
              mediaId
            );
            medias.push({
              downloadUrl,
              thumbnailId: null,
              thumbnail: null,
              name: element.file.name,
              type: element.type,
              size: null,
              id: mediaId
            });
          }
          if (element.type === 'application') {
            const downloadUrl = await uploadFile(
              'events',
              element,
              element.file,
              element.type,
              mediaId
            );
            medias.push({
              downloadUrl: downloadUrl ?? null,
              thumbnailId: null,
              thumbnail: null,
              name: element?.name,
              type: 'file',
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
        } else {
          medias.push(element);
        }
        itemsProcessed += 1;
        if (itemsProcessed === array?.length) {
          if (draft) {
            const draftsRef = doc(db, 'drafts', id);
            // check type for update draft or new add
            if (type) {
              updateDoc(draftsRef, {
                title,
                createdAt: date,
                object: {
                  title,
                  location: locations,
                  description,
                  startDate,
                  startTime,
                  startTimePeriod,
                  endDate,
                  endTime,
                  endTimePeriod,
                  medias,
                  residentsId,
                  roomsId,
                  facilitiesId,
                  facilityName,
                  createdAt: date
                }
              })
                .then((res) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Drafts Updated Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  navigate('/home/drafts', { replace: true });
                  setDraft(false);
                  return setDraftLoading(false);
                })
                .catch((err) => console.log(err));
            }
            if (!type) {
              // check type for update draft or new add
              setDoc(draftsRef, {
                title,
                type: 'events',
                createdAt: date,
                id,
                object: {
                  title,
                  location: locations,
                  description,
                  startDate,
                  startTime,
                  startTimePeriod,
                  endDate,
                  endTime,
                  endTimePeriod,
                  medias,
                  residentsId,
                  roomsId,
                  facilitiesId,
                  facilityName,
                  createdAt: date,
                  id
                }
              })
                .then((res) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Drafts Updated Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  navigate('/home/drafts', { replace: true });
                  setDraft(false);
                  return setDraftLoading(false);
                })
                .catch((err) => console.log(err));
            }
          } else {
            // for  add even from draft
            if (type) {
              const eventsRef = doc(db, 'events', id);
              return setDoc(eventsRef, {
                id,
                title,
                location: locations,
                description,
                startDate,
                startTime,
                startTimePeriod,
                endDate,
                endTime,
                endTimePeriod,
                medias,
                postedById: localUser.id,
                authorName: `${localUser?.firstname} ${localUser?.lastname}`,
                createdAt: date,
                likes: [],
                roomsId,
                facilitiesId,
                facilityName,
                residentsId,
                commentsCount: 0
              })
                .then(async (res) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Event Added Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  deleteDoc(doc(db, 'drafts', id));

                  // -----------------------------------------Comment Notifications------------------------------------
                  const object = {
                    id,
                    title,
                    location: locations,
                    description,
                    startDate,
                    startTime,
                    startTimePeriod,
                    endDate,
                    endTime,
                    endTimePeriod,
                    medias,
                    postedById: localUser.id,
                    createdAt: date,
                    likes: [],
                    roomsId,
                    facilitiesId,
                    facilityName,
                    residentsId,
                    commentsCount: 0
                  };
                  const commentNotifiRef = doc(collection(db, 'notifications'));
                  await setDoc(commentNotifiRef, {
                    id: commentNotifiRef.id,
                    body: `${localUser?.firstname} ${localUser?.lastname} has created a new event`,
                    commentId: id,
                    createdAt: date,
                    profileImgUrl: localUser.avatarUrl ?? null,
                    title: 'A new event',
                    topic: `${facilitiesId}_event`,
                    viewedBy: [],
                    senderId: localUser.id,
                    receiverId: '',
                    object,
                    senderName: `${localUser?.firstname} ${localUser?.lastname}`,
                    type: 'events'
                  });
                  // -----------------------------------------Comment Notifications------------------------------------

                  navigate('/home/events', { replace: true });
                })
                .catch((err) => console.log(err));
            }

            // ********************* For update events
            const eventsRef = doc(db, 'events', id);
            return updateDoc(eventsRef, {
              title,
              location: locations,
              description,
              startDate,
              startTime,
              startTimePeriod,
              endDate,
              endTime,
              endTimePeriod,
              medias,
              residentsId,
              roomsId,
              facilitiesId,
              facilityName,
              createdAt: date
            })
              .then((res) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Event Updated Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/events', { replace: true });
              })
              .catch((err) => console.log(err));
          }
        }
      });
    } else {
      if (draft) {
        const draftsRef = doc(db, 'drafts', id);
        // check type for update draft or new add
        if (type) {
          return updateDoc(draftsRef, {
            title,
            type: 'events',
            createdAt: date,
            object: {
              title,
              location: locations,
              description,
              startDate,
              startTime,
              startTimePeriod,
              endDate,
              endTime,
              endTimePeriod,
              medias,
              residentsId,
              roomsId,
              facilitiesId,
              facilityName,
              createdAt: date
            }
          })
            .then((res) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Drafts Updated Successfully',
                showConfirmButton: false,
                timer: 2000
              });
              setDraft(false);
              setDraftLoading(false);
              return navigate('/home/drafts', { replace: true });
            })
            .catch((err) => console.log(err));
        }
        // check type for update draft or new add
        if (!type) {
          return setDoc(draftsRef, {
            title,
            createdAt: date,
            type: 'events',
            id,
            object: {
              title,
              location: locations,
              description,
              startDate,
              startTime,
              startTimePeriod,
              endDate,
              endTime,
              endTimePeriod,
              medias,
              residentsId,
              roomsId,
              facilitiesId,
              facilityName,
              createdAt: date,
              id
            }
          })
            .then((res) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Drafts Updated Successfully',
                showConfirmButton: false,
                timer: 2000
              });
              setDraft(false);
              setDraftLoading(false);
              return navigate('/home/drafts', { replace: true });
            })
            .catch((err) => console.log(err));
        }
      }
      // } else {
      const eventsRef = doc(db, 'events', id);
      return updateDoc(eventsRef, {
        title,
        location: locations,
        description,
        startDate,
        startTime,
        startTimePeriod,
        endDate,
        endTime,
        endTimePeriod,
        medias,
        residentsId,
        roomsId,
        facilitiesId,
        facilityName,
        createdAt: date
      })
        .then((res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Event Updated Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          navigate('/home/events', { replace: true });
        })
        .catch((err) => console.log(err));
      // }
    }
  };
  // });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched
  } = formik;
  // ************************************************** Default Options ********************************
  // --------------------------------------start for Rooms ---------------------
  useEffect(async () => {
    const roomCollection = query(collection(db, 'rooms'), where('id', 'in', roomsId));
    const roomsPromise = await getDocs(roomCollection);
    const opt = [];
    roomsPromise?.forEach((result) => {
      opt.push({
        value: result.data().id,
        title: result.data().name
      });
    });
    setFieldValue('roomsId', opt);
    setDefaultoptRooms(opt);
  }, []);
  // --------------------------------------start for residents ---------------------
  useEffect(async () => {
    const residentsCollection = query(collection(db, 'residents'), where('id', 'in', residentsId));
    const residentsPromise = await getDocs(residentsCollection);
    const opt = [];
    residentsPromise.forEach((result) => {
      opt.push({
        value: result.data().id,
        title: `${result.data().firstName} ${result.data().lastName}`
      });
    });
    setFieldValue('residentsId', opt);
    setDefaultopt(opt);
  }, []);
  // --------------------------------------end for residents ---------------------

  // for room
  const handleClickAway = (e) => {
    setOpenRoom(false);
  };
  // for resident
  const handleClickAwayResi = (e) => {
    setOpen(false);
  };

  // -------for room
  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    // setCheckAll(true);
    if (event.target.checked) {
      setDefaultoptRooms(roomOptions);
      setRoomIds(roomOptions);
      setFieldValue('roomsId', roomOptions);
    } else {
      setDefaultoptRooms([]);
      setRoomIds([]);
    }
    setFieldValue('residentsId', []);
    setresidentIDs([]);
    setDefaultopt([]);
    setCheckAllResi(false);
  };

  // -----For resident
  const checkAllChangeResi = (event) => {
    setCheckAllResi(event.target.checked);
    // setCheckAllResi(true);
    if (event.target.checked) {
      setresidentIDs(options);
      setDefaultopt(options);
      setFieldValue('residentsId', options);
    } else {
      setresidentIDs([]);
      setDefaultopt([]);
      setFieldValue('residentsId', []);
    }
  };

  //------------------------------Google places---
  const { ref: materialRef } = usePlacesWidget({
    apiKey: GOOGLE_PLACES_API_KEY,
    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions: { country: 'Aus' }
    },
    onPlaceSelected: (place) => {
      setFieldValue('Location', place.formatted_address);
    }
  });
  //------------------------------Google places---

  return (
    <Box>
      {selectOption == 'preview' ? (
        <EventPreview
          file={files}
          title={{ ...getFieldProps('title') } ?? ''}
          description={{ ...getFieldProps('description') }}
          setPreview={setPreview}
          AddSubmitFunc={AddEventFunc}
          draftLoading={draftLoading}
          isLoading={isLoading}
          head1="Update Events"
          head2={type ? 'Add Event' : 'Update Event'}
          type="event"
          location={getFieldProps('Location').value}
          sdate={getFieldProps('start').value}
          edate={getFieldProps('end').value}
          setSelectOption={setSelectOption}
          section="Event"
          setDraft={setDraft}
          handleSubmit={handleSubmit}
        />
      ) : (
        <Card
          sx={{
            marginTop: '',
            width: '100%',
            minWidth: '100%',
            paddingX: '20px',
            paddingY: '20px'
          }}
        >
          {selectOption != 'add' && (
            <div
              style={{ marginBottom: '12px' }}
              onClick={() => {
                if (selectOption == 'audience') {
                  setSelectOption('add');
                } else if (selectOption == 'schedule') {
                  setSelectOption('audience');
                }
              }}
            >
              <img src={BackButtonImage} style={{ width: '35px', cursor: 'pointer' }} />
            </div>
          )}
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <NotificationContainer />
              <Stack spacing={3}>
                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Title
                      </div>

                      <TextField
                        fullWidth
                        // label="Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Box>
                  </Stack>
                )}
                {selectOption == 'add' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Description
                    </div>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={20}
                      // label="Description"
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Box>
                )}
                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Event Location
                      </div>

                      <TextField
                        fullWidth
                        // label="Address"
                        // placeholder=" "
                        {...getFieldProps('Location')}
                        error={Boolean(touched.Location && errors.Location)}
                        helperText={touched.Location && errors.Location}
                        inputRef={materialRef}
                      />
                    </Box>
                  </Stack>
                )}
                {selectOption == 'add' && (
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    sx={{ width: '100%', '& > *': { flex: 1 } }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          width: '100%'
                        }}
                      >
                        <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                          Start date/time
                        </div>
                        <DateTimePicker
                          format="dd/MM/yyyy hh:mm aa"
                          fullWidth
                          inputFormat="Start Event"
                          // label="Start Event"
                          {...getFieldProps('start')}
                          onChange={(e, value) => {
                            setFieldValue('start', e || '');
                          }}
                          onChangeRaw={(e) => {
                            setFieldTouched('start', true, true);
                          }}
                          onOpen={() => {
                            setFieldTouched('start', true, true);
                          }}
                          onClose={() => {
                            if (getFieldProps('start').value == null) {
                              setFieldValue('start', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={touched.start && errors.start}
                              helperText={touched.start && errors.start}
                            />
                          )}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          width: '100%'
                        }}
                      >
                        <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                          End date/time
                        </div>
                        <DateTimePicker
                          format="dd/MM/yyyy hh:mm aa"
                          // inputFormat="End Event"
                          // label="End Event"
                          {...getFieldProps('end')}
                          onChange={(e, value) => setFieldValue('end', e || '')}
                          onChangeRaw={(e) => {
                            setFieldTouched('end', true, true);
                          }}
                          onOpen={() => {
                            setFieldTouched('start', true, true);
                          }}
                          onClose={() => {
                            if (getFieldProps('end').value == null) {
                              setFieldValue('end', '');
                            }
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                        />
                      </div>
                    </LocalizationProvider>
                  </Stack>
                )}

                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Group
                      </div>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        open={openFacility}
                        onOpen={() => {
                          setOpenFacility(true);
                        }}
                        onClose={() => {
                          setOpenFacility(false);
                        }}
                        onChange={(e, value) => {
                          setFieldValue('facilityName', value?.title || '');
                          setFieldValue('facility', value?.value || '');
                          setFacilityID(value?.value);
                          // for empty fields
                          setFieldValue('roomsId', []);
                          setFieldValue('residentsId', []);
                          setRoomIds([]);
                          setresidentIDs([]);
                          // for check all empty
                          setCheckAll(false);
                          setCheckAllResi(false);
                        }}
                        defaultValue={{
                          title: props
                            ? props.facilityName
                            : getFieldProps('facilityName').value ?? '',
                          value: props
                            ? props.facilityIds
                            : getFieldProps('facility').value ?? ''
                        }}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        options={facilityOptions}
                        loading={loadingFacility}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            // label="Select Group"
                            // {...getFieldProps('facility')}
                            {...getFieldProps('facility')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingFacility ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                            error={Boolean(touched.facility && errors.facility)}
                            helperText={touched.facility && errors.facility}
                          />
                        )}
                      />
                    </div>
                  </Stack>
                )}
                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Location
                      </div>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            multiple
                            limitTags={3}
                            disableCloseOnSelect
                            id="asynchronous-demo"
                            open={openRoom}
                            disabled={!facilityID ?? false}
                            onOpen={() => {
                              setOpenRoom(true);
                            }}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'selectOption') {
                                setDefaultoptRooms(newValue);
                                setFieldValue('roomsId', newValue ?? '');
                                setRoomIds(newValue);
                              } else if (reason === 'removeOption') {
                                setDefaultoptRooms(newValue);
                                setFieldValue('roomsId', newValue ?? '');
                                setRoomIds(newValue);
                              } else if (reason === 'clear') {
                                setRoomIds([]);
                                setCheckAll(false);
                                setFieldValue('roomsId', []);
                                setDefaultoptRooms([]);
                              }
                              setFieldValue('residentsId', []);
                              setresidentIDs([]);
                              setCheckAllResi(false);
                              setDefaultopt([]);
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            getOptionSelected={(option, value) => value.value === option.value}
                            options={roomOptions}
                            value={defaultOptionsRooms}
                            loading={loadingRooms}
                            PopperComponent={(param) => (
                              <PopperStyledComponent {...param}>
                                <Box {...param} />
                                <Divider />
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    height: '45px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <Checkbox
                                    checked={checkAll}
                                    onChange={checkAllChange}
                                    id="check-all"
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                  Select All
                                </Box>
                              </PopperStyledComponent>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Location"
                                {...getFieldProps('room')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingRooms ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.roomsId && errors.roomsId)}
                                helperText={touched.roomsId && errors.roomsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Stack>
                )}

                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Clients
                      </div>

                      <ClickAwayListener onClickAway={handleClickAwayResi}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            disableCloseOnSelect
                            multiple
                            limitTags={3}
                            disabled={IDROOM.length === 0 ?? false}
                            id="asynchronous-demo"
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'selectOption') {
                                setFieldValue('residentsId', newValue ?? '');
                                setresidentIDs(newValue);
                                setDefaultopt(newValue);
                                setCheckAllResi(false);
                              } else if (reason === 'removeOption') {
                                setCheckAllResi(false);
                                setFieldValue('residentsId', newValue ?? '');
                                setresidentIDs(newValue);
                                setDefaultopt(newValue);
                              } else if (reason === 'clear') {
                                setresidentIDs([]);
                                setDefaultopt([]);
                                setCheckAllResi(false);
                                setFieldValue('residentsId', []);
                              }
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            getOptionSelected={(option, value) => value.value === option.value}
                            options={options}
                            value={defaultOptions}
                            loading={loading}
                            PopperComponent={(param) => (
                              <PopperStyledComponent {...param}>
                                <Box {...param} />
                                <Divider />
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    height: '45px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <Checkbox
                                    checked={checkAllResi}
                                    onChange={checkAllChangeResi}
                                    id="check-all"
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                  Select All
                                </Box>
                              </PopperStyledComponent>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label="Select Clients"
                                {...getFieldProps('resident')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.residentsId && errors.residentsId)}
                                helperText={touched.residentsId && errors.residentsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Stack>
                )}

                {selectOption == 'schedule' && (
                  <Stack sx={{ width: '100%', '& > *': { flex: 1 } }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Date
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          format="dd/MM/yyyy"
                          inputFormat="dd/MM/yyyy"
                          // label="Date"
                          {...getFieldProps('date')}
                          onChange={(e, value) => setFieldValue('date', e || '')}
                          onChangeRaw={(e) => {
                            setFieldTouched('date', true, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(touched.date && errors.date)}
                              helperText={touched.date && errors.date}
                            />
                          )}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                          maxDate={new Date()}
                        />
                      </LocalizationProvider>
                    </div>
                  </Stack>
                )}

                {/* <Grid container spacing={2}>
                  {files.length > 0 &&
                    files.map((item, index) => (
                      <Grid item xs={4} sm={4} md={2}>
                        <Icon
                          icon="ci:close-big"
                          onClick={() => deleteFile(index, item.downloadUrl, item.thumbnail)}
                          style={{ cursor: 'pointer' }}
                        />
                        <Card style={{}}>
                          <Box sx={{ pt: '100%', position: 'relative' }}>
                            {item?.type === 'file' || item?.type === 'application' ? (
                              FileView(item?.downloadUrl, item?.name)
                            ) : (
                              <ProductImageStyle
                                alt="Image"
                                src={item.thumbnail ?? item.downloadUrl}
                              />
                            )}
                          </Box>
                        </Card>
                      </Grid>
                    ))}
                </Grid> */}
                {selectOption == 'add' && (
                  <div className="grid">
                    {files.length > 0 &&
                      files.map((item, index) => {
                        return (
                          <>
                            {!(
                              (item.type != 'image' && item.type != 'video') ||
                              item.name.endsWith('.avi') ||
                              item.name.endsWith('.mpeg')
                            ) && (
                              <div
                                key={item.id}
                                style={{ position: 'relative' }}
                                className={
                                  item === images[0] && images.length > 7
                                    ? 'firstIndex'
                                    : 'allIndex'
                                }
                              >
                                {' '}
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '-3px',
                                    right: '-3px',
                                    zIndex: 100
                                  }}
                                >
                                  <Icon
                                    icon="ci:close-big"
                                    onClick={() => deleteFile(index)}
                                    style={{
                                      cursor: 'pointer',
                                      backgroundColor: 'white',
                                      borderRadius: '100%',
                                      outline: '1px',
                                      outlineColor: '#9A76F5'
                                    }}
                                  />
                                </div>
                                <Card>
                                  {/* <ProductImageStyle
                                          alt="Image"
                                          src={item.thumbnail ?? item.downloadUrl}
                                        /> */}
                                  <ShopProductCard img={item} Index={index} images={images} />
                                </Card>
                              </div>
                            )}
                          </>
                        );
                      })}
                  </div>
                )}
                {selectOption == 'add' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {files.length > 0 &&
                      files.map((item, index) => {
                        return (
                          <>
                            {((item.type != 'image' && item.type != 'video') ||
                              item.name.endsWith('.avi') ||
                              item.name.endsWith('.mpeg')) && (
                              <Box
                                sx={{
                                  maxWidth: '250px',
                                  width: 'full',
                                  display: 'flex',
                                  gap: '5px',
                                  alignItems: 'center'
                                }}
                              >
                                <Icon
                                  icon="ci:close-big"
                                  onClick={() => deleteFile(index)}
                                  style={{ cursor: 'pointer' }}
                                />
                                <Box
                                  sx={{
                                    maxWidth: '50px',
                                    maxHeight: '60px',
                                    minWidth: '50px',
                                    minHeight: '60px',
                                    overflow: 'hidden'
                                  }}
                                >
                                  <img
                                    src={item.thumbnail}
                                    style={{ width: '100%', object: 'contain', height: '100%' }}
                                  />
                                </Box>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>
                                  {item.name && item.name.length > 25
                                    ? item.name.slice(0, 22) + '...'
                                    : item.name}
                                  {' ('}
                                  {item.size ?? ''}
                                  {' KB)'}
                                </div>
                              </Box>
                            )}
                          </>
                        );
                      })}
                  </Box>
                )}
                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button variant="contained" component="label">
                      Add attachment
                      <input
                        type="file"
                        hidden
                        multiple
                        fullWidth
                        accept="image/*,video/* ,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        onChange={handleFileUpload}
                        error={Boolean(touched.photo && errors.photo)}
                        helperText={touched.photo && errors.photo}
                      />
                    </Button>
                  </Stack>
                )}

                {(selectOption == 'add' ||
                  selectOption == 'audience' ||
                  selectOption == 'schedule') && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="outlined"
                      loading={draftLoading}
                      onClick={() => setDraft(true)}
                    >
                      Save drafts
                    </LoadingButton>
                    <LoadingButton
                      fullWidth
                      size="large"
                      // type="submit"
                      onClick={() => {
                        if (selectOption == 'add') {
                          if (!getFieldProps('title').value) {
                            setFieldTouched('title');
                          }
                          if (!getFieldProps('description').value) {
                            setFieldTouched('description');
                          }
                          if (!getFieldProps('Location').value) {
                            setFieldTouched('Location');
                          }
                          if (!getFieldProps('start').value) {
                            setFieldValue('start', '');
                          }
                          if (!getFieldProps('end').value) {
                            setFieldValue('end', '');
                          }
                          // setStory(true);

                          if (
                            getFieldProps('title').value &&
                            getFieldProps('description').value &&
                            getFieldProps('end').value &&
                            getFieldProps('start').value &&
                            getFieldProps('Location').value
                          ) {
                            setSelectOption('audience');
                          }
                        } else if (selectOption == 'audience') {
                          if (!getFieldProps('facilityName').value) {
                            setFieldTouched('facilityName');
                          }

                          if (getFieldProps('roomsId').value.length == 0) {
                            setFieldTouched('roomsId');
                          }
                          if (getFieldProps('residentsId').value.length == 0) {
                            setFieldTouched('residentsId');
                          }
                          if (
                            getFieldProps('facilityName').value &&
                            getFieldProps('roomsId').value.length > 0 &&
                            getFieldProps('residentsId').value.length > 0
                          ) {
                            setSelectOption('schedule');
                          }
                        } else if (selectOption == 'schedule') {
                          setSelectOption('preview');
                        }
                      }}
                      variant="contained"
                      loading={isLoading}
                    >
                      {selectOption == 'add'
                        ? 'Choose audience'
                        : selectOption == 'audience'
                        ? 'Schedule'
                        : 'Preview'}
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      )}
    </Box>
  );
}
