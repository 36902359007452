import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// components
// ------------------------for popup box-------------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
// ------------------------------------------------------------------
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  query,
  where,
  onSnapshot
} from 'firebase/firestore';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead } from '../../components/_dashboard/user';
//
import { db } from '../../App';
import EventListToolbar from '../../components/_dashboard/user/EventListToolbar';
import EventMoreMenu from '../../components/_dashboard/user/EventMoreMenu';
import DeletedTimeResi from '../../components/_dashboard/resident/DeletedTimeResi';
import Label from '../../components/Label';
import { DeleteEvent } from '../Wellbeing/DeletedWellness';
import repeatOutline from '@iconify/icons-eva/repeat-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
// ------------------------End for popup box-------------------------

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  // { id: 'title', label: 'Title', alignRight: false },
  // { id: 'location', label: 'Location', alignRight: false },
  // { id: 'startDate', label: 'Start Date', alignRight: false },
  // { id: 'startTime', label: 'Start Time', alignRight: false },
  // { id: 'endDate', label: 'End Date', alignRight: false },
  // { id: 'endTime', label: 'End Time', alignRight: false },
  // { id: 'createdAt', label: 'Date', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'facilityName', label: 'Group', alignRight: false },
  { id: 'location', label: 'Address', alignRight: false },
  { id: 'startDate', label: 'Start Date', alignRight: false },
  { id: 'startTime', label: 'Start Time', alignRight: false },
  { id: 'endDate', label: 'End Date', alignRight: false },
  { id: 'endTime', label: 'End Time', alignRight: false },
  { id: 'likes', label: 'Likes', alignRight: false },
  { id: 'commentsCount', label: 'Comments', alignRight: false },
  { id: 'deletedAt', label: 'Delete', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
  // { id: 'download', label: 'Download', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//* **************Add Users From Firebase**********************
export const addUser = (likes, title, comments) => {
  const newCityRef = doc(collection(db, 'users'));
  return setDoc(newCityRef, {
    id: newCityRef.id,
    title,
    comments,
    likes
  });
};

//* **************Delete Users From Firebase**********************
export const deleteUser = (id) =>
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteDoc(doc(db, 'users', id))
        .then((res) => window.location.reload(false))
        .catch((err) => console.log(err.message));
      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });

//* **************Delete Users From Firebase**********************
export const restoreEvent = (data) => {
  console.log(data.id);
  console.log(data);
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to be restore this event?',
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, restore it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      // ----------------------------------------------Loading-------------
      let timerInterval;
      Swal.fire({
        title: 'Processing...',
        html: 'I will close in few<b></b> Moments.',
        // timer: 2000,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer');
        }
      });
      // ----------------------------------------------Loading---------------------
      await deleteDoc(doc(db, 'recycleBin', 'events', 'events', data.id));
      // .then(async (res) => {
      await setDoc(doc(db, 'events', data.id), {
        ...data
      });
      //   window.location.reload(false);
      // })
      // .catch((err) => console.log(err.message));
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Event has been restored.',
        showConfirmButton: false,
        timer: 1500
      });
      window.location.reload(false);
    }
  });
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, eventDate) {
  if (eventDate && !query) {
    return array.filter((d) => new Date(d.startDate).getDate() >= new Date(eventDate).getDate());
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Events() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);
  const [value, setValue] = useState();

  const [open, setOpen] = useState(false);
  const [modelRow, setModelRow] = useState('');

  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Noticeboard From Firebase**********************

  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'recycleBin', 'events', 'events'),
      where('facilitiesId', 'in', user?.facilityIds)
    );
  } else if (user?.role === 'admin') {
    userCollection = collection(db, 'recycleBin', 'events', 'events');
  }

  // const userCollection = collection(db, 'recycleBin', 'events', 'events');
  const noticeboard = [];
  const getUsers = async () => {
    // getDocs(userCollection).then((res) => {
    onSnapshot(userCollection, (res) => {
      res.forEach((result) => {
        noticeboard.push({
          id: result.data().id,
          avatarUrl: result.data()?.medias[0]?.downloadUrl,
          title: result.data().title,
          commentsCount: result.data().commentsCount ?? 0,
          likes: result.data().likes ?? 0,
          // startDate: result.data().startDate.toDate().toDateString(),
          startDate: result.data().startDate,
          // startTime: `${result.data().startTime} ${result.data().startTimePeriod}`,
          startTime: result.data().startTime,
          // endDate: result.data().endDate.toDate().toDateString(),
          endDate: result.data().endDate,
          // endTime: `${result.data().endTime} ${result.data().endTimePeriod}`,
          endTime: result.data().endTime,
          // createdAt: result.data().createdAt.toDate().toDateString(),
          createdAt: result.data().createdAt,
          deletedAt: result.data()?.deletedAt,
          // images: result.data().images,
          medias: result.data()?.medias ?? [],
          description: result.data().description,
          location: result.data().location,
          endTimePeriod: result.data()?.endTimePeriod,
          startTimePeriod: result.data()?.startTimePeriod,
          residentsId: result.data().residentsId,
          facilityName: result.data().facilityName,
          facilitiesId: result.data().facilitiesId,
          postedById: result.data()?.postedById,
          roomsId: result.data().roomsId
        });
      });
      if (!noticeboard.length) {
        return setNotFound(true);
      }
      setUSERLIST(noticeboard);
    });
  };

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  const handleFilterByDate = (event) => {
    // console.log(event);
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setValue(event);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName, value);

  const isUserNotFound = filteredUsers.length === 0;

  // ------------------------for popup box-------------------------
  const handleClickOpen = (title) => {
    setModelRow(title);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ------------------------End for popup box-------------------------

  return (
    <>
      <Page title="Events | Rel8">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Deleted events
            </Typography>
          </Stack>

          <Card>
            <EventListToolbar
              selected={selected}
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              value={value}
              filterByDate={handleFilterByDate}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    deletedTab={true}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          title,
                          description,
                          commentsCount,
                          startDate,
                          startTime,
                          endDate,
                          endTime,
                          createdAt,
                          likes,
                          location,
                          medias,
                          startTimePeriod,
                          endTimePeriod,
                          residentsId,
                          roomsId,
                          facilitiesId,
                          facilityName,
                          deletedAt
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            description="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            /> */}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="2px">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={title} src={avatarUrl} /> */}
                                <Typography
                                  variant="subtitle2"
                                  component={RouterLink}
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    color: '#212B36',
                                    fontWeight: 'bold'
                                  }}
                                  // to="/content/events/event-detail"
                                  to="/recycleBin/deleted-events-details"
                                  state={{
                                    id,
                                    title,
                                    location,
                                    description,
                                    medias,
                                    startDate,
                                    startTime,
                                    endTime,
                                    endDate,
                                    createdAt,
                                    likes,
                                    commentsCount: 0,
                                    startTimePeriod,
                                    endTimePeriod,
                                    residentsId,
                                    roomsId,
                                    facilitiesId,
                                    facilityName,
                                    object: row,
                                    deleted: 'yes'
                                  }}
                                >
                                  {title.length < 40 ? (
                                    title
                                  ) : (
                                    <>
                                      {title.slice(0, 35)}
                                      <span
                                        style={{
                                          color: 'blue',
                                          cursor: 'pointer',
                                          marginLeft: '5px'
                                        }}
                                        onClick={() => handleClickOpen(title)}
                                        role="button"
                                        tabIndex="0"
                                        onKeyDown={handleClick}
                                      >
                                        Read more...
                                      </span>
                                    </>
                                  )}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{facilityName}</TableCell>
                            <TableCell align="left">
                              {location.length < 30 ? (
                                location
                              ) : (
                                <>
                                  {location.slice(0, 27)}
                                  <br />
                                  <span
                                    style={{
                                      color: 'blue',
                                      cursor: 'pointer',
                                      marginLeft: '5px'
                                    }}
                                    onClick={() => handleClickOpen(location)}
                                    role="button"
                                    tabIndex="0"
                                    onKeyDown={handleClick}
                                  >
                                    Read more...
                                  </span>
                                </>
                              )}
                            </TableCell>
                            {/* <TableCell align="left">{commentsCount}</TableCell>
                            <TableCell align="left">{likes.length}</TableCell> */}
                            <TableCell align="left">
                              {moment(startDate?.toDate()).format('DD MMM YYYY')}
                            </TableCell>
                            <TableCell align="left">{`${startTime}${startTimePeriod}`}</TableCell>
                            {/* <TableCell align="left">{endDate.toDate().toDateString()}</TableCell> */}
                            <TableCell align="left">
                              {moment(endDate?.toDate()).format('DD MMM YYYY')}
                            </TableCell>
                            <TableCell align="left">{`${endTime}${endTimePeriod}`}</TableCell>
                            <TableCell align="center">{likes?.length ?? 0}</TableCell>
                            <TableCell align="center">{commentsCount ?? 0}</TableCell>
                            {/* <TableCell align="left">
                              {moment(createdAt?.toDate()).format('DD/MM/YYYY')}
                            </TableCell> */}
                            <TableCell align="left">
                              <Label variant="ghost" color="error">
                                {DeletedTimeResi(deletedAt)}-remaining
                              </Label>
                            </TableCell>

                            <TableCell align="right">
                              <Stack spacing={2} direction={{ xs: 'row', sm: 'row' }}>
                                <Tooltip title="Restore Event" placement="top">
                                  <LoadingButton
                                    size="small"
                                    variant="contained"
                                    // loading={isLoading}
                                    onClick={() => {
                                      restoreEvent(row);
                                    }}
                                    disabled={
                                      JSON.parse(localStorage.getItem('Rel8User')).role ===
                                        'Family Member' ?? false
                                    }
                                  >
                                    {/* Restore */}
                                    <Icon icon={repeatOutline} style={{ fontSize: '20px' }} />
                                  </LoadingButton>
                                </Tooltip>
                                <Tooltip title="Delete Event" placement="top">
                                  <LoadingButton
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      DeleteEvent(row?.id, 'events', 'events');
                                    }}
                                    disabled={
                                      JSON.parse(localStorage.getItem('Rel8User')).role ===
                                        'Family Member' ?? false
                                    }
                                  >
                                    <Icon icon={trash2Outline} style={{ fontSize: '20px' }} />
                                  </LoadingButton>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound &&
                    (NotFound ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                            <UserNotFound />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      {/* ***************************** Start PopUp ************************* */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', fontWeight: '700' }} id="draggable-dialog-title">
          {modelRow}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText sx={{ color: '#34495E' }}>{modelRow?.description}</DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* ***************************** End PopUp ************************* */}
    </>
  );
}
