import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Swal from 'sweetalert2';
import axios from 'axios';
import { cloudFuncBaseUrl } from 'src/utils/config';
import { localUser } from 'src/App';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

export function listUserDelete(array, collect) {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    // allowOutsideClick: false,
    showDenyButton:
      collect === 'rooms' || collect === 'users' || collect === 'facilites' ? false : true,
    denyButtonText: `Delete forever`,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: 'gray',
    confirmButtonText: 'Yes, delete it!'
  }).then(async (result) => {
    // Delete event to recycle bin
    if (result.isConfirmed) {
      // ----------------------------------------------Loading-------------
      let timerInterval;
      Swal.fire({
        title: 'Deleting...',
        html: 'I will close in few<b></b> Moments.',
        // timer: 2000,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer');
        }
      });
      // ----------------------------------------------Loading---------------------
      try {
        // for users
        if (collect === 'users') {
          for (let i of array) {
            await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/adminDeleteFamilyAndFacilityMember`,
              data: {
                userId: i
              }
            });
          }
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Deleted Successfully.',
            showConfirmButton: false,
            timer: 1500
          });
          return window.location.reload(false);
        }

        // for drafts
        if (collect === 'drafts') {
          for (let i of array) {
            await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
              data: {
                id: i?.id,
                collectionName: collect,
                role: localUser.role
              }
            });
          }
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Deleted Successfully.',
            showConfirmButton: false,
            timer: 1500
          });
          return window.location.reload(false);
        }

        // for over all
        for (let i of array) {
          const res = await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
            data: {
              id: i,
              collectionName: collect,
              role: localUser.role,
              delete: true
            }
          });
          console.log(res);
        }

        // ----------------------------------

        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Deleted Successfully.',
          showConfirmButton: false,
          timer: 1500
        });
        window.location.reload(false);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message
        });
      }
      // Delete event permanently
    } else if (result.isDenied) {
      try {
        // ----------------------------------------------Loading-------------
        let timerInterval;
        Swal.fire({
          title: 'Deleting...',
          html: 'I will close in few<b></b> Moments.',
          // timer: 2000,
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector('b');
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer');
          }
        });
        // ----------------------------------------------Loading---------------------

        if (collect === 'drafts') {
          for (let i of array) {
            await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/DeleteAllDataPermanently`,
              data: {
                id: i?.id,
                collection: `${collect} ${i?.type === 'noticeboard' ? 'noticeboards' : i?.type}`
              }
            });
          }
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Deleted Successfully.',
            showConfirmButton: false,
            timer: 1500
          });
          return window.location.reload(false);
        }

        for (let i of array) {
          await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/DeleteAllDataPermanently`,
            data: {
              id: i,
              collection: collect
            }
          });
        }
        // ----------------------------------
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Deleted successfully.',
          showConfirmButton: false,
          timer: 1500
        });
        window.location.reload(false);
      } catch (err) {
        return Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err.message
        });
      }
    }
  });
}

EventListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function EventListToolbar({
  selected,
  numSelected,
  filterName,
  onFilterName,
  value,
  filterByDate
}) {
  console.log(value);
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search..."
          type="search"
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete" onClick={() => listUserDelete(selected, 'events')}>
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      )  }
    </RootStyle>
  );
}

// (
//   <Tooltip title="Filter list">
//     <IconButton>
//       <LocalizationProvider dateAdapter={AdapterDateFns}>
//         <DesktopDatePicker
//           label="Filter by Date"
//           format='dd/MM/yyyy'
//           inputFormat="dd/MM/yyyy"
//           value={value}
//           onChange={filterByDate}
//           renderInput={(params) => <TextField {...params} />}
//         />
//       </LocalizationProvider>
//     </IconButton>
//   </Tooltip>
// )