import React, { useEffect, useState } from 'react';
import './commentBox.module.css';
import MessageIcon from '../../../assest/messageIcon2.svg';
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  orderBy,
  onSnapshot,
  deleteDoc,
  updateDoc
} from 'firebase/firestore';
import heartOutline from '@iconify/icons-eva/heart-outline';
import heartFill from '@iconify/icons-eva/heart-fill';
import { Icon } from '@iconify/react';
// material
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
  styled,
  Grid,
  Avatar
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactPlayer from 'react-player';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import Linkify from 'react-linkify';
import ReactLoading from 'react-loading';

import Scrollbar from '../../Scrollbar';
import { db } from '../../../App';
import { deleteFolder } from '../user/deleteFiles';
import axios from 'axios';
import { cloudFuncBaseUrl } from 'src/utils/config';

const localUser = JSON.parse(localStorage.getItem('Rel8User'));

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const ProductImgStyle = {
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
};



const RenderText = ({ description }) => (
  <div>
    {description?.split('\n\n\n').map((block, blockIndex) => (
      <div key={blockIndex}>
        {block.split('\n\n').map((paragraph, paraIndex) => (
          <div key={paraIndex} style={{ marginBottom: '0.3em' }}>
            {paragraph.split('\n').map((line, lineIndex) => (
              <React.Fragment key={lineIndex}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        ))}

        {/* {block.length-1 != blockIndex && <br />}   */}
      </div>
    ))}
  </div>
);

// ------------------------------------------------------------------------------------------------------------------------
// ---------------------------------Like or not comment------------------------
// ---------------------------------Like or not comment------------------------
const outlineOrFill = (comment) => {
  const likes = comment.likes ?? [];
  const userLike = [];
  likes?.forEach((e) => {
    if (e === localUser.id) {
      userLike.push(e);
    }
  });
  if (userLike.length === 0) {
    return 0;
  }
  if (userLike.length > 0) {
    return 1;
  }
};
// ---------------------------------Like comment------------------------
// ---------------------------------Like comment------------------------
const commentLike = async (comment, object, collections) => {
  try {
    const likes = comment.likes ?? [];

    const userLike = [];
    likes?.forEach((e) => {
      if (e === localUser?.id) {
        userLike.push(e);
      }
    });
    if (userLike.length > 0) {
      const index = likes.indexOf(localUser?.id);
      likes.splice(index, 1);

      // delete nofification when dislike
      const commentNotifiRef = query(
        collection(db, 'notifications'),
        where('commentId', '==', comment?.id),
        where('senderId', '==', localUser?.id)
      );
      const snapshot = await getDocs(commentNotifiRef);
      snapshot.forEach(async (e) => {
        await deleteDoc(doc(db, 'notifications', e.data()?.id));
      });
      // ---------------------
    } else {
      likes.push(localUser?.id);
      // -----------------------------------------Comment Notifications------------------------------------
      let type;
      if (collections === 'stories') {
        type = 'stories';
      } else if (collections === 'events') {
        type = 'events';
      } else if (collections === 'noticeboards') {
        type = 'noticeboard';
      } else if (collections === 'wellness') {
        type = 'wellness';
      }

      if (localUser?.id !== comment?.writerId) {
        const commentNotifiRef = doc(collection(db, 'notifications'));
        setDoc(commentNotifiRef, {
          id: commentNotifiRef.id,
          body: `${localUser?.firstname} liked your comment`,
          commentId: comment.id,
          createdAt: new Date(),
          profileImgUrl: localUser?.avatarUrl ?? null,
          title: 'Got a like',
          topic: 'comment',
          viewedBy: null,
          senderId: localUser?.id,
          // receiverId: object?.postedById,
          receiverId: comment?.writerId,
          object,
          senderName: `${localUser?.firstname} ${localUser?.lastname}`,
          type
        })
          .then(() => console.log('comment liked'))
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.message
            });
          });
      }
      // -----------------------------------------Comment Notifications------------------------------------
    }

    await updateDoc(doc(db, 'comments', comment.id), {
      likes
    });
  } catch (err) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: err.message
    });
  }
};

// ------------------------------------------------------------------------------------------------------------------------

export default function NewCommentBox({
  storyId,
  commentsCount,
  collections,
  object,
  deleted,
  likeColor,
  getLikes,
  setlikeColor
}) {
  object.createdAt = new Date();
  // console.log(object);
  if (Navigator.online) {
  }
  const [allcomments, setComments] = useState([]);
  const [allReplies, setAllReplies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [replyLoading, setReplyLoading] = useState(true);

  // get comment

  useEffect(async () => {
    const q = query(
      collection(db, 'comments'),
      where('storyId', '==', storyId),
      where('repliedId', '==', null),
      orderBy('createdAt', 'desc')
    );

    let comments = [];
    onSnapshot(q, async (querySnapshot) => {
      setLoading(true);
      setReplyLoading(true);
      querySnapshot.forEach(async (doc) => {
        comments.push(doc.data());
      });
      // const areaResponseArr = await Promise.all(promises);
      // areaResponseArr.forEach((item) => {
      //   item.forEach((subdoc) => {
      //     replies.push(subdoc.data());
      //   });
      // });
      setComments(comments);
      comments = [];
      setLoading(false);
    });
  }, [storyId]);

  useEffect(async () => {
    setReplyLoading(true);

    const ids = allcomments.map((element) => element.id);
    console.log("ids ",ids)
    if(ids.length>0){

      const q = query(collection(db, 'comments'), where('repliedId', 'in', ids));
      
      onSnapshot(q, async (querySnapshot) => {
        setAllReplies([]);
        const replies = [];
        querySnapshot.forEach((e) => {
          replies.push(e.data());
        });
        setAllReplies(replies);
        setReplyLoading(false);
      });
    }
  }, [allcomments, storyId]);

  const RenderText = ({ description }) => (
    <div>
      {description?.split('\n\n\n').map((block, blockIndex) => (
        <div key={blockIndex}>
          {block.split('\n\n').map((paragraph, paraIndex) => (
            <div key={paraIndex} style={{ marginBottom: '1em' }}>
              {paragraph.split('\n').map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          ))}
          <br />  {/* Add extra line break to distinguish between blocks */}
        </div>
      ))}
    </div>
  );

  return (
    <Box id="CommentBoxId" style={{ paddingRight: '0', width: '100%' }}>
      <Box
        sx={{
          marginLeft: '0',
          color: '#9A76F5',
          fontWeight: 500,
          fontSize: '25px',
          display: 'flex',
          flexDirection: 'row',
          gap: '5px'
        }}
      >
        
        <img src={MessageIcon} style={{ maxWidth: '45px', minWidth: '45px' }} /> <div>Comments</div>
      </Box>
      <Scrollbar style={{ paddingRight: '0%' }}>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {/* {NEWS.map((news) => (
            <NewsItem key={news.title} description={description} />
          ))} */}
          {loading && (
            <div
              style={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999
              }}
            >
              <ReactLoading type="spokes" color="#08639B" height={100} width={100} />
            </div>
          )}
        </Stack>
        <CommentSection
          comments={allcomments}
          allReplies={allReplies}
          storyId={storyId}
          commentsCount={commentsCount}
          collections={collections}
          object={object}
          deleted={deleted}
          replyLoading={replyLoading}
          likeColor={likeColor}
          getLikes={getLikes}
          setlikeColor={setlikeColor}
        />
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }} />
    </Box>
  );
}

const sendComment = async (
  comment,
  storyId,
  // commentsCount,
  CommentReplyWriterId,
  setcommentsCounts,
  isReply,
  replyId,
  setIsReply,
  location,
  collections,
  CommentReplyWriterName,
  object,
  commentRepliesId
) => {
  try {
    // console.log('replyId !!', replyId);
    const commentsRef = doc(collection(db, 'comments'));
    let type;
    if (collections === 'stories') {
      type = 'stories';
    } else if (collections === 'events') {
      type = 'events';
    } else if (collections === 'noticeboards') {
      type = 'noticeboard';
    } else if (collections === 'wellness') {
      type = 'wellness';
    }
    if (isReply) {
      setIsReply(false);
      await setDoc(commentsRef, {
        id: commentsRef.id,
        comment: comment.substr(CommentReplyWriterName.length),
        likes: null,
        medias: [],
        replies: [],
        repliedId: replyId,
        repliedTo: CommentReplyWriterName,
        repliedToId: CommentReplyWriterId,
        storyId,
        writerId: localUser?.id,
        writerName: `${localUser?.firstname} ${localUser?.lastname}`,
        writerProfileImage: localUser?.avatarUrl ?? null,
        createdAt: new Date()
      });
      await updateDoc(doc(db, 'comments', replyId), {
        replies: [...commentRepliesId, commentsRef.id]
      });
      // https://corsproxyapi.herokuapp.com/https://us-central1-rel8app-services-test.cloudfunctions.net/addDeleteCommentCount

      // -----------------------------------------Comment Reply Notifications------------------------------------
      if (localUser?.id !== CommentReplyWriterId) {
        if (object.startDate) {
          object.startDate = new Date(object?.startDate?.seconds * 1000);
          object.endDate = new Date(object?.endDate?.seconds * 1000);
        }
        delete object.avatarUrl;

        const commentNotifiRef = doc(collection(db, 'notifications'));
        await setDoc(commentNotifiRef, {
          id: commentNotifiRef.id,
          body: `${localUser?.firstname} replied to your comment`,
          // commentId: replyId,
          commentId: commentsRef.id,
          createdAt: new Date(),
          profileImgUrl: localUser?.avatarUrl ?? null,
          title: 'Got a reply',
          topic: 'comment',
          viewedBy: null,
          senderId: localUser?.id,
          receiverId: CommentReplyWriterId,
          object,
          senderName: `${localUser?.firstname} ${localUser?.lastname}`,
          type
        });
      }
      await axios({
        method: 'post',
        url: `${cloudFuncBaseUrl}.cloudfunctions.net/addDeleteCommentCount`,
        data: {
          id: storyId,
          collection: collections,
          type: 'post'
        }
      });
      // -----------------------------------------Comment Notifications------------------------------------
    } else {
      await setDoc(commentsRef, {
        id: commentsRef.id,
        comment,
        likes: null,
        medias: [],
        replies: [],
        repliedId: null,
        storyId,
        writerId: localUser?.id,
        writerName: `${localUser?.firstname} ${localUser?.lastname}`,
        writerProfileImage: localUser?.avatarUrl ?? null,
        createdAt: new Date()
      });

      // -----------------------------------------Comment Notifications------------------------------------
      if (localUser?.id !== object?.postedById) {
        const commentNotifiRef = doc(collection(db, 'notifications'));
        await setDoc(commentNotifiRef, {
          id: commentNotifiRef.id,
          body: `${localUser?.firstname} commented on your post`,
          commentId: commentsRef.id,
          createdAt: new Date(),
          profileImgUrl: localUser?.avatarUrl ?? null,
          title: 'Got a comment',
          topic: 'comment',
          viewedBy: null,
          senderId: localUser?.id,
          receiverId: !object?.mood ? object?.postedById : object?.createdById,
          object,
          senderName: `${localUser?.firstname.trim()} ${localUser?.lastname.trim()}`,
          type
        });
      }
      await axios({
        method: 'post',
        url: `${cloudFuncBaseUrl}.cloudfunctions.net/addDeleteCommentCount`,
        data: {
          id: storyId,
          collection: collections,
          type: 'post'
        }
      });
      // -----------------------------------------Comment Notifications------------------------------------
    }

    // for collection commentsCount add
    // const incrementEventComment = doc(db, collections, storyId);
    // await updateDoc(incrementEventComment, { commentsCount: commentsCounts + 1 });
    // setcommentsCounts(commentsCounts + 1);
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message
    });
  }
};

// ------------------------------------------------remove all extra spaces---------------------
const removeExtraSpaces = (string) => {
  const newText = string
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/g, '')
    .replace(/ +(\W)/g, '$1');
  return newText;
};
// ------------------------------------------------remove all extra spaces---------------------

const CommentSection = ({
  allReplies,
  comments,
  storyId,
  commentsCount,
  collections,
  object,
  deleted,
  replyLoading,
  likeColor,
  getLikes,
  setlikeColor
}) => {
  const [commentsCounts, setcommentsCounts] = useState(commentsCount);
  const [comment, setComment] = useState();
  const [update, setUpdate] = useState();
  const [replyHit, setReplyHit] = useState(false);
  const [CommentReplyWriterName, setCommentReplyWriterName] = useState('');
  const [CommentReplyWriterId, setCommentReplyWriterId] = useState('');
  const [file, setFile] = useState([]);
  const [count, setCount] = useState(0);
  const [isReply, setIsReply] = useState(false);
  const [replyId, setReplyId] = useState();
  const [commentRepliesId, setCommentRepliesId] = useState([]);
  const [disable, setdisable] = useState(null);
  const [CommentIdUpdate, setCommentIdUpdate] = useState('');
  object.avatarUrl = object.avatarUrl ?? null;
  const locationOne = useLocation();

  const likeFunction = async (storyId, object, collections, likes, likeColor, deleted) => {
    const user = localUser;
    console.log('likes : : ', likes);
    console.log('getLikes : : ', getLikes);
    try {
      const like = likes ?? [];
      const userLike = [];
      like?.forEach((e) => {
        if (e === user?.id) {
          userLike.push(e);
        }
      });
      console.log('userLike : : ', userLike);
      if (userLike.length > 0) {
        console.log('inside : : ', userLike);
        const index = like.indexOf(user?.id);
        like.splice(index, 1);
        setlikeColor(false);
      } else {
        setlikeColor(true);
        like.push(user?.id);
      }
      console.log;
      await updateDoc(doc(db, collections, storyId), {
        likes: like
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.message
      });
    }
  };

  useEffect(() => {
    setCount(count + 1);
    if (replyHit) {
      setReplyHit(false);
    }
  }, [locationOne]);

  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  };

  const handleFileUpload = async (e) => {
    let thumbnail;
    const ImagesArray = e.target.files[0];
    const { size } = e.target.files[0];
    if (size / 1024 > 20480) {
      return NotificationManager.error('File size is greater than 20 mb');
    }
    if (file.length >= 6) {
      return NotificationManager.error('maximum 6 files to upload');
    }
    const downloadUrl = Object.entries(e.target.files).map((e) => URL.createObjectURL(e[1]));
    const mediaTypeLocal = e.target.files[0].type.split('/')[0];
    if (mediaTypeLocal === 'video') {
      thumbnail = await generateVideoThumbnail(e.target.files[0]);
    }
    const obj = {
      file: ImagesArray,
      downloadUrl,
      thumbnail,
      size,
      type: mediaTypeLocal
    };
    setFile([...file, { ...obj }]);
  };

  // ---------------------------------------------------------------------------------------
  const deleteOrNot = (comment) => {
    if (localUser?.role === 'admin') {
      return 1;
    }
    if (comment.writerId === localUser?.id) {
      return 1;
    }
    return 0;
  };

  // ---------------------------------------------------------------------------------------
  const deleteComment = (
    id,
    file,
    // commentsCounts,
    // setcommentsCounts,
    storyId,
    collections,
    replies
  ) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You delete this comment!',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // ************** delete comment and its notification
          // let nmbr = 0;
          // https://corsproxyapi.herokuapp.com/
          await deleteDoc(doc(db, 'comments', id));

          const notiRef = query(collection(db, 'notifications'), where('commentId', '==', id));
          const notiData = await getDocs(notiRef);
          notiData?.forEach(async (e) => {
            await deleteDoc(doc(db, 'notifications', e.data()?.id));
          });
          await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/addDeleteCommentCount`,
            data: {
              id: storyId,
              collection: collections,
              type: 'delete'
            }
          });
          // ************** delete comment and its notification

          const q = query(collection(db, 'comments'), where('repliedId', '==', id));
          const data = await getDocs(q);
          data?.forEach(async (e) => {
            await deleteDoc(doc(db, 'comments', e.data().id));
            await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/addDeleteCommentCount`,
              data: {
                id: storyId,
                collection: collections,
                type: 'delete'
              }
            });
            // ***********delete notification
            const notiRef = query(collection(db, 'notifications'), where('repliedId', '==', id));
            const notiData = await getDocs(notiRef);
            notiData?.forEach(async (e) => {
              await deleteDoc(doc(db, 'notifications', e.data()?.id));
            });
          });

          // for collection commentsCount subtract when delete comment
          // const decrimentEventComment = doc(db, collections, storyId);
          // await updateDoc(decrimentEventComment, { commentsCount: commentsCounts - nmbr });
          // setcommentsCounts(commentsCounts - nmbr);

          for (let i = 0; i < file?.length; i += 1) {
            deleteFolder(file[i]?.downloadUrl, file[i]?.thumbnail);
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message
      });
    }
  };

  const location = useLocation();
  //Reply Hit

  const handleReplyClick = () => {
    if (replyHit) {
      const commentElement = document.getElementById('CommentBoxId');
      const commentElementTextArea = document.getElementById('my-comment');
      if (commentElement) {
        commentElement.scrollIntoView({ block: 'start', inline: 'nearest' });
        commentElementTextArea.focus();
      }
    }
  };

  const [commetId, setCommentid] = useState('');
  const [repliesLoaded, setRepliesLoaded] = useState(false);

  // Scroll to the Comment and Highlight the Comment

  //   useEffect(()=>{
  //     console.log("comments")
  //     if(commetId != CommentID)
  //     setCommentid(CommentID);
  //   if(  CommentID && CommentID.endsWith('rp')? allReplies.length>0 && comments.length >0 : comments.length >0){
  //     console.log("replies ", allReplies)
  //     if(update != storyId)
  //     setUpdate(storyId);

  //     if(!replyHit){
  //     if (CommentID  ) {
  //     const commentElement = document.getElementById(CommentID);
  //     {CommentID && CommentID.endsWith('rp') && setRepliesLoaded(true)}
  //     if(commentElement === null){
  //       setRepliesLoaded(!repliesLoaded)
  //       return;
  //     }
  //     console.log("cmt elemnt ", commentElement)
  //     if (commentElement) {
  //       commentElement.style.scrollMargin = '20vh';
  //       commentElement.style.borderRadius = '10px';
  //       commentElement.style.backgroundColor = 'rgba(237, 227, 255, 0.4)';
  //       commentElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }); // use object for scrollIntoView
  //       {CommentID && CommentID.endsWith('rp') && setRepliesLoaded(true)}
  //       setTimeout(function () {
  //         commentElement.style.backgroundColor = 'rgba(237, 227, 255, 0)';
  //       }, 3500);

  //     }
  //   }
  //   }}
  // },[comments, allReplies, replyHit,  commetId, repliesLoaded])

  let CommentID = new URLSearchParams(location.search).get('c_id');
  let commentElement = '';
  commentElement = document.getElementById(CommentID);
  setTimeout(
    function () {
      if (
        CommentID && CommentID.endsWith('rp')
          ? allReplies.length > 0 && comments.length > 0
          : comments.length > 0
      ) {
        if (!replyHit) {
          if (CommentID) {
            let commentElement = '';
            commentElement = document.getElementById(CommentID);
            if (commentElement) {
              commentElement.style.scrollMargin = '15vh';
              commentElement.style.borderRadius = '10px';
              commentElement.style.backgroundColor = 'rgba(237, 227, 255, 0.4)';
              commentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
              }); // use object for scrollIntoView

              setTimeout(function () {
                if (CommentID && !CommentID.endsWith('rp')) {
                  commentElement.style.backgroundColor = 'rgba(237, 227, 255, 0)';
                }
                if (CommentID && CommentID.endsWith('rp')) {
                  commentElement.style.backgroundColor = 'rgb(248,	249,	250)';
                }
                CommentID = '';
              }, 5000);
            }
          }
        }
      }
    },
    CommentID && CommentID.endsWith('rp') ? (commentElement === null ? 2000 : 0) : 0
  );

  return (
    <div className="" style={{ width: '100%', paddingLeft: '5%' }}>
      <div className="p-3">
        <div className="">
          <div className="">
            <div className="d-flex">
              <NotificationContainer />
              {/* <img
                className="rounded-circle me-3"
                style={{ width: '3rem', height: '3rem', objectFit: 'cover' }}
                src={JSON.parse(localStorage.getItem('Rel8User')).avatarUrl}
                alt={JSON.parse(localStorage.getItem('Rel8User')).firstname?.slice(0, 1)}
              /> */}

              <div className="flex-grow-1">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    marginBottom: '10px'
                  }}
                >
                  {localUser.avatarUrl ? (
                    <Avatar src={localUser.avatarUrl} alt="photoURL" />
                  ) : (
                    <Avatar src="" sx={{ backgroundColor: '#9A76F5', fontWeight: 400 }}>
                      {localUser.firstname?.slice(0, 1)?.toUpperCase()}
                      {localUser.lastname?.slice(0, 1)?.toUpperCase()}
                    </Avatar>
                    // <Avatar src="">{account.displayName?.slice(0, 1)}</Avatar>
                  )}
                  <div className="hstack gap-2 mb-1">
                    <div className="fw-bold link-dark" style={{ color: '#9A76F5' }}>
                      {`${localUser?.firstname} ${localUser?.lastname}`}
                    </div>
                  </div>
                </div>
                <div className=" mb-3">
                  <textarea
                    className="form-control w-100"
                    placeholder=""
                    id="my-comment"
                    style={{ height: '7rem', borderRadius: '15px', border: '2px solid #DFDFDF' }}
                    value={comment}
                    disabled={deleted === 'yes' && true}
                    onChange={(e) => {
                      setComment(e.target.value);
                      setdisable(e.target.value);
                    }}
                  >
                    {comment}
                  </textarea>
                  {/* <label htmlFor="form-controlf">Leave a comment here</label> */}
                </div>
                <div className="hstack justify-content-end gap-3">
                  {/* <Button variant="" component="label">
                    <Icon icon="carbon:document-attachment" width={18} />
                    <input
                      type="file"
                      hidden
                      fullWidth
                      accept="image/*,video/*"
                      onChange={handleFileUpload}
                      // error={Boolean(touched.photo && errors.photo)}
                      // helperText={touched.photo && errors.photo}
                    />
                  </Button> */}
                  <Icon
                    icon={likeColor != 0 ? heartFill : heartOutline}
                    style={{
                      fontSize: '35px',
                      paddingRight: 'px',
                      color: '#9a76f5',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      deleted !== 'yes' && likeFunction(storyId, object, collections, getLikes);
                    }}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    onClick={() => {
                      sendComment(
                        comment,
                        storyId,
                        // commentsCount,
                        CommentReplyWriterId,
                        setcommentsCounts,
                        isReply,
                        replyId,
                        setIsReply,
                        location,
                        collections,
                        CommentReplyWriterName,
                        object,
                        commentRepliesId
                      );
                      setdisable('');
                      setComment('');
                      setCommentReplyWriterName('');
                      setCommentReplyWriterId('');
                    }}
                    disabled={!disable ?? false}
                  >
                    Comment
                  </Button>
                </div>
              </div>
            </div>

            <Grid container spacing={2}>
              {file.length > 0 &&
                file.map((item, index) => (
                  <Grid item xs={4} sm={4} md={2}>
                    <Icon
                      icon="ci:close-big"
                      onClick={() => deleteFile(index)}
                      style={{ cursor: 'pointer' }}
                    />
                    <Card style={{}}>
                      <Box sx={{ pt: '100%', position: 'relative' }}>
                        <ProductImageStyle alt="Image" src={item.thumbnail ?? item.downloadUrl} />
                      </Box>
                    </Card>
                  </Grid>
                ))}
            </Grid>
            <br />
            <br />
            {comments?.length === 0 && 'No Comment'}
            {comments?.map((x, index) => {
              return (
                <>
                  <div className="d-flex comment mb-4" id={x.id + 'cmt'} key={x.id + index}>
                    {/* <input
                    type="image"
                    img
                    width={60}
                    height={60}
                    className="rounded-circle comment-img"
                    src={x.writerProfileImage}
                    alt="photo"
                    style={{ objectFit: 'cover' }}
                  /> */}
                    {/* <Avatar alt="photo" src={x.writerProfileImage} /> */}
                    {x.writerProfileImage ? (
                      <Avatar src={x.writerProfileImage} alt="photoURL" />
                    ) : (
                      <Avatar src="" sx={{ backgroundColor: '#9A76F5', fontWeight: 400 }}>
                        {x.writerName?.split(' ')[0].slice(0, 1)?.toUpperCase()}
                        {x.writerName?.split(' ')[1].slice(0, 1)?.toUpperCase()}
                      </Avatar>
                      // <Avatar src="">{account.displayName?.slice(0, 1)}</Avatar>
                    )}
                    <div className="flex-grow-1 ms-3">
                      <div className="">
                        <div className="" style={{ fontWeight: 700 }}>
                          {x.writerName}
                        </div>
                        {/* <span
                          className="text-muted text-nowrap"
                          style={{ fontSize: '12px', marginTop: '-5px' }}
                        >
                          {moment(x.createdAt.seconds * 1000).fromNow()}
                        </span> */}
                      </div>
                      {x.medias?.length > 0 ? (
                        <Grid container spacing={2}>
                          {x.medias.map((item, index) =>
                            (() => {
                              if (item.type === 'image')
                                return (
                                  <Grid item xs={4} sm={4} md={2}>
                                    <Card style={{}}>
                                      <Box sx={{ pt: '100%', position: 'relative' }}>
                                        <ProductImageStyle alt="Image" src={item.downloadUrl} />
                                      </Box>
                                    </Card>
                                  </Grid>
                                );
                              if (item.type === 'video')
                                return (
                                  <Grid item xs={4} sm={4} md={2}>
                                    <Card style={{}}>
                                      <Box sx={{ pt: '100%', position: 'relative' }}>
                                        <ReactPlayer
                                          style={ProductImgStyle}
                                          url={item.downloadUrl}
                                          playing
                                          controls
                                          light={item.thumbnail}
                                          config={{
                                            file: { attributes: { controlsList: 'nodownload' } }
                                          }}
                                          width="100%"
                                          height="100%"
                                        />{' '}
                                      </Box>
                                    </Card>
                                  </Grid>
                                );
                            })()
                          )}
                        </Grid>
                      ) : (
                        <div className="" style={{ textAlign: 'justify', fontWeight: 400 ,}}>
                          
                            <RenderText description={x.comment} />
                      
                        </div>
                      )}

                      <div
                        className=""
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '20px',
                          fontSize: '12px',
                          fontWeight: 400
                        }}
                      >
                        <div style={{ color: '#999999' }}>
                          {moment(x.createdAt.seconds * 1000).fromNow()}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                          <Icon
                            icon={`ant-design:heart-${
                              outlineOrFill(x) === 0 ? 'outlined' : 'filled'
                            }`}
                            style={{ cursor: '', color: 'black' }}
                            // onClick={() => commentLike(x, object, collections)}
                          />

                          <span className="" style={{ marginLeft: '' }}>
                            {x.likes?.length || 0}
                          </span>
                        </div>
                        <span
                          onClick={() => commentLike(x, object, collections)}
                          style={{ cursor: 'pointer' }}
                        >
                          Like
                        </span>
                        <div
                          className=""
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            if (replyHit) {
                              handleReplyClick();
                            }
                          }}
                          onMouseDown={() => {
                            setReplyHit(true);
                            setIsReply(true);
                            setCommentReplyWriterName(`@${removeExtraSpaces(x?.writerName)} `);
                            setCommentReplyWriterId(x?.writerId);
                            setComment(`@${removeExtraSpaces(x?.writerName)} `);
                            setReplyId(x.id);
                            setCommentRepliesId(x?.replies);
                          }}
                        >
                          Reply
                        </div>
                        {/* -----------------Facility Member not delete comment-------------------- */}
                        {deleteOrNot(x) === 1 && (
                          <div
                            className=""
                            role="button"
                            tabIndex={0}
                            onMouseDown={() =>
                              deleteComment(
                                x.id,
                                x?.medias,
                                // commentsCounts,
                                // setcommentsCounts,
                                storyId,
                                collections,
                                x?.replies
                              )
                            }
                          >
                            Delete
                          </div>
                        )}
                        {/* -----------------Facility Member not delete comment-------------------- */}
                      </div>
                      {/* <a className="fw-bold d-flex align-items-center" href="#">
                    <i className="zmdi zmdi-chevron-down fs-4 me-3" />
                    <span>Hide Replies</span>
                  </a> */}
                    </div>
                  </div>
                  {/* bg-light */}
                  {allReplies
                    ?.sort((a, b) => {
                      const timeA = a.createdAt.seconds;
                      const timeB = b.createdAt.seconds;
                      return timeA - timeB;
                    })
                    .map(
                      // #f8f9fa
                      (item) => {
                        return (
                          item.repliedId === x.id && (
                            <div
                              id={item.id + 'rp'}
                              style={{
                                backgroundColor: '',
                                marginBottom: '12px',
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'end',
                                marginLeft: '20px'
                              }}
                              className="comment-replies   p-3 rounded"
                            >
                              <div className="d-flex py-2" style={{}}>
                                <ReplySection
                                  item={item}
                                  collections={collections}
                                  object={object}
                                  deleteComment={deleteComment}
                                  deleteOrNot={deleteOrNot}
                                  replyHit={replyHit}
                                  setReplyHit={setReplyHit}
                                  setIsReply={setIsReply}
                                  handleReplyClick={handleReplyClick}
                                  setCommentReplyWriterName={setCommentReplyWriterName}
                                  setCommentReplyWriterId={setCommentReplyWriterId}
                                  setComment={setComment}
                                  setReplyId={setReplyId}
                                />
                              </div>
                            </div>
                          )
                        );
                      }
                    )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const ReplySection = ({
  item,
  collections,
  object,
  deleteComment,
  deleteOrNot,
  replyHit,
  setReplyHit,
  setIsReply,
  handleReplyClick,
  setCommentReplyWriterName,
  setCommentReplyWriterId,
  setComment,
  setReplyId
}) => (
  <>
    {/* <input
      type="image"
      img
      width={60}
      height={60}
      className="rounded-circle comment-img"
      src={item.writerProfileImage}
      alt="photo"
      style={{ objectFit: 'cover' }}
    /> */}
    {/* <Avatar alt="photo" src={item.writerProfileImage} /> */}
    {item.writerProfileImage ? (
      <Avatar src={item.writerProfileImage} alt="photoURL" />
    ) : (
      <Avatar src="" sx={{ backgroundColor: '#9A76F5', fontWeight: 400 }}>
        {item.writerName?.split(' ')[0].slice(0, 1)?.toUpperCase()}
        {item.writerName?.split(' ')[1].slice(0, 1)?.toUpperCase()}
      </Avatar>
      // <Avatar src="">{account.displayName?.slice(0, 1)}</Avatar>
    )}
    <div className="flex-grow-1 ms-3">
      <div className="mb-1">
        <div className="" style={{ fontWeight: 700 }}>
          {item.writerName}
        </div>
        {/* <span className="text-muted text-nowrap">
          {moment(new Date(item.createdAt.seconds * 1000)).fromNow()}
        </span> */}
      </div>

      {item.medias?.length > 0 ? (
        <Grid container spacing={2}>
          {item.medias.map((item, index) =>
            (() => {
              if (item.type === 'image')
                return (
                  <Grid item xs={4} sm={4} md={2}>
                    <Card style={{}}>
                      <Box sx={{ pt: '100%', position: 'relative' }}>
                        <ProductImageStyle alt="Image" src={item.downloadUrl} />
                      </Box>
                    </Card>
                  </Grid>
                );
              if (item.type === 'video')
                return (
                  <Grid item xs={4} sm={4} md={2}>
                    <Card style={{}}>
                      <Box sx={{ pt: '100%', position: 'relative' }}>
                        <ReactPlayer
                          style={ProductImgStyle}
                          url={item.downloadUrl}
                          playing
                          controls
                          light={item.thumbnail}
                          config={{
                            file: { attributes: { controlsList: 'nodownload' } }
                          }}
                          width="100%"
                          height="100%"
                        />{' '}
                      </Box>
                    </Card>
                  </Grid>
                );
            })()
          )}
        </Grid>
      ) : (
        <div className="mb-2" style={{ textAlign: 'justify' }}>
          <span style={{ fontWeight: 500 }}>{item?.repliedTo ?? ''}</span>{' '}
          {item.comment.split('\n').map((item) => (
            <div>{item}</div>
          ))}
        </div>
      )}
      <div
        className="hstack align-items-center"
        style={{ display: 'flex', alignItems: 'center', gap: '15px', fontSize: '12px' }}
      >
        {/* <div className="text-danger me-2">
          <Icon icon="ant-design:heart-outlined" />
        </div> */}
        <span style={{ color: '#999999' }}>
          {moment(new Date(item.createdAt.seconds * 1000)).fromNow()}
        </span>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Icon
            icon={`ant-design:heart-${outlineOrFill(item) === 0 ? 'outlined' : 'filled'}`}
            style={{ cursor: '', color: 'black' }}
            // onClick={() => commentLike(item, object, collections)}
          />
          <span>{item.likes?.length ?? 0}</span>
        </div>
        <span onClick={() => commentLike(item, object, collections)} style={{ cursor: 'pointer' }}>
          Like
        </span>
        <div
          className=""
          role="button"
          tabIndex={0}
          onClick={() => {
            if (replyHit) {
              handleReplyClick();
            }
          }}
          onMouseDown={() => {
            setReplyHit(true);
            setIsReply(true);
            setCommentReplyWriterName(`@${removeExtraSpaces(item.writerName)} `);
            setCommentReplyWriterId(item.writerId);
            setComment(`@${removeExtraSpaces(item.writerName)} `);
            setReplyId(x.id);
            setCommentRepliesId(x?.replies);
          }}
        >
          Reply
        </div>
        {deleteOrNot(item) === 1 && (
          <span className="" style={{ marginLeft: '' }}>
            <div
              role="button"
              tabIndex={0}
              onMouseDown={() =>
                deleteComment(
                  item?.id,
                  item?.medias,
                  // commentsCounts,
                  // setcommentsCounts,
                  item.storyId,
                  collections,
                  item?.repliedId
                )
              }
            >
              Delete
            </div>
          </span>
        )}
      </div>
    </div>
  </>
);
