import {
  Avatar,
  Card,
  Container,
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  Tooltip
} from '@mui/material';
import { useState, useEffect } from 'react';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// import { useLocation } from 'react-router-dom';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import copyOutline from '@iconify/icons-eva/copy-outline';

import Members from './Members';
import Page from '../../Page';
import { LightBox } from '../user/LightBox';
import noImage from '../../../assest/no-image.png';

export default function InfoCard({ row, deleted }) {
  const [copied, setcopied] = useState(false);

  // ------for lightbox
  const [image, setImage] = useState('');

  // ---------------------Copy text to clip board------------------------------------
  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setcopied(true);
      return navigator.clipboard.writeText(str);
      // return Promise.reject('The Clipboard API is not available.');
    }
  };
  // ---------------------------------------------------------
  console.log("row ... ",row?.chatDisable)
  return (
    <Card sx={{ margin: 2, width: '100%', zIndex: 1 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} p={2} spacing={2}>
        {/* <LightBox src={row.avatarUrl} alt={row.firstName}> */}
        {/* <Avatar
            sx={{ width: 220, height: 220, objectFit: 'conta', cursor: 'pointer' }}
            variant="rounded"
            src={row.avatarUrl}
          /> */}
        <img
          src={row.avatarUrl ?? noImage}
          alt={row?.name?.slice(0, 1).toUpperCase()}
          style={{
            width: 220,
            height: 220,
            objectFit: 'contain',
            borderRadius: '10px',
            cursor: 'pointer',
            backgroundColor: '#9A76F5',
            color: 'white'
          }}
          onClick={() => {
            setImage(row.avatarUrl);
          }}
        />
        {/* </LightBox> */}
        <div>
          <Box m={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" gutterBottom>
              {/* {row?.name} */}
              {row?.name?.length > 130 ? <>{row?.name?.slice(0, 130)}...</> : row?.name}
            </Typography>
            <TextTypo text={row?.type} icon={<Icon icon="ic:baseline-category" />} />
            <TextTypo text={row.contact} icon={<Icon icon="ant-design:phone-filled" />} />
            <TextTypo text={row.location} icon={<Icon icon="carbon:location-filled" />} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div style={{ marginRight: 5, marginLeft: 15, fontSize: 11 }}>
                {row.id?.slice(0, 20)}
              </div>
              <Tooltip title={copied ? 'copied' : 'copy'} placement="top">
                <Icon
                  icon={copyOutline}
                  style={{ fontSize: 22, cursor: 'pointer', color: '#9a76f5' }}
                  onClick={() => copyToClipboard(row.id)}
                  onMouseLeave={() => setcopied(false)}
                />
              </Tooltip>
            </div>
          </Box>
          <Box m={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
              Number of Clients
            </Typography>
            {row.capacity}
          </Box>
          {JSON.parse(localStorage.getItem('Rel8User')).role == 'admin' && (
            <Button
              variant="contained"
              size="small"
              disabled={deleted === 'yes' && true}
              style={{ position: 'relative', float: 'right' }}
              component={RouterLink}
              to="/organisation/groups/update"
              state={{
                userId: row?.id,
                name: row?.name,
                contact: row?.contact,
                location: row?.location,
                capacity: row?.capacity,
                avatarUrl: row?.avatarUrl,
                organization: row?.organisation,
                type: row?.type,
                organizationId: row?.organizationId,
                chatDisableUpdate : row?.chatDisable
              }}
            >
              Edit
              <Icon icon={edit2Fill} style={{ fontSize: '20px', paddingLeft: 5 }} />
            </Button>
          )}
        </div>
      </Stack>
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Card>
  );
}

const TextTypo = ({ text, icon }) => (
  <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
    {icon}
    &nbsp;
    {text}
  </Typography>
);
