import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Box } from '@mui/material';
import { shadows } from '@mui/system';

// layouts
import { useEffect } from 'react';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import logo from '../assest/LOGO D4.png';
import Logo from '../assest/rel8Logo.png';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F6F6F6',
        minHeight: '100vh'
      }}
    >
      {/* <AuthLayout /> */}

      {/* <MHidden width="mdDown">
        <SectionStyle>
          <img
            src={logo}
            alt="logo"
            width="45%"
            style={{ position: 'absolute', top: 25, left: 25 }}
          />
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          width: 'full'
        }}
        maxWidth="sm"
      >
        <img src={Logo} alt="logo" style={{ maxWidth: '300px' }} />
        <Box
          sx={{
            backgroundColor: 'white',
            minWidth: '310px',
            paddingX: '25px',
            paddingY: '40px',
            boxShadow: 3
          }}
        >
          <Stack sx={{ mb: 3 }}>
            <Typography textAlign={'center'} color="#9A76F5" variant="h4">
              Log in
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography> */}
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm />

          {/* <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden> */}
        </Box>
        <Box>
          <p style={{ textAlign: 'center', fontSize: '14px', fontWeight: 600 }}>
            Don’t have an account?
          </p>
          <p style={{ textAlign: 'center' }}>
            <a
              href="https://rel8.app/signup/"
              target='_blank'
              style={{
                textAlign: 'center',
                fontSize: '14px',
                color: '#9A76F5',
                fontWeight: 600,
                textDecoration: 'none'
              }}
            >
              Sign up
            </a>
          </p>
        </Box>
      </Box>
    </Box>
  );
}
