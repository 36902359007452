import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import StoryIcon from '../assest/storyIcon.svg';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Checkbox,
  Box
} from '@mui/material';
// components

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// ------------------------for popup box-------------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
// ------------------------------------------------------------------

import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  onSnapshot,
  query,
  where,
  updateDoc,
  getDoc,Timestamp
} from 'firebase/firestore';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import moment from 'moment';
import UserNotFound from '../components/UserNotFound';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
//
import { db, localUser } from '../App';
import StoriesMoreMenu from '../components/_dashboard/stories/StoriesMoreMenu';
import { LightBox } from '../components/_dashboard/user/LightBox';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
// ------------------------End for popup box-------------------------

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'facilityName', label: 'Group', alignRight: false },
  { id: 'roomsId', label: 'Locations', alignRight: false },
  { id: 'residentsId', label: 'Clients', alignRight: false },
  { id: 'views', label: 'Views', alignRight: false },
  { id: 'likes', label: 'Likes', alignRight: false },
  { id: 'comments', label: 'Comments', alignRight: false },
  { id: 'createdAt', label: 'Published Date', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
 

  if(!a[orderBy] || !b[orderBy]){
    if(!a[orderBy]){
      return 1;
    }
    if(!b[orderBy]){
      return -1;
    }
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//* **************Add Users From Firebase**********************
export const addUser = (likes, title, comments) => {
  const newCityRef = doc(collection(db, 'users'));
  return setDoc(newCityRef, {
    id: newCityRef.id,
    title,
    comments,
    likes
  });
};

//* **************Delete Users From Firebase**********************
export const deleteUser = (id) =>
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteDoc(doc(db, 'users', id))
        .then((res) => window.location.reload(false))
        .catch((err) => console.log(err.message));
      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });

//* **************Delete Users From Firebase**********************
export const listUserDelete = (ids) =>
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      for (let i = 0; i < ids.length; i += 1) {
        deleteDoc(doc(db, 'users', ids[i]))
          .then((res) => {
            if (i === ids.length - 1) {
              window.location.reload(false);
            }
          })
          .catch((err) => console.log(err.message));
      }
      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });

//* **************Update Users From Firebase**********************
export const updateUser = (id, likes, title, comments) => {
  setDoc(doc(db, 'users', id), {
    id,
    title,
    comments,
    likes
  })
    .then(() => console.log('deleted successfully'))
    .catch((err) => console.log(err.message));
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, query2) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  const Query2 = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    console.log("query : ", query)
    return filter(
      array,
      (_user) =>
      
     _user.title ?  _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 : -1
    );
  }
   console.log("1")
   const ReturnGroups = query2
   ? stabilizedThis.filter((a) => {
     console.log('a ', a[0].facilityName);
     console.log('query 2 ', query2);
     if (query2 == a[0].facilityName) {
       return a[0];
      }
    })
    : stabilizedThis;
    
    console.log("2")
  console.log('return group ', ReturnGroups);
  return ReturnGroups.map((el) => {
    return el[0];
  });
}

export default function Stories() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(35);
  const [isLoading, setisLoading] = useState(true);
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);
  const [filterByGrp, setFilterByGrp] = useState('');

  const [open, setOpen] = useState(false);
  const [modelRow, setModelRow] = useState('');

  const navigate = useNavigate();

  // ------for lightbox
  const [image, setImage] = useState('');


  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Noticeboard From Firebase**********************
  const timestamp = new Timestamp(user.createdAt.seconds, user.createdAt.nanoseconds);
  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'stories'),
      where('facilitiesId', 'in', user?.facilityIds)
    );
  } else if (user?.role === 'admin') {
    userCollection = collection(db, 'stories');
  } else if (user?.role === 'Family Member') {
    userCollection = query(
      collection(db, 'stories'),
      where('residentsId', 'array-contains-any', user?.residentIds),
    );
  }

  // const userCollection = collection(db, 'stories');
  let stories = [];
  const getUsers = async () => {
    // getDocs(userCollection).then((snapshot) => {
    // const snapshot = await getDocs(userCollection);
    onSnapshot(userCollection, (snapshot) => {
      stories = [];
      snapshot.docs?.forEach(async (result) => {
        stories.push({
          id: result.data()?.id,
          avatarUrl: result?.data()?.medias && result?.data()?.medias[0]?.downloadUrl,
          title: result.data()?.title,
          postedById: result.data()?.postedById,
          commentsCount: result.data()?.commentsCount ?? 0,
          likes: result.data()?.likes ?? [],
          // createdAt: result.data().createdAt.toDate().toDateString(),
          createdAt: result.data()?.createdAt,
          medias: result.data()?.medias,
          description: result.data()?.description,
          residentsId: result.data()?.residentsId,
          isPublished: result.data()?.isPublished,
          facilityName: result.data()?.facilityName,
          facilitiesId: result.data()?.facilitiesId,
          roomsId: result.data()?.roomsId,
          authorName: result.data()?.authorName ?? '',
          views: result.data().views ? result.data().views.length :  0
        });
      });
      if (!stories.length) {
        return setNotFound(true);
      }
      setUSERLIST(stories);
    });
  };

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName??'', filterByGrp??'');
     console.log("not filter users in stories ", USERLIST)
     console.log("filter users in stories ", filteredUsers)
  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );

  // ------------------------for popup box-------------------------
  const handleClickOpen = (description, title) => {
    const data = {
      description,
      title
    };
    setModelRow(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ------------------------End for popup box-------------------------

  return (
    <Box>
      <Page title="Stories | Rel8">
        <Container sx={{ width: '100%', minWidth: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                color: '#9A76F5'
              }}
            >
              <img
                src={StoryIcon}
                style={{ width: 35, height: 35, minWidth: 30, minHeight: 30, marginRight: '5px' }}
              />
              <p
                style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}
              >
                 Stories
              </p>
            </Box>
            
            {/* {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' && (
              <Button
                variant="contained"
                component={RouterLink}
                to="/content/stories/add-story"
                startIcon={<Icon icon={plusFill} />}
              >
                Add Story
              </Button>
            )} */}
          </Stack>

          <Card>
            <UserListToolbar
             USERLIST={[]}
             localuser={{}}
              selected={selected}
              numSelected={selected.length}
              filterName={filterName}
              setFilterByGrp={setFilterByGrp}
              onFilterName={handleFilterByName}
              collect="stories"
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    deletedTab={localUser.role === 'Family Member' && true}
                  />
                  <TableBody>
                    {filteredUsers.filter((item)=>{if(item.title){
                      return item;
                    }})
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          title,
                          description,
                          medias,
                          commentsCount,
                          avatarUrl,
                          createdAt,
                          likes,
                          residentsId,
                          isPublished,
                          roomName,
                          roomsId,
                          facilitiesId,
                          facilityName,
                          postedById,
                          views,
                          authorName
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            // component={Link}
                            sx={{ textDecoration: 'none' }}
                            tabIndex={-1}
                            description="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            // state={{
                            //   description,
                            //   images,
                            //   likes,
                            //   comments,
                            //   title,
                            //   date,
                            //   residentsId,
                            //   published
                            // }}
                            // to="/home/story-detail"
                          >
                            <TableCell padding="checkbox">
                              {localUser.role !== 'Family Member' && (
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="2px" width="25%">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {avatarUrl ? (
                                  <Avatar
                                    alt={title}
                                    src={avatarUrl ?? ''}
                                    sx={{ cursor: 'pointer', backgroundColor: '#9A76F5' }}
                                    onClick={() => {
                                      setImage(avatarUrl);
                                    }}
                                  />
                                ) : (
                                  <Avatar
                                    alt={title}
                                    src={avatarUrl ?? ''}
                                    sx={{ backgroundColor: '#9A76F5' }}
                                  />
                                )}
                                <Typography
                                  variant="subtitle2"
                                  component={RouterLink}
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    color: '#212B36',
                                    fontWeight: 'bold'
                                  }}
                                  to="/content/stories/story-detail"
                                  state={{
                                    id,
                                    description,
                                    medias,
                                    likes,
                                    commentsCount,
                                    title,
                                    createdAt,
                                    residentsId,
                                    isPublished,
                                    roomsId,
                                    facilitiesId,
                                    facilityName,
                                    object: row
                                  }}
                                >
                                  {title?.slice(0, 80)}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell>
                              {description?.length < 25 ? (
                                description
                              ) : (
                                <>
                                  {description?.slice(0, 25)}
                                  <span
                                    style={{
                                      color: 'blue',
                                      cursor: 'pointer',
                                      marginLeft: '5px'
                                    }}
                                    onClick={() => handleClickOpen(description, title)}
                                    role="button"
                                    tabIndex="0"
                                    onKeyDown={handleClick}
                                  >
                                    Read more...
                                  </span>
                                </>
                              )}
                            </TableCell> */}
                            <TableCell align="left">{facilityName}</TableCell>
                            <TableCell align="center">{roomsId?.length ?? 0}</TableCell>
                            <TableCell align="center">{residentsId?.length ?? 0}</TableCell>
                            <TableCell align="center">{views}</TableCell>
                            <TableCell align="center">{likes?.length ?? 0}</TableCell>
                            <TableCell align="center">{commentsCount}</TableCell>
                            {/* <TableCell align="left">
                              {commentsCount < 0 ? 0 : commentsCount}
                            </TableCell>
                            <TableCell align="left">{likes.length}</TableCell> */}
                            {/* <TableCell align="left">{createdAt.toDate().toDateString()}</TableCell> */}
                            <TableCell align="left">
                              {moment(createdAt?.toDate()).format('DD MMM YYYY')}
                            </TableCell>
                            <TableCell align="right">
                              {selected.length === 0 && (
                                <StoriesMoreMenu
                                  id={id}
                                  medias={medias}
                                  description={description}
                                  likes={likes}
                                  commentsCount={commentsCount}
                                  title={title}
                                  createdAt={createdAt}
                                  residentsId={residentsId}
                                  isPublished={isPublished}
                                  roomName={roomName}
                                  roomsId={roomsId}
                                  facilitiesId={facilitiesId}
                                  facilityName={facilityName}
                                  object={row}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound &&
                    (NotFound ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <UserNotFound />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      {/* ***************************** Start PopUp ************************* */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', fontWeight: '700' }} id="draggable-dialog-title">
          {modelRow?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: '#34495E' }}>{modelRow?.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* ***************************** End PopUp ************************* */}
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Box>
  );
}
