import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Button, Grid, Box, styled, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import InputMask from 'react-input-mask';
import { doc, collection, setDoc, updateDoc } from 'firebase/firestore';
import { addFacility } from '../../../pages/Facilities';
import { db, localUser } from '../../../App';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_PLACES_API_KEY, cloudFuncBaseUrl } from 'src/utils/config';
import axios from 'axios';

// ----------------------------------------------------------------------

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
export default function OrganizationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(200, 'Too Long!')
      .required('Organisation Name is required'),
    contact: Yup.string().required('Contact number is required'),
    Location: Yup.string().required('Address is required')
  });
  const formik = useFormik({
    initialValues: {
      name: state?.organizationName ?? '',
      contact: state?.contact ?? '',
      photo: state?.image ?? '',
      Location: state?.location ?? ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      if (!image && !state?.image) {
        return NotificationManager.error('Please select an image.');
      }

      setIsLoading(true);

      const name = getFieldProps('name').value;
      const contact = getFieldProps('contact').value;
      const location = getFieldProps('Location').value;
      const storage = getStorage();
      let facilityRef;
      if (state?.id) {
        facilityRef = doc(db, 'organizations', state.id);
      } else {
        facilityRef = doc(collection(db, 'organizations'));
      }

      if (state?.id && !image) {
        // const updRef = doc(db, 'organizations', state.id);
        await axios({
          method: 'post',
          url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateOrganization`,
          data: {
            id: state?.id,
            organizationName: name,
            address: location,
            contact,
            image: state?.image
          }
        });

        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Organisation Added Successfully',
          showConfirmButton: false,
          timer: 1500
        });
        return navigate('/home/organisation', { replace: true });

        return;
      }

      if (image) {
        const storageRef = ref(storage, `organization/${facilityRef.id}/organizationImgUrl`);
        const metadata = {
          contentType: 'image/jpeg'
        };
        const uploadTask = uploadBytesResumable(storageRef, image, metadata);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => NotificationManager.error(error.message),
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then(async (avatarUrl) => {
              let data = {
                id: facilityRef.id,
                organizationName: name,
                address: location,
                contact: contact,
                createdAt: new Date(),
                image: avatarUrl
              };
              if (!state?.id) data.createdBy = localUser.id;
              if (state?.id) {
                await axios({
                  method: 'post',
                  url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateOrganization`,
                  data: {
                    id: state?.id,
                    organizationName: name,
                    address: location,
                    contact,
                    image: avatarUrl
                  }
                });
                return navigate('/home/organisation', { replace: true });
              }

              setDoc(facilityRef, data, { merge: true })
                .then((res) => {
                  console.log(res);
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Organisation Added Successfully',
                    showConfirmButton: false,
                    timer: 1500
                  });
                  navigate('/home/organisation', { replace: true });
                })
                .catch((err) => console.log(err));
            });
          }
        );
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  //------------------------------Google places---
  const { ref: materialRef } = usePlacesWidget({
    // apiKey: 'AIzaSyDWeMrcmMOtW-QlR2xJjwpdbWxyXkQamQY',
    apiKey: GOOGLE_PLACES_API_KEY,
    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions: { country: 'Aus' }
    },
    onPlaceSelected: (place) => {
      setFieldValue('Location', place.formatted_address);
    }
  });
  //------------------------------Google places---

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Organisation Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Address"
              placeholder=" "
              {...getFieldProps('Location')}
              error={Boolean(touched.Location && errors.Location)}
              helperText={touched.Location && errors.Location}
              inputRef={materialRef}
            />
            <InputMask
              mask="(+61) 999 999 999"
              {...getFieldProps('contact')}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  fullWidth
                  label="Contact"
                  {...getFieldProps('contact')}
                  error={Boolean(touched.contact && errors.contact)}
                  helperText={touched.contact && errors.contact}
                />
              )}
            </InputMask>
          </Stack>
          {/* -------------------------------------------------------------------------------------------- */}

          {/* -------------------------------------------------------------------------------------------- */}
          <Grid container spacing={2}>
            {(image || state?.image) && (
              <Grid item xs={4} sm={4} md={2}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle
                      alt="Image"
                      src={image ? URL.createObjectURL(image) : state?.image}
                    />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label">
              Upload Image
              <input
                fullWidth
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            {state?.id ? 'Update Organistaion' : 'Add Organisation'}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
