import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Alert, Button } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../../App';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover, { logout } from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import menuIcon from '../../assest/menu.svg';
import Logo from '../../assest/rel8Logo.png';
import CreatePopover from './CreatePopover';
import { MicNone } from '@material-ui/icons';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = "7vh";
const APPBAR_DESKTOP = "9vh";

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar, setPopupOpen }) {
  const [updateMessage, setupdateMessage] = useState(false);
  const [version, setVersion] = useState('')
  const navigate = useNavigate();

  // ----------------------------------For version -------------------
  const compareVersion = async () => {
    const localVersion = localStorage.getItem('Rel8Version');
    console.log('old version', localVersion);

    const ref = doc(db, 'portalVersion', 'portal_version');
    onSnapshot(ref, (snapshot) => {
      console.log(snapshot.data()?.version);
      setVersion(snapshot.data()?.version)
      if (snapshot.data()?.version > localVersion || !localVersion) {
        setupdateMessage(true);
      }
    });
  };

  useEffect(() => {
    compareVersion();
  }, []);

  // ----------------------------------For version -------------------
  useEffect(async () => {
    try {
      const userId = JSON.parse(localStorage.getItem('Rel8User')).id;
      const user = await getDoc(doc(db, 'users', userId));
      localStorage.setItem('Rel8User', JSON.stringify(user.data()));
      // console.log(user.data());
    } catch (err) {
      console.log(err.message);
    }
  }, []);
  const user = JSON.parse(localStorage.getItem('Rel8User'));
  console.log('user : ', user);
  // ------------------------------------------

  return (
    <Box sx={{ zIndex: '100', width: '100%', backgroundColor: 'white', boxShadow: 10 }}>
      <ToolbarStyle>
        {/* <img src={menuIcon} style={{width:"40px"}}/> */}
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 0, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <MHidden width="lgDown">
          <a href='/home'><img src={Logo} style={{ maxWidth: '200px', marginLeft: '15px' }} /></a>
        </MHidden>
        <MHidden width="lgUp">
         <a href='/home'> <img src={Logo} style={{ maxWidth: '120px', marginLeft: '0px' }} /></a>
        </MHidden>

        {updateMessage && (
          <Alert
            action={
              <Button
                color="inherit"
                size="small"
                onClick={async () => {
                  await logout(navigate);
                  window.location.reload();
                }}
              >
                Refresh
              </Button>
            }
            sx={{ backgroundColor: '#131518', width: '83%', color: '#DEDEDE' }}
            severity="warning"
          >
            Your Dashboard is out of date. Please refresh to get the latest version.
          </Alert>
        )}
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
          style={{ position: 'relative', zIndex: '1' }}
        >
          <MHidden width="lgDown">
        
              <p style={{ color: '#9A76F5', fontSize: '24px', fontWeight: 500 }}>
                {user.organization}
              </p>
           
          </MHidden>
          {user.role !== 'Family Member' && user.role !== 'Family Admin' && <CreatePopover setPopOpen={setPopupOpen}/>}
          {/* <LanguagePopover /> */}
          <NotificationsPopover setPopupOpen={setPopupOpen} />
          <AccountPopover setPopOpen={setPopupOpen} version={version} />
        </Stack>
      </ToolbarStyle>
    </Box>
  );
}
