import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from 'src/App';
import Swal from 'sweetalert2';
import { deleteObject, getStorage, ref } from 'firebase/storage';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

export default function OrganizatioListToolbar({
  selected,
  numSelected,
  filterName,
  onFilterName,
  collect
}) {
  const deleteOrganizations = () => {
    console.log(selected);
    try {
      // ----------------------------------confirm box----------------------
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete these Organisation!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // //  --------------loading---------------------
          let timerInterval;
          Swal.fire({
            title: 'Deleting...',
            html: 'I will close in few<b></b> Moments.',
            timerProgressBar: true,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              if (b) {
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              }
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });
          // -----------------------------------------------------------------------------------------------
          try {
            // await axios({
            //   method: 'post',
            //   url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
            //   data: {
            //     id,
            //     collectionName: 'facilites',
            //     role: user
            //   }
            // });

            for (let id of selected) {
              const storage = getStorage();
              await deleteDoc(doc(db, 'organizations', id));
              const storageRef = ref(storage, `organization/${id}/organizationImgUrl`);
              await deleteObject(storageRef);
            }

            //--------------------------

            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Organisation has been deleted.',
              showConfirmButton: false,
              timer: 1500
            });
            window.location.reload(false);
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message
      });
    }
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          type="search"
          placeholder="Search..."
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete" arrow placement="top" onClick={() => deleteOrganizations()}>
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
