import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import BackButtonImage from "../../../assest/backButton.svg"

// material
import {
  Stack,
  TextField,
  Button,
  Grid,
  Card,
  Box,
  styled,
  CircularProgress,
  Autocomplete,
  Popper,
  ClickAwayListener,
  Divider,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ref, uploadBytesResumable, getStorage } from 'firebase/storage';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../../../App';
import { uploadFile } from '../user/UploadFile';
import { deleteFolder } from '../user/deleteFiles';
import EventPreview from '../event/EventPreview';
import FileView from '../event/FileView';
import SortByTitle from '../event/SortByTitle';
import ShopProductCard from '../../../components/_dashboard/products/ProductCard';

const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`
}));

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function UpdateNoticeboardForm({ props, selectOption, setSelectOption }) {
  const navigate = useNavigate();
  const {
    id,
    title,
    description,
    medias,
    residentsId,
    roomsId,
    createdAt,
    facilitiesId,
    facilityName
  } = props;

  // console.log(props);

  const feature = props?.featuredNotice;
  const type = props?.type;
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState();
  const [files, setFile] = useState(medias);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [draft, setDraft] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);

  // ------------------------------------------Facility room resident----------------------------------------
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [defaultOptions, setDefaultopt] = useState([]);
  const [defaultOptionsRooms, setDefaultoptRooms] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [openRoom, setOpenRoom] = useState(false);
  const [openFacility, setOpenFacility] = useState(false);
  const loadingRooms = openRoom && roomOptions.length === 0;
  const loadingFacility = openFacility && facilityOptions.length === 0;

  // Facility
  const [facilityID, setFacilityID] = useState(facilitiesId);
  const [roomId, setRoomIds] = useState(roomsId);
  const [checkAll, setCheckAll] = useState(false);

  const [checkAllResi, setCheckAllResi] = useState(false);

  const [preview, setPreview] = useState(false);
  const [previewError, setpreviewError] = useState(false);

  const loading = open && options.length === 0;

  // for rooms Id array for resident
  const IDROOM = [];
  roomId.forEach((e) => {
    IDROOM.push(e.value);
  });

  // // --------------------------------------start for residents ---------------------
  // useEffect(async () => {
  //   const residentsCollection = query(collection(db, 'residents'), where('id', 'in', residentsId));
  //   const residentsPromise = await getDocs(residentsCollection);
  //   const opt = [];
  //   residentsPromise.forEach((result) => {
  //     opt.push({
  //       value: result.data().id,
  //       title: `${result.data().firstName} ${result.data().lastName}`
  //     });
  //   });
  //   setDefaultopt(opt);
  // }, []);
  // // --------------------------------------end for residents ---------------------

  // // --------------------------------------start for Rooms ---------------------
  // useEffect(async () => {
  //   const roomCollection = query(collection(db, 'rooms'), where('id', 'in', roomsId));
  //   const roomsPromise = await getDocs(roomCollection);
  //   const opt = [];
  //   roomsPromise?.forEach((result) => {
  //     opt.push({
  //       value: result.data().id,
  //       title: result.data().name
  //     });
  //   });
  //   setDefaultoptRooms(opt);
  // }, []);
  // // --------------------------------------End for residents ---------------------

  useEffect(async () => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const residentsCollection = query(collection(db, 'residents'), where('roomId', 'in', IDROOM));
      const residentsPromise = await getDocs(residentsCollection);
      const opt = [];
      residentsPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: `${result.data().firstName} ${result.data().lastName}`
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // -------------Rooms Autocomplete--------------------------//
  useEffect(() => {
    let active = true;

    if (!loadingRooms) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const roomsCollection = query(collection(db, 'rooms'), where('facilityId', '==', facilityID));
      const roomsPromise = await getDocs(roomsCollection);
      const opt = [];
      roomsPromise?.forEach((result) => {
        opt.push({
          value: result.data()?.id,
          title: result.data()?.name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setRoomOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingRooms]);

  useEffect(() => {
    if (!openRoom) {
      setRoomOptions([]);
    }
  }, [openRoom]);

  // -----------------------------get facility--------------------------
  useEffect(() => {
    let active = true;

    if (!loadingFacility) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        facilitesCollection = collection(db, 'facilites');
      }
      // const facilitesCollection = collection(db, 'facilites');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise?.forEach((result) => {
        opt.push({
          value: result.data()?.id,
          title: result.data()?.name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setFacilityOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingFacility]);

  useEffect(() => {
    if (!openFacility) {
      setFacilityOptions([]);
    }
  }, [openFacility]);

  // ------------------------------------------Facility room resident----------------------------------------

  const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    description: Yup.string().required('Description is required'),
    facilitiesId: Yup.string().required('Group is required').nullable(),
    // roomsId: Yup.string().required('Room is required').nullable(),
    roomsId: Yup.array().min(1, 'at least 1 location is required').required('required'),
    residentsId: Yup.array().min(1, 'at least 1 client is required').required('required'),
    date: Yup.date().required('Date is required')
  });
  const RegisterSchemaDraft = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    description: Yup.string().required('Description is required.').nullable(),
    facility: Yup.string().nullable(),
    roomsId: Yup.array().nullable(),
    residentsId: Yup.array().nullable(),
    date: Yup.date().nullable()
  });

  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e, downloadUrl, thumbnail) => {
    const s = files.filter((item, index) => index !== e);
    if (thumbnail && RgExp.test(thumbnail)) {
      setDeleteFiles([...deleteFiles, thumbnail, downloadUrl]);
    } else if (RgExp.test(downloadUrl)) setDeleteFiles([...deleteFiles, downloadUrl]);
    setFile(s);
  };

  const handleFileUpload = async (e) => {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      arr.push(e.target.files[i]);
    }

    if (arr.length > 30) {
      return NotificationManager.error('maximum 30 files to upload');
    }
    const obj = [];

    for (let i = 0; i < arr.length; i += 1) {
      let thumbnail;
      const ImagesArray = arr[i];
      const { size } = arr[i];
      const downloadUrl = URL.createObjectURL(arr[i]);
      const mediaTypeLocal = arr[i].type.split('/')[0];
      if (mediaTypeLocal !== 'application') {
        if (mediaTypeLocal === 'video') {
          thumbnail = await generateVideoThumbnail(arr[i]);
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          size,
          type: mediaTypeLocal
        });
      } else {
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          size,
          name: arr[i]?.name,
          type: 'application'
        });
      }
    }
    setFile([...files, ...obj]);
  };

  const formik = useFormik({
    initialValues: {
      title,
      description,
      photo: '',
      residentsId: defaultOptions,
      facilitiesId: props.facilitiesId,
      facilityName: props.facilityName,
      roomsId: defaultOptionsRooms,
      featureNotifi: feature ?? false,
      date: new Date(createdAt?.seconds * 1000)
    },
    validationSchema: draft? RegisterSchemaDraft : RegisterSchema,
    onSubmit: () => {
      if (previewError) {
        return setPreview(true);
      }
      AddEventFunc(draft ? 1 : 0);
    }
  });
  const AddEventFunc = async (drft) => {
    console.log(drft ?? false);
    const draft = drft ?? false;
    console.log(draft);
    // if (files.length === 0) {
    //   return NotificationManager.error("You didn't select file");
    // }
    if (draft) {
      setDraftLoading(true);
    } else {
      setIsLoading(true);
    }
    const title = getFieldProps('title').value;
    const description = getFieldProps('description').value;
    const residentsId = [];
    const roomsId = [];
    const mediaId = uuidv4();
    const facilityName = getFieldProps('facilityName').value;
    const facilitiesId = getFieldProps('facilitiesId').value;
    const roomId = getFieldProps('roomsId').value;
    const featuredNotice = getFieldProps('featureNotifi').value;
    const date = getFieldProps('date').value;

    defaultOptions.forEach((element) => {
      residentsId.push(element.value);
    });

    defaultOptionsRooms.forEach((element) => {
      roomsId.push(element.value);
    });

    // console.log(facilitiesId);
    // console.log(facilityName);
    // console.log(roomsId);
    // console.log(residentsId);

    const medias = [];
    let itemsProcessed = 0;
    deleteFiles?.forEach((element) => {
      deleteFolder(element);
      const newFiles = files.filter((item) => item.downloadUrl === element);
      setFile(newFiles);
    });

    if (files.length > 0) {
      files.forEach(async (element, index, array) => {
        element.id = id;
        if (!RgExp.test(element.downloadUrl)) {
          if (element.type === 'video') {
            const downloadUrl = await uploadFile(
              'noticeboards',
              element,
              element.file,
              element.type,
              mediaId
            );
            const thumbnailId = uuidv4();
            const downloadUrlThumbnail = await uploadFile(
              'noticeboards',
              element,
              element.thumbnail,
              'thumbnail',
              thumbnailId
            );
            medias.push({
              downloadUrl,
              thumbnailId,
              thumbnail: downloadUrlThumbnail,
              name: element.file.name,
              type: element.type,
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
          if (element.type === 'image') {
            const downloadUrl = await uploadFile(
              'noticeboards',
              element,
              element.file,
              element.type,
              mediaId
            );
            medias.push({
              downloadUrl,
              thumbnailId: null,
              thumbnail: null,
              name: element.file.name,
              type: element.type,
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
          if (element.type === 'application') {
            const downloadUrl = await uploadFile(
              'noticeboards',
              element,
              element.file,
              element.type,
              mediaId
            );
            medias.push({
              downloadUrl,
              thumbnailId: null,
              thumbnail: null,
              name: element?.name,
              type: 'file',
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
        } else {
          medias.push(element);
        }
        itemsProcessed += 1;
        if (itemsProcessed === array.length) {
          // if (draft) {
          if (draft) {
            console.log(id);
            const draftsRef = doc(db, 'drafts', id);
            // check for add new noticeboard or update
            if (type) {
              updateDoc(draftsRef, {
                title,
                createdAt: date,
                object: {
                  title,
                  description,
                  medias,
                  residentsId,
                  roomsId,
                  createdAt: date,
                  facilitiesId,
                  facilityName,
                  featuredNotice
                }
              })
                .then((res) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Draft Updated Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  navigate('/home/drafts', { replace: true });
                })
                .catch((err) => console.log(err));
            }
            // check for add new noticeboard or update
            if (!type) {
              setDoc(draftsRef, {
                title,
                createdAt: date,
                type: 'noticeboard',
                id,
                object: {
                  id,
                  title,
                  description,
                  medias,
                  residentsId,
                  roomsId,
                  createdAt: date,
                  facilitiesId,
                  facilityName,
                  featuredNotice
                }
              })
                .then((res) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Draft Updated Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  navigate('/home/drafts', { replace: true });
                })
                .catch((err) => console.log(err));
            }
          } else {
            if (type) {
              const noticeboardsRef = doc(db, 'noticeboards', id);
              return setDoc(noticeboardsRef, {
                id,
                title,
                description,
                medias,
                commentsCount: 0,
                likes: [],
                // uid: null,
                postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                  JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                }`,
                createdAt: date,
                roomsId,
                facilitiesId,
                facilityName,
                residentsId,
                featuredNotice
              })
                .then(async (res) => {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Noticeboard Added Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  deleteDoc(doc(db, 'drafts', id));

                  // -----------------------------------------Comment Notifications------------------------------------
                  const object = {
                    id,
                    title,
                    description,
                    medias,
                    commentsCount: 0,
                    likes: [],
                    // uid: null,
                    postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                    createdAt: date,
                    roomsId,
                    facilitiesId,
                    facilityName,
                    residentsId
                  };
                  const commentNotifiRef = doc(collection(db, 'notifications'));
                  await setDoc(commentNotifiRef, {
                    id: commentNotifiRef.id,
                    body: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                      JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                    } has posted a new notice`,
                    commentId: id,
                    createdAt: date,
                    profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
                    title: 'A new notice',
                    topic: `${facilitiesId}_notice`,
                    viewedBy: [],
                    senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
                    receiverId: '',
                    object,
                    senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                      JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                    }`,
                    type: 'noticeboard'
                  });
                  // -----------------------------------------Comment Notifications------------------------------------

                  navigate('/home/noticeboard', { replace: true });
                })
                .catch((err) => console.log(err));
            }

            const eventsRef = doc(db, 'noticeboards', id);

            updateDoc(eventsRef, {
              title,
              description,
              medias,
              residentsId,
              roomsId,
              facilitiesId,
              facilityName,
              featuredNotice,
              createdAt: date
            })
              .then((res) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Noticeboard Updated Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/noticeboard', { replace: true });
              })
              .catch((err) => console.log(err));
          }
        }
      });
      // ************************************************ For with out image *********************************************************
    } else {
      if (draft) {
        console.log(id);
        const draftsRef = doc(db, 'drafts', id);
        // check for add new noticeboard or update
        if (type) {
          return updateDoc(draftsRef, {
            title,
            createdAt: date,
            object: {
              title,
              description,
              medias,
              residentsId,
              roomsId,
              facilitiesId,
              facilityName,
              featuredNotice,
              createdAt: date
            }
          })
            .then((res) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Draft Updated Successfully',
                showConfirmButton: false,
                timer: 2000
              });
              navigate('/home/drafts', { replace: true });
            })
            .catch((err) => console.log(err));
        }
        // check for add new noticeboard or update
        if (!type) {
          return setDoc(draftsRef, {
            title,
            createdAt: date,
            type: 'noticeboard',
            id,
            object: {
              title,
              description,
              medias,
              residentsId,
              roomsId,
              facilitiesId,
              facilityName,
              featuredNotice,
              createdAt: date,
              id
            }
          })
            .then((res) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Draft Updated Successfully',
                showConfirmButton: false,
                timer: 2000
              });
              navigate('/home/drafts', { replace: true });
            })
            .catch((err) => console.log(err));
        }
      }
      //  else {
      if (type) {
        const noticeboardsRef = doc(db, 'noticeboards', id);
        return setDoc(noticeboardsRef, {
          id,
          title,
          description,
          medias,
          commentsCount: 0,
          likes: [],
          // uid: null,
          postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
          authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
            JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          }`,
          createdAt: date,
          roomsId,
          facilitiesId,
          facilityName,
          residentsId,
          featuredNotice
        })
          .then(async (res) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Noticeboard Added Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            deleteDoc(doc(db, 'drafts', id));

            // -----------------------------------------Comment Notifications------------------------------------
            const object = {
              id,
              title,
              description,
              medias,
              commentsCount: 0,
              likes: [],
              // uid: null,
              postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
              createdAt: date,
              roomsId,
              facilitiesId,
              facilityName,
              residentsId
            };
            const commentNotifiRef = doc(collection(db, 'notifications'));
            await setDoc(commentNotifiRef, {
              id: commentNotifiRef.id,
              body: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              } has posted a new notice`,
              commentId: id,
              createdAt: date,
              profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
              title: 'A new notice',
              topic: `${facilitiesId}_notice`,
              viewedBy: [],
              senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
              receiverId: '',
              object,
              senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              }`,
              type: 'noticeboard'
            });
            // -----------------------------------------Comment Notifications------------------------------------

            navigate('/home/noticeboard', { replace: true });
          })
          .catch((err) => console.log(err));
      }

      const eventsRef = doc(db, 'noticeboards', id);

      updateDoc(eventsRef, {
        title,
        description,
        medias,
        residentsId,
        roomsId,
        facilitiesId,
        facilityName,
        featuredNotice,
        createdAt: date
      })
        .then((res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Noticeboard Updated Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          navigate('/home/noticeboard', { replace: true });
        })
        .catch((err) => console.log(err));
      // }
    }
  };

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldTouched,
    setFieldValue
  } = formik;

  // ************************************************** Default Options ********************************
  // --------------------------------------start for Rooms ---------------------
  useEffect(async () => {
    const roomCollection = query(collection(db, 'rooms'), where('id', 'in', roomsId));
    const roomsPromise = await getDocs(roomCollection);
    const opt = [];
    roomsPromise?.forEach((result) => {
      opt.push({
        value: result.data().id,
        title: result.data().name
      });
    });
    setFieldValue('roomsId', opt);
    setDefaultoptRooms(opt);
  }, []);
  // --------------------------------------start for residents ---------------------
  useEffect(async () => {
    const residentsCollection = query(collection(db, 'residents'), where('id', 'in', residentsId));
    const residentsPromise = await getDocs(residentsCollection);
    const opt = [];
    residentsPromise.forEach((result) => {
      opt.push({
        value: result.data().id,
        title: `${result.data().firstName} ${result.data().lastName}`
      });
    });
    setFieldValue('residentsId', opt);
    setDefaultopt(opt);
  }, []);
  // --------------------------------------end for residents ---------------------

  // ---------------------------------------------------
  // for room
  const handleClickAway = (e) => {
    setOpenRoom(false);
  };
  // for resident
  const handleClickAwayResi = (e) => {
    setOpen(false);
  };

  // -------for room
  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      setDefaultoptRooms(roomOptions);
      setRoomIds(roomOptions);
      setFieldValue('roomsId', roomOptions);
    } else {
      setDefaultoptRooms([]);
      setRoomIds([]);
    }
    setFieldValue('residentsId', []);
    setDefaultopt([]);
    setCheckAllResi(false);
  };

  // -----For resident
  const checkAllChangeResi = (event) => {
    setCheckAllResi(event.target.checked);
    if (event.target.checked) {
      setDefaultopt(options);
      setFieldValue('residentsId', options);
    } else {
      setDefaultopt([]);
      setFieldValue('residentsId', []);
    }
  };

  // ---------------------------------------------------
  const images =
    files.length != 0
      ? files.filter((image) => {
          console.log('image :: ', image);
          const file =
            (image.type != 'image' && image.type != 'video') ||
            (image.name && image.name.endsWith('.avi')) ||
            (image.name && image.name.endsWith('.mpeg')) ||
            (image.name && image.name.endsWith('.heic'));
          const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
          if (type != 'file') {
            return image;
          }
        })
      : [];
  return (
    <Box sx={{ width: '100%', minWidth: '100%' }}>
      {selectOption == 'preview' ? (
        <EventPreview
          file={files}
          title={{ ...getFieldProps('title') } ?? ''}
          description={{ ...getFieldProps('description') }}
          setPreview={setPreview}
          AddSubmitFunc={AddEventFunc}
          draftLoading={draftLoading}
          isLoading={isLoading}
          head1="Publish"
          head2="Add Notice"
          section='Noticeboard'
          setDraft={setDraft}
          handleSubmit={handleSubmit}
          date={getFieldProps('date').value}
          setSelectOption={setSelectOption}
        />
      ) : (
        <Card
          sx={{
            marginTop: '',
            width: '100%',
            minWidth: '100%',
            paddingX: '20px',
            paddingY: '20px'
          }}
        >
           {selectOption != 'add' &&  <div style={{marginBottom:"12px"}} onClick={()=>{
              if(selectOption=='audience'){
                setSelectOption('add')
              }else if(selectOption=='schedule'){
                setSelectOption('audience')
              }
             }}><img src={BackButtonImage} style={{width:"35px", cursor:"pointer"}}/></div>}
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <NotificationContainer />
              <Stack spacing={3}>
                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Title
                      </div>

                      <TextField
                        fullWidth
                        // label="Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Box>
                  </Stack>
                )}
                {selectOption == 'schedule' && (
                  <Stack sx={{ width: '100%', '& > *': { flex: 1 } }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Date
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          format="dd/MM/yyyy"
                          inputFormat="dd/MM/yyyy"
                          label="Date"
                          {...getFieldProps('date')}
                          onChange={(e, value) => setFieldValue('date', e || '')}
                          onChangeRaw={(e) => {
                            setFieldTouched('date', true, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(touched.date && errors.date)}
                              helperText={touched.date && errors.date}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Stack>
                )}
                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Group
                      </div>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        open={openFacility}
                        onOpen={() => {
                          setOpenFacility(true);
                        }}
                        onClose={() => {
                          setOpenFacility(false);
                        }}
                        onChange={(e, value) => {
                          setFieldValue('facilityName', value?.title || '');
                          setFieldValue('facilitiesId', value?.value || '');
                          setFacilityID(value?.value);

                          // for empty
                          setDefaultoptRooms([]);
                          setFieldValue('roomsId', []);
                          setDefaultopt([]);
                          setFieldValue('residentsId', []);
                          // for check all empty
                          setCheckAll(false);
                          setCheckAllResi(false);
                          setDefaultopt([]);
                        }}
                        defaultValue={{ title: props.facilityName, value: props.facilitiesId }}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        options={facilityOptions}
                        loading={loadingFacility}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            // label="Select Group"
                            {...getFieldProps('facility')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingFacility ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                            error={Boolean(touched.facilitiesId && errors.facilitiesId)}
                            helperText={touched.facilitiesId && errors.facilitiesId}
                          />
                        )}
                      />
                    </div>
                  </Stack>
                )}
                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Location
                      </div>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            multiple
                            limitTags={3}
                            disabled={!facilityID ?? false}
                            disableCloseOnSelect
                            id="asynchronous-demo"
                            open={openRoom}
                            onOpen={() => {
                              setOpenRoom(true);
                            }}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'selectOption') {
                                setDefaultoptRooms(newValue);
                                setFieldValue('roomsId', newValue ?? '');
                                setRoomIds(newValue);
                              } else if (reason === 'removeOption') {
                                setDefaultoptRooms(newValue);
                                setFieldValue('roomsId', newValue ?? '');
                                setRoomIds(newValue);
                              } else if (reason === 'clear') {
                                setRoomIds([]);
                                setFieldValue('roomsId', []);
                                setDefaultoptRooms([]);
                              }
                              setCheckAll(false);
                              setFieldValue('residentsId', []);
                              setCheckAllResi(false);
                              setDefaultopt([]);
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            getOptionSelected={(option, value) => value.value === option.value}
                            options={roomOptions}
                            value={defaultOptionsRooms}
                            loading={loadingRooms}
                            PopperComponent={(param) => (
                              <PopperStyledComponent {...param}>
                                <Box {...param} />
                                <Divider />
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    height: '45px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <Checkbox
                                    checked={checkAll}
                                    onChange={checkAllChange}
                                    id="check-all"
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                  Select All
                                </Box>
                              </PopperStyledComponent>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Select Location"
                                {...getFieldProps('room')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingRooms ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.roomsId && errors.roomsId)}
                                helperText={touched.roomsId && errors.roomsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Stack>
                )}
                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Clients
                      </div>
                      <ClickAwayListener onClickAway={handleClickAwayResi}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            disableCloseOnSelect
                            multiple
                            limitTags={3}
                            disabled={IDROOM.length === 0 ?? false}
                            id="asynchronous-demo"
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'selectOption') {
                                setFieldValue('residentsId', newValue ?? '');
                                setDefaultopt(newValue);
                              } else if (reason === 'removeOption') {
                                setFieldValue('residentsId', newValue ?? '');
                                setDefaultopt(newValue);
                              } else if (reason === 'clear') {
                                setDefaultopt([]);
                                setFieldValue('residentsId', []);
                              }
                              setCheckAllResi(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            getOptionSelected={(option, value) => value.value === option.value}
                            options={options}
                            value={defaultOptions}
                            loading={loading}
                            PopperComponent={(param) => (
                              <PopperStyledComponent {...param}>
                                <Box {...param} />
                                <Divider />
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    height: '45px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <Checkbox
                                    checked={checkAllResi}
                                    onChange={checkAllChangeResi}
                                    id="check-all"
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                  Select All
                                </Box>
                              </PopperStyledComponent>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                // label="Select Clients"
                                {...getFieldProps('resident')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.residentsId && errors.residentsId)}
                                helperText={touched.residentsId && errors.residentsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Stack>
                )}
                {selectOption == 'add' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Description
                    </div>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={20}
                      // label="Description"
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Box>
                )}
                {selectOption == 'add' && (
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Feature this notice"
                    {...getFieldProps('featureNotifi')}
                    onChange={(e, value) => setFieldValue('featureNotifi', value)}
                  />
                )}
                {selectOption == 'add' && (
                  <>
                    {true && (
                      <div className="grid">
                        {files.length > 0 &&
                          files.map((item, index) => {
                            return (
                              <>
                                {!(
                                  (item.type != 'image' && item.type != 'video') ||
                                item.name &&  item.name.endsWith('.avi') ||
                                item.name &&  item.name.endsWith('.mpeg')
                                ) && (
                                  <div
                                    key={item.id}
                                    style={{ position: 'relative' }}
                                    className={
                                      item === images[0] && images.length > 7
                                        ? 'firstIndex'
                                        : 'allIndex'
                                    }
                                  >
                                    {' '}
                                    <div
                                      style={{
                                        position: 'absolute',
                                        top: '-3px',
                                        right: '-3px',
                                        zIndex: 100
                                      }}
                                    >
                                      <Icon
                                        icon="ci:close-big"
                                        onClick={() => deleteFile(index)}
                                        style={{
                                          cursor: 'pointer',
                                          backgroundColor: 'white',
                                          borderRadius: '100%',
                                          outline: '1px',
                                          outlineColor: '#9A76F5'
                                        }}
                                      />
                                    </div>
                                    <Card>
                                    <ShopProductCard img={item} Index={index} images={images} />
                                    </Card>
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </div>
                    )}
                    {true && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {files.length > 0 &&
                          files.map((item, index) => {
                            return (
                              <>
                                {((item.type != 'image' && item.type != 'video') ||
                                item.name &&  item.name.endsWith('.avi') ||
                                item.name &&  item.name.endsWith('.mpeg')) && (
                                  <Box
                                    sx={{
                                      maxWidth: '250px',
                                      width: 'full',
                                      display: 'flex',
                                      gap: '5px',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Icon
                                      icon="ci:close-big"
                                      onClick={() => deleteFile(index)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                    <Box
                                      sx={{
                                        maxWidth: '50px',
                                        maxHeight: '60px',
                                        minWidth: '50px',
                                        minHeight: '60px',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <img
                                        src={item.thumbnail}
                                        style={{ width: '100%', object: 'contain', height: '100%' }}
                                      />
                                    </Box>
                                    <div style={{ fontSize: '12px', fontWeight: 500 }}>
                                      {item.name && item.name.length > 25
                                        ? item.name.slice(0, 22) + '...'
                                        : item.name}
                                      {' ('}
                                      {item.size ?? ''}
                                      {' KB)'}
                                    </div>
                                  </Box>
                                )}
                              </>
                            );
                          })}
                      </Box>
                    )}
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Button variant="contained" component="label">
                        Add attachment
                        <input
                          type="file"
                          hidden
                          multiple
                          fullWidth
                          accept="image/*,video/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.mp3, .heic"
                          onChange={handleFileUpload}
                          error={Boolean(touched.photo && errors.photo)}
                          helperText={touched.photo && errors.photo}
                        />
                      </Button>
                    </Stack>
                  </>
                )}
                {/* {selectOption == 'schedule' && (
                  <Box
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#9A76F5',
                      paddingY: '25px',
                      textAlign: 'center'
                    }}
                  >
                    Schedule your content in advance - coming soon!
                  </Box>
                )} */}

                {/* ************************************************************************************************************************* */}

                {/* ------------------------------------------------------ */}

                {/* <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => setpreviewError(true)}
                >
                  Preview
                </LoadingButton> */}

                {/* {type ? (
                  <>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="outlined"
                        loading={draftLoading}
                        onClick={() => setDraft(true)}
                      >
                        Save Drafts
                      </LoadingButton>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                      >
                        Add Notice
                      </LoadingButton>
                    </Stack>
                  </>
                ) : (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="outlined"
                      loading={draftLoading}
                      onClick={() => setDraft(true)}
                    >
                      Save Drafts
                    </LoadingButton>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                    >
                      Update Notice
                    </LoadingButton>
                  </Stack>
                )} */}
                {(selectOption == 'add' ||
                  selectOption == 'audience' ||
                  selectOption == 'schedule') && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="outlined"
                      loading={draftLoading}
                      onClick={() => setDraft(true)}
                    >
                      Save drafts
                    </LoadingButton>
                    <LoadingButton
                      fullWidth
                      size="large"
                      // type="submit"
                      onClick={() => {
                        if (selectOption == 'add') {
                          if (!getFieldProps('title').value) {
                            setFieldTouched('title');
                          }
                          if (!getFieldProps('description').value) {
                            setFieldTouched('description');
                          }
                          if (getFieldProps('title').value && getFieldProps('description').value) {
                            setSelectOption('audience');
                          }
                        } else if (selectOption == 'audience') {
                          if (!getFieldProps('facilityName').value) {
                            setFieldTouched('facilityName');
                          }
                          if (!getFieldProps('roomsId').value) {
                            setFieldTouched('roomsId');
                          }
                          if (!getFieldProps('residentsId').value) {
                            setFieldTouched('residentsId');
                          }
                          if (
                            getFieldProps('facilityName').value &&
                            getFieldProps('roomsId').value &&
                            getFieldProps('residentsId').value
                          ) {
                            setSelectOption('schedule');
                          }
                        } else if (selectOption == 'schedule') {
                          setSelectOption('preview');
                        }
                      }}
                      variant="contained"
                      loading={isLoading}
                    >
                      {selectOption == 'add' ? 'Choose audience' : selectOption == 'audience'? 'Schedule':"Preview"}
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      )}  
    </Box>
  );
}
