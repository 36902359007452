import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL,
  listAll
} from 'firebase/storage';

export const deleteFolder = async (file, thumbnail) => {
  const storage = getStorage();
  // Create a refserence to the file to delete
  const fileRef = ref(storage, file);
  const thumbnailRef = ref(storage, thumbnail);
  // Delete the file
  deleteObject(fileRef);
  if (thumbnail) {
    deleteObject(thumbnailRef);
  }
};
