import { styled, Tooltip } from '@mui/material';
import imgFile from '../../../assest/file.png';

// ------------------------------
//         For file
// ------------------------------
const ProductFileStyle = styled('div')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  cursor: 'pointer',
  backgroundColor: '#E4E4E4'
});

export default function FileView(downloadUrl, name) {
  // const { downloadUrl } = img.img;
  return (
    <a href={downloadUrl} target="_blank" download>
      <Tooltip title={`File name : ${name}`}>
        <ProductFileStyle>
          <div
            style={{
              display: 'flex',
              fontSize: '15px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10px',
              color: '#9a76f5'
            }}
          >
            <img src={imgFile} width="70" height="70" />
            <div>{name}</div>
          </div>
        </ProductFileStyle>
      </Tooltip>
    </a>
  );
}
