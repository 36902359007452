import { Icon } from '@iconify/react';
import homeOutline from '@iconify/icons-eva/home-outline';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, CircularProgress, Box } from '@mui/material';
// utils
import { useEffect, useState } from 'react';
import {
  collection,
  getCountFromServer,
  getDocs,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import { fShortenNumber } from '../../../utils/formatNumber';
import { db } from '../../../App';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: '#CCF3E2'
  // backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------
export default function AppBugReports() {
  const [TOTAL, setTOTAL] = useState();
  // const facilityMembersCollection = collection(db, 'residents');

  const user = JSON.parse(localStorage.getItem('Rel8User'));

  // get resident from firebase
  let facilityMembersCollection;
  if (user?.role === 'admin') {
    facilityMembersCollection = collection(db, 'residents');
  } else if (user?.role === 'Facility Member') {
    facilityMembersCollection = query(
      collection(db, 'residents'),
      where('facilityId', 'in', user?.facilityIds)
    );
  } else if (user?.role === 'Family Member') {
    facilityMembersCollection = query(
      collection(db, 'residents'),
      where('id', 'in', user?.residentIds)
    );
  }

  useEffect(async () => {
    const totalCount = await getCountFromServer(facilityMembersCollection);

    if (totalCount.data()?.count > 0) {
      return setTOTAL(totalCount.data()?.count);
    }
    setTOTAL('Not found');

   
  }, []);
  return (
    <Box sx={{border:"4px solid #FAAD1F", minWidth:"120px", height:"120px", borderRadius:"20px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
      {/* <IconWrapperStyle>
        <Icon icon={homeOutline} width={24} height={24} />
      </IconWrapperStyle> */}
      <Typography variant="h2" sx={{textAlign:"center",color: '#FAAD1F' }}>
        {(() => {
          if (!TOTAL) return <CircularProgress style={{ color: '#FAAD1F' }} />;
          if (TOTAL === 'Not found') return fShortenNumber(0);
          return fShortenNumber(TOTAL);
        })()}
      </Typography>
      <p style={{fontSize:"20px", fontWeight:600, textAlign:"center", color:"black" }}>
        Clients
      </p>
    </Box>
  );
}
