import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from 'firebase/auth';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { doc, getDoc } from 'firebase/firestore';
import App, { db } from '../../../App';
// ----------------------------------------------------------------------
export const signout = () => signOut(getAuth());
export default function LoginForm({setEmailSent}) {
  const auth = getAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = async (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsLoading(false);
        NotificationManager.success('Mail sent successfully');
        setTimeout(()=>{ setEmailSent(true)},1000)
      
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = error.message;
        errorMessage = errorMessage.substr(errorMessage.indexOf('/') + 1);
        errorMessage = errorMessage.substr(0, errorMessage.indexOf(')'));
        setIsLoading(false);
        NotificationManager.error(errorMessage);
      });
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: ({ setSubmitting }) => {
      const email = getFieldProps('email').value;
      setIsLoading(true);
      getUser(email);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const goToForgot = () => {
    localStorage.setItem('forgot', true);
    window.location.reload(false);
  };
  return (
    <FormikProvider value={formik}>
      <NotificationContainer />

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            variant="standard"
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
          Continue
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
