import { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import { Box, Drawer } from '@mui/material';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden'
});

// const MainStyle = styled('div')(({ theme }) => ({
//   flexGrow: 1,
//   overflow: 'auto',
//   minHeight: '100%',
//   paddingTop: APP_BAR_MOBILE + 24,
//   paddingBottom: theme.spacing(10),
//   paddingLeft: theme.spacing(2),
//   paddingRight: theme.spacing(2),
//   [theme.breakpoints.up('lg')]: {
//     paddingTop: APP_BAR_DESKTOP + 24,
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(2)
//   }
// }));
// APP_BAR_DESKTOP + 24
const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  backgroundColor:"#F6F6F6",
  paddingTop: window.location.pathname == '/home/app' ? theme.spacing(3) : APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingTop: window.location.pathname == '/home/app' ? '20px' : '20px',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(10)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  console.log('window ', window.location.pathname == '/home/app');
  return (
    <RootStyle sx={{ overflow: open ? 'hidden' : 'scroll', height: open ? '100vh' : '' }}>
      <DashboardNavbar setPopupOpen={setPopupOpen} onOpenSidebar={() => setOpen(true)} />
      <Box sx={{ display: 'flex' }}>
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyle>
         
          <Outlet />
        </MainStyle>
      </Box>
      {popupOpen && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.4)'
          }}
        ></div>
      )}
    </RootStyle>
  );
}
