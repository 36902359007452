import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ReactPlayer from 'react-player/lazy';
import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import ColorPreview from '../../ColorPreview';
// import LightBox from './LightBox';
import imgFile from '../../../assest/file.png';
import LoadingGif from '../../../assest/loading.gif';
// ----------------------------------------------------------------------

const ProductImgStyle = {
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
};

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  cursor: 'pointer'
});
const ProductFileStyle = styled('div')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  cursor: 'pointer',
  backgroundColor: '#E4E4E4'
});
// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ img, Index, images }) {
  const [imgState, setimgState] = useState(false);
  const [imgIndex, setImgIndex] = useState(-1);
  const { loading, setLoading } = useState(true);
  // console.log(imgState);
  // const { name, cover, price, colors, status, priceSale } = product;
  const CheckFileType = (type) => {
    // console.log('type : : :', type);

    switch (type) {
      case 'image':
        return (
          <Image img={img} setimgState={setimgState} Index={Index} setImgIndex={setImgIndex} />
        );
      case 'video':
        return <Video img={img} />;
      default :
        return <File img={img}/>;
    }
  };
  return (
    <>
      {imgState ? (
        <Lightbox
          mainSrc={loading ? LoadingGif : images[imgIndex]?.downloadUrl}
          onImageLoadError={() => {
            setLoading(false);
          }}
          prevSrc={
            imgIndex > 0
              ? images[imgIndex - 1].type != 'image'
                ? undefined
                : images[imgIndex - 1].downloadUrl
              : undefined
          }
          nextSrc={
            imgIndex < images.length - 1
              ? images[imgIndex + 1].type != 'image'
                ? undefined
                : images[imgIndex + 1].downloadUrl
              : undefined
          }
          onMoveNextRequest={() => {
            setImgIndex(imgIndex + 1);
          }}
          onMovePrevRequest={() => {
            setImgIndex(imgIndex - 1);
          }}
          onCloseRequest={() => {
            setImgIndex(-1);
            setimgState('');
          }}
        />
      ) : (
        <Card>
          <Box sx={{ pt: '100%', position: 'relative' }}>
            {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )} */}
            {/* <ProductImgStyle alt="Image" src={img} /> */}
            {CheckFileType(img.type)}
          </Box>

          {/* <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ColorPreview colors={colors} />
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through'
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
        </Stack>
      </Stack> */}
        </Card>
      )}
    </>
  );
}

function PopupError (){
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  Toast.fire({
    icon: "error",
    title: "First Downloading Complete"
  });
}

function Video(img) {
  const { downloadUrl, thumbnail } = img.img;
  return (
    <ReactPlayer
      style={ProductImgStyle}
      url={downloadUrl}
      playing
      controls
      light={thumbnail}
      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
      width="100%"
      height="100%"
    />
  );
}

function Image({ img, setimgState, Index, setImgIndex, isInProgress }) {
  // const { downloadUrl } = img.img;
  const { downloadUrl } = img;
  return (
    <ProductImageStyle
      alt="Image"
      src={downloadUrl}
      onClick={() => {

        setImgIndex(Index);
        setimgState(true);
      }}
    />
  );
}

function File({ img}) {
  // const { downloadUrl } = img.img;
  const { downloadUrl, name } = img;
  return (
   
      <Tooltip title={`File name : ${name}`}>
        <ProductFileStyle>
          <div
            style={{
              display: 'flex',
              fontSize: '15px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10px',
              color: '#9a76f5'
            }}
          >
            <img src={img.thumbnail} width="70" height="70" />
          </div>
        </ProductFileStyle>
      </Tooltip>

  );
}
