import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Box } from '@mui/material';
// layouts
import { useEffect, useState } from 'react';
// import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
// import { MHidden } from '../components/@material-extend';
// import { LoginForm } from '../components/authentication/login';
// import AuthSocial from '../components/authentication/AuthSocial';
import ForgotForm from '../components/authentication/forgot/ForgotForm';
import Logo from '.././assest/rel8Logo.png';

import TickIcon from "../assest/tickIcon.svg"
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Forgot() {
  const [emailSent, setEmailSent] = useState(false)
  useEffect(() => {
    localStorage.removeItem('forgot');
  }, []);
  return (
   <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F6F6F6',
        minHeight: '100vh'
      }}
    >
      {/* <AuthLayout />

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          width: 'full'
        }}
        maxWidth="sm"
      >
       <a href='/login'> <img src={Logo} alt="logo" style={{ maxWidth: '300px' }} /></a>
       {!emailSent?  <Box
          sx={{
            backgroundColor: 'white',
            minWidth: '310px',
            paddingX: '25px',
            paddingY: '40px',
            boxShadow: 3
          }}
        >
          <Stack sx={{ mb: 3 }}>
          <Typography textAlign={'center'}  color="#9A76F5" variant="h4" >
              Forgot password
            </Typography>
          </Stack>
          <Stack sx={{ mb: 3, maxWidth:"310px" }}>
            <Typography sx={{ color: 'black' , fontSize:"12px"}}>
              Please enter your email address and we’ll send you instructions to change your
              password.
            </Typography>
            </Stack>
          {/* <AuthSocial /> */}

          <ForgotForm setEmailSent={setEmailSent}/>

          {/* <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden> */}
        </Box> :   <Box
          sx={{
            backgroundColor: 'white',
            minWidth: '310px',
            paddingX: '25px',
            paddingY: '40px',
            boxShadow: 3,
            maxWidth: '360px'
          }}
        >
          <Stack sx={{ mb: 4 }}>
            <Typography textAlign={'center'} color="#9A76F5" variant="h4">
              Email sent
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography> */}
          </Stack>
           <Stack sx={{display:'flex', justifyContent:"center", alignItems:"center", width:"full", minHeight:"80px", mb:4}}>
               <img src={TickIcon} alt='tick icon' style={{width:"80px"}}/>
           </Stack>
          <p style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Please check your email for instructions on how to change your password.
          </p>
        </Box>}
        <a href='https://rel8app.freshdesk.com/support/home' target='_blank' style={{textAlign:"center", fontSize:"10px",fontWeight:"bold", color:'#9A76F5', textDecoration:"none"}}>Need help?</a>
      </Box>
    </Box>
  );
}
