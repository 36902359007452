import { useState } from 'react';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import MainCard from './MoreInfo/MainCard';
import Residents from '../facilities/Resident';

export default function FamilyMoreInfo() {
  const location = useLocation();
  const { row } = location.state;

  // console.log(row?.residentIds[0]);

  return (
    <Grid container spacing={12}>
      <Grid
        item
        sx={{
          display: 'flex',
          position: 'relative'
        }}
      >
        <MainCard row={JSON.parse(JSON.stringify(row))} />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={12}>
        <Members id={row.id} name={row.name} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Rooms id={row.id} name={row.name} />
      </Grid> */}
      <Grid item xs={12} sm={12} md={12}>
        <Residents id={row?.residentIds} name={row.firstname} />
      </Grid>
    </Grid>
  );
}
