import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { doc, getDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import App, { db } from '../../../App';
// ----------------------------------------------------------------------
export const signout = () => signOut(getAuth());
export default function LoginForm() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = async (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const docRef = doc(db, 'users', userCredential.user.uid);
        getDoc(docRef).then(async (res) => {
          console.log(res.data());
          if (!res.data()) {
            setIsLoading(false);
            signOut(auth);
            return NotificationManager.error('user-not-found');
          }
          if (res.data().restrictAt === 1) {
            setIsLoading(false);
            signOut(auth);
            return Swal.fire({
              allowOutsideClick: false,
              icon: 'error',
              title: 'Oops...',
              text: 'Your account is disabled for 48 hours!'
            });
          }
          if (res.data().restrictAt === 2) {
            setIsLoading(false);
            signOut(auth);
            return Swal.fire({
              allowOutsideClick: false,
              icon: 'error',
              title: 'Oops...',
              text: 'Your account is disabled for 30 days!'
            });
          }
          if (res.data().restrictAt === 3) {
            setIsLoading(false);
            signOut(auth);
            return Swal.fire({
              allowOutsideClick: false,
              icon: 'error',
              title: 'Oops...',
              text: 'Your account is disabled for 90 days!'
            });
          }
          if (res.data().restrictAt === 4) {
            setIsLoading(false);
            signOut(auth);
            return Swal.fire({
              allowOutsideClick: false,
              icon: 'error',
              title: 'Oops...',
              text: 'Your account is permanently disabled!'
            });
          }

          localStorage.setItem('Rel8User', JSON.stringify(res.data()));

          // get version and store into local storage
          const version = await getDoc(doc(db, 'portalVersion', 'portal_version'));
          localStorage.setItem('Rel8Version', version.data()?.version);
          // navigate('/home/app', { replace: true });
          navigate('/', { replace: true });
          // window.location.href = '/home/app';
          window.location.reload(false);
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = error.message;
        errorMessage = errorMessage.substr(errorMessage.indexOf('/') + 1);
        errorMessage = errorMessage.substr(0, errorMessage.indexOf(')'));
        setIsLoading(false);
        NotificationManager.error(errorMessage);
      });
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: ({ setSubmitting }) => {
      const email = getFieldProps('email').value;
      const password = getFieldProps('password').value;
      setIsLoading(true);
      getUser(email, password);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const goToForgot = () => {
    localStorage.setItem('forgot', true);
    window.location.reload(false);
  };
  return (
    <FormikProvider value={formik}>
      <NotificationContainer />

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
          sx={{ backgroundColor:"white"}}
            fullWidth
            autoComplete="username"
            variant='standard'
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <Box>
          <TextField
            fullWidth
            variant='standard'
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        <div onClick={goToForgot} style={{textAlign:"right", marginTop:"5px" , fontSize:"10px", color:"#9A76F5",fontWeight:"bold"}}><a href='/forgot' style={{textDecoration:"none",color:"#9A76F5"}}>Forgot password?</a></div>
        </Box>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

          {/* <Link component={RouterLink} variant="subtitle2" to="/forgot" onClick={goToForgot}>
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
          Log in
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
