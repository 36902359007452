import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import NoticeboardForm from '../../components/_dashboard/noticeboard/NoticeboardForm';
import WellbeingForm from '../../components/_dashboard/Wellbeing/WellbeingForm';
import { useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px',
  padding: theme.spacing(0, 0)
}));

// ----------------------------------------------------------------------

export default function AddWellness() {
  const [selectOption, setSelectOption] = useState('add');
  return (
    <RootStyle title="Add notice | Rel8">
      <ContentStyle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%'
          }}
        >
          <div
            onClick={() => {
              if (selectOption == 'schedule' || selectOption == 'preview' ) {
                setSelectOption('add');
              }
            }}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'add' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'add' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
            Add Wellness
          </div>
          <div
             onClick={() => {
              if (selectOption == 'preview') {
                setSelectOption('schedule');
              }
            }}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'schedule' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'schedule' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
            Schedule
          </div>
          <div
            onClick={() => {}}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'preview' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'preview' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
            Preview
          </div> 
        </Box>

          <WellbeingForm selectOption={selectOption} setSelectOption={setSelectOption} />

        {/* <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden> */}
      </ContentStyle>
    </RootStyle>
  );
}
