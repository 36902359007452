import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  where,
  query,
  deleteField,
  updateDoc
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL,
  listAll
} from 'firebase/storage';
import Swal from 'sweetalert2';
import axios from 'axios';
import { db } from '../../../App';
import { deleteUser } from '../../../pages/facilityMember/User';
import { deleteFolder } from './deleteFiles';
import { cloudFuncBaseUrl } from 'src/utils/config';
// ----------------------------------------------------------------------

export default function DraftMoreMenu(props) {
  const refs = useRef(null);
  const { id, type, object, creatorId } = props;
  const [isOpen, setIsOpen] = useState(false);
  object.type = type;
  object.createdAt = new Date();
  object.endDate = new Date();
  object.endTime = new Date();
  const {
    description,
    medias,
    likes,
    title,
    createdAt,
    residentsId,
    isPublished,
    // roomName,
    roomsId,
    facilitiesId,
    facilityName,
    endDate,
    endTime,
    endTimePeriod,
    startTimePeriod,
    startTime,
    startDate,
    location,
    featuredNotice
  } = object;

  const user = JSON.parse(localStorage.getItem('Rel8User')).role;
  // console.log(id);

  const userDelete = () => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: `Delete forever`,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: 'gray',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let timerInterval;
          Swal.fire({
            title: 'Proceeing...',
            html: 'I will close in <b></b> Moments.',
            // timer: 2000,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });
          try {
            await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
              data: {
                id,
                collectionName: 'drafts',
                role: user
              }
            });
            // -----------------------------------
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Draft has been deleted.',
              showConfirmButton: false,
              timer: 1500
            });
            window.location.reload(false);
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message
            });
          }
          // Delete event permanently
        } else if (result.isDenied) {
          try {
            // ----------------------------------------------Loading-------------
            let timerInterval;
            Swal.fire({
              title: 'Deleting...',
              html: 'I will close in few<b></b> Moments.',
              // timer: 2000,
              allowOutsideClick: false,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer');
              }
            });
            // ----------------------------------------------Loading---------------------
            let typeCollection;
            if (type === 'events') {
              typeCollection = 'events';
            } else if (type === 'stories') {
              typeCollection = 'stories';
            } else if (type === 'noticeboard') {
              typeCollection = 'noticeboards';
            }
            const response = await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/DeleteAllDataPermanently`,
              data: {
                id,
                collection: `drafts ${typeCollection}`
              }
            });
            // console.log(response);
            // console.log(response?.data);
            // ----------------------------------
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Draft has been deleted.',
              showConfirmButton: false,
              timer: 1500
            });
            // window.location.reload(false);
          } catch (err) {
            return Swal.fire({
              icon: 'error',
              title: 'Error',
              text: err.message
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message
      });
    }
  };

  const Morepath = () => {
    if (type === 'events') {
      return '/content/events/event-detail';
    }

    if (type === 'stories') {
      return '/content/stories/story-detail';
    }

    if (type === 'noticeboard') {
      return '/home/noticeboard/notice-detail';
    }
  };

  const iff = (condition) => condition && Morepath();

  const path = () => {
    if (type === 'events') {
      return '/content/events/update-event';
    }

    if (type === 'stories') {
      return '/content/stories/update-story';
    }

    if (type === 'noticeboard') {
      return '/content/noticeboard/update-notice';
    }
  };

  const iff2 = (condition) => condition && path();

  return (
    <>
      <IconButton ref={refs} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={refs.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: '1' }}
      >
        {user !== 'Family Member' && (
          <>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={userDelete}>
              <ListItemIcon>
                <Icon icon={trash2Outline} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>

            <MenuItem
              sx={{ color: 'text.secondary' }}
              component={RouterLink}
              to={iff2(type)}
              // state={{ props: object }}
              state={{
                props: {
                  id,
                  description,
                  location: location ?? '',
                  medias,
                  likes,
                  title,
                  createdAt,
                  residentsId,
                  isPublished: isPublished ?? '',
                  // roomName,
                  roomsId,
                  facilitiesId,
                  facilityName,
                  type,
                  endDate: endDate ?? '',
                  endTime: endTime ?? '',
                  endTimePeriod: endTimePeriod ?? '',
                  startTimePeriod: startTimePeriod ?? '',
                  startTime: startTime ?? '',
                  startDate: startDate ?? '',
                  featuredNotice: featuredNotice ?? false
                }
              }}
            >
              <ListItemIcon>
                <Icon icon={editFill} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </>
        )}
        <MenuItem
          sx={{ color: 'text.secondary' }}
          component={RouterLink}
          to={iff(type)}
          // state={{ props: object }}
          state={{
            id,
            description,
            medias,
            likes,
            title,
            createdAt,
            residentsId,
            isPublished,
            // roomName,
            roomsId,
            facilitiesId,
            facilityName,
            type,
            object
          }}
        >
          <ListItemIcon>
            <Icon icon={moreHorizontalFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="More" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
