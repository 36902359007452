import { Card, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
// import Rooms from '../../facilities/Rooms';
import Facilitys from '../../FaciltiyMembers/MoreInfo/Facilitys';
// import MainCard from './MoreInfo/MainCard';
// import Facilitys from './Facilitys';
import MainCardResident from './MainCardResident';
import Rooms from './Rooms';
import Wellness from './Wellness';
// import Residents from '../facilities/Resident';

export default function ResidentMoreInfoDetail() {
  const location = useLocation();
  const { row, deleted, notifyArray } = location?.state;

  return (
    <Grid container spacing={12}>
      <Grid
        item
        sx={{
          display: 'flex',
          position: 'relative'
        }}
      >
        <MainCardResident row={row} deleted={deleted} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Card sx={{ margin: 2, marginTop: '-2%', width: '100%', paddingLeft: '1.5%' }}>
          <div>
            <h3> About Me</h3>
            <p>{row?.aboutMe}</p>
          </div>
          <div style={{ marginTop: '2%' }}>
            <h3> Likes</h3>
            <p>{row?.likes}</p>
          </div>
          <div style={{ marginTop: '2%', marginBottom: '1%' }}>
            <h3> Dislikes</h3>
            <p>{row?.dislikes}</p>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Rooms id={row?.roomId} name={row?.name} deleted={deleted} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Facilitys id={row?.facilityId} name={row?.firstname} deleted={deleted} root="residents" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Wellness id={row?.id} deleted={deleted} root="residents" notifyArray={notifyArray} />
      </Grid>
    </Grid>
  );
}
