import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider, validateYupSchema } from 'formik';
import { useNavigate } from 'react-router-dom';
import filePNG from '../../../assest/file.png';
import AviThumbnail from '../../../assest/aviThumbnail.png';
import HEICThumbnail from '../../../assest/heicThumbnail.png';
import MPEGThumbnail from '../../../assest/mpegThumbnail.png';
import AudioThumbnail from '../../../assest/audioThumbnail.png';
import Textthumbnail from '../../../assest/textThumbnail.png';
import PdfThumbnail from '../../../assest/pdfThumbnail.webp';
import BackButtonImage from '../../../assest/backButton.svg';

// material
import {
  Stack,
  TextField,
  Card,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  CircularProgress,
  Popper,
  Divider,
  ClickAwayListener
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { collection, doc, setDoc, getDocs, where, query } from 'firebase/firestore';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';

import { db } from '../../../App';
import { uploadFile } from '../user/UploadFile';
import EventPreview from '../event/EventPreview';
import SortByTitle from '../event/SortByTitle';
import { elementType } from 'prop-types';
import { isGlobalState } from '@mui/utils';
import ShopProductCard from '../../../components/_dashboard/products/ProductCard';

const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`
}));
const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function StoryForm({ setSelectOption, selectOption }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [draft, setDraft] = useState(false);
  const [Story, setStory] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [onceClickRoom, setOnceClickRoom] = useState(false);

  const [facilityOptions, setFacilityOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [openRoom, setOpenRoom] = useState(false);
  const [openFacility, setOpenFacility] = useState(false);
  const loadingRooms = openRoom && roomOptions.length === 0;
  const loadingFacility = openFacility && facilityOptions.length === 0;
  const [IsGroupContent, setIsGroupContent] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewError, setpreviewError] = useState(false);
  const [onceDone, setOnceDone] = useState(false);
  // Facility
  const [facilityID, setFacilityID] = useState('');
  const [roomId, setRoomIds] = useState([]);
  // console.log("loading location : ", loadingStateRooms )
  const [checkAll, setCheckAll] = useState(false);
  const [onceClick, setOnceClick] = useState(false);
  const [onceClickClient, setOnceClickClient] = useState(false);
  const [update, setUpdate] = useState(false);
  // for empty change facility
  const [residentIDs, setresidentIDs] = useState([]);
  const [checkAllResi, setCheckAllResi] = useState(false);

  const loading = open && options.length === 0;

  // for rooms Id array for resident
  const IDROOM = [];
  roomId.forEach((e) => {
    IDROOM.push(e.value);
  });

  const images =
    file.length != 0
      ? file.filter((image) => {
          console.log('image :: ', image);
          const file =
            (image.type != 'image' && image.type != 'video') ||
            (image.name && image.name.endsWith('.avi')) ||
            (image.name && image.name.endsWith('.mpeg')) ||
            (image.name && image.name.endsWith('.heic'));
          const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
          if (type != 'file') {
            return image;
          }
        })
      : [];


  useEffect(() => {
    if (location.state && !onceDone) {
      setFieldValue('title', location.state.props.title ? location.state.props.title : '');
      setFieldValue(
        'description',
        location.state.props.description ? location.state.props.description : ''
      );
      setFieldValue(
        'facilityName',
        location.state.props.facilityName ? location.state.props.facilityName : ''
      );
      setFieldValue(
        'facility',
        location.state.props.facilitiesId ? location.state.props.facilitiesId : ''
      );
      setFacilityID(location.state.props.facilitiesId ? location.state.props.facilitiesId : '');

      console.log('location.state.props.isPublished ', location.state.props.isPublished);
      setFieldValue('isPublished', location.state.props.isPublished);
      setIsGroupContent(location.state.props.isPublished);
      if (roomId.length != 0 && !onceClickClient) {
        (async () => {
          console.log('found');
          // await sleep(1e3); // For demo purposes.
          const residentsCollection = query(
            collection(db, 'residents'),
            where('id', 'in', location.state.props.residentsId)
          );
          const residentsPromise = await getDocs(residentsCollection);
          const opt = [];
          residentsPromise.forEach((result) => {
            opt.push({
              value: result.data().id,
              title: `${result.data().firstName} ${result.data().lastName}`
            });
          });

          const optArr = SortByTitle(opt);
          console.log('optArr ', optArr);
          setOptions([...optArr]);
          setresidentIDs(optArr);
          setFieldValue('residentsId', optArr);
          setOnceDone(true);
        })();
      }
      if (roomId.length == 0 && !onceClick) {
        console.log('not found');

        (async () => {
          // await sleep(1e3); // For demo purposes.
          const roomsCollection = query(
            collection(db, 'rooms'),
            where('id', 'in', location.state.props.roomsId)
          );
          const roomsPromise = await getDocs(roomsCollection);
          const opt = [];
          roomsPromise?.forEach((result) => {
            // console.log(result?.data());
            opt.push({
              value: result.data().id,
              title: result.data().name
            });
          });

          // console.log(opt);
          const optArr = SortByTitle(opt);
          setRoomIds(optArr);
          setFieldValue('roomsId', optArr);
        })();
      }

      // for check all empty
      setCheckAll(false);
      setCheckAllResi(false);
      console.log('location.state ', location.state);
      setFile(location.state.props.medias);
      console.log('get ', getFieldProps('facilityName'));
    }
  }, [roomId]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const residentsCollection = query(collection(db, 'residents'), where('roomId', 'in', IDROOM));
      const residentsPromise = await getDocs(residentsCollection);
      const opt = [];
      residentsPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: `${result.data().firstName} ${result.data().lastName}`,
          isAllow: result.data().includedInAdminStories,
          deletedAt: result.data().deletedAt
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        console.log('optArr ', optArr);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  };

  const handleFileUpload = async (e) => {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      arr.push(e.target.files[i]);
    }
    if (arr.length + file.length > 30) {
      return NotificationManager.error('maximum 30 files to upload');
    }
    const obj = [];

    for (let i = 0; i < arr.length; i += 1) {
      let thumbnail;
      const ImagesArray = arr[i];
      const { size } = arr[i];
      if (size / 1024 > 20480) {
        return NotificationManager.error('File size is greater than 20 mb');
      }

      const downloadUrl = URL.createObjectURL(arr[i]);
      const mediaTypeLocal = arr[i].type.split('/')[0];
      if (mediaTypeLocal !== 'application' && !arr[i].name.endsWith('.mpeg')) {
        if (mediaTypeLocal === 'video' && !arr[i].name.endsWith('.avi')) {
          thumbnail = await generateVideoThumbnail(arr[i]);
        }
        if (arr[i].name.endsWith('.heic')) {
          thumbnail = HEICThumbnail;
        }
        if (arr[i].name.endsWith('.avi')) {
          thumbnail = AviThumbnail;
        }
        if (mediaTypeLocal === 'audio') {
          thumbnail = AudioThumbnail;
        }
        if (mediaTypeLocal === 'text') {
          thumbnail = Textthumbnail;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          name: arr[i]?.name,
          size,
          type: mediaTypeLocal
        });
      } else {
        if (arr[i].name.endsWith('.pdf')) {
          thumbnail = PdfThumbnail;
        } else if (arr[i].name.endsWith('.mpeg')) {
          thumbnail = MPEGThumbnail;
        } else {
          thumbnail = filePNG;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          size,
          name: arr[i]?.name,
          type: 'file'
        });
      }
    }
    setFile([...file, ...obj]);
  };

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    description: Yup.string().required('Description is required.').nullable(),
    facility: Yup.string().required('Group is required').nullable(),
    roomsId: Yup.array().min(1, 'at least 1 location is required').required('required'),
    residentsId: Yup.array().min(1, 'at least 1 client is required').required('required'),
    date: Yup.date().required('Date is required')
  });

  const DraftRegisterSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    description: Yup.string().nullable(),
    facility: Yup.string().nullable(),
    facilityName: Yup.string().nullable(),
    roomsId: Yup.array(),
    residentsId: Yup.array(),
    date: Yup.date()
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      residentsId: [],
      roomsId: [],
      facility: '',
      facilityName: '',
      isPublished: false,
      date: new Date()
    },
    validationSchema: draft ? DraftRegisterSchema : RegisterSchema,
    onSubmit: async ({ resetForm }) => {
      if (!draft) {
        if (file.length <= 0) {
          return;
        }
      }
      if (previewError) {
        return setPreview(true);
      }
      AddEventFunc(draft ? 1 : 0);
    }
  });
  const AddEventFunc = async (drft) => {
    const draft = drft ?? false;

    if (file.length > 30) {
      return NotificationManager.error('Maximum 30 files to upload');
    }
    if (draft) {
      setDraftLoading(true);
    } else {
      setIsLoading(true);
    }
    const title = getFieldProps('title').value;
    const description = getFieldProps('description').value;
    const isPublished = getFieldProps('isPublished').value;
    const residents = getFieldProps('residentsId').value;
    const facilityName = getFieldProps('facilityName').value;
    const facilitiesId = getFieldProps('facility').value;
    const roomId = getFieldProps('roomsId').value;
    const date = getFieldProps('date').value;
    console.log('is published ', isPublished);
    let eventsRef;
    if (draft) {
      eventsRef = doc(collection(db, 'drafts'));
    } else {
      eventsRef = doc(collection(db, 'stories'));
    }

    const residentsId = [];
    residents.forEach((element) => {
      residentsId.push(element.value);
    });

    const roomsId = [];
    roomId.forEach((element) => {
      roomsId.push(element.value);
    });

    setTimeout(() => {
      NotificationManager.warning('Please wait! File is being upload');
    }, 10000);

    const medias = [];
    let itemsProcessed = 0;
    if (file.length > 0) {
      file.forEach(async (element, index, array) => {
        console.log('element :: ', element);
        const mediaId = uuidv4();
        if (element.type === 'video') {
          const downloadUrl = location.state
            ? element.downloadUrl
            : await uploadFile('stories', eventsRef, element.file, element.type, mediaId);
          const thumbnailId = uuidv4();
          const downloadUrlThumbnail = location.state
            ? element.thumbnail
            : await uploadFile('stories', eventsRef, element.thumbnail, 'thumbnail', thumbnailId);
          console.log('name 1 : ', element);
          medias.push({
            downloadUrl: location.state ? element.downloadUrl : downloadUrl ?? null,
            thumbnailId: thumbnailId ?? null,
            thumbnail: location.state ? element.thumbnail : downloadUrlThumbnail ?? null,
            name: location.state ? element.name : element.file.name,
            type: element.type,
            size: Math.floor(element.size / 1024, 3),
            id: mediaId
          });
        }

        if (element.type === 'image') {
          const downloadUrl = location.state
            ? element.downloadUrl
            : await uploadFile('stories', eventsRef, element.file, element.type, mediaId);
          console.log('name 2 : ', element);

          medias.push({
            downloadUrl,
            thumbnailId: null,
            thumbnail: null,
            name: location.state ? element.name : element.file.name,
            type: element.type,
            size: null,
            id: mediaId
          });
        }

        if (element.type === 'application') {
          const downloadUrl = location.state
            ? element.downloadUrl
            : await uploadFile('stories', eventsRef, element.file, element.type, mediaId);
          console.log('name 3 : ', element);

          medias.push({
            downloadUrl,
            thumbnailId: null,
            thumbnail: null,
            name: location.state ? element.name : image.name,
            type: 'file',
            size: element.size,
            id: mediaId
          });
        }

        itemsProcessed += 1;

        if (itemsProcessed === array.length) {
          if (draft) {
            setDoc(eventsRef, {
              title,
              id: eventsRef.id,
              type: 'stories',
              createdAt: date,
              creatorId: JSON.parse(localStorage.getItem('Rel8User'))?.id,
              object: {
                id: eventsRef.id,
                title,
                description,
                isPublished,
                medias,
                residentsId,
                postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                  JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                }`,
                createdAt: date,
                // roomName,
                roomsId,
                facilitiesId,
                facilityName,
                commentsCount: 0
              }
            })
              .then((res) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'first ',
                  showConfirmButton: false,
                  timer: 2000
                });
                setDraft(false);
                setDraftLoading(false);
                navigate('/home/drafts', { replace: true });
              })
              .catch((err) => console.log(err));
          } else {
            setDoc(eventsRef, {
              id: eventsRef.id,
              title,
              description,
              isPublished,
              medias,
              residentsId,
              postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
              authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              }`,
              createdAt: date,
              // roomName,
              roomsId,
              facilitiesId,
              facilityName,
              commentsCount: 0
            })
              .then((res) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Story Added Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/stories', { replace: true });
              })
              .catch((err) => console.log(err));

            // -----------------------------------------Comment Notifications------------------------------------
            const object = {
              id: eventsRef.id,
              title,
              description,
              isPublished,
              medias,
              residentsId,
              postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
              authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              }`,
              createdAt: date,
              roomsId,
              facilitiesId,
              facilityName,
              commentsCount: null,
              likes: null
            };
            const commentNotifiRef = doc(collection(db, 'notifications'));
            await setDoc(commentNotifiRef, {
              id: commentNotifiRef.id,
              body: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              } has posted a new story`,
              commentId: eventsRef.id,
              createdAt: date,
              profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
              title: 'A new story',
              topic: `${facilitiesId}_story`,
              viewedBy: [],
              senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
              receiverId: '',
              object,
              senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
                JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              }`,
              type: 'stories'
            });
            // -----------------------------------------Comment Notifications------------------------------------
          }
        }
      });
      // ************************** for with out image ***********************
    } else {
      if (draft) {
        console.log('facility 2 : ', facilityName);

        return setDoc(eventsRef, {
          title,
          id: eventsRef.id,
          type: 'stories',
          createdAt: date,
          creatorId: JSON.parse(localStorage.getItem('Rel8User'))?.id,
          object: {
            id: eventsRef.id,
            title,
            description: description ? description : '',
            isPublished,
            medias: medias ? medias : [],
            residentsId: residentsId ? residentsId : [],
            postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
            authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
              JSON.parse(localStorage.getItem('Rel8User'))?.lastname
            }`,
            createdAt: date,
            // roomName,
            roomsId,
            facilitiesId,
            facilityName,
            commentsCount: 0
          }
        })
          .then((res) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Draft Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            setDraft(false);
            setDraftLoading(false);
            navigate('/home/drafts', { replace: true });
          })
          .catch((err) => console.log(err));
      }

      setDoc(eventsRef, {
        id: eventsRef.id,
        title,
        description,
        isPublished,
        medias,
        residentsId,
        postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
        authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
          JSON.parse(localStorage.getItem('Rel8User'))?.lastname
        }`,
        createdAt: date,
        // roomName,
        roomsId,
        facilitiesId,
        facilityName,
        commentsCount: 0
      })
        .then((res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Story Added Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          navigate('/home/stories', { replace: true });
        })
        .catch((err) => console.log(err));

      // -----------------------------------------Comment Notifications------------------------------------
      const object = {
        id: eventsRef.id,
        title,
        description,
        isPublished,
        medias,
        residentsId,
        postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
        authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
          JSON.parse(localStorage.getItem('Rel8User'))?.lastname
        }`,
        createdAt: date,
        roomsId,
        facilitiesId,
        facilityName,
        commentsCount: null,
        likes: null
      };
      const commentNotifiRef = doc(collection(db, 'notifications'));
      await setDoc(commentNotifiRef, {
        id: commentNotifiRef.id,
        body: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
          JSON.parse(localStorage.getItem('Rel8User'))?.lastname
        } has posted a new story`,
        commentId: eventsRef.id,
        createdAt: date,
        profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
        title: 'A new story',
        topic: `${facilitiesId}_story`,
        viewedBy: [],
        senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
        receiverId: '',
        object,
        senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${
          JSON.parse(localStorage.getItem('Rel8User'))?.lastname
        }`,
        type: 'stories'
      });
      // -----------------------------------------Comment Notifications------------------------------------
    }
  };
  // }

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched
  } = formik;

  // -------------Rooms Autocomplete--------------------------//
  useEffect(() => {
    let active = true;

    if (!loadingRooms) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const roomsCollection = query(collection(db, 'rooms'), where('facilityId', '==', facilityID));
      const roomsPromise = await getDocs(roomsCollection);
      const opt = [];
      roomsPromise?.forEach((result) => {
        // console.log(result?.data());
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setRoomOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingRooms]);

  useEffect(() => {
    if (!openRoom) {
      setRoomOptions([]);
    }
  }, [openRoom]);

  // -----------------------------get facility--------------------------
  useEffect(() => {
    let active = true;
    console.log('useEffect ');
    if (!loadingFacility) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        console.log('facalties');
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        console.log('others');
        facilitesCollection = collection(db, 'facilites');
      }
      // const facilitesCollection = collection(db, 'facilites');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise.forEach((result) => {
        // console.log(result.data());
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setFacilityOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingFacility, draft]);

  useEffect(() => {
    if (!openFacility) {
      setFacilityOptions([]);
    }
  }, [openFacility]);

  // -------------------------------------------------
  // for room
  const handleClickAway = (e) => {
    setOpenRoom(false);
  };
  // for resident
  const handleClickAwayResi = (e) => {
    setOpen(false);
  };

  // -------for room
  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      setRoomIds(roomOptions);
      setFieldValue('roomsId', roomOptions);
    } else {
      setRoomIds([]);
    }
    setFieldValue('residentsId', []);
    setresidentIDs([]);
  };

  // -----For resident
  const checkAllChangeResi = (event) => {
    if (!IsGroupContent) {
      return;
    }
    setCheckAllResi(event.target.checked);
    if (event.target.checked) {
      const arry = [];
      options.map((item) => {
        if (item.isAllow && item.deletedAt == null) {
          arry.push(item);
        }
      });
      console.log('array : ', arry);
      setresidentIDs(arry);
      setFieldValue('residentsId', arry);
    } else {
      setFieldValue('residentsId', []);
      setresidentIDs([]);
    }
  };

  // -------------------------------------------------
  return (
    <Box>
      {selectOption == 'preview' ? (
        <EventPreview
          file={file}
          title={{ ...getFieldProps('title') } ?? ''}
          description={{ ...getFieldProps('description') }}
          setPreview={setPreview}
          AddSubmitFunc={AddEventFunc}
          draftLoading={draftLoading}
          isLoading={isLoading}
          head1="Add Story"
          head2="Add Story"
          setSelectOption={setSelectOption}
          setDraft={setDraft}
          handleSubmit={handleSubmit}
          date={getFieldProps('date').value}
          section="Story"
        />
      ) : (
        <Card
          sx={{
            marginTop: '',
            width: '100%',
            minWidth: '100%',
            paddingX: '20px',
            paddingY: '20px'
          }}
        >
          {selectOption != 'add' && (
            <div
              style={{ marginBottom: '12px' }}
              onClick={() => {
                if (selectOption == 'audience') {
                  setSelectOption('add');
                } else if (selectOption == 'schedule') {
                  setSelectOption('audience');
                }
              }}
            >
              <img src={BackButtonImage} style={{ width: '35px', cursor: 'pointer' }} />
            </div>
          )}

          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <NotificationContainer />
              <Stack spacing={3}>
                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Title
                      </div>

                      <TextField
                        fullWidth
                        // label="Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Box>
                  </Stack>
                )}
                {selectOption == 'audience' && (
                  <FormControlLabel
                    control={<Checkbox checked={IsGroupContent} />}
                    // label="Published"
                    label="Is this a group story?"
                    {...getFieldProps('isPublished')}
                    onChange={(e, value) => {
                      setFieldValue('residentsId', []);
                      setresidentIDs([]);
                      setOptions([]);
                      setIsGroupContent(!IsGroupContent);
                      setFieldValue('isPublished', value);
                    }}
                  />
                )}
                {selectOption == 'schedule' && (
                  <Stack sx={{ width: '100%', '& > *': { flex: 1 } }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Date
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          format="dd/MM/yyyy"
                          inputFormat="dd/MM/yyyy"
                          label="Date"
                          {...getFieldProps('date')}
                          onChange={(e, value) => setFieldValue('date', e || '')}
                          onChangeRaw={(e) => {
                            setFieldTouched('date', true, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(touched.date && errors.date)}
                              helperText={touched.date && errors.date}
                            />
                          )}
                          maxDate={new Date()}
                        />
                      </LocalizationProvider>
                    </div>
                  </Stack>
                )}
                {/* {selectOption == 'schedule' && (
                  <Box
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#9A76F5',
                      paddingY: '25px',
                      textAlign: 'center'
                    }}
                  >
                    Schedule your content in advance - coming soon!
                  </Box>
                )} */}
                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Group
                      </div>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        open={openFacility}
                        onOpen={() => {
                          setOpenFacility(true);
                        }}
                        onClose={() => {
                          setOpenFacility(false);
                        }}
                        onChange={(e, value) => {
                          setFieldValue('facilityName', value?.title || '');
                          setFieldValue('facility', value?.value || '');
                          setFacilityID(value?.value);

                          // for empty
                          setRoomIds([]);
                          setFieldValue('roomsId', []);
                          setresidentIDs([]);
                          setFieldValue('residentsId', []);
                          // for check all empty
                          setCheckAll(false);
                          setCheckAllResi(false);
                        }}
                        defaultValue={{
                          title: location.state
                            ? location.state.facilityName
                            : getFieldProps('facilityName').value ?? '',
                          value: location.state
                            ? location.state.facilitiesId
                            : getFieldProps('facility').value ?? ''
                        }}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        options={facilityOptions}
                        loading={loadingFacility}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            label="Select Group"
                            {...getFieldProps('facility')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingFacility ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                            error={Boolean(touched.facility && errors.facility)}
                            helperText={touched.facility && errors.facility}
                          />
                        )}
                      />
                    </div>
                  </Stack>
                )}
                {/* ************************************************************************************************************************* */}
                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Location
                      </div>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            disableCloseOnSelect
                            multiple
                            limitTags={3}
                            disabled={!facilityID ?? false}
                            id="asynchronous-demo"
                            open={openRoom}
                            onOpen={() => {
                              setOpenRoom(true);
                            }}
                            onChange={(event, newValue, reason) => {
                              if (roomId.length != 0) {
                                setOnceClickRoom(true);
                              }
                              if (reason === 'selectOption') {
                                setFieldValue('roomsId', newValue ?? '');
                                setRoomIds(newValue);
                              } else if (reason === 'removeOption') {
                                setFieldValue('roomsId', newValue ?? '');
                                setRoomIds(newValue);
                              } else if (reason === 'clear') {
                                setRoomIds([]);
                                setFieldValue('roomsId', []);
                              }
                              setCheckAll(false);
                              setFieldValue('residentsId', []);
                              setresidentIDs([]);
                              setCheckAllResi(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            options={roomOptions}
                            value={roomId}
                            loading={loadingRooms}
                            PopperComponent={(param) => (
                              <PopperStyledComponent {...param}>
                                <Box {...param} />
                                <Divider />
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    height: '45px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <Checkbox
                                    checked={checkAll}
                                    onChange={checkAllChange}
                                    id="check-all"
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                  Select All
                                </Box>
                              </PopperStyledComponent>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  location.state && roomId.length == 0 && !onceClickRoom
                                    ? 'Loading...'
                                    : 'Select Location'
                                }
                                {...getFieldProps('room')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingRooms ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.roomsId && errors.roomsId)}
                                helperText={touched.roomsId && errors.roomsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Stack>
                )}
                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Clients
                      </div>
                      {/* <ClickAwayListener onClickAway={handleClickAwayResi}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            disableCloseOnSelect
                            multiple
                            limitTags={3}
                            disabled={IDROOM.length === 0 ?? false}
                            id="asynchronous-demo"
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'selectOption') {
                                if (IsGroupContent) {
                                  // Handle group content selection
                                  setFieldValue('residentsId', newValue ?? '');
                                  setresidentIDs(newValue);
                                } else {
                                  // Handle non-group content selection
                                  setFieldValue('residentsId', [newValue[newValue.length - 1]]);
                                  setresidentIDs([newValue[newValue.length - 1]]);
                                }
                              } else if (reason === 'removeOption') {
                                // Handle option removal
                                setFieldValue('residentsId', newValue ?? '');
                                setresidentIDs(newValue);
                              } else if (reason === 'clear') {
                                // Handle clearing of selections
                                setresidentIDs([]);
                                setFieldValue('residentsId', []);
                              }
                              setCheckAllResi(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            options={options}
                            value={residentIDs}
                            loading={loading}
                            getOptionDisabled={
                              IsGroupContent
                                ? (option) => option.isAllow === false || option.deletedAt != null
                                : (option) => (option.deletedAt != null ? true : false)
                            }
                            renderOption={(props, option) => (
                              <li
                                {...props}
                                style={{
                                  opacity: IsGroupContent
                                    ? option.isAllow && option.deletedAt == null
                                      ? 1
                                      : 0.5
                                    : option.deletedAt != null
                                    ? 0.5
                                    : 1
                                }}
                              >
                                {option.title}
                              </li>
                            )}
                            PopperComponent={(param) => (
                              <PopperStyledComponent {...param}>
                                <Box {...param} />
                                <Divider />
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    height: '45px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <Checkbox
                                    checked={checkAllResi}
                                    onChange={checkAllChangeResi}
                                    id="check-all"
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                  Select All
                                </Box>
                              </PopperStyledComponent>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={
                                  location.state && residentIDs.length == 0 && !onceDone
                                    ? 'Loading...'
                                    : 'Select Clients'
                                }
                                {...getFieldProps('resident')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.residentsId && errors.residentsId)}
                                helperText={touched.residentsId && errors.residentsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener> */}
                      <ClickAwayListener onClickAway={handleClickAwayResi}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            disableCloseOnSelect
                            multiple
                            limitTags={3}
                            disabled={IDROOM.length === 0 ?? false}
                            id="asynchronous-demo"
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'selectOption') {
                                if (IsGroupContent) {
                                  // Handle group content selection
                                  setFieldValue('residentsId', newValue ?? '');
                                  setresidentIDs(newValue);
                                } else {
                                  // Handle non-group content selection
                                  setFieldValue('residentsId', [newValue[newValue.length - 1]]);
                                  setresidentIDs([newValue[newValue.length - 1]]);
                                }
                              } else if (reason === 'removeOption') {
                                // Handle option removal
                                setFieldValue('residentsId', newValue ?? '');
                                setresidentIDs(newValue);
                              } else if (reason === 'clear') {
                                // Handle clearing of selections
                                setresidentIDs([]);
                                setFieldValue('residentsId', []);
                              }
                              setCheckAllResi(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            options={options}
                            value={residentIDs}
                            loading={loading}
                            getOptionDisabled={
                              IsGroupContent
                                ? (option) => option.isAllow === false || option.deletedAt != null
                                : (option) => (option.deletedAt != null ? true : false)
                            }
                            renderOption={(props, option) => (
                              <li
                                {...props}
                                style={{
                                  opacity: IsGroupContent
                                    ? option.isAllow && option.deletedAt == null
                                      ? 1
                                      : 0.5
                                    : option.deletedAt != null
                                    ? 0.5
                                    : 1
                                }}
                              >
                                {option.title}
                              </li>
                            )}
                            PopperComponent={(param) => (
                              <PopperStyledComponent {...param}>
                                <Box {...param} />
                                <Divider />
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    height: '45px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <Checkbox
                                    checked={checkAllResi}
                                    onChange={checkAllChangeResi}
                                    id="check-all"
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                  Select All
                                </Box>
                              </PopperStyledComponent>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={
                                  location.state && residentIDs.length == 0 && !onceClickClient
                                    ? 'Loading...'
                                    : 'Select Clients'
                                }
                                {...getFieldProps('resident')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.residentsId && errors.residentsId)}
                                helperText={touched.residentsId && errors.residentsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Stack>
                )}
                {/* ************************************************************************************************************************* */}
                {selectOption == 'add' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Description
                    </div>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={20}
                      // label="Description"
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Box>
                )}
                {/* ------------------------------------------------------------- */}
                {selectOption == 'add' && (
                  <>
                    {true && (
                      <div className="grid">
                        {file.length > 0 &&
                          file.map((item, index) => {
                            return (
                              <>
                                {!(
                                  (item.type != 'image' && item.type != 'video') ||
                                  item.name.endsWith('.avi') ||
                                  item.name.endsWith('.mpeg')
                                ) && (
                                  <div
                                    key={item.id}
                                    style={{ position: 'relative' }}
                                    className={
                                      item === images[0] && images.length > 7
                                        ? 'firstIndex'
                                        : 'allIndex'
                                    }
                                  >
                                    {' '}
                                    <div
                                      style={{
                                        position: 'absolute',
                                        top: '-3px',
                                        right: '-3px',
                                        zIndex: 100
                                      }}
                                    >
                                      <Icon
                                        icon="ci:close-big"
                                        onClick={() => deleteFile(index)}
                                        style={{
                                          cursor: 'pointer',
                                          backgroundColor: 'white',
                                          borderRadius: '100%',
                                          outline: '1px',
                                          outlineColor: '#9A76F5'
                                        }}
                                      />
                                    </div>
                                    <Card>
                                     
                                        {/* <ProductImageStyle
                                          alt="Image"
                                          src={item.thumbnail ?? item.downloadUrl}
                                        /> */}
                                        <ShopProductCard img={item} Index={index} images={images} />
                                      
                                    </Card>
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </div>
                    )}
                    {true && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {file.length > 0 &&
                          file.map((item, index) => {
                            return (
                              <>
                                {((item.type != 'image' && item.type != 'video') ||
                                  item.name.endsWith('.avi') ||
                                  item.name.endsWith('.mpeg')) && (
                                  <Box
                                    sx={{
                                      maxWidth: '250px',
                                      width: 'full',
                                      display: 'flex',
                                      gap: '5px',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Icon
                                      icon="ci:close-big"
                                      onClick={() => deleteFile(index)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                    <Box
                                      sx={{
                                        maxWidth: '50px',
                                        maxHeight: '60px',
                                        minWidth: '50px',
                                        minHeight: '60px',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <img
                                        src={item.thumbnail}
                                        style={{ width: '100%', object: 'contain', height: '100%' }}
                                      />
                                    </Box>
                                    <div style={{ fontSize: '12px', fontWeight: 500 }}>
                                      {item.name && item.name.length > 25
                                        ? item.name.slice(0, 22) + '...'
                                        : item.name}
                                      {' ('}
                                      {item.size ?? ''}
                                      {' KB)'}
                                    </div>
                                  </Box>
                                )}
                              </>
                            );
                          })}
                      </Box>
                    )}
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Button variant="contained" component="label">
                        Add Photos
                        <input
                            type="file"
                            hidden
                            multiple
                            fullWidth
                            accept="image/*, video/*,  .heic"
                            onChange={handleFileUpload}
                            error={Boolean(touched.photo && errors.photo)}
                            helperText={touched.photo && errors.photo}
                          />
                      </Button>
                    </Stack>
                    {file.length <= 0 && Story && (
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          At least One Image is required
                        </p>
                      )}
                      </Box>
                  </>
                )}
               

                {(selectOption == 'add' ||
                  selectOption == 'audience' ||
                  selectOption == 'schedule') && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="outlined"
                      loading={draftLoading}
                      onClick={() => setDraft(true)}
                    >
                      Save drafts
                    </LoadingButton>
                    <LoadingButton
                      fullWidth
                      size="large"
                      // type="submit"
                      onClick={() => {
                        if (selectOption == 'add') {
                          if (!getFieldProps('title').value) {
                            setFieldTouched('title');
                          }
                          if (!getFieldProps('description').value) {
                            setFieldTouched('description');
                          }
                          setStory(true);

                          if (
                            getFieldProps('title').value &&
                            getFieldProps('description').value &&
                            file.length > 0
                          ) {
                            setSelectOption('audience');
                          }
                        } else if (selectOption == 'audience') {
                          if (!getFieldProps('facility').value) {
                            setFieldTouched('facility');
                          }

                          if (getFieldProps('roomsId').value.length == 0) {
                            setFieldTouched('roomsId');
                          }
                          if (getFieldProps('residentsId').value.length == 0) {
                            setFieldTouched('residentsId');
                          }
                          if (
                            getFieldProps('facility').value &&
                            getFieldProps('roomsId').value.length > 0 &&
                            getFieldProps('residentsId').value.length > 0
                          ) {
                            setSelectOption('schedule');
                          }
                        } else if (selectOption == 'schedule') {
                          setSelectOption('preview');
                        }
                      }}
                      variant="contained"
                      loading={isLoading}
                    >
                      {selectOption == 'add'
                        ? 'Choose audience'
                        : selectOption == 'audience'
                        ? 'Schedule'
                        : 'Preview'}
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      )}
    </Box>
  );
}
