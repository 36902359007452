import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getAuth, updatePassword } from 'firebase/auth';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import axios from 'axios';
import { cloudFuncBaseUrl } from 'src/utils/config';

const missingCharFunc = (password) => {
  console.log(password);
  const upper = /[A-Z]/;
  const lowwer = /[a-z]/;
  const number = /[0-9]/;
  const specialChar = /[@$!%#*?&]/;
  const messageList = [];
  // upper case
  if (upper.exec(password) === null) {
    messageList.push(' one upper case letter');
  }
  // Lower Case
  if (lowwer.exec(password) === null) {
    messageList.push(' one lower case letter');
  }
  // Number
  if (number.exec(password) === null) {
    messageList.push(' one number');
  }
  // special Character
  if (specialChar.exec(password) === null) {
    messageList.push(' one special characters');
  }
  // password length
  if (password.length <= 7) {
    messageList.push(' at least 8 characters');
  }
  return messageList;
};

// ----------------------------------------------------------------------
export default function UpdatePassword({ id, type }) {
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const RegisterSchema = Yup.object().shape({
    NewPassword: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('New password required'),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref('NewPassword'), null], 'Passwords must match')
      .required('Confirm password required')
  });

  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!#%*?&]{8,}$/;

  const updatePass = (NewPassword, ConfirmPassword) => {
    console.log(user);
    updatePassword(user, NewPassword)
      .then(() => {
        console.log('updated successfullt');
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Password Updated Successfully',
          showConfirmButton: false,
          timer: 2000
        });
        navigate('/home', { replace: true });
        setIsLoading(false);
      })
      .catch((error) => {
        let errorMessage = error.message;
        errorMessage = errorMessage.substr(errorMessage.indexOf('/') + 1);
        errorMessage = errorMessage.substr(0, errorMessage.indexOf(')'));
        setIsLoading(false);
        NotificationManager.error(errorMessage);
      });
  };
  const formik = useFormik({
    initialValues: {
      NewPassword: '',
      ConfirmPassword: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async ({ resetForm }) => {
      try {
        const NewPassword = getFieldProps('NewPassword').value;

        const require = missingCharFunc(NewPassword);
        console.log(require);
        // if (regex.exec(NewPassword) == null) {
        if (require.length > 0) {
          return Swal.fire({
            allowOutsideClick: false,
            title: 'Warning !',
            // text: 'Please set password with one lower case letter, one upper case letter, One number, one special character and at least 8 characters!',
            text: `Required ${require.join(',').toString()}!`,
            icon: 'warning'
          });
        }
        setIsLoading(true);
        if (!id) {
          updatePass(NewPassword);
        } else {
          await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateUsersPassword`,
            data: {
              id,
              password: NewPassword,
              type: 'password'
            }
          });
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Password Updated Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          navigate(
            `/home/${
              type === 'family' ? 'family-members' : type === 'admin' ? 'app' : 'staff-members'
            }`,
            {
              replace: true
            }
          );
          setIsLoading(false);
        }
        // resetForm();
      } catch (err) {
        setIsLoading(false);
        Swal.fire({
          allowOutsideClick: false,
          title: 'Error',
          text: err.message,
          icon: 'error'
        });
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <NotificationContainer />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div style={{ marginBottom: '20px', fontFamily: 'arial' }}>
          <b> Password Requirements :</b>
          <div> - One lower case letter</div>
          <div> - One upper case letter</div>
          <div> - One number</div>
          <div> - One special characters</div>
          <div> - At least 8 characters</div>
        </div>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="New Password"
              {...getFieldProps('NewPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.NewPassword && errors.NewPassword)}
              helperText={touched.NewPassword && errors.NewPassword}
            />
          </Stack>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('ConfirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.ConfirmPassword && errors.ConfirmPassword)}
            helperText={touched.ConfirmPassword && errors.ConfirmPassword}
          />

          {/* <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          /> */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Update Password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
