import faker from 'faker';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { set, sub, formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import BellIcon from '../../assest/bellIcon.svg';
// firebase
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  wherem,
  Timestamp,
  where
} from 'firebase/firestore';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Avatar
} from '@mui/material';
// utils
import { mockImgAvatar } from '../../utils/mockImages';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import Popover from '@mui/material/Popover';

import { db, localUser } from '../../App';
import axios from 'axios';
import { cloudFuncBaseUrl } from 'src/utils/config';
import Swal from 'sweetalert2';
import { result } from 'lodash';
import { MHidden } from 'src/components/@material-extend';
// ----------------------------------------------------------------------

const Iconify = forwardRef(({ icon, width = 20, sx, ...other }, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
));

// ------------------------------------
//            local user
// ------------------------------------
const localUserId = JSON.parse(localStorage.getItem('Rel8User'))?.id;

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification?.body.length <= 70 ? notification?.body : notification?.body?.slice(0, 70)}
    </Typography>
  );

  return {
    avatar: <img alt={notification.title} src={notification?.profileImgUrl} />,
    title
  };
}

// ------------------------------
//      for reading notification
const readNofity = async (notifications) => {
  const viewedBy = notifications?.viewedBy ?? [];

  const index = viewedBy.indexOf(localUserId);
  // delete nofification when dislike
  if (index === -1) {
    viewedBy.push(localUserId);
    await updateDoc(doc(db, 'notifications', notifications?.id), {
      viewedBy
    });
  }
};
// ------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};
function formatRelativeTime(date) {
  let timeString = formatDistanceToNow(date, { addSuffix: true });
  return timeString.replace('about ', '');
}
function NotificationItem({ notification, setOpen, setPopupOpen }) {
  const { avatar, title } = renderContent(notification);
  // console.log(title);
  return (
    <ListItemButton
      to={makeRouteNotify(notification?.type, notification)}
      state={makePropsNotify(notification?.type, notification?.object)}
      disableGutters
      onClick={() => {
        setPopupOpen(false);
        setOpen(false);
        readNofity(notification);
        sessionStorage.setItem('commentId', notification.commentId);
      }}
      component={RouterLink}
      sx={{
        py: 1.5,
        paddingX: '10px',
        mt: '1px',
        '&:hover': {
          bgcolor: '#F6F7F8',
          color: 'black'
        },
        ...(notification?.viewedBy === null
          ? { bgcolor: '#EAE2FD' }
          : notification?.viewedBy?.indexOf(localUserId) === -1 && {
              // bgcolor: 'action.selected'
              bgcolor: '#EAE2FD'
            })
      }}
    >
      <ListItemAvatar>
        <Avatar
          src={avatar?.props?.src}
          sx={{
            outline:
              notification?.viewedBy != null && notification?.viewedBy?.indexOf(localUserId) != -1
                ? ''
                : '2px solid white',
            boxShadow:
              notification?.viewedBy != null && notification?.viewedBy?.indexOf(localUserId) != -1
                ? 0
                : 8
          }}
        >
          {!avatar?.props?.src && title?.props?.children?.slice(0, 1)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            {formatRelativeTime(new Date(notification?.createdAt.seconds * 1000))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
function NotificationItemMobile({ notification, setOpen, setPopupOpen }) {
  const { avatar, title } = renderContent(notification);
  // console.log(title);
  return (
    <ListItemButton
      to={makeRouteNotify(notification?.type, notification)}
      state={makePropsNotify(notification?.type, notification?.object)}
      disableGutters
      onClick={() => {
        setPopupOpen(false);
        setOpen(false);
        readNofity(notification);
        sessionStorage.setItem('commentId', notification.commentId);
      }}
      component={RouterLink}
      sx={{
        py: 1,
        paddingX: '6px',
        '&:hover': {
          bgcolor: '#F6F7F8',
          color: 'black'
        },
        mt: '1px',
        ...(notification?.viewedBy === null
          ? { bgcolor: '#FAF1FE' }
          : notification?.viewedBy?.indexOf(localUserId) === -1 && {
              // bgcolor: 'action.selected'
              bgcolor: '#FAF1FE'
            })
      }}
    >
      <ListItemAvatar>
        <Avatar
          src={avatar?.props?.src}
          sx={{
            outline:
              notification?.viewedBy != null && notification?.viewedBy?.indexOf(localUserId) != -1
                ? ''
                : '2px solid white',
            boxShadow:
              notification?.viewedBy != null && notification?.viewedBy?.indexOf(localUserId) != -1
                ? 0
                : 8
          }}
        >
          {!avatar?.props?.src && title?.props?.children?.slice(0, 1)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            {formatRelativeTime(new Date(notification?.createdAt.seconds * 1000))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------
//               Make route and props for notification
// ----------------------------------------------------------------
const makeRouteNotify = (type, noti) => {
  let route;
  if (type === 'stories') {
    route = `/content/stories/story-detail${
      noti?.title === 'Got a reply'
        ? '?c_id=' + noti?.commentId + 'rp'
        : noti?.title === 'Got a comment'
        ? '?c_id=' + noti?.commentId + 'cmt'
        : ''
    }`;
  }
  if (type === 'events') {
    route = '/content/events/event-detail';
  }
  if (type === 'noticeboard') {
    route = '/content/noticeboard/notice-detail';
  }
  if (type === 'wellness') {
    route = '/content/wellness/wellness-detail';
  }

  return route;
};

const makePropsNotify = (type, object) => {
  let props;
  if (type === 'stories') {
    props = {
      id: object?.id,
      description: object?.description,
      medias: object?.medias,
      likes: object?.likes,
      commentsCount: object?.commentsCount,
      title: object?.title,
      createdAt: object?.createdAt,
      residentsId: object?.residentsId,
      isPublished: object?.isPublished,
      // roomName,
      roomsId: object?.roomsId,
      facilitiesId: object?.facilitiesId,
      facilityName: object?.facilityName,
      object
    };
  }
  if (type === 'events') {
    props = {
      id: object?.id,
      title: object?.title,
      location: object?.location,
      description: object?.description,
      medias: object?.medias,
      startDate: object?.startDate,
      startTime: object?.startTime,
      endTime: object?.endTime,
      endDate: object?.endDate,
      createdAt: object?.createdAt,
      likes: object?.likes,
      commentsCount: object?.commentsCount,
      startTimePeriod: object?.startTimePeriod,
      endTimePeriod: object?.endTimePeriod,
      residentsId: object?.residentsId,
      roomsId: object?.roomsId,
      facilitiesId: object?.facilitiesId,
      facilityName: object?.facilityName,
      object
    };
  }
  if (type === 'noticeboard') {
    props = {
      id: object?.id,
      description: object?.description,
      medias: object?.medias,
      likes: object?.likes,
      commentsCount: object?.commentsCount,
      title: object?.title,
      createdAt: object?.createdAt,
      object
    };
  }
  if (type === 'wellness') {
    object.date = new Date();
    props = {
      id: object?.id,
      description: object?.description,
      commentsCount: object?.commentsCount,
      createdAt: object?.createdAt,
      likes: object?.likes,
      medias: object?.medias,
      residentId: object?.residentsId && object?.residentsId[0],
      residentName: object?.residentName,
      roomId: object?.roomId,
      facilityId: object?.facilityId,
      facilityName: object?.facilityName,
      object
    };
  }
  return props;
};
// ----------------------------------------------------------------
//                             End
// ----------------------------------------------------------------

export default function NotificationsPopover({ setPopupOpen }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  // filter unRead comment
  const localResidentIds = JSON.parse(localStorage.getItem('Rel8User')).residentIds;
  const totalUnRead = notifications
    .filter((noti) => {
      if (
        JSON.parse(localStorage.getItem('Rel8User'))?.role == 'admin' 
      ) {
        return noti;
      }
      if(noti.topic != "comment" && noti.topic != "like" ){
      if (noti.object.residentsId && noti.object.residentsId.length != 0) {
        if (localResidentIds.some((id) => noti.object.residentsId.includes(id))) {
          return noti;
        }
      }} else{
        if(noti.receiverId ==  JSON.parse(localStorage.getItem('Rel8User'))?.id){
          return noti;
        }
      }
    })
    .slice(0, 30)
    .filter((item) =>
      item.viewedBy === null ? -1 : item.viewedBy?.indexOf(localUserId) === -1
    ).length;

  //---------------------------------------------
  //          get notification from firebase
  //---------------------------------------------'
 
  const timestamp = new Timestamp(localUser.createdAt.seconds, localUser.createdAt.nanoseconds);

  const notifyCollection = query(
    collection(db, 'notifications'),
    where('createdAt', '>', timestamp),
    orderBy('createdAt', 'desc')
  );
  useEffect(() => {
    onSnapshot(notifyCollection, (snapshot) => {
      const notification = [];
      snapshot.docs.forEach((result) => {
        notification.push(result.data());
      });
      setNotifications(notification.filter((e) => e.senderId !== localUserId));
    });
  }, []);
  // -------------------------------------------

  //-------------------------------------------
  //        notification open or close
  //-------------------------------------------

  const handleOpen = () => {
    setPopupOpen(true);
    setOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
    setOpen(false);
  };

  //------------------ handle Mark All As Read -------------------------
  async function handleMarkAllAsRead() {
    // for read locally
    for (let noti of notifications) {
      const viewedBy = noti?.viewedBy ?? [];
      const index = viewedBy.indexOf(localUserId);
      // delete nofification when dislike
      if (index === -1) {
        viewedBy.push(localUserId);
        const modify_arr = notifications.map((e) => (e.id === noti.id ? { ...e, viewedBy } : e));
        setNotifications(modify_arr);
      }
    }
    axios({
      method: 'post',
      url: `${cloudFuncBaseUrl}.cloudfunctions.net/allReadNotifications`,
      data: {
        uid: localUser.id
      },
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then(({ data }) => {
        if (data?.success === false) {
          return Swal.fire({
            icon: 'error',
            title: data?.message,
            text: data?.errorMessage
          });
        }
      })
      .catch((err) => {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.message
        });
      });

    // for (let noti of notifications) {
    //   const viewedBy = noti?.viewedBy ?? [];
    //   const index = viewedBy.indexOf(localUserId);
    //   // delete nofification when dislike
    //   if (index === -1) {
    //     viewedBy.push(localUserId);
    //     await updateDoc(doc(db, 'notifications', noti?.id), {
    //       viewedBy
    //     });
    //   }
    // }
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="medium"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <MHidden width="lgDown">
            <Icon icon={bellFill} width={20} height={20} />{' '}
          </MHidden>
          <MHidden width="lgUp">
            <Icon icon={bellFill} width={16} height={16} />{' '}
          </MHidden>
        </Badge>
      </IconButton>

      <MHidden width="lgDown">
        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          sx={{ width: 360, marginTop: '32px' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            horizontal: 'right'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              paddingX: '10px',
              paddingY: '15px',
              backgroundColor: '#9A76F5',
              minWidth: '330px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                paddingX: '10px',
                gap: '12px',
                flexGrow: 1,
                alignItems: 'center'
              }}
            >
              <img src={BellIcon} style={{ width: '20px' }} />
              <div style={{ fontSize: '18px', fontWeight: 600, color: 'white' }}>Notifications</div>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography> */}
            </Box>
            {/* // ----------------------------- */}
            {totalUnRead > 0 && (
              <Tooltip title=" Mark all as read" placement="top" arrow>
                <IconButton color="primary" onClick={handleMarkAllAsRead}>
                  <Iconify icon="eva:done-all-fill" />
                </IconButton>
              </Tooltip>
            )}
            {/* // ----------------------------- */}
          </Box>

          <Divider />
          <p
            style={{
              fontSize: '12px',
              color: '#9A76F5',
              lineHeight: '12px',
              fontWeight: 500,
              textAlign: 'right',
              paddingRight: '4px',
              paddingTop: '8px',
              cursor: 'pointer'
            }}
          >
            <button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: '#9A76F5',
                cursor: 'pointer'
              }}
              onClick={handleMarkAllAsRead}
            >
              Mark all as read
            </button>
          </p>
          <Scrollbar sx={{ height: { xs: 340, sm: 450 } }}>
            <List>
              {notifications.length !== 0 ? (
                notifications
                  .filter((noti) => {
                    if (
                      JSON.parse(localStorage.getItem('Rel8User'))?.role == 'admin' 
                    ) {
                      return noti;
                    }
                   
                    if(noti.topic != "comment" && noti.topic != "like" ){
                      if (noti.object.residentsId && noti.object.residentsId.length != 0) {
                        if (localResidentIds.some((id) => noti.object.residentsId.includes(id))) {
                          return noti;
                        }
                      }} else{
                        if(noti.receiverId ==  JSON.parse(localStorage.getItem('Rel8User'))?.id){
                          return noti;
                        }
                      }
                  })
                  .slice(0, 30)
                  .map((notification, i) => {
                    return (
                      <NotificationItem
                        setPopupOpen={setPopupOpen}
                        key={notification?.id}
                        notification={notification}
                        setOpen={setOpen}
                      />
                    );
                  })
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    paddingTop: '15px',
                    color: '#9A76F5',
                    fontSize: '20px'
                  }}
                >
                  Loading...
                </div>
              )}
            </List>
          </Scrollbar>
        </Popover>
      </MHidden>

      <MHidden width="lgUp">
        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          sx={{ width: 360, marginTop: '32px' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            horizontal: 'right'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              paddingX: '6px',
              paddingY: '15px',
              backgroundColor: '#9A76F5',
              minWidth: '250px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                paddingX: '6px',
                gap: '6px',
                flexGrow: 1,
                alignItems: 'center'
              }}
            >
              <img src={BellIcon} style={{ width: '16px' }} />
              <div style={{ fontSize: '16px', fontWeight: 500, color: 'white' }}>Notifications</div>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography> */}
            </Box>
            {/* // ----------------------------- */}
            {totalUnRead > 0 && (
              <Tooltip title=" Mark all as read" placement="top" arrow>
                <IconButton color="primary" onClick={handleMarkAllAsRead}>
                  <Iconify icon="eva:done-all-fill" />
                </IconButton>
              </Tooltip>
            )}
            {/* // ----------------------------- */}
          </Box>

          <Divider />
          <p
            style={{
              fontSize: '10px',
              color: '#9A76F5',
              lineHeight: '10px',
              fontWeight: 500,
              textAlign: 'right',
              paddingRight: '4px',
              paddingTop: '8px',
              cursor: 'pointer'
            }}
          >
            <button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: '#9A76F5',
                cursor: 'pointer'
              }}
              onClick={handleMarkAllAsRead}
            >
              Mark all as read
            </button>
          </p>
          <Scrollbar sx={{ height: { xs: 340, sm: 450 } }}>
            <List>
              {notifications.length !== 0 ? (
                notifications
                  .filter((noti) => {
                    if (
                      JSON.parse(localStorage.getItem('Rel8User'))?.role == 'admin' 
                    ) {
                      return noti;
                    }

                    if(noti.topic != "comment" && noti.topic != "like" ){
                      if (noti.object.residentsId && noti.object.residentsId.length != 0) {
                        if (localResidentIds.some((id) => noti.object.residentsId.includes(id))) {
                          return noti;
                        }
                      }} else{
                        if(noti.receiverId ==  JSON.parse(localStorage.getItem('Rel8User'))?.id){
                          return noti;
                        }
                      }
                  })
                  .slice(0, 30)
                  .map((notification, i) => {
                    return (
                      <NotificationItemMobile
                        setPopupOpen={setPopupOpen}
                        key={notification?.id}
                        notification={notification}
                        setOpen={setOpen}
                      />
                    );
                  })
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    paddingTop: '15px',
                    color: '#9A76F5',
                    fontSize: '20px'
                  }}
                >
                  Loading...
                </div>
              )}
            </List>
          </Scrollbar>
        </Popover>
      </MHidden>
    </>
  );
}
