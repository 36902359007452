import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import { DateTimePicker, DesktopDateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
// import { DesktopDatePicker  } from '@mui/x-date-pickers/DesktopDatePicker ';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Button
} from '@mui/material';
import { listUserDelete } from './EventListToolbar';
import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from 'src/App';
import CrossIcon from 'src/components/crossIcon';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  overflowX: 'auto'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function UserListToolbar({
  USERLIST,
  localuser,
  selected,
  numSelected,
  setFilterByType,
  filterByType,
  filterByOrg,
  setfilterByOrg,
  filterByGrp,
  setFilterByGrp,
  filterName,
  onFilterName,
  collect,
  eventDate,
  handleFilterByDate,
  setFilterByDate
}) {
  const [options, setOptions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsSlice, setGroupsSlice] = useState(0);
  const [isGroups, setIsGroups] = useState(false);
  const [Types, setTypes] = useState([]);
  const type = ['Residential Aged Care', 'Retirement Communities', 'Home Care', 'Disability Care'];
  function handleClearBtn() {
    setFilterByType('');
    setfilterByOrg('');
  }

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  useEffect(() => {
    //* **************Get Facilites From Firebase**********************
    let userCollection;
    if (user?.role === 'Facility Member') {
      userCollection = query(collection(db, 'facilites'), where('id', 'in', user?.facilityIds));
    } else if (user?.role === 'admin') {
      userCollection = collection(db, 'facilites');
    } else if (user?.role === 'Family Member') {
      userCollection = query(collection(db, 'facilites'), where('id', 'in', user?.facilityIds));
    }

    onSnapshot(userCollection, (snapshot) => {
      const groups = [];
      snapshot.docs.forEach((result) => {
        groups.push(result.data()?.name);
      });
      groups.sort((a, b) => a.localeCompare(b));
      setGroups(groups);
      setIsGroups(true);
    });
    console.log('all gorups : ', groups);
    onSnapshot(collection(db, 'organizations'), (snapshot) => {
      const types = [];
      const users = [];
      snapshot.docs.forEach((result) => {
        users.push(result.data());
      });
      setOptions(
        users?.filter((e) => {
          if (localuser.role == 'admin') {
            return e;
          }
          if (e.organizationName == localuser.organization) {
            return e;
          }
        })
      );

      users?.filter((e) => {
        if (e.organizationName == localuser.organization) {
          USERLIST.filter((a) => {
            if (a.organisation == e.organizationName) {
              types.push(a.type);
            }
          });
        }
      });
      setTypes(types);
    });
  }, [isGroups]);
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          type="search"
          placeholder="Search..."
          sx={{}}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip
          title="Delete"
          arrow
          placement="top"
          onClick={() => listUserDelete(selected, collect)}
        >
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : collect === 'facilites' ? (
        <div style={{ display: 'flex', marginRight: 'auto', marginLeft: '120px' }}>
          {/* Org */}
          <FormControl style={{ minWidth: 200 }} size="small">
            <InputLabel id="demo-simple-select-label">Filter By Organisation</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-label"
              value={filterByOrg}
              label="Filter By Organisation"
              onChange={(e) => {
                setfilterByOrg(e.target.value);
              }}
            >
              <MenuItem value="">All</MenuItem>
              {options?.map((e) => (
                <MenuItem value={e.organizationName}>{e.organizationName}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* type  */}
          <FormControl style={{ minWidth: 200, marginLeft: '20px' }} size="small">
            <InputLabel id="demo-simple-select-label">Filter By Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-label"
              value={filterByType}
              label="Filter By Type"
              onChange={(e) => {
                setFilterByType(e.target.value);
              }}
            >
              <MenuItem value="">All</MenuItem>
              {localuser.role == 'admin'
                ? type.map((e) => {
                    return <MenuItem value={e}>{e}</MenuItem>;
                  })
                : Types?.map((e) => {
                    return <MenuItem value={e}>{e}</MenuItem>;
                  })}
            </Select>
          </FormControl>

          {(filterByType != '' || filterByOrg != '') && (
            <FormControl
              style={{
                minWidth: 40,
                marginLeft: '20px',
                display: 'flex',
                justifyContent: 'center',
                height: 'full'
              }}
              size="small"
            >
              <button
                onClick={handleClearBtn}
                style={{
                  cursor: 'pointer',
                  width: 'fit',
                  border: 'none',
                  backgroundColor: 'transparent'
                }}
              >
                <CrossIcon />
              </button>
            </FormControl>
          )}
        </div>
      ) : (
        (collect === 'groups' ||
          collect === 'wellness' ||
          collect == 'noticeboards' ||
          collect == 'stories' ||
          collect == 'events') && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              width: '100%',
              marginRight: 'auto',
              marginLeft: '120px',
              gap: '16px'
            }}
          >
            <FormControl style={{ minWidth: 200 }} size="small">
              <InputLabel sx={{ marginY: '7px' }} id="demo-simple-select-label">
                Filter By Group
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select-label"
                value={filterByGrp}
                label="Filter By Group"
                sx={{ paddingY: '7px' }}
                onChange={(e) => {
                  setFilterByGrp(e.target.value);
                }}
              >
                <MenuItem value="">All</MenuItem>
                {groups?.map((e) => (
                  <MenuItem value={e}>{e.slice(0, 18)}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {filterByGrp && (
              <button
                onClick={() => {
                  setFilterByGrp('');
                }}
                style={{
                  cursor: 'pointer',
                  width: 'fit',
                  backgroundColor: 'transparent',
                  border: 'none'
                }}
              >
                <CrossIcon />
              </button>
            )}
          </div>
        )
      )}

      {numSelected == 0 && collect == 'events' && (
        <div style={{ position: 'relative' }}>
         {eventDate && <div
            style={{
              position: 'absolute',
              bottom: '80%',
              right: '-4px',
              background: 'gray',
              borderRadius: '100%',
              padding: '8px',
              fontWeight: 'bold',
              color: 'darkgray',
              fontSize: '16px',
              zIndex: 100,
              cursor: 'pointer'
            }}
            onClick={() => {
              setFilterByDate(null);
            }}
          >
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '10px',
                  color: 'white'
                }}
              >
                X
              </div>
            </div>
          </div>}

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              sx={{
                padding: 0,
                marginLeft: '20px'
              }}
              format="dd/MM/yyyy"
              fullWidth
              inputFormat="Filter Date"
              label="Filter Date"
              value={eventDate}
              onChange={handleFilterByDate}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  // error={touched.start && errors.start}
                  // helperText={touched.start && errors.start}
                  // InputProps={{ style: { margin: '0px', padding: '0px' } }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      )}
    </RootStyle>
  );
}
