import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


function crossIcon() {
  return (
    <CloseOutlinedIcon/>
  )
}

export default crossIcon
