import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Checkbox,
  Box
} from '@mui/material';
// components
// ------------------------for popup box-------------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
// ------------------------------------------------------------------
import {
  collection,
  doc,
  deleteDoc,
  setDoc,
  onSnapshot,
  query,
  where,
  getCountFromServer,
  Timestamp
} from 'firebase/firestore';
import Swal from 'sweetalert2';
import moment from 'moment';
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
//
import { db, localUser } from '../../App';
import EventListToolbar from '../../components/_dashboard/user/EventListToolbar';
import EventMoreMenu from '../../components/_dashboard/user/EventMoreMenu';
import axios from 'axios';
import EventsIcon from '../../assest/eventsIcon.svg';
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
// ------------------------End for popup box-------------------------

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'facilityName', label: 'Group', alignRight: false },
  { id: 'location', label: 'Address', alignRight: false },
  { id: 'startDate', label: 'Start Date/Time', alignRight: false },
  // { id: 'startTime', label: 'Start Time', alignRight: false },
  { id: 'endDate', label: 'End Date/Time', alignRight: false },
  // { id: 'endTime', label: 'End Time', alignRight: false },
  { id: 'views', label: 'Views', alignRight: false },
  { id: 'likes', label: 'Likes', alignRight: false },
  { id: 'comments', label: 'Comments', alignRight: false },
  { id: 'createdAt', label: 'Published Date', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//* **************Add Users From Firebase**********************
export const addUser = (likes, title, comments) => {
  const newCityRef = doc(collection(db, 'users'));
  return setDoc(newCityRef, {
    id: newCityRef.id,
    title,
    comments,
    likes
  });
};

//* **************Delete Users From Firebase**********************
export const deleteUser = (id) =>
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteDoc(doc(db, 'users', id))
        .then((res) => window.location.reload(false))
        .catch((err) => console.log(err.message));
      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });

//* **************Delete Users From Firebase**********************
export const listUserDelete = (ids) =>
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      for (let i = 0; i < ids.length; i += 1) {
        deleteDoc(doc(db, 'users', ids[i]))
          .then((res) => {
            if (i === ids.length - 1) {
              window.location.reload(false);
            }
          })
          .catch((err) => console.log(err.message));
      }
      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });

//* **************Update Users From Firebase**********************
export const updateUser = (id, likes, title, comments) => {
  setDoc(doc(db, 'users', id), {
    id,
    title,
    comments,
    likes
  })
    .then(() => console.log('deleted successfully'))
    .catch((err) => console.log(err.message));
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, query2, eventDate) {
  if (eventDate && !query) {

    return array.filter((d) => {
      const adjustedTimestamp = d.startDate.seconds * 1000;
      if (
        new Date(adjustedTimestamp).getFullYear() == new Date(eventDate).getFullYear() &&
        new Date(adjustedTimestamp).getDate() == new Date(eventDate).getDate() &&
        new Date(adjustedTimestamp).getMonth() == new Date(eventDate).getMonth()
      ) {
        return d;
      }
    });
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  const Query2 = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    console.log('query : ', query);
    return filter(array, (_user) =>
      _user.title ? _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 : -1
    );
  }
  console.log('1');
  const ReturnGroups = query2
    ? stabilizedThis.filter((a) => {
        console.log('a ', a[0].facilityName);
        console.log('query 2 ', query2);
        if (query2 == a[0].facilityName) {
          return a[0];
        }
      })
    : stabilizedThis;

  console.log('2');
  console.log('return group ', ReturnGroups);
  return ReturnGroups.map((el) => {
    return el[0];
  });
}

export default function Events() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);
  const [value, setValue] = useState();
  const [filterByGrp, setFilterByGrp] = useState('');
  const [filterByDate, setFilterByDate] = useState();
  const [open, setOpen] = useState(false);
  const [modelRow, setModelRow] = useState('');
  // const [eventDate, setEventDate] = useState()
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Noticeboard From Firebase**********************
  const timestamp = new Timestamp(user.createdAt.seconds, user.createdAt.nanoseconds);

  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'events'),
      where('facilitiesId', 'in', user?.facilityIds)
    );
  } else if (user?.role === 'admin') {
    userCollection = collection(db, 'events');
  } else if (user?.role === 'Family Member') {
    userCollection = query(
      collection(db, 'events'),
      where('residentsId', 'array-contains-any', user?.residentIds)
    );
  }

  let noticeboard = [];
  const getUsers = async () => {
    onSnapshot(userCollection, async (snapshot) => {
      noticeboard = [];
      snapshot.docs.forEach(async (result) => {
        noticeboard.push({
          id: result.data().id,
          avatarUrl: 'result.data().images[0]',
          title: result.data().title,
          comments: result.data()?.commentsCount || 0,
          likes: result.data().likes ?? [],
          postedById: result.data().postedById,
          startDate: result.data().startDate,
          startTime: result.data().startTime,
          endDate: result.data().endDate,
          endTime: result.data().endTime,
          createdAt: result.data().createdAt,
          medias: result.data().medias ?? [],
          description: result.data().description,
          location: result.data().location,
          endTimePeriod: result.data()?.endTimePeriod,
          startTimePeriod: result.data()?.startTimePeriod,
          residentsId: result.data().residentsId ?? [],
          facilityName: result.data().facilityName ?? null,
          facilitiesId: result.data().facilitiesId ?? null,
          roomsId: result.data()?.roomsId ?? [],
          authorName: result.data()?.authorName ?? '',
          views: result.data()?.views ? result.data()?.views.length : 0
        });
      });

      if (!noticeboard.length) {
        return setNotFound(true);
      }
      setUSERLIST(noticeboard);
    });
  };

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  const handleFilterByDate = (event) => {
    console.log(event);

    // let filterByDateArray=USERLIST?.filter(e=>e.createdAt.toDate.toDateString)

    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterByDate(event);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  console.log('runing');
  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName ?? '',
    filterByGrp ?? '',
    filterByDate ?? ''
  );
  console.log('stop', filteredUsers);
  const isUserNotFound = filteredUsers.length === 0;

  // ------------------------for popup box-------------------------
  const handleClickOpen = (title) => {
    setModelRow(title);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ------------------------End for popup box-------------------------

  const toTimestamp = (strDate) => {
    const datum = Date.parse(strDate);
    return datum / 1000;
  };

  return (
    <Box>
      <Page title="Events | Rel8">
        <Container sx={{ width: '100%', minWidth: '100%' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                color: '#9A76F5'
              }}
            >
              <img
                src={EventsIcon}
                style={{ width: 35, height: 35, minWidth: 30, minHeight: 30, marginRight: '5px' }}
              />
              <p
                style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}
              >
                Events
              </p>
            </Box>
            {/* {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' && (
              <Button
                variant="contained"
                component={RouterLink}
                to="/content/noticeboard/add-notice-board"
                startIcon={<Icon icon={plusFill} />}
              >
                Add Notice
              </Button>
            )} */}
          </Stack>

          <Card sx={{ boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px' }}>
            {/* <EventListToolbar
              selected={selected}
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              value={value}
              filterByDate={handleFilterByDate}
            /> */}

            <UserListToolbar
              USERLIST={[]}
              localuser={{}}
              selected={selected}
              numSelected={selected.length}
              filterName={filterName}
              setFilterByGrp={setFilterByGrp}
              onFilterName={handleFilterByName}
              collect="events"
              eventDate={filterByDate}
              handleFilterByDate={handleFilterByDate}
              setFilterByDate={setFilterByDate}
              
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    deletedTab={localUser.role === 'Family Member' && true}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          title,
                          description,
                          comments,
                          avatarUrl,
                          startDate,
                          startTime,
                          endDate,
                          endTime,
                          createdAt,
                          likes,
                          location,
                          medias,
                          startTimePeriod,
                          endTimePeriod,
                          residentsId,
                          roomsId,
                          facilitiesId,
                          facilityName,
                          postedById,
                          authorName,
                          views
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        console.log('location : ', location);
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            description="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              {localUser.role !== 'Family Member' && (
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="2px">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={title} src={avatarUrl} /> */}
                                <Typography
                                  variant="subtitle2"
                                  component={RouterLink}
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    color: '#212B36',
                                    fontWeight: 'bold'
                                  }}
                                  to="/content/events/event-detail"
                                  state={{
                                    id,
                                    title,
                                     address : location,
                                    description,
                                    medias,
                                    startDate,
                                    startTime,
                                    endTime,
                                    endDate,
                                    createdAt,
                                    likes,
                                    commentsCount: comments,
                                    startTimePeriod,
                                    endTimePeriod,
                                    residentsId,
                                    roomsId,
                                    facilitiesId,
                                    facilityName,
                                    object: row
                                  }}
                                >
                                  {title.length < 40 ? (
                                    title
                                  ) : (
                                    <>
                                      {title.slice(0, 35)}
                                      <span
                                        style={{
                                          color: 'blue',
                                          cursor: 'pointer',
                                          marginLeft: '5px'
                                        }}
                                        onClick={() => handleClickOpen(title)}
                                        role="button"
                                        tabIndex="0"
                                        onKeyDown={handleClick}
                                      >
                                        Read more...
                                      </span>
                                    </>
                                  )}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{facilityName}</TableCell>
                            <TableCell align="left">
                              {location?.length > 60 ? (
                                <>
                                  <p>{location.slice(0, 25)}</p>
                                  <p>{location.slice(25, 50)}</p>
                                  <span
                                    style={{
                                      color: 'blue',
                                      cursor: 'pointer',
                                      marginLeft: '5px'
                                    }}
                                    onClick={() => handleClickOpen(location)}
                                    role="button"
                                    tabIndex="0"
                                    onKeyDown={handleClick}
                                  >
                                    Read more...
                                  </span>
                                </>
                              ) : (
                                <>
                                  <p>{location.slice(0, 30)}</p>
                                  {location.length > 30 && (
                                    <p>{location.slice(30, location.length)}</p>
                                  )}
                                </>
                              )}
                            </TableCell>

                            <TableCell align="left">
                              <p>{moment(startDate.toDate()).format('DD MMM YYYY')}</p>
                              <p>{`${startTime}${startTimePeriod}`}</p>
                            </TableCell>

                            <TableCell align="left">
                              <p>{moment(endDate.toDate()).format('DD MMM YYYY')}</p>
                              <p>{`${endTime}${endTimePeriod}`}</p>
                            </TableCell>

                            {/* <TableCell align="left">{date.toDate().toDateString()}</TableCell> */}
                            <TableCell align="center">{views}</TableCell>
                            <TableCell align="center">{likes?.length}</TableCell>
                            <TableCell align="center">{comments}</TableCell>
                            <TableCell align="left">
                              {moment(createdAt.toDate()).format('DD MMM YYYY')}
                            </TableCell>
                            <TableCell align="right">
                              {selected.length === 0 && (
                                <EventMoreMenu
                                  id={id}
                                  title={title}
                                  location={location}
                                  description={description}
                                  medias={medias}
                                  startDate={startDate}
                                  startTime={startTime}
                                  startTimePeriod={startTimePeriod}
                                  endTimePeriod={endTimePeriod}
                                  endTime={endTime}
                                  endDate={endDate}
                                  createdAt={createdAt}
                                  likes={likes}
                                  commentsCount={comments}
                                  residentsId={residentsId}
                                  roomsId={roomsId}
                                  facilitiesId={facilitiesId}
                                  facilityName={facilityName}
                                  object={row}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound &&
                    (NotFound ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                            <UserNotFound />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      {/* ***************************** Start PopUp ************************* */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', fontWeight: '700' }} id="draggable-dialog-title">
          {modelRow}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText sx={{ color: '#34495E' }}>{modelRow?.description}</DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* ***************************** End PopUp ************************* */}
    </Box>
  );
}
