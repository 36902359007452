import { filter, forEach, result } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// components

import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDocm,
  where,
  query,
  updateDoc,
  onSnapshot
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import Lightbox from 'react-image-lightbox';

import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import DeletedTimeResi from '../../components/_dashboard/resident/DeletedTimeResi';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
//
import { db , localUser } from '../../App';
import ResidentMoreMenu from '../../components/_dashboard/user/ResidentMoreMenu';
import { LightBox } from '../../components/_dashboard/user/LightBox';

import repeatOutline from '@iconify/icons-eva/repeat-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { DeleteEvent } from '../Wellbeing/DeletedWellness';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  // { id: 'firstName', label: 'First Name', alignRight: false },
  // { id: 'lastName', label: 'Last Name', alignRight: false },
  // { id: 'dateOfBirth', label: 'DOB', alignRight: false },
  // { id: 'createdAt', label: 'Date', alignRight: false },
  // { id: 'facilityName', label: 'Facilty', alignRight: false },
  // { id: 'roomId', label: 'Room', alignRight: false },
  // { id: 'adminStory', label: 'Admin Story', alignRight: false },
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'facilityName', label: 'Group', alignRight: false },
  { id: 'roomId', label: 'Locations', alignRight: false },
  { id: 'createdAt', label: 'Date Added', alignRight: false },
  { id: 'adminStory', label: 'Group Story', alignRight: false },
  { id: 'delete', label: 'Delete', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//* **************Delete Users From Firebase**********************
export const restoreEvent = (data) => {
  // console.log(data.id);
  console.log(data);
  try {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to be restore this resident?',
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, restore it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        // ----------------------------------------------Loading-------------
        let timerInterval;
        Swal.fire({
          title: 'Processing...',
          html: 'I will close in few<b></b> Moments.',
          // timer: 2000,
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector('b');
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer');
          }
        });
        // ----------------------------------------------Loading---------------------
        // await setDoc(doc(db, 'residents', data.id), {
        //   ...data
        // });
        console.log(data);

        await setDoc(doc(db, 'residents', data.id), {
          ...data,
          deletedAt: null
        });
        await deleteDoc(doc(db, 'recycleBin', 'residents', 'residents', data.id));
        // ------------------------------------
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Resident has been restored.',
          showConfirmButton: false,
          timer: 1500
        });
        window.location.reload(false);
      }
    });
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message
    });
  }
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DeletedResidents() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [NotFound, setNotFound] = useState(false);
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [USERLIST, setUSERLIST] = useState([]);
  const location = useLocation();

  // ------for lightbox
  const [image, setImage] = useState('');

  //* **************Get Facilites From Firebase**********************
  // let userCollection;
  // if (!location.state) {
    
  let userCollection ;
  // } else {
  //   userCollection = query(
  //     collection(db, 'residents'),
  //     where('deletedAt', '!=', null),
  //     where('roomId', '==', location.state.roomId)
  //   );
  // }

  if(localUser.role=='admin'){
    userCollection = query(collection(db, 'recycleBin', 'residents', 'residents'));
  }else{
    console.log("local user ", localUser)
    userCollection = query(collection(db, 'recycleBin', 'residents', 'residents'),where('facilityId','in',localUser.facilityIds));
  }
  const users = [];
  const getUsers = async () => {
    // getDocs(userCollection).then((res) => {
    onSnapshot(userCollection, (res) => {
      res.forEach((result) => {
        users.push({
          id: result.data().id,
          profileImgUrl: result.data().profileImgUrl,
          firstName: result.data().firstName,
          lastName: result.data().lastName,
          dateOfBirth: result.data().dateOfBirth,
          createdAt: result.data().createdAt,
          deletedAt: result.data()?.deletedAt,
          includedInAdminStories: result.data().includedInAdminStories,
          facilityId: result.data().facilityId,
          facilityName: result.data().facilityName,
          roomId: result.data().roomId,
          roomName: result.data().roomName,
          aboutMe: result.data().aboutMe ?? '',
          likes: result.data().likes ?? '',
          dislikes: result.data().dislikes ?? ''
        });
      });
      if (!users.length) {
        return setNotFound(true);
      }
      setUSERLIST(users);
    });
  };

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );
  return (
    <Page title="Residents | Rel8">
      <Container>
        <Typography variant="h4" gutterBottom>
          Deleted Clients
        </Typography>
        <Card>
          <UserListToolbar
           USERLIST={[]}
           localuser={{}}
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                 USERLIST={[]}
                 localuser={{}}
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  deletedTab={true}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        firstName,
                        lastName,
                        facilityId,
                        facilityName,
                        includedInAdminStories,
                        dateOfBirth,
                        profileImgUrl,
                        createdAt,
                        roomId,
                        roomName,
                        deletedAt,
                        dislikes,
                        likes,
                        aboutMe
                      } = row;

                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          facilityName="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            /> */}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="2px">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {profileImgUrl ? (
                                <Avatar
                                  alt={firstName}
                                  src={profileImgUrl}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setImage(profileImgUrl);
                                  }}
                                />
                              ) : (
                                <Avatar alt={firstName} src={profileImgUrl} />
                              )}
                              <Typography
                                variant="subtitle2"
                                component={RouterLink}
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                  color: '#212B36',
                                  fontWeight: 'bold'
                                }}
                                to="/administration/clients/resident-more-info"
                                state={{ row, deleted: 'yes' }}
                              >
                                {firstName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{lastName}</TableCell>
                          <TableCell align="left">
                            {facilityName || (
                              <Label variant="ghost" color="error">
                                Unassigned
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {roomName || (
                              <Label variant="ghost" color="error">
                                Unassigned
                              </Label>
                            )}
                          </TableCell>
                          {/* <TableCell align="left">
                            {moment(dateOfBirth?.toDate()).format('DD/MM/YYYY')}
                          </TableCell> */}
                          <TableCell align="left">
                            {moment(createdAt?.toDate()).format('DD MMM YYYY')}
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={includedInAdminStories ? 'success' : 'error'}
                            >
                              {sentenceCase(includedInAdminStories ? 'True' : 'False')}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color="error">
                              {DeletedTimeResi(deletedAt)}-remaining
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            <Stack spacing={2} direction={{ xs: 'row', sm: 'row' }}>
                              <Tooltip title="Restore Resident" placement="top">
                                <LoadingButton
                                  size="small"
                                  variant="contained"
                                  // loading={isLoading}
                                  onClick={() => {
                                    restoreEvent(row);
                                  }}
                                  disabled={
                                    JSON.parse(localStorage.getItem('Rel8User')).role ===
                                      'Family Member' ?? false
                                  }
                                >
                                  {/* Restore */}
                                  <Icon icon={repeatOutline} style={{ fontSize: '20px' }} />
                                </LoadingButton>
                              </Tooltip>
                              <Tooltip title="Delete Clients" placement="top">
                                <LoadingButton
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    DeleteEvent(row?.id, 'residents', 'residents');
                                  }}
                                  disabled={
                                    JSON.parse(localStorage.getItem('Rel8User')).role ===
                                      'Family Member' ?? false
                                  }
                                >
                                  <Icon icon={trash2Outline} style={{ fontSize: '20px' }} />
                                </LoadingButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound &&
                  (NotFound ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <UserNotFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Page>
  );
}
