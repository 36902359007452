import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import archiveOutline from '@iconify/icons-eva/archive-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import axios from 'axios';
import flipFill from '@iconify/icons-eva/flip-fill';
import { restoreEvent } from '../../../pages/residents/DeletedResidents';
import { deleteUser } from '../../../pages/facilityMember/User';
import { deleteFolder } from './deleteFiles';
import { db } from '../../../App';
import { cloudFuncBaseUrl } from 'src/utils/config';

// ----------------------------------------------------------------------

export default function ResidentMoreMenu(props) {
  const { id, deletedAt } = props;
  // console.log(id);
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const user = JSON.parse(localStorage.getItem('Rel8User')).role;

  // -------------------------------------
  //           Archive Resident
  // -------------------------------------
  const ArchiveResident = () => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to archive this!',
        icon: 'warning',
        // allowOutsideClick: false,
        // showDenyButton: true,
        // denyButtonText: `Delete forever`,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, archive it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // ------------------------------------------------------Loading-------
          let timerInterval;
          Swal.fire({
            title: 'Processing...',
            html: 'I will close in <b></b> Moments.',
            // timer: 2000,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });
          // ------------------------------------------------------Loading-------
          try {
            await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
              data: {
                id,
                collectionName: 'residents',
                role: user
              }
            });
            //--------------------------

            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Residents has been archived.',
              showConfirmButton: false,
              timer: 1500
            });
            window.location.reload(false);
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message
      });
    }
  };

  // --------------------------------------------
  //               Delete Resident
  // --------------------------------------------
  const userDelete = () => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete this!',
        icon: 'warning',
        // allowOutsideClick: false,
        // showDenyButton: true,
        // denyButtonText: `Delete forever`,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // ----------------------------------------------Loading-------------
            let timerInterval;
            Swal.fire({
              title: 'Deleting...',
              html: 'I will close in few<b></b> Moments.',
              // timer: 2000,
              allowOutsideClick: false,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer');
              }
            });
            // ----------------------------------------------Loading---------------------
            await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
              data: {
                id,
                collectionName: 'residents',
                role: user,
                delete: true
              }
            });
            // ----------------------------------
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Resident has been deleted.',
              showConfirmButton: false,
              timer: 1500
            });
            window.location.reload(false);
          } catch (err) {
            return Swal.fire({
              icon: 'error',
              title: 'Error',
              text: err.message
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message
      });
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: '1' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => (!deletedAt ? ArchiveResident() : restoreEvent(props))}
        >
          <ListItemIcon>
            <Icon icon={!deletedAt ? archiveOutline : flipFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={!deletedAt ? 'Archive' : 'Restore'}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => userDelete()}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          component={RouterLink}
          to="/administration/clients/update-residents"
          state={{ props }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* <MenuItem
          sx={{ color: 'text.secondary' }}
          component={RouterLink}
          to="/administration/clients"
          // state={{ docId: id, email }}
        >
          <ListItemIcon>
            <Icon icon={moreHorizontalFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Show Residents" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
    </>
  );
}
