import { CloseOutlined } from '@ant-design/icons';
import { useState } from 'react';

export const LightBox = ({ children, src, alt, Wrapper = 'div', zIndex = 100 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [d, setd] = useState(false);

  const toggleIsOpen = () => {
    if (d === false) {
      setIsOpen(!isOpen);
      setd(true);
    }
  };

  return (
    <Wrapper onClick={toggleIsOpen}>
      {children}
      {isOpen ? (
        <div
          tabIndex={0}
          role="button"
          // onMouseDown={toggleIsOpen}
          // onClick={toggleIsOpen}
          style={{
            // position: 'fixed',
            position: 'fixed',
            top: '5%',
            left: '10%',
            height: '90vh',
            width: 'auto',
            // backgroundColor: 'rgba(0,0,0,0.7)',
            backgroundColor: '#9A76F5',
            zIndex: 1
          }}
        >
          <CloseOutlined
            style={{
              backgroundColor: '#626567',
              border: '1px solid #fff',
              borderRadius: '3px',
              color: '#fff',
              position: 'absolute',
              fontSize: '25px',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
              zIndex: 1
            }}
            onClick={() => {
              setIsOpen(!isOpen);
              setd(false);
            }}
          />
          <img
            src={src}
            alt={alt}
            style={{
              // objectFit: 'cover',
              objectFit: 'fill',
              height: '100%',
              width: 'auto',
              zIndex: 1
            }}
          />
        </div>
      ) : null}
    </Wrapper>
  );
};
