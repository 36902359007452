// get difference of two dates
function difference(date1, date2) {
  const date1utc = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const date2utc = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  const day = 1000 * 60 * 60 * 24;
  return (date2utc - date1utc) / day;
}

function DeletedTimeFamily(deleteDate, deleteCount) {
  const dDate = deleteDate.toDate();
  const date = new Date();

  //   console.log(dDate);
  //   console.log(deleteCount);
  if (deleteCount === 1) {
    dDate.setHours(dDate.getHours() + 48);
  }
  if (deleteCount === 2) {
    dDate.setDate(dDate.getDate() + 30);
  }
  if (deleteCount === 3) {
    dDate.setDate(dDate.getDate() + 90);
  }

  //   console.log(difference(date, dDate));
  return `${difference(date, dDate)} Days`;
}

export default DeletedTimeFamily;
