import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { listUserDelete } from './EventListToolbar';
import CrossIcon from 'src/components/crossIcon';
// import { listUserDelete } from '../../../pages/facilityMember/User';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

DraftListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function DraftListToolbar({
  selected,
  numSelected,
  filterName,
  onFilterName,
  value,
  setFilterByType,
  filterByType,
  setSearch,
  setSearchSet,
  Search,
  collect
}) {
  const AccountChange = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterByType(event.target.value);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search..."
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete" onClick={() => listUserDelete(selected, collect)}>
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        // <Tooltip title="Filter list">
        //   <IconButton>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel style={{}} id="demo-simple-select-label">
              Filter By Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-label"
              value={filterByType}
              label="Filter By Type"
              onChange={(e) => {
                AccountChange(e);
              }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="stories">Stories</MenuItem>
              <MenuItem value="noticeboard">Noticeboards</MenuItem>
              <MenuItem value="events">Events</MenuItem>
            </Select>
          </FormControl>
        
            {filterByType && (
              <button
                onClick={() => {
                  setFilterByType('');
                }}
                style={{ cursor: 'pointer', width:"fit", backgroundColor:"transparent", border:"none", }}
              >
                <CrossIcon/>
              </button>
            )}
          
        </div>
        //   </IconButton>
        // </Tooltip>
      )}
    </RootStyle>
  );
}
