import { Avatar, Card, Container, Grid, Stack, Box, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import calendarOutline from '@iconify/icons-eva/calendar-outline';
import copyFill from '@iconify/icons-eva/copy-fill';
import moment from 'moment';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// import { LightBox } from '../../user/LightBox';
import DeletedTimeFamily from '../../FamilyMember/DeletedTimeFamily';

// import DeletedTimeFamily from '../DeletedTimeFamily';

export default function MainCardResident({ row, deleted }) {
  const {
    id,
    firstName,
    likes,
    dislikes,
    aboutMe,
    lastName,
    dateOfBirth,
    facilityId,
    facilityName,
    roomId,
    roomName,
    createdAt,
    includedInAdminStories,
    profileImgUrl,
    deletedAt
  } = row;
  // ------for lightbox
  const [image, setImage] = useState('');
  // console.log(row);
  return (
    <Card sx={{ margin: 2, width: '100%', zIndex: 1 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} p={2} spacing={2}>
        {/* <LightBox src={row?.profileImgUrl} alt={row?.firstName}> */}
        <img
          src={row?.profileImgUrl}
          alt={row?.name?.slice(0, 1)?.toUpperCase()}
          style={{
            width: 220,
            height: 220,
            objectFit: 'contain',
            borderRadius: '10px',
            cursor: 'pointer',
            backgroundColor: '#F3F3F3'
          }}
          onClick={() => {
            setImage(row?.profileImgUrl);
          }}
        />
        {/* </LightBox> */}
        <div>
          <Box m={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" gutterBottom>
              {`${row?.firstName} ${row?.lastName}`}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Date of Birth
            </Typography>
            <TextTypo
              text={moment(new Date(row?.dateOfBirth.seconds * 1000)).format('DD/MM/YYYY')}
              icon={<Icon icon={calendarOutline} />}
            />
            {/* <TextTypo text={row?.aboutMe} icon={<Icon icon={copyFill} />} /> */}
          </Box>

          {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' && (
            <Button
              variant="contained"
              size="small"
              disabled={deleted === 'yes' && true}
              style={{ position: 'absolute', float: 'right', bottom: 20, right: 20 }}
              component={RouterLink}
              to="/administration/clients/update-residents"
              state={{
                props: {
                  id,
                  firstName,
                  likes: likes,
                  dislikes: dislikes,
                  aboutMe,
                  lastName,
                  dateOfBirth,
                  facilityId,
                  facilityName,
                  roomId,
                  roomName,
                  createdAt,
                  includedInAdminStories,
                  profileImgUrl,
                  deletedAt: deletedAt ?? null
                }
              }}
            >
              Edit
              <Icon icon={edit2Fill} style={{ fontSize: '20px', paddingLeft: 5 }} />
            </Button>
          )}
        </div>
      </Stack>
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Card>
  );
}

const TextTypo = ({ text, icon }) => (
  <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
    {icon}
    &nbsp;
    {text}
  </Typography>
);
