import axios from 'axios';
import React from 'react';
import { cloudFuncBaseUrl } from 'src/utils/config';

export default function SubscribeFCMToken(id, fcm) {
  axios({
    method: 'post',
    url: `${cloudFuncBaseUrl}.cloudfunctions.net/subscribeWebFcmToken`,
    data: {
      uid: id,
      fcm
    }
  })
    .then((res) => {
      // console.log(res.data);
      if (res.data.success) console.log('Successfully subscribed from topic');
      else console.log('Error subscribing from topic');
    })
    .catch((err) => {
      console.log('Error!', err.message);
    });
}
