import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Stack,
  Container,
  Typography,
  Avatar,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { MHidden } from '../components/@material-extend';

// layouts
import { db } from '../App';
import RoomForm from '../components/_dashboard/room/RoomForm';
import FacilityMembersForm from '../components/_dashboard/FaciltiyMembers/FacilityMembersForm';
import AuthLayout from '../layouts/AuthLayout';
// components
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDocm,
  where,
  query,
  onSnapshot,
  Timestamp
} from 'firebase/firestore';
import Page from '../components/Page';
import { RegisterForm } from '../components/authentication/register';
import AuthSocial from '../components/authentication/AuthSocial';
import ProfileCard from '../components/_dashboard/Profile/ProfileCard';
import ProfileIcon from '../assest/profile.svg';
import { localUser } from 'src/App';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Icon } from '@iconify/react';
import peopleOutline from '@iconify/icons-eva/people-outline';
import arrowOutline from '@iconify/icons-eva/arrow-ios-forward-outline';
import NoticeIcon from '../assest/noticeIcon.svg';
import WellnessIcon from '../assest/wallnessIcon.svg';
import Happyhdpi from '../assest/Wellness-Happyhdpi.png';
import Neutralhdpi from '../assest/Wellness-Neutralhdpi.png';
import Sadhdpi from '../assest/Wellness-Sadhdpi.png';
import ProfileMoreMenu from '../components/_dashboard/Profile/ProfileMoreMenu';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px',
  padding: theme.spacing(0, 0)
}));

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function getStoryComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingStoryComparator(a, b, orderBy)
    : (a, b) => -descendingStoryComparator(a, b, orderBy);
}

function applyStorySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user?.title?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingStoryComparator(a, b, orderBy) {
  if (!a[orderBy] || !b[orderBy]) {
    if (!a[orderBy]) {
      return 1;
    }
    if (!b[orderBy]) {
      return -1;
    }
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
// ----------------------------------------------------------------------

export default function Profile() {
  function Events() {
    const [USERS, setUSERLIST] = useState([]);
    const [NotFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);

    const [Notifications, setNotifications] = useState([]);
    const [NotificationsNotFound, setNotificationsNotFound] = useState(false);
    const [notificationsLoading, setNotificationsLoading] = useState(true);

    const [Family, setFamily] = useState([]);
    const [familyNotFound, setFamilyNotFound] = useState(false);
    const [familyLoading, setFamilyLoading] = useState(true);
    const timestamp = new Timestamp(localUser.createdAt.seconds, localUser.createdAt.nanoseconds);
    let userCollection;
    if (localUser?.role === 'Facility Member') {
      userCollection = query(
        collection(db, 'events'),
        where('facilitiesId', 'in', localUser?.facilityIds)
      );
    } else if (localUser?.role === 'admin') {
      userCollection = collection(db, 'events');
    } else if (localUser?.role === 'Family Member') {
      userCollection = query(
        collection(db, 'events'),
        where('residentsId', 'array-contains-any', localUser?.residentIds)
      );
    }

    // const userCollection = collection(db, 'stories');
    let stories = [];
    const getUsers = async () => {
      setLoading(true);
      // getDocs(userCollection).then((snapshot) => {
      // const snapshot = await getDocs(userCollection);
      onSnapshot(userCollection, (snapshot) => {
        stories = [];
        snapshot.docs.forEach(async (result) => {
          stories.push({
            id: result.data().id,
            avatarUrl: 'result.data().images[0]',
            title: result.data().title,
            comments: result.data()?.commentsCount || 0,
            likes: result.data().likes ?? [],
            postedById: result.data().postedById,
            startDate: result.data().startDate,
            startTime: result.data().startTime,
            endDate: result.data().endDate,
            endTime: result.data().endTime,
            createdAt: result.data().createdAt,
            medias: result.data().medias ?? [],
            description: result.data().description,
            location: result.data().location,
            endTimePeriod: result.data()?.endTimePeriod,
            startTimePeriod: result.data()?.startTimePeriod,
            residentsId: result.data().residentsId ?? [],
            facilityName: result.data().facilityName ?? null,
            facilitiesId: result.data().facilitiesId ?? null,
            roomsId: result.data()?.roomsId ?? [],
            authorName: result.data()?.authorName ?? '',
            views: result.data()?.views ?? []
          });
        });
        if (!stories.length) {
          setLoading(false);
          // setLoadingSpace(false);

          return setNotFound(true);
        } else {
          setNotFound(false);
        }
        setLoading(false);
        setUSERLIST(stories);
        // setLoadingSpace(false);
      });
    };

    // family collections
    let familyCollection = query(
      collection(db, 'residents'),
      where('id', 'in', localUser?.residentIds)
    );
    let family = [];
    const getFamily = async () => {
      setFamilyLoading(true);
      onSnapshot(familyCollection, (snapshot) => {
        family = [];
        snapshot.docs.forEach((result) => {
          family.push({
            id: result.data().id,
            firstName: result.data().firstName,
            lastName: result.data().lastName
          });
        });
        if (!family.length) {
          setFamilyLoading(false);
          return setFamilyNotFound(true);
        }
        setFamilyLoading(false);
        setFamily(family);
      });
    };

    useEffect(() => {
      getUsers();
    }, []);

    useEffect(() => {
      if (!loading && !NotFound) {
        if (localUser.role == 'Family Member' || localUser.role == 'Family Admin') {
          getFamily();
        }
      }
    }, [loading, NotFound]);

    const filteredUsers = applySortFilter(USERS, getComparator('desc', 'createdAt'), '');
    const filterFamily = applySortFilter(Family, getComparator('desc', 'createdAt'), '');
    let familyNames = filterFamily[0]?.firstName
      ? filterFamily[0]?.firstName
      : '' + filterFamily[1]?.firstName != undefined
      ? ' , ' + filterFamily[1]?.firstName
      : '';

    return (
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          borderRadius: '20px',
          boxShadow: 20,
          border: '0.5px solid #E3E4E6',
          paddingY: '20px',
          flexWrap: 'wrap',
          gap: '15px',
          paddingX: '30px'
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Featured notices">
            <TableHead sx={{ color: '#9A76F5', borderBottom: '2px solid #9A76F5' }}>
              <TableRow sx={{ color: '#9A76F5' }}>
                <TableCell sx={{ color: '#9A76F5' }}>Title</TableCell>

                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  {localUser?.role !== 'Family Member' ? 'Views' : 'Family'}
                </TableCell>

                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  Comments
                </TableCell>
                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  Likes
                </TableCell>
                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  Event Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ minHeight: '150px' }}>
              {!loading &&
                !NotFound &&
                filteredUsers.length != 0 &&
                filteredUsers.slice(0, 5).map((item, index) => {
                  const {
                    id,
                    title,
                    description,
                    comments,
                    avatarUrl,
                    startDate,
                    startTime,
                    endDate,
                    endTime,
                    createdAt,
                    likes,
                    location,
                    medias,
                    startTimePeriod,
                    endTimePeriod,
                    residentsId,
                    roomsId,
                    facilitiesId,
                    facilityName,
                    postedById,
                    authorName
                  } = item;

                  const a = Notifications.filter((a) => {
                    if (a.commentId == item.id) {
                      return a;
                    }
                  });
                  return (
                    <TableRow key={item.title + index} sx={{}}>
                      <TableCell
                        component={Link}
                        to="/content/events/event-detail"
                        state={{
                          id,
                          title,
                          location,
                          description,
                          medias,
                          startDate,
                          startTime,
                          endTime,
                          endDate,
                          createdAt,
                          likes,
                          commentsCount: comments,
                          startTimePeriod,
                          endTimePeriod,
                          residentsId,
                          roomsId,
                          facilitiesId,
                          facilityName,
                          object: item
                        }}
                        scope="row"
                        sx={{ fontWeight: 600, textDecoration: 'none' }}
                      >
                        {item.title != undefined
                          ? item.title.length < 35
                            ? item.title
                            : item.title.slice(0, 32) + '...'
                          : 'N/A'}
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip
                          title={
                            localUser.role == 'Family Member'
                              ? familyLoading
                                ? 'Loading'
                                : familyNotFound
                                ? 'N/A'
                                : familyNames
                              : null
                          }
                        >
                          {localUser?.role != 'Family Member' && localUser?.role != 'Family Admin'
                            ? item.views != undefined
                              ? item.views.length
                              : '0'
                            : familyLoading
                            ? 'Loading'
                            : familyNotFound
                            ? 'N/A'
                            : familyNames}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{item.comments}</TableCell>
                      <TableCell align="center">
                        {item.likes != undefined ? item.likes.length : '0'}
                      </TableCell>
                      <TableCell align="center">
                        {moment(item.startDate.toDate()).format('DD MMM YYYY')}
                        <br /> {moment(item.endDate.toDate()).format('DD MMM YYYY')}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && !NotFound && filteredUsers.length != 0 && (
          <a
            href="/content/events"
            style={{
              textAlign: 'right',
              fontWeight: 500,
              fontSize: '16px',
              color: '#9A76F5',
              textDecoration: 'none',
              display: 'flex',
              gap: '0px',
              justifyContent: 'end',
              width: '100%'
            }}
          >
            <p>view more</p>
            <Box sx={{ marginLeft: '0px' }}>{getIcon(arrowOutline)}</Box>
          </a>
        )}
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <CircularProgress style={{ color: '#9A76F5', width: '60px', height: '60px' }} />
          </Box>
        )}
        {!loading && NotFound && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
          </Box>
        )}
        {!loading && !NotFound && filteredUsers.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
          </Box>
        )}
      </Box>
    );
  }
  function Stories() {
    const [USERS, setUSERLIST] = useState([]);
    const [NotFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);

    const [Notifications, setNotifications] = useState([]);
    const [NotificationsNotFound, setNotificationsNotFound] = useState(false);
    const [notificationsLoading, setNotificationsLoading] = useState(true);

    const [Family, setFamily] = useState([]);
    const [familyNotFound, setFamilyNotFound] = useState(false);
    const [familyLoading, setFamilyLoading] = useState(true);
    const timestamp = new Timestamp(localUser.createdAt.seconds, localUser.createdAt.nanoseconds);

    let userCollection;
    if (localUser?.role === 'Facility Member') {
      userCollection = query(
        collection(db, 'stories'),
        where('facilitiesId', 'in', localUser?.facilityIds)
      );
    } else if (localUser?.role === 'admin') {
      userCollection = collection(db, 'stories');
    } else if (localUser?.role === 'Family Member' || localUser?.role === 'Family Admin') {
      userCollection = query(
        collection(db, 'stories'),
        where('residentsId', 'array-contains-any', localUser?.residentIds)
      );
    }

    // const userCollection = collection(db, 'stories');
    let stories = [];
    const getUsers = async () => {
      setLoading(true);
      // getDocs(userCollection).then((snapshot) => {
      // const snapshot = await getDocs(userCollection);
      onSnapshot(userCollection, (snapshot) => {
        stories = [];
        snapshot.docs?.forEach(async (result) => {
          stories.push({
            id: result.data()?.id,
            avatarUrl: result?.data()?.medias && result?.data()?.medias[0]?.downloadUrl,
            title: result.data()?.title,
            postedById: result.data()?.postedById,
            commentsCount: result.data()?.commentsCount ?? 0,
            likes: result.data()?.likes ?? [],
            // createdAt: result.data().createdAt.toDate().toDateString(),
            createdAt: result.data()?.createdAt,
            medias: result.data()?.medias,
            description: result.data()?.description,
            residentsId: result.data()?.residentsId,
            isPublished: result.data()?.isPublished,
            facilityName: result.data()?.facilityName,
            facilitiesId: result.data()?.facilitiesId,
            roomsId: result.data()?.roomsId,
            authorName: result.data()?.authorName ?? '',
            views: result.data()?.views ?? []
          });
        });
        if (!stories.length) {
          setLoading(false);
          // setLoadingSpace(false);
          return setNotFound(true);
        } else {
          setNotFound(false);
        }
        setLoading(false);
        setUSERLIST(stories);
        // setLoadingSpace(false);
      });
    };

    // family collections
    let familyCollection = query(
      collection(db, 'residents'),
      where('id', 'in', localUser?.residentIds)
    );
    let family = [];
    const getFamily = async () => {
      setFamilyLoading(true);
      onSnapshot(familyCollection, (snapshot) => {
        family = [];
        snapshot.docs.forEach((result) => {
          family.push({
            id: result.data().id,
            firstName: result.data().firstName,
            lastName: result.data().lastName
          });
        });
        if (!family.length) {
          setFamilyLoading(false);
          return setFamilyNotFound(true);
        }
        setFamilyLoading(false);
        setFamily(family);
      });
    };

    useEffect(() => {
      getUsers();
    }, []);

    useEffect(() => {
      if (!loading && !NotFound) {
        if (localUser.role !== 'Family Member') {
        } else {
          getFamily();
        }
      }
    }, [loading, NotFound]);

    const filteredUsers = applyStorySortFilter(USERS, getStoryComparator('desc', 'createdAt'), '');
    const filterFamily = applySortFilter(Family, getComparator('desc', 'createdAt'), '');
    let familyNames = filterFamily[0]?.firstName
      ? filterFamily[0]?.firstName
      : '' + filterFamily[1]?.firstName != undefined
      ? ' , ' + filterFamily[1]?.firstName
      : '';

    return (
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          borderRadius: '20px',
          boxShadow: 20,
          border: '0.5px solid #E3E4E6',
          paddingY: '20px',
          flexWrap: 'wrap',
          gap: '15px',
          paddingX: '30px'
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Featured notices">
            <TableHead sx={{ color: '#9A76F5', borderBottom: '2px solid #9A76F5' }}>
              <TableRow sx={{ color: '#9A76F5' }}>
                <TableCell sx={{ color: '#9A76F5' }}>Title</TableCell>

                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  {localUser?.role !== 'Family Member' ? 'Views' : 'Family'}
                </TableCell>

                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  Comments
                </TableCell>
                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  Likes
                </TableCell>
                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ minHeight: '150px' }}>
              {!loading &&
                !NotFound &&
                filteredUsers.length != 0 &&
                filteredUsers.slice(0, 5).map((item, index) => {
                  const {
                    id,
                    title,
                    description,
                    medias,
                    commentsCount,
                    avatarUrl,
                    createdAt,
                    likes,
                    residentsId,
                    isPublished,
                    // roomName,
                    roomsId,
                    facilitiesId,
                    facilityName,
                    postedById,
                    authorName
                  } = item;

                  return (
                    <TableRow key={item.title + index} sx={{}}>
                      <TableCell
                        component={Link}
                        to="/content/stories/story-detail"
                        state={{
                          id,
                          description,
                          medias,
                          likes,
                          commentsCount,
                          title,
                          createdAt,
                          residentsId,
                          isPublished,
                          roomsId,
                          facilitiesId,
                          facilityName,
                          object: item
                        }}
                        scope="row"
                        sx={{ fontWeight: 600, textDecoration: 'none' }}
                      >
                        {item.title
                          ? item.title.length < 35
                            ? item.title
                            : item.title.slice(0, 32) + '...'
                          : 'N/A'}
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip
                          title={
                            localUser.role == 'Family Member' &&
                            localUser.role == 'Family Admin' &&
                            familyLoading
                              ? 'Loading'
                              : familyNotFound
                              ? 'N/A'
                              : familyNames
                          }
                        ></Tooltip>

                        {localUser?.role != 'Family Member' && localUser?.role != 'Family Admin'
                          ? item.views != undefined
                            ? item.views.length
                            : '0'
                          : familyLoading
                          ? 'Loading'
                          : familyNotFound
                          ? 'N/A'
                          : familyNames}
                      </TableCell>
                      <TableCell align="center">{item.commentsCount}</TableCell>
                      <TableCell align="center">
                        {item.likes != undefined ? item.likes.length : '0'}
                      </TableCell>
                      <TableCell align="center">
                        {moment(item.createdAt ? item.createdAt.toDate() : new Date()).format(
                          'DD MMM YYYY'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && !NotFound && filteredUsers.length != 0 && (
          <a
            href="/content/stories"
            style={{
              textAlign: 'right',
              fontWeight: 500,
              fontSize: '16px',
              color: '#9A76F5',
              textDecoration: 'none',
              display: 'flex',
              gap: '0px',
              justifyContent: 'end',
              width: '100%'
            }}
          >
            <p>view more</p>
            <Box sx={{ marginLeft: '0px' }}>{getIcon(arrowOutline)}</Box>
          </a>
        )}
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <CircularProgress style={{ color: '#9A76F5', width: '60px', height: '60px' }} />
          </Box>
        )}
        {!loading && NotFound && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
          </Box>
        )}
        {!loading && !NotFound && filteredUsers.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
          </Box>
        )}
      </Box>
    );
  }
  function Notices({ SliceNumber, featuredNotice }) {
    const [USERS, setUSERLIST] = useState([]);
    const [NotFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);

    const [Family, setFamily] = useState([]);
    const [familyNotFound, setFamilyNotFound] = useState(false);
    const [familyLoading, setFamilyLoading] = useState(true);
    const timestamp = new Timestamp(localUser.createdAt.seconds, localUser.createdAt.nanoseconds);
    let userCollection;
    if (localUser?.role === 'admin') {
      userCollection = collection(db, 'noticeboards');
    } else if (localUser?.role === 'Family Member') {
      userCollection = query(
        collection(db, 'noticeboards'),
        where('residentsId', 'array-contains-any', localUser?.residentIds)
      );
    } else if (localUser?.role === 'Facility Member') {
      userCollection = query(
        collection(db, 'noticeboards'),
        where('facilitiesId', 'in', localUser?.facilityIds)
      );
    }

    let noticeboard = [];
    const getUsers = async () => {
      setLoading(true);
      onSnapshot(userCollection, async (snapshot) => {
        noticeboard = [];
        for (let result of snapshot.docs) {
          noticeboard.push({
            id: result.data()?.id,
            avatarUrl: 'result.data().images[0]',
            title: result.data()?.title,
            comments: result.data()?.commentsCount ?? 0,
            likes: result.data()?.likes ?? [],
            createdAt: result.data()?.createdAt,
            postedById: result.data()?.postedById,
            description: result.data()?.description,
            medias: result.data()?.medias,
            residentsId: result.data()?.residentsId,
            facilityName: result.data()?.facilityName,
            facilitiesId: result.data()?.facilitiesId,
            roomsId: result.data()?.roomsId,
            featuredNotice: result.data()?.featuredNotice,
            authorName: result.data()?.authorName ?? '',
            views: result.data()?.views ?? []
          });
        }
        if (!noticeboard.length) {
          setLoading(false);
          if (!featuredNotice) {
            // setLoadingSpace(false);
          }
          return setNotFound(true);
        } else {
          setNotFound(false);
        }
        setLoading(false);
        setUSERLIST(noticeboard);
        if (!featuredNotice) {
          // setLoadingSpace(false);
        }
      });
    };

    // family collections
    let familyCollection = query(
      collection(db, 'residents'),
      where('id', 'in', localUser?.residentIds)
    );
    let family = [];
    const getFamily = async () => {
      setFamilyLoading(true);
      onSnapshot(familyCollection, (snapshot) => {
        family = [];
        snapshot.docs.forEach((result) => {
          family.push({
            id: result.data().id,
            firstName: result.data().firstName,
            lastName: result.data().lastName
          });
        });
        if (!family.length) {
          setFamilyLoading(false);
          return setFamilyNotFound(true);
        }
        setFamilyLoading(false);
        setFamily(family);
      });
    };

    useEffect(() => {
      try {
        getUsers();
      } catch {
        setLoading(false);
        setNotFound(true);
      }
    }, []);

    useEffect(() => {
      if (!loading && !NotFound) {
        if (localUser.role != 'Family Member') {
        } else {
          getFamily();
        }
      }
    }, [loading, NotFound]);

    const filterFamily = applySortFilter(Family, getComparator('desc', 'createdAt'), '');
    const filteredUsers = applySortFilter(USERS, getComparator('desc', 'createdAt'), '');
    const filteredUsers2 = filteredUsers.filter((item) => {
      if (featuredNotice) {
        if (item.featuredNotice) {
          return item;
        }
      } else {
        return item;
      }
    });
    let familyNames = filterFamily[0]?.firstName
      ? filterFamily[0]?.firstName
      : '' + filterFamily[1]?.firstName != undefined
      ? ' , ' + filterFamily[1]?.firstName
      : '';
    return (
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          borderRadius: '20px',
          boxShadow: 20,
          border: '0.5px solid #E3E4E6',
          paddingY: '20px',
          flexWrap: 'wrap',
          gap: '15px',
          paddingX: '30px'
        }}
      >
        {/* <Box
          sx={{
            width: '100%',
            display: SliceNumber == 10 ? 'none' : 'flex',
            alignItems: 'center',
            gap: '5px',
            color: '#9A76F5'
          }}
        >
          <img
            src={NoticeIcon}
            style={{ width: 35, height: 35, minWidth: 30, minHeight: 30, marginRight: '5px' }}
          />
          <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '22px', fontWeight: 600 }}>
            Featured notices
          </p>
        </Box> */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Featured notices">
            <TableHead sx={{ color: '#9A76F5', borderBottom: '2px solid #9A76F5' }}>
              <TableRow sx={{ color: '#9A76F5' }}>
                <TableCell sx={{ color: '#9A76F5' }}>Title</TableCell>
                {localUser?.role !== 'Family Member' && (
                  <TableCell align="center" sx={{ color: '#9A76F5' }}>
                    Views
                  </TableCell>
                )}
                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  {localUser.role !== 'Family Member' ? 'Comments' : 'Family'}
                </TableCell>
                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  {localUser.role !== 'Family Member' ? 'Likes' : 'Groups'}
                </TableCell>
                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  Date
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ minHeight: '150px' }}>
              {!loading &&
                !NotFound &&
                filteredUsers2.length != 0 &&
                filteredUsers2.slice(0, SliceNumber).map((item, index) => {
                  const {
                    id,
                    title,
                    description,
                    comments,
                    createdAt,
                    likes,
                    medias,
                    residentsId,
                    roomsId,
                    facilitiesId,
                    facilityName,
                    postedById,
                    featuredNotice,
                    authorName,
                    views
                  } = item;

                  return (
                    <TableRow key={item.title + index} sx={{}}>
                      <TableCell
                        component={Link}
                        to="/content/noticeboard/notice-detail"
                        state={{
                          id,
                          description,
                          medias,
                          likes,
                          commentsCount: comments,
                          title,
                          createdAt,
                          object: item
                        }}
                        scope="row"
                        sx={{ fontWeight: 600, textDecoration: 'none' }}
                      >
                        {item.title != undefined
                          ? item.title.length < 35
                            ? item.title
                            : item.title.slice(0, 32) + '...'
                          : 'N/A'}
                      </TableCell>
                      {localUser?.role !== 'Family Member' && (
                        <TableCell align="center">{views ? views.length : '0'}</TableCell>
                      )}
                      <TableCell align="center">
                        <Tooltip
                          title={familyLoading ? 'Loading' : familyNotFound ? 'N/A' : familyNames}
                        >
                          {localUser?.role !== 'Family Member'
                            ? item.comments
                            : familyLoading
                            ? 'Loading'
                            : familyNotFound
                            ? 'N/A'
                            : familyNames != undefined
                            ? familyNames.length < 30
                              ? familyNames
                              : familyNames
                            : ''.slice(0, 28) + '...'}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {localUser?.role !== 'Family Member'
                          ? item.likes != undefined
                            ? item.likes.length
                            : ''
                          : item.facilityName}
                      </TableCell>
                      <TableCell align="center">
                        {moment(item.createdAt ? item.createdAt.toDate() : '').format(
                          'DD MMM YYYY'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && !NotFound && filteredUsers2.length != 0 && (
          <a
            href="/content/noticeboard"
            style={{
              textAlign: 'right',
              fontWeight: 500,
              fontSize: '16px',
              color: '#9A76F5',
              textDecoration: 'none',
              display: 'flex',
              gap: '0px',
              justifyContent: 'end',
              width: '100%'
            }}
          >
            <p>view more</p>
            <Box sx={{ marginLeft: '0px' }}>{getIcon(arrowOutline)}</Box>
          </a>
        )}
        {loading && !NotFound && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <CircularProgress style={{ color: '#9A76F5', width: '60px', height: '60px' }} />
          </Box>
        )}
        {!loading && (NotFound || filteredUsers2.length === 0) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
          </Box>
        )}
      </Box>
    );
  }

  function Wellness() {
    const [USERS, setUSERLIST] = useState([]);
    const [NotFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [getNotifyWellness, setgetNotifyWellness] = useState([]);
    const timestamp = new Timestamp(localUser.createdAt.seconds, localUser.createdAt.nanoseconds);

    let userCollection;
    if (localUser?.role === 'Facility Member') {
      userCollection = query(
        collection(db, 'wellness'),
        where('facilityId', 'in', localUser?.facilityIds)
      );
    } else if (localUser?.role === 'admin') {
      userCollection = collection(db, 'wellness');
    } else if (localUser?.role === 'Family Member') {
      userCollection = query(
        collection(db, 'wellness'),
        where('residentsId', 'array-contains-any', localUser?.residentIds)
      );
    }
    let noticeboard = [];
    const getUsers = async () => {
      setLoading(true);
      // getDocs(userCollection).then((res) => {
      onSnapshot(userCollection, (snapshot) => {
        noticeboard = [];
        snapshot.docs.forEach((result) => {
          noticeboard.push({
            id: result.data()?.id,
            // avatarUrl: 'result.data().images[0]',
            // title: result.data().title,
            commentsCount: result.data()?.commentsCount || 0,
            likes: result.data().likes ?? [],
            createdAt: result.data()?.createdAt,
            createdById: result.data()?.createdById,
            // images: result.data().images,
            description: result.data()?.description,
            medias: result.data()?.medias,
            residentId: result.data()?.residentsId && result.data()?.residentsId[0],
            residentName: result.data()?.residentName,
            facilityName: result.data()?.facilityName,
            facilityId: result.data()?.facilityId,
            roomId: result.data()?.roomId,
            mood: result.data()?.mood,
            authorName: result.data()?.authorName ?? ''
          });
        });
        if (!noticeboard.length) {
          setLoading(false);

          return setNotFound(true);
        }
        setLoading(false);
        setUSERLIST(noticeboard);
      });
    };

    useEffect(() => {
      getWellnessNotifu();

      getUsers();
    }, []);

    function getWellnessNotifu() {
      const ref = query(
        collection(db, 'notifications'),
        where('type', '==', 'wellness'),
        where('viewedBy', 'array-contains', localUser.id)
      );

      onSnapshot(ref, (snapshot) => {
        const notify = [];
        snapshot?.docs?.forEach(function (result) {
          notify.push(result.data());
        });
        setgetNotifyWellness(notify);
      });
    }

    function showDotOnResident(id) {
      let array = getNotifyWellness?.filter((e) => e.object?.id === id);
      return array;
    }

    const filteredUsers = applySortFilter(USERS, getComparator('desc', 'createdAt'), '');
    return (
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '20px',
          boxShadow: 20,
          border: '0.5px solid #E3E4E6',
          paddingY: '20px',
          flexWrap: 'wrap',
          gap: '15px',
          paddingX: '30px',
          width: '100%',
          height: '100%'
        }}
      >
        {/* <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            color: '#9A76F5'
          }}
        >
          <img
            src={WellnessIcon}
            style={{ width: 35, height: 35, minWidth: 30, minHeight: 30, marginRight: '5px' }}
          />
          <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '22px', fontWeight: 600 }}>
            Wellness
          </p>
        </Box> */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }} aria-label="Featured notices">
            <TableHead sx={{ color: '#9A76F5', borderBottom: '2px solid #9A76F5' }}>
              <TableRow sx={{ color: '#9A76F5' }}>
                <TableCell sx={{ color: '#9A76F5' }}>Name</TableCell>
                <TableCell align="center" sx={{ color: '#9A76F5' }}>
                  Mood
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ minHeight: '150px' }}>
              {!loading &&
                !NotFound &&
                filteredUsers.length != 0 &&
                filteredUsers.slice(0, 5).map((item, index) => {
                  const {
                    id,
                    description,
                    commentsCount,
                    createdAt,
                    likes,
                    medias,
                    residentId,
                    residentName,
                    roomId,
                    facilityId,
                    facilityName,
                    mood,
                    createdById,
                    authorName
                  } = item;
                  return (
                    <TableRow key={item.title + index} sx={{ width: '100%' }}>
                      <TableCell
                        component={Link}
                        scope="row"
                        sx={{
                          fontWeight: 600,
                          textDecoration: 'none',
                          display: 'flex',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                        to="/content/wellness/wellness-detail"
                        state={{
                          id,
                          description,
                          commentsCount,
                          createdAt,
                          likes,
                          medias,
                          residentId,
                          residentName,
                          roomId,
                          facilityId,
                          facilityName,
                          object: item,
                          notifyArray: showDotOnResident(id)
                        }}
                      >
                        <Avatar src="" sx={{ backgroundColor: '#9A76F5' }}>
                          {item.residentName?.split(' ')[0]?.slice(0, 1)?.toUpperCase()}
                          {item.residentName?.split(' ')[1]?.slice(0, 1)?.toUpperCase()}
                        </Avatar>
                        <p>
                          {item.residentName != undefined
                            ? item.residentName.length < 25
                              ? item.residentName
                              : item.residentName.slice(0, 22) + '...'
                            : 'N/A'}
                        </p>
                      </TableCell>
                      <TableCell align="right" sx={{}}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          {item.mood?.toLowerCase() === 'happy' && (
                            <img
                              src={Happyhdpi}
                              // style={{ marginX: 'auto' }}
                              alt="mood"
                              width="30"
                            />
                          )}
                          {item.mood?.toLowerCase() === 'neutral' && (
                            <img
                              src={Neutralhdpi}
                              // style={{ marginX: 'auto' }}
                              alt="mood"
                              width="30"
                              // height="30"
                            />
                          )}
                          {item.mood?.toLowerCase() === 'sad' && (
                            <img
                              src={Sadhdpi}
                              // style={{ marginX: 'auto' }}
                              alt="mood"
                              width="30"
                              // height="30"
                            />
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && !NotFound && filteredUsers.length != 0 && (
          <a
            href="/content/wellness"
            style={{
              textAlign: 'right',
              fontWeight: 500,
              fontSize: '16px',
              color: '#9A76F5',
              textDecoration: 'none',
              display: 'flex',
              gap: '0px',
              justifyContent: 'end',
              width: '100%'
            }}
          >
            <p>view more</p>
            <Box sx={{ marginLeft: '0px' }}>{getIcon(arrowOutline)}</Box>
          </a>
        )}
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <CircularProgress style={{ color: '#9A76F5', width: '60px', height: '60px' }} />
          </Box>
        )}
        {!loading && NotFound && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
          </Box>
        )}
        {!loading && !NotFound && filteredUsers.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              minHeight: '120px',
              alignItems: 'center'
            }}
          >
            <p style={{ textAlign: 'center', fontSize: '24px', color: '#9A76F5' }}>Not Found</p>
          </Box>
        )}
        {/* <MHidden width="mdDown">
          <p
            style={{
              fontSize: '26px',
              color: '#9A76F5',
              textAlign: 'center',
              marginTop: '35%',
              fontWeight: 600,
              maxWidth: '90%'
            }}
          >
            More information widgets coming soon
          </p>
        </MHidden> */}
      </Box>
    );
  }

  return (
    <RootStyle title="Profile | Rel8">
      <ContentStyle>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              color: '#9A76F5'
            }}
          >
            <img
              src={ProfileIcon}
              style={{ width: 35, height: 35, minWidth: 30, minHeight: 30, marginRight: '5px' }}
            />
            <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}>
              My Profile
            </p>
          </Box>
          {(localUser.role == 'admin' || localUser.role == 'Facility Member') &&  <ProfileMoreMenu props={localUser} dots={true} />}
        </Stack>

        <MHidden width="mdDown">
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px',
              display: 'flex',
              width: 'full',
              paddingY: '25px',
              paddingX: '40px',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', width: '50%' }}>
              {localUser.avatarUrl ? (
                <Avatar
                  src={localUser.avatarUrl}
                  alt="photoURL"
                  sx={{ width: '120px', height: '120px' }}
                />
              ) : (
                <Avatar
                  src=""
                  sx={{
                    backgroundColor: '#9A76F5',
                    fontWeight: 400,
                    width: '120px',
                    height: '120px',
                    fontSize: '60px'
                  }}
                >
                  {localUser.firstname?.slice(0, 1)?.toUpperCase()}
                  {localUser.lastname?.slice(0, 1)?.toUpperCase()}
                </Avatar>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <Box sx={{ fontSize: '24px' }}>
                  {localUser.firstname} {localUser.lastname}
                </Box>
                <Box sx={{ fontSize: '18px', color: '#9A76F5' }}>
                  {localUser.role.toUpperCase()}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '100px',
                width: '50%',
                height: '100%'
              }}
            >
              {localUser.role == 'admin' || localUser.role == 'Facility Member' && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                    gap: '15px'
                  }}
                >
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Organisation</Box>
                    <Box sx={{ fontSize: '16px' }}>{localUser.organization ? localUser.organization :  'N/A'}</Box>
                  </Box>
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Job Title</Box>
                    <Box sx={{ fontSize: '16px' }}>
                      {localUser.jobTitle.length > 0 ? localUser.jobTitle : 'N/A'}
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection:
                    localUser.role != 'admin' && localUser.role != 'Facility Member'
                      ? 'row'
                      : 'column',
                  gap:
                    localUser.role != 'admin' && localUser.role != 'Facility Member'
                      ? '100px'
                      : '15px',
                  height: '100%'
                }}
              >
                <Box sx={{ height: '50%' }}>
                  <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Email</Box>
                  <Box sx={{ fontSize: '16px' }}>{localUser.email ?? ''}</Box>
                </Box>
                <Box sx={{ height: '50%' }}>
                  <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Status</Box>
                  <Box sx={{ fontSize: '16px' }}>{localUser.status.toUpperCase() ?? ''}</Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </MHidden>

        <MHidden width="mdUp">
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px',
              display: 'flex',
              flexDirection: 'column',
              width: 'full',
              paddingY: '25px',
              paddingX: '15px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '30px'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', width: '100%' }}>
              {localUser.avatarUrl ? (
                <Avatar
                  src={localUser.avatarUrl}
                  alt="photoURL"
                  sx={{ width: '60px', height: '60px', fontSize: '40px' }}
                />
              ) : (
                <Avatar
                  src=""
                  sx={{
                    backgroundColor: '#9A76F5',
                    fontWeight: 400,
                    width: '60px',
                    height: '60px'
                  }}
                >
                  {localUser.firstname?.slice(0, 1)?.toUpperCase()}
                  {localUser.lastname?.slice(0, 1)?.toUpperCase()}
                </Avatar>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <Box sx={{ fontSize: '18px' }}>
                  {localUser.firstname} {localUser.lastname}
                </Box>
                <Box sx={{ fontSize: '14px', color: '#9A76F5' }}>
                  {localUser.role.toUpperCase()}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
                width: '100%',
                justifyContent: 'space-around'
              }}
            >
        {(localUser.role == 'admin' || localUser.role == 'Facility Member') &&       
           <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'space-between',
                  gap: '10px'
                }}
              >
                <Box sx={{ height: '50%' }}>
                  <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Organisation</Box>
                  <Box sx={{ fontSize: '16px' }}>{localUser.organization ? localUser.organization :  'N/A'}</Box>
                </Box>
                <Box sx={{ height: '50%' }}>
                  <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Job Title</Box>
                  <Box sx={{ fontSize: '16px' }}>
                    {localUser.jobTitle.length > 0 ? localUser.jobTitle : 'N/A'}
                  </Box>
                </Box>
              </Box>}
              <Box sx={{ display: 'flex', flexDirection:localUser.role != 'admin' && localUser.role != 'Facility Member'
                      ? 'row'
                      : 'column',
                  gap:
                    localUser.role != 'admin' && localUser.role != 'Facility Member'
                      ? '30px'
                      : '10px', height: '100%' }}>
                <Box sx={{ height: '50%' }}>
                  <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Email</Box>
                  <Box sx={{ fontSize: '16px' }}>{localUser.email.slice(0,20) ?? ''}</Box>
                </Box>
                <Box sx={{ height: '50%' }}>
                  <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Status</Box>
                  <Box sx={{ fontSize: '16px' }}>{localUser.status.toUpperCase() ?? ''}</Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </MHidden>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
            marginTop: '35px'
          }}
        >
          <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}>
            My Stories
          </p>
          <Stories />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
            marginTop: '35px'
          }}
        >
          <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}>
            My Events
          </p>
          <Events />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
            marginTop: '35px'
          }}
        >
          <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}>
            My Notice
          </p>
          <Notices SliceNumber={5} featuredNotice={false} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
            marginTop: '35px'
          }}
        >
          <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}>
            My Wellness
          </p>
          <Wellness />
        </Box>

        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ProfileCard />
          </div> */}
      </ContentStyle>
    </RootStyle>
  );
}
