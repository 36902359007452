import { filter, forEach, result } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// components
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  query,
  where,
  onSnapshot
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import moment from 'moment';
// ------------------------for popup box-------------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
// ------------------------------------------------------------------
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
//
import { db } from '../../App';
import StoriesMoreMenu from '../../components/_dashboard/stories/StoriesMoreMenu';
import NoticeMoreMenu from '../../components/_dashboard/user/NoticeMoreMenu';
import DeletedTimeResi from '../../components/_dashboard/resident/DeletedTimeResi';
import repeatOutline from '@iconify/icons-eva/repeat-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { DeleteEvent } from '../Wellbeing/DeletedWellness';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
// ------------------------End for popup box-------------------------

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  // { id: 'title', label: 'Title', alignRight: false },
  // { id: 'description', label: 'Description', alignRight: false },
  // { id: 'facilityName', label: 'Facility Name', alignRight: false },
  // { id: 'featuredNotice', label: 'Featured', alignRight: false },
  // { id: 'createdAt', label: 'Date', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'facilityName', label: 'Group', alignRight: false },
  { id: 'roomsId', label: 'Locations', alignRight: false },
  { id: 'residentsId', label: 'Clients', alignRight: false },
  { id: 'likes', label: 'Likes', alignRight: false },
  { id: 'comments', label: 'Comments', alignRight: false },
  { id: 'deletedAt', label: 'Delete', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//* **************Delete Users From Firebase**********************
export const restoreEvent = (data) => {
  console.log(data.id);
  console.log(data);
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to be restore this Noticeboard!',
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, restore it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      // ----------------------------------------------Loading-------------
      let timerInterval;
      Swal.fire({
        title: 'Processing...',
        html: 'I will close in few<b></b> Moments.',
        // timer: 2000,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer');
        }
      });
      // ----------------------------------------------Loading---------------------
      await deleteDoc(doc(db, 'recycleBin', 'noticeboards', 'noticeboards', data.id));
      // .then(async (res) => {
      await setDoc(doc(db, 'noticeboards', data.id), {
        ...data
      });
      //   window.location.reload(false);
      // })
      // .catch((err) => console.log(err.message));
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Noticeboard has been restored.',
        showConfirmButton: false,
        timer: 1500
      });
      window.location.reload(false);
    }
  });
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DeletedNotice() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);

  const [open, setOpen] = useState(false);
  const [modelRow, setModelRow] = useState('');

  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Noticeboard From Firebase**********************

  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'recycleBin', 'noticeboards', 'noticeboards'),
      where('facilitiesId', 'in', user?.facilityIds)
    );
  } else if (user?.role === 'admin') {
    userCollection = collection(db, 'recycleBin', 'noticeboards', 'noticeboards');
  }

  // const userCollection = collection(db, 'recycleBin', 'noticeboards', 'noticeboards');
  const noticeboard = [];
  const getUsers = async () => {
    // getDocs(userCollection).then((res) => {
    onSnapshot(userCollection, (res) => {
      res.forEach((result) => {
        noticeboard.push({
          id: result.data().id,
          //   avatarUrl: 'result.data().images[0]',
          title: result.data().title,
          commentsCount: result.data().commentsCount || 0,
          likes: result.data().likes ?? [],
          createdAt: result.data().createdAt,
          deletedAt: result.data()?.deletedAt,
          // images: result.data().images,
          description: result.data().description,
          medias: result.data().medias,
          residentsId: result.data().residentsId,
          facilityName: result.data().facilityName,
          facilitiesId: result.data().facilitiesId,
          roomsId: result.data().roomsId,
          postedById: result.data()?.postedById,
          featuredNotice: result.data()?.featuredNotice ?? false
        });
      });
      if (!noticeboard.length) {
        return setNotFound(true);
      }
      setUSERLIST(noticeboard);
    });
  };

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );

  // ------------------------for popup box-------------------------
  const handleClickOpen = (description, title) => {
    const data = {
      description,
      title
    };
    setModelRow(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ------------------------End for popup box-------------------------

  return (
    <>
      <Page title="Noticeboard | Rel8">
        <Container>
          <Typography variant="h4" gutterBottom>
            Deleted noticeboard
          </Typography>
          <Card>
            <UserListToolbar
            USERLIST={[]}
            localuser={{}}
              selected={selected}
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    deletedTab={true}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          title,
                          description,
                          commentsCount,
                          createdAt,
                          likes,
                          medias,
                          residentsId,
                          roomsId,
                          facilitiesId,
                          facilityName,
                          featuredNotice,
                          deletedAt
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        console.log(row);

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            description="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            sx={{ textDecoration: 'none' }}
                            // component={Link}
                            // to="/home/story-detail"
                            // state={{
                            //   description,
                            //   images: medias,
                            //   likes,
                            //   comments,
                            //   title,
                            //   date
                            // }}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            /> */}
                            </TableCell>
                            <TableCell component="th" scope="row" width="20%">
                              {/* <Stack direction="row" alignItems="center" spacing={2}> */}
                              {/* <Avatar alt={title} src={avatarUrl} /> */}
                              <Typography
                                variant="subtitle2"
                                component={RouterLink}
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                  color: '#212B36',
                                  fontWeight: 'bold'
                                }}
                                // to="/home/noticeboard/notice-detail"
                                to="/recycleBin/deleted-notice-details"
                                state={{
                                  id,
                                  description,
                                  medias,
                                  likes,
                                  commentsCount: 0,
                                  title,
                                  createdAt,
                                  object: row,
                                  deleted: 'yes'
                                }}
                              >
                                {title?.slice(0, 80)}
                              </Typography>
                              {/* </Stack> */}
                            </TableCell>

                            {/* <TableCell>
                              {description.length < 25 ? (
                                description
                              ) : (
                                <>
                                  {description.slice(0, 25)}
                                  <span
                                    style={{
                                      color: 'blue',
                                      cursor: 'pointer',
                                      marginLeft: '5px'
                                    }}
                                    onClick={() => handleClickOpen(description, title)}
                                    role="button"
                                    tabIndex="0"
                                    onKeyDown={handleClick}
                                  >
                                    Read more...
                                  </span>
                                </>
                              )}
                            </TableCell> */}
                            <TableCell align="left">{facilityName}</TableCell>
                            <TableCell align="center">{roomsId?.length ?? 0}</TableCell>
                            <TableCell align="center">{residentsId?.length ?? 0}</TableCell>
                            <TableCell align="center">{likes?.length ?? 0}</TableCell>
                            <TableCell align="center">{commentsCount ?? 0}</TableCell>
                            {/* <TableCell align="left">
                              <Label variant="ghost" color={featuredNotice ? 'success' : 'error'}>
                                {featuredNotice ? 'Yes' : 'No'}
                              </Label>
                            </TableCell> */}
                            {/* <TableCell align="left">{commentsCount}</TableCell>
                            <TableCell align="left">{likes.length}</TableCell> */}
                            {/* <TableCell align="left">{createdAt.toDate().toDateString()}</TableCell> */}
                            {/* <TableCell align="left">
                              {moment(createdAt?.toDate()).format('DD/MM/YYYY')}
                            </TableCell> */}
                            <TableCell align="left">
                              <Label variant="ghost" color="error">
                                {DeletedTimeResi(deletedAt)}-remaining
                              </Label>
                            </TableCell>

                            <TableCell align="right">
                              <Stack spacing={2} direction={{ xs: 'row', sm: 'row' }}>
                                <Tooltip title="Restore Noticeboard" placement="top">
                                  <LoadingButton
                                    size="small"
                                    variant="contained"
                                    // loading={isLoading}
                                    onClick={() => {
                                      restoreEvent(row);
                                    }}
                                    disabled={
                                      JSON.parse(localStorage.getItem('Rel8User')).role ===
                                        'Family Member' ?? false
                                    }
                                  >
                                    {/* Restore */}
                                    <Icon icon={repeatOutline} style={{ fontSize: '20px' }} />
                                  </LoadingButton>
                                </Tooltip>
                                <Tooltip title="Delete Noticeboard" placement="top">
                                  <LoadingButton
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      DeleteEvent(row?.id, 'noticeboards', 'noticeboards');
                                    }}
                                    disabled={
                                      JSON.parse(localStorage.getItem('Rel8User')).role ===
                                        'Family Member' ?? false
                                    }
                                  >
                                    <Icon icon={trash2Outline} style={{ fontSize: '20px' }} />
                                  </LoadingButton>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound &&
                    (NotFound ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <UserNotFound />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      {/* ***************************** Start PopUp ************************* */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', fontWeight: '700' }} id="draggable-dialog-title">
          {modelRow?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: '#34495E' }}>{modelRow?.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* ***************************** End PopUp ************************* */}
    </>
  );
}
