import { Link as RouterLink, useLocation } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent } from '@mui/material';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
import { Icon } from '@iconify/react';
import { CommentOutlined, LikeOutlined } from '@ant-design/icons/lib/icons';
import messageSquareOutline from '@iconify/icons-eva/message-square-outline';
import heartOutline from '@iconify/icons-eva/heart-outline';

import { useState, useEffect } from 'react';
import Spinner from 'react-spinner-material';
import ReactPlayer from 'react-player/lazy';
import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import moment from 'moment';

import { AppNewsUpdate, AppTrafficBySite } from '../app';
import SvgIconStyle from '../../SvgIconStyle';
import ShopProductCard from '../products/ProductCard';
import { fShortenNumber } from '../../../utils/formatNumber';
import CommentBox from '../app/CommentBox';
import { db } from '../../../App';
import DetailBforeImages from '../app/DetailBforeImages';

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});

const card = {
  '@media (min-width: 720px)': {
    width: '48px'
  }
};

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));
const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});
const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
export default function DeletedStoryDetail() {
  const [isLoading, setLoading] = useState(true);
  const [getComment, setComments] = useState([]);
  const [getLikes, setgetLikes] = useState([]);

  const location = useLocation();
  const { id, likes, commentsCount, medias, title, createdAt, object, deleted } = location.state;

  // console.log(createdAt);

  // const images = location.state.images ?? location.state.props.medias;
  const images = location.state.medias;
  console.log(images);

  useEffect(() => {
    setLoading(false);
    const script = document.createElement('link');
    script.href =
      'https://cdn.jsdelivr.net/gh/StudioKonKon/bootstrap-konkon@1.3.0-beta/dist/css/studio-konkon.min.css';
    script.rel = 'stylesheet';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // ------------------------------
  useEffect(async () => {
    const q = query(collection(db, 'comments'), where('storyId', '==', id));
    let comments = [];
    onSnapshot(q, async (querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        comments.push(doc.data());
      });
      setComments(comments);
      comments = [];
    });

    // -------------likes-----------

    await onSnapshot(doc(db, 'stories', id), (doc) => {
      // console.log(doc.data()?.likes);
      setgetLikes(doc.data()?.likes ?? []);
    });
  }, []);
  // ------------------------------
  // console.log(getComment);

  const CheckFileType = (type) => {
    switch (type) {
      case 'image':
        return (
          <Image
            images={images ?? []}
            likes={getLikes ?? []}
            comments={getComment.length}
            title={title ?? ''}
            date={moment(createdAt?.seconds * 1000).format('DD MMMM YYYY') ?? ''}
          />
        );
      case 'video':
        return (
          <Video
            images={images}
            likes={getLikes}
            comments={getComment.length}
            title={title}
            date={moment(createdAt?.seconds * 1000).format('DD MMMM YYYY')}
          />
        );
      case 'file':
        return (
          <Image
            images={images}
            likes={getLikes.length}
            comments={getComment.length}
            title={title}
            date={moment(createdAt?.seconds * 1000).format('DD MMMM YYYY')}
          />
        );
      default:
        return '';
      //   default:
      //     return '';
    }
  };
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );

  return (
    <>
      <DetailBforeImages
        createdAt={createdAt ?? new Date()}
        object={object}
        type="story"
        medias={medias}
        deleted={deleted}
      />
      {/* <Image images={images} likes={likes} comments={comments} title={title} date={date} /> */}
      {CheckFileType(images[0]?.type)}
      <AppNewsUpdate
        UserName={object?.authorName}
        likes={getLikes}
        comments={getComment.length}
        storyId={id}
        collections="stories"
        object={object}
        deleted={deleted}
      />
      <br />
      <CommentBox
        storyId={id ?? ''}
        commentsCount={getComment.length}
        collections="stories"
        object={object}
        deleted={deleted}
      />
      <br />
    </>
  );
}

function Image({ images, likes, comments, title, date }) {
  return (
    <>
      {/* <Card
        style={card}
        sx={{
          width: '50%',
          position: 'relative',
          left: '25%'
        }}
      >
        <CardMediaStyle
          sx={{
            ...((1 || 1) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            }),
            ...(1 && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              ...((1 || 1) && { display: 'none' })
            }}
          />
          <AvatarStyle
            alt="image"
            src={images[0].downloadUrl}
            sx={{
              ...((1 || 1) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40
              })
            }}
          />

          <CoverImgStyle alt="title" src={images[0].downloadUrl} />
        </CardMediaStyle>
        <CardContent
          sx={{
            pt: 4,
            ...((1 || 1) && {
              bottom: 0,
              width: '100%',
              position: 'absolute'
            })
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'text.disabled', display: 'block' }}
          >
            {date}
          </Typography>
          <TitleStyle
            to={images[0]}
            color="inherit"
            variant="subtitle2"
            underline="hover"
            sx={{
              ...(1 && { typography: 'h5', height: 60 }),
              ...((1 || 1) && {
                color: 'common.white'
              })
            }}
          >
            {title}
          </TitleStyle>
          <InfoStyle>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: 1 === 0 ? 0 : 1.5,
                ...((1 || 1) && {
                  color: 'grey.500'
                })
              }}
            >
              <Icon icon={heartOutline} />
              <Typography variant="caption" sx={{ ml: 1, mr: 1 }}>
                {fShortenNumber(likes.length)}
              </Typography>
              <Icon icon={messageSquareOutline} />
              <Typography variant="caption" sx={{ ml: 1, mr: 1 }}>
                {fShortenNumber(comments)}
              </Typography>
            </Box>
          </InfoStyle>
        </CardContent>
      </Card> */}
      <br />
      <br />
      <Grid container spacing={3} padding={4} sx={{ paddingRight: '9.5%' }}>
        {images.map((img, index) => (
          <Grid key={img.id} item xs={12} sm={6} md={2}>
            <ShopProductCard img={img} Index={index} images={images} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function Video({ images, likes, comments, title, date }) {
  console.log(images[0].thumbnail);
  return (
    <>
      {/* <Card
        style={card}
        sx={{
          width: '50%',
          position: 'relative',
          left: '25%'
        }}
        sm={{ width: '100%', position: 'relative' }}
      >
        <CardMediaStyle
          sx={{
            ...((1 || 1) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            }),
            ...(1 && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              ...((1 || 1) && { display: 'none' })
            }}
          />
          <AvatarStyle
            alt="image"
            src={images[0].thumbnail}
            sx={{
              ...((1 || 1) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40
              })
            }}
          />

          <CoverImgStyle alt="title" src={images[0].thumbnail} />
        </CardMediaStyle>
        <CardContent
          sx={{
            pt: 4,
            ...((1 || 1) && {
              bottom: 0,
              width: '100%',
              position: 'absolute'
            })
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'text.disabled', display: 'block' }}
          >
            {date}
          </Typography>
          <TitleStyle
            to={images[0]}
            color="inherit"
            variant="subtitle2"
            underline="hover"
            sx={{
              ...(1 && { typography: 'h5', height: 60 }),
              ...((1 || 1) && {
                color: 'common.white'
              })
            }}
          >
            {title}
          </TitleStyle>
          <InfoStyle>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: 1 === 0 ? 0 : 1.5,
                ...((1 || 1) && {
                  color: 'grey.500'
                })
              }}
            >
              <Icon icon={heartOutline} />
              <Typography variant="caption" sx={{ ml: 1, mr: 1 }}>
                {fShortenNumber(likes)}
              </Typography>
              <Icon icon={messageSquareOutline} />
              <Typography variant="caption" sx={{ ml: 1, mr: 1 }}>
                {fShortenNumber(comments)}
              </Typography>
            </Box>
          </InfoStyle>
        </CardContent>
      </Card> */}
      <br />
      <br />
      <Grid container spacing={3} padding={4} sx={{ paddingRight: '9.5%' }}>
        {images.map((img, index) => (
          <Grid key={img.id} item xs={12} sm={6} md={2}>
            <ShopProductCard img={img} Index={index} images={images} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
