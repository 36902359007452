import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import homeOutline from '@iconify/icons-eva/home-outline';
import stopCircleOutline from '@iconify/icons-eva/stop-circle-outline';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import menuFill from '@iconify/icons-eva/menu-fill';
import clipboardOutline from '@iconify/icons-eva/clipboard-outline';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import baselineMood from '@iconify/icons-ic/baseline-mood';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import settingOutline from '@iconify/icons-eva/settings-outline';
import linkOutline from '@iconify/icons-eva/cube-outline';
import bookOutline from '@iconify/icons-eva/menu-2-outline';
// import homeOutline from '@iconify/icons-eva/home-outline';
import DashboardLayout from '.';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Home',
    path: '/home/app',
    icon: getIcon(homeOutline)
  },
  {
    title: 'Drafts',
    path: '/home/drafts',
    icon: <Icon icon="fluent:drafts-24-filled" width={22} height={22} />
  },

  {
    title: 'Administration',
    allowFor: 'admin',
    path: '/administration',
    icon: getIcon(settingOutline),
    children: [
      {
        title: 'Staff Members',
        path: '/administration/staff-members',
        icon: getIcon(peopleFill)
      },
      {
        title: 'Family Members',
        path: '/administration/family-members',
        icon: <Icon icon="carbon:pedestrian-family" width={22} height={22} />
      },
      {
        title: 'Clients',
        path: '/administration/clients',
        icon: getIcon(homeOutline)
      }
    ]
  },
  {
    title: 'Administration',
    allowFor: 'Family Member',
    path: '/administration',
    icon: getIcon(settingOutline),
    children: [
      {
        title: 'My Family',
        path: '/administration/clients',
        icon: getIcon(homeOutline)
      },
      {
        title: 'Connections',
        path: '/administration/family-members',
        icon: <Icon icon="carbon:pedestrian-family" width={22} height={22} />
      }
    
    ]
  },
  {
    title: 'Administration',
    allowFor: 'Family Admin',
    path: '/administration',
    icon: getIcon(settingOutline),
    children: [
      {
        title: 'My Family',
        path: '/administration/clients',
        icon: getIcon(homeOutline)
      },
      {
        title: 'Family Members',
        path: '/administration/family-members',
        icon: <Icon icon="carbon:pedestrian-family" width={22} height={22} />
      }
    
    ]
  },


  {
    title: 'Organisation',
    path: '/organisation',
    icon: getIcon(linkOutline),
    children: [
      {
        title: 'All',
        path: '/organisation/organisation',
        icon: <Icon icon="carbon:category" width={22} />
      },
      {
        title: 'Groups',
        path: '/organisation/groups',
        icon: getIcon(menuFill)
      },
      {
        title: 'Locations',
        path: '/organisation/locations',
        icon: <Icon icon="ic:baseline-meeting-room" width={22} height={22} />
      },
    ]
  },
  {
    title: 'Content',
    path: '/content',
    icon: getIcon(bookOutline),
    children: [
      {
        title: 'Stories',
        path: '/content/stories',
        icon: getIcon(stopCircleOutline)
      },
      {
        title: 'Noticeboard',
        path: '/content/noticeboard',
        icon: getIcon(clipboardOutline)
      },
      {
        title: 'Events',
        path: '/content/events',
        icon: <Icon icon="bi:calendar-event" width={18} height={18} />
      },
      {
        title: 'Wellness',
        path: '/content/wellness',
        icon: getIcon(baselineMood)
      },
    ]
  },


  // {
  //   // title: 'Chat',
  //   title: 'Messages',
  //   path: '/home/messages',
  //   icon: <Icon icon="bi:chat-left-text-fill" width={18} height={18} />
  // },
  // {
  //   title: 'Organisations',
  //   path: '/home/organisation',
  //   icon: <Icon icon="carbon:category" width={22} />
  // },
  // {
  //   title: 'Groups',
  //   path: '/home/groups',
  //   icon: getIcon(menuFill)
  // },
  // {
  //   title: 'Staff Members',
  //   path: '/home/staff-members',
  //   icon: getIcon(peopleFill)
  // },
  // {
  //   title: 'Family Members',
  //   path: '/home/family-members',
  //   icon: <Icon icon="carbon:pedestrian-family" width={22} height={22} />
  // },
  // {
  //   title: 'Locations',
  //   path: '/home/locations',
  //   icon: <Icon icon="ic:baseline-meeting-room" width={22} height={22} />
  // },
  // {
  //   title: 'Clients',
  //   path: '/administration/clients',
  //   icon: getIcon(homeOutline)
  // },
  // {
  //   title: 'Noticeboard',
  //   path: '/home/noticeboard',
  //   icon: getIcon(clipboardOutline)
  // },
  // {
  //   title: 'stories',
  //   path: '/home/stories',
  //   icon: getIcon(stopCircleOutline)
  // },
  // {
  //   title: 'Events',
  //   path: '/home/events',
  //   icon: <Icon icon="bi:calendar-event" width={18} height={18} />
  // },
  // {
  //   title: 'Wellness',
  //   path: '/home/wellness',
  //   icon: getIcon(baselineMood)
  // },

  {
    title: 'Recycle Bin',
    path: '/recycleBin',
    icon: getIcon(trash2Outline),
    children: [
      {
        title: 'Stories',
        path: '/recycleBin/deleted-stories',
        icon: getIcon(stopCircleOutline)
      },
      {
        title: 'Events',
        path: '/recycleBin/deleted-events',
        icon: getIcon(stopCircleOutline)
      },
      {
        title: 'Noticeboard',    
        path: '/recycleBin/deleted-notice',
        icon: getIcon(stopCircleOutline)
      },
      {
        title: 'Clients',
        path: '/recycleBin/deleted-Residents',
        icon: getIcon(stopCircleOutline)
      },
      {
        title: 'Groups',
        path: '/recycleBin/deleted-groups',
        icon: getIcon(stopCircleOutline)
      },
      {
        title: 'Drafts',
        path: '/recycleBin/deleted-drafts',
        icon: getIcon(stopCircleOutline)
      },
      {
        title: 'Users',
        path: '/recycleBin/deleted-users',
        icon: getIcon(stopCircleOutline)
      },
      {
        title: 'Wellness',
        path: '/recycleBin/deleted-wellness',
        icon: getIcon(stopCircleOutline)
      }
    ]
  }

  // {
  //   title: 'blog',
  //   path: '/home/blog',
  //   icon: getIcon(fileTextFill)
  // }
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

const user = JSON.parse(localStorage.getItem('Rel8User'));
// const loggedUser = user?.id;
const sidebar = [];
sidebarConfig?.forEach((item) => {
  if (user?.role === 'Family Member' && user.subRole !=0) {
    if (
      item.title !== 'Recycle Bin' &&
      item.title !== 'Drafts' &&
      item.title !== 'Organisation'
      // item.title !== 'Family Members'
      // item.title !== 'Chat'
    ) {
      if(item.title == "Administration"){
           if(item.allowFor=='admin' || item.allowFor=='Family Admin')
           {
            return;
           }

      }
      sidebar.push(item);
    }
  }
 else if (user?.role === 'Family Member' && user.subRole ==0) {
    if (
      item.title !== 'Recycle Bin' &&
      item.title !== 'Drafts' &&
      item.title !== 'Organisation'
      // item.title !== 'Family Members'
      // item.title !== 'Chat'
    ) {
      if(item.title == "Administration"){
           if(item.allowFor=='admin' || item.allowFor=='Family Member')
           {
            return;
           }

      }
      sidebar.push(item);
    }
  }
  else {
    if(item.title == "Administration"){
      if(item.allowFor=='Family Member' || item.allowFor=='Family Admin')
      {
       return;
      }

 }
    sidebar.push(item);
  }
});
export default sidebar;
