import { Icon } from '@iconify/react';
import { Button, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { useCSVReader } from 'react-papaparse';
import closeOutline from '@iconify/icons-eva/close-outline';
import cloudUploadFill from '@iconify/icons-eva/cloud-upload-fill';
import { NotificationManager } from 'react-notifications';
import cloudDownloadFill from '@iconify/icons-eva/cloud-download-fill';
import ResidentCSV from '../../../assest/Resident_csv_sample.csv';

const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10
  },
  browseFile: {
    // width: '20%',
    cursor: 'pointer'
  },
  acceptedFile: {
    // border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '40%'
  },
  remove: {
    lineHeight: 2.5,
    cursor: 'pointer'
    // borderRadius: 0,
    // padding: '0 10px'
  },
  progressBarBackgroundColor: {
    backgroundColor: '#9a76f5'
  }
};

function CsvUploadFunc({
  setcsvData,
  setcsvResult,
  csvResult,
  BulkUploadFunc,
  csvData,
  setcsvFile
}) {
  const { CSVReader } = useCSVReader();

  return (
    <Grid container>
      <Grid xs={8} sx={{ marginBottom: '10px' }}>
        <CSVReader
          onUploadAccepted={(results) => {
            setcsvData(results?.data);
            console.log('---------------------------');
            // console.log(results?.data);
            setcsvResult(true);

            // BulkUploadFunc(results?.data);
            console.log('---------------------------');
          }}
        >
          {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
            <>
              {setcsvFile(acceptedFile)}
              <div style={styles.csvReader}>
                <Button
                  variant="outlined"
                  component="label"
                  // type="button"
                  {...getRootProps()}
                  style={styles.browseFile}
                  accept=".csv"
                >
                  Bulk Upload
                </Button>
                {csvResult && (
                  <>
                    {/* {console.log(acceptedFile)} */}

                    <div style={styles.acceptedFile}>{acceptedFile && acceptedFile.name}</div>
                    <div
                      style={styles.remove}
                      {...getRemoveFileProps()}
                      role="button"
                      tabIndex={0}
                      onMouseDown={() => {
                        setcsvResult(false);
                        setcsvData([]);
                      }}
                    >
                      <Icon icon={closeOutline} />
                    </div>
                  </>
                )}
              </div>
              <div style={{ width: '65%' }}>
                <ProgressBar style={styles.progressBarBackgroundColor} />
              </div>
            </>
          )}
        </CSVReader>
      </Grid>
      <Grid xs={4}>
        <Tooltip title="Download sample csv pattern">
          <a href={ResidentCSV} style={{ color: '#585757' }}>
            <Icon
              icon={cloudDownloadFill}
              style={{
                fontSize: 30,
                cursor: 'pointer'
              }}
            />
          </a>
        </Tooltip>
        <Tooltip title="Bulk upload residents from csv">
          <Icon
            icon={cloudUploadFill}
            style={{ fontSize: 30, marginLeft: 5, cursor: 'pointer', color: '#585757' }}
            onClick={() => {
              if (csvData.length === 0) {
                return NotificationManager.error('Please select csv file');
              }
              BulkUploadFunc();
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default CsvUploadFunc;
