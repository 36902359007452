import { filter, forEach, result, update } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  onSnapshot,
  query,
  where,
  updateDoc
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import moment from 'moment';
// ------------------------for popup box-------------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
// ------------------------------------------------------------------
import UserNotFound from 'src/components/UserNotFound';
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../user';
// //
import { db, localUser } from 'src/App';
import WellnessMoreMenu from '../../user/WellnessMoreMenu';

// import UserNotFound from '../../components/UserNotFound';
// import Page from '../../components/Page';
// import Label from '../../components/Label';
// import Scrollbar from '../../components/Scrollbar';
// import SearchNotFound from '../../components/SearchNotFound';
// import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
// //
// import { db } from '../../App';
// import WellnessMoreMenu from '../../components/_dashboard/user/WellnessMoreMenu';
import Happyhdpi from '../../../../assest/Wellness-Happyhdpi.png';
import Neutralhdpi from '../../../../assest/Wellness-Neutralhdpi.png';
import Sadhdpi from '../../../../assest/Wellness-Sadhdpi.png';
// import Neutralhdpi from '../../assest/Wellness-Neutralhdpi.png';
// import Sadhdpi from '../../assest/Wellness-Sadhdpi.png';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
// ------------------------End for popup box-------------------------

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'residentName', label: 'Resident', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'facilityName', label: 'Facility', alignRight: false },
  { id: 'mood', label: 'Mood', alignRight: false },
  { id: 'likes', label: 'Likes', alignRight: false },
  { id: 'commentsCount', label: 'Comments', alignRight: false },
  { id: 'createdAt', label: 'Published Date', alignRight: false }
  // { id: 'download', label: 'Download', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.residentName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Wellness({ id, deleted, root, notifyArray }) {
  // -------------------------------
  useEffect(() => {
    if (notifyArray?.length > 0) {
      for (let data of notifyArray) {
        let array = data?.viewedBy?.filter((e) => e !== localUser.id);
        updateDoc(doc(db, 'notifications', data.id), { viewedBy: array });
      }
    }
  }, []);
  // -------------------------------
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);

  const [open, setOpen] = useState(false);
  const [modelRow, setModelRow] = useState('');

  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Noticeboard From Firebase**********************

  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'wellness'),
      where('facilityId', 'in', user?.facilityIds),
      where('residentsId', 'array-contains', id)
    );
  } else if (user?.role === 'admin') {
    userCollection = query(collection(db, 'wellness'), where('residentsId', 'array-contains', id));
  } else if (user?.role === 'Family Member') {
    userCollection = query(
      collection(db, 'wellness'),
      where('residentsId', 'array-contains-any', user?.residentIds),
      where('residentsId', 'array-contains', id)
    );
  }

  let noticeboard = [];
  const getUsers = async () => {
    onSnapshot(userCollection, (snapshot) => {
      noticeboard = [];
      snapshot.docs.forEach((result) => {
        noticeboard.push({
          id: result.data()?.id,
          commentsCount: result.data()?.commentsCount || 0,
          likes: result.data().likes ?? [],
          createdAt: result.data()?.createdAt,
          createdById: result.data()?.createdById,
          description: result.data()?.description,
          medias: result.data()?.medias,
          residentId: result.data()?.residentsId && result.data()?.residentsId[0],
          residentName: result.data()?.residentName,
          facilityName: result.data()?.facilityName,
          facilityId: result.data()?.facilityId,
          roomId: result.data()?.roomId,
          mood: result.data()?.mood,
          authorName: result.data()?.authorName ?? ''
        });
      });
      if (!noticeboard.length) {
        return setNotFound(true);
      }
      setUSERLIST(noticeboard);
    });
  };

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );

  // ------------------------for popup box-------------------------
  const handleClickOpen = (description, title) => {
    const data = {
      description,
      title
    };
    setModelRow(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ------------------------End for popup box-------------------------

  return (
    <>
      <Page title="Noticeboard | Rel8">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Wellness
            </Typography>
            {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' && (
              <Button
                variant="contained"
                component={RouterLink}
                to="/content/wellness/add-wellness"
                startIcon={<Icon icon={plusFill} />}
                disabled={deleted}
              >
                Add Wellness Check
              </Button>
            )}
          </Stack>

          <Card>
            <UserListToolbar
             USERLIST={[]}
             localuser={{}}
              selected={selected}
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    deletedTab={true}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          description,
                          commentsCount,
                          createdAt,
                          likes,
                          medias,
                          residentId,
                          residentName,
                          roomId,
                          facilityId,
                          facilityName,
                          mood,
                          createdById,
                          authorName
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            description="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            sx={{ textDecoration: 'none' }}
                            // component={Link}
                            // to="/home/story-detail"
                            // state={{
                            //   description,
                            //   images: medias,
                            //   likes,
                            //   comments,
                            //   title,
                            //   date
                            // }}
                          >
                            <TableCell padding="checkbox">{/* helo */}</TableCell>

                            <TableCell align="left">
                              {/* <Typography
                                variant="subtitle2"
                                component={RouterLink}
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                  color: '#212B36',
                                  fontWeight: 'bold'
                                }}
                                to="/content/wellness/wellness-detail"
                                state={{
                                  id,
                                  description,
                                  commentsCount,
                                  createdAt,
                                  likes,
                                  medias,
                                  residentId,
                                  residentName,
                                  roomId,
                                  facilityId,
                                  facilityName,
                                  object: row
                                }}
                              > */}
                              {residentName}
                              {/* </Typography> */}
                            </TableCell>

                            <TableCell>
                              {description?.length < 25 ? (
                                description
                              ) : (
                                <>
                                  {description?.slice(0, 25)}
                                  <span
                                    style={{
                                      color: 'blue',
                                      cursor: 'pointer',
                                      marginLeft: '5px'
                                    }}
                                    onClick={() => handleClickOpen(description, '')}
                                    role="button"
                                    tabIndex="0"
                                    onKeyDown={handleClick}
                                  >
                                    Read more...
                                  </span>
                                </>
                              )}
                            </TableCell>
                            <TableCell align="left">{facilityName}</TableCell>
                            <TableCell align="left">
                              {mood.toLowerCase() === 'happy' && (
                                <img src={Happyhdpi} alt="mood" width="30" height="30" />
                              )}
                              {mood.toLowerCase() === 'neutral' && (
                                <img src={Neutralhdpi} alt="mood" width="30" height="30" />
                              )}
                              {mood.toLowerCase() === 'sad' && (
                                <img src={Sadhdpi} alt="mood" width="30" height="30" />
                              )}
                            </TableCell>
                            {/* <TableCell align="left">
                              {commentsCount < 0 ? 0 : commentsCount}
                            </TableCell> */}
                            <TableCell align="left">{likes?.length}</TableCell>
                            <TableCell align="left">{commentsCount}</TableCell>
                            <TableCell align="left">
                              {moment(createdAt.toDate()).format('DD MMM YYYY')}
                            </TableCell>

                            {!deleted && (
                              <TableCell align="right">
                                <WellnessMoreMenu
                                  id={id}
                                  description={description}
                                  commentsCount={commentsCount}
                                  createdAt={createdAt}
                                  likes={likes}
                                  medias={medias}
                                  residentId={residentId}
                                  residentName={residentName}
                                  roomId={roomId}
                                  facilityId={facilityId}
                                  facilityName={facilityName}
                                  mood={mood}
                                  object={row}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound &&
                    (NotFound ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <UserNotFound />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      {/* ***************************** Start PopUp ************************* */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', fontWeight: '700' }} id="draggable-dialog-title">
          {modelRow?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: '#34495E' }}>{modelRow?.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* ***************************** End PopUp ************************* */}
    </>
  );
}
