import { Avatar, Card, Container, Grid, Stack, Box, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import edit2Fill from '@iconify/icons-eva/edit-2-fill';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { LightBox } from '../../user/LightBox';
import DeletedTimeFamily from '../DeletedTimeFamily';
// import { LightBox } from '../user/LightBox';

export default function MainCard({ row }) {
  // ------for lightbox
  const [image, setImage] = useState('');

  const {
    id,
    firstname,
    lastname,
    email,
    residentIds,
    facilityId,
    date,
    avatarUrl,
    facilityName,
    role,
    status,
    restrictAt,
    deletedAt,
    subRole
  } = row;
  // console.log(row);
  return (
    <Card sx={{ margin: 2, width: '100%', zIndex: 1 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} p={2} spacing={2}>
        {row?.avatarUrl ? (
          // <LightBox src={row?.avatarUrl} alt={row?.firstname}>
          <img
            src={row?.avatarUrl}
            alt={row?.name?.slice(0, 1)?.toUpperCase()}
            style={{
              width: 220,
              height: 220,
              objectFit: 'contain',
              borderRadius: '10px',
              cursor: 'pointer',
              backgroundColor: '#9A76F5'
            }}
            onClick={() => {
              setImage(row?.avatarUrl);
            }}
          />
        ) : (
          // </LightBox>
          <img
            src={row?.avatarUrl}
            alt={row?.name?.slice(0, 1)?.toUpperCase()}
            style={{
              width: 220,
              height: 220,
              objectFit: 'contain',
              borderRadius: '10px',
              cursor: 'pointer',
              backgroundColor: '#9A76F5'
            }}
          />
        )}
        <div>
          <Box m={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" gutterBottom>
              {`${row?.firstname} ${row?.lastname}`}
            </Typography>
            {/* <TextTypo text={row.contact} icon={<Icon icon="ant-design:phone-filled" />} /> */}
            <TextTypo text={row?.email} icon={<Icon icon="ant-design:mail-filled" />} />
            <TextTypo
              text={row?.subRole === 0 ? 'Administrator' : 'Member'}
              icon={<Icon icon="ant-design:idcard-filled" />}
            />
          </Box>

          {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' && (
            <Button
              size="small"
              variant="contained"
              style={{ position: 'absolute', float: 'right', bottom: 20, right: 20 }}
              component={RouterLink}
              to="/administration/family-members/update-family-member"
              state={{
                id,
                firstname,
                lastname,
                email,
                residentIds: row.residentIds,
                facilityId,
                createdAt: date,
                avatarUrl,
                facilityName,
                role,
                status,
                restrictAt,
                deletedAt,
                subRole
              }}
            >
              Edit <Icon icon={edit2Fill} style={{ fontSize: '20px', paddingLeft: 5 }} />
            </Button>
          )}
        </div>
      </Stack>
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Card>
  );
}

const TextTypo = ({ text, icon }) => (
  <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
    {icon}
    &nbsp;
    {text}
  </Typography>
);
