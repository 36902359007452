import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  Checkbox
} from '@mui/material';
// components

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { collection, setDoc, where, query, onSnapshot } from 'firebase/firestore';
import Spinner from 'react-spinner-material';
import copyOutline from '@iconify/icons-eva/copy-outline';

import UserNotFound from '../components/UserNotFound';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
import { db, localUser } from '../App';

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'organisation', label: 'Organisation', alignRight: false },
  { id: 'location', label: 'Address', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'capacity', label: 'Resident Capacity', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//* **************Add Users From Firebase**********************
export const addFacility = (
  facilityRef,
  name,
  contact,
  location,
  capacity,
  avatarUrl,
  // jobTitle,
  organization,
  type,
  organizationId,
  chatDisable
) =>
  setDoc(facilityRef, {
    id: facilityRef.id,
    name,
    contact,
    location,
    capacity: `${capacity}`,
    avatarUrl,
    createdAt: new Date(),
    // jobTitle,
    organization,
    type,
    organizationId,
    chatDisable
  });

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, type, filterByOrg) {
  let newArr = array;
  if (type) {
    newArr = array.filter((d) => d.type?.toLowerCase() === type?.toLowerCase());
  }
  if (filterByOrg) {
    newArr = newArr.filter((d) => d.organisation?.toLowerCase() === filterByOrg?.toLowerCase());
  }
  const stabilizedThis = newArr.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(newArr, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Facilities() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);
  const [filterByType, setFilterByType] = useState('');
  const [filterByOrg, setfilterByOrg] = useState('');

  // const [copied, setcopied] = useState(false);

  // ------for lightbox
  const [image, setImage] = useState('');

  const navigate = useNavigate();
  // const getMembersSize = (id) => {
  //   let sizes;
  //   const members = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
  //   return getDocs(members).then((res) => res.size);
  // };

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Facilites From Firebase**********************
  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(collection(db, 'facilites'), where('id', 'in', user?.facilityIds));
  } else if (user?.role === 'admin') {
    userCollection = collection(db, 'facilites');
  } else if (user?.role === 'Family Member') {
    userCollection = query(collection(db, 'facilites'), where('id', 'in', user?.facilityIds));
  }

  console.log("localuser : ",localUser);
  // const userCollection = collection(db, 'facilites');
  let users = [];
  const getUsers = async () => {
    // getDocs(userCollection).then((res) => {
    onSnapshot(userCollection, (snapshot) => {
      users = [];
      snapshot.docs.forEach((result) => {
        // getMembersSize(result.data().id).then();
        if (result.data().email !== 'admin@admin.com') {
          if (result.data().deletedAt === undefined || result.data().deletedAt === null) {
            users.push({
              id: result.data().id,
              avatarUrl: result.data().avatarUrl,
              name: result.data().name,
              contact: result.data().contact,
              capacity: result.data().capacity,
              location: result.data().location,
              createdAt: result.data().createdAt,
              organisation: result.data()?.organization ?? '',
              type: result.data()?.type ?? '',
              organizationId: result.data()?.organizationId ?? '',
              chatDisable: result.data().chatDisable ? result.data().chatDisable : false
            });
          }
        }
      });

      if (!users.length) {
        return setNotFound(true);
      }
      setUSERLIST(users);
    });
  };

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName,
    filterByType,
    filterByOrg
  );

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );

  // ---------------------Copy text to clip board------------------------------------
  // const copyToClipboard = (str) => {
  //   if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
  //     setcopied(true);
  //     return navigator.clipboard.writeText(str);
  //     // return Promise.reject('The Clipboard API is not available.');
  //   }
  // };
  // ---------------------------------------------------------
  return (
    <Page title="Facilities | Rel8">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Groups
          </Typography>
          {JSON.parse(localStorage.getItem('Rel8User')).role === 'admin' && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/organisation/groups/register"
              startIcon={<Icon icon={plusFill} />}
            >
              Add Group
            </Button>
          )}
        </Stack>

        <Card>
          <UserListToolbar
            USERLIST={USERLIST}
            localuser={localUser}
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setFilterByType={setFilterByType}
            filterByOrg={filterByOrg}
            setfilterByOrg={setfilterByOrg}
            filterByType={filterByType}
            collect="facilites"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  deletedTab={localUser.role === 'Family Member' && true}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        name,
                        location,
                        contact,
                        avatarUrl,
                        capacity,
                        createdAt,
                        organisation,
                        type,
                        organizationId,
                        chatDisable
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      console.log("row ::: ",row)
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          location="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {localUser.role !== 'Family Member' && (
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="2px">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {avatarUrl ? (
                                // <LightBox src={avatarUrl} alt={name}>
                                <Avatar
                                  style={{ cursor: 'pointer', backgroundColor: '#9A76F5' }}
                                  alt={name}
                                  src={avatarUrl}
                                  onClick={() => {
                                    setImage(avatarUrl);
                                  }}
                                />
                              ) : (
                                // </LightBox>
                                <Avatar
                                  alt={name}
                                  src={avatarUrl}
                                  style={{ backgroundColor: '#9A76F5' }}
                                />
                              )}

                              <Typography
                                variant="subtitle2"
                                component={RouterLink}
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                  color: '#212B36'
                                }}
                                to="/organisation/groups/facility-more-info"
                                state={{ row }}
                              >
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left" max-width="20%">
                            {organisation ?? ''}
                          </TableCell>
                          <TableCell align="left" width="20%">
                            {location}
                          </TableCell>
                          <TableCell align="left" width="150px">
                            {type}
                          </TableCell>
                          <TableCell align="left">{capacity}</TableCell>
                          <TableCell align="left">{contact}</TableCell>

                          <TableCell align="right">
                            {selected.length === 0 && user.role == 'admin' && (
                              <UserMoreMenu
                                id={id}
                                name={name}
                                contact={contact}
                                location={location}
                                capacity={capacity}
                                avatarUrl={avatarUrl}
                                createdAt={createdAt}
                                jobTitle=""
                                organization={organisation ?? ''}
                                type={type ?? ''}
                                organizationId={organizationId ?? ''}
                                chatDisable={chatDisable}
                                object={row}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound &&
                  (NotFound ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <UserNotFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Page>
  );
}
