import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import Swal from 'sweetalert2';
import { deleteDoc, doc, setDoc } from 'firebase/firestore';
import axios from 'axios';

import { db } from '../../../App';
import { deleteUser } from '../../../pages/facilityMember/User';
import { cloudFuncBaseUrl } from 'src/utils/config';
import { deleteObject, getStorage, ref } from 'firebase/storage';
// ----------------------------------------------------------------------

export default function OrganizationMoreMenu(props) {
  const { id } = props?.row;
  const refe = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // console.log(organization);
  const user = JSON.parse(localStorage.getItem('Rel8User')).role;

  const deleteOrganization = () => {
    try {
      // ----------------------------------confirm box----------------------
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete this Organisation!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // //  --------------loading---------------------
          let timerInterval;
          Swal.fire({
            title: 'Deleting...',
            html: 'I will close in few<b></b> Moments.',
            timerProgressBar: true,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              if (b) {
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              }
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });
          // -----------------------------------------------------------------------------------------------
          try {
            // await axios({
            //   method: 'post',
            //   url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
            //   data: {
            //     id,
            //     collectionName: 'facilites',
            //     role: user
            //   }
            // });

            await deleteDoc(doc(db, 'organizations', id));
            const storage = getStorage();
            const storageRef = ref(storage, `organization/${id}/organizationImgUrl`);
            await deleteObject(storageRef);

            //--------------------------

            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Organisation has been deleted.',
              showConfirmButton: false,
              timer: 1500
            });
            window.location.reload(false);
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message
      });
    }
  };

  return (
    <>
      <IconButton ref={refe} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={refe.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: '1' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={deleteOrganization}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          component={RouterLink}
          to="/organisation/organisation/add_organisation"
          state={{
            ...props?.row
          }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
