import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Grid,
  Card,
  Box,
  Button,
  styled,
  ClickAwayListener,
  Popper,
  Divider,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import AsyncSelect from 'react-select/async';
import { collection, doc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import axios from 'axios';
import Spinner from 'react-spinner-material';
import { options } from 'numeral';
import { addFacility } from '../../../pages/Facilities';
import { db } from '../../../App';
import { subRoleFacility } from '../../subRole/SubRole';
import SortByTitle from '../event/SortByTitle';
import { cloudFuncBaseUrl } from 'src/utils/config';

// ----------------------------------------------------------------------
const user = JSON.parse(localStorage.getItem('Rel8User'));

const addFacilityMemberAuth = async (
  email,
  facilityIds,
  facilityNames,
  firstname,
  lastname,
  jobTitle,
  organization,
  image,
  subRole,
  residentIds,
  organizationId
) => {
  try {
    const data = {
      email,
      facilityIds,
      facilityNames,
      firstname,
      lastname,
      jobTitle,
      organization,
      adminName: `${user?.firstname} ${user?.lastname}`,
      subRole,
      residentIds,
      organizationId
    };
    const resp = await axios.post(
      // `${cloudFuncBaseUrl}.cloudfunctions.net/addFacilityMembers`,
      `${cloudFuncBaseUrl}.cloudfunctions.net/addFacilityMembers`,
      data
    );
    // console.log('image', image);
    // console.log(resp);

    // console.log(resp);

    // if (resp?.data?.data?.error === 'auth/email-already-exists') {
    if (resp?.data?.error) {
      return Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Auth/email-already-exists'
      });
    }

    // -----------------
    if (image) {
      const storage = getStorage();
      const storageRef = ref(storage, `users/${resp?.data?.data?.id}/profile`);
      const metadata = {
        contentType: 'image/jpeg'
      };
      const uploadTask = await uploadBytesResumable(storageRef, image, metadata);
      const avatarUrl = await getDownloadURL(uploadTask.ref);
      // -----
      await setDoc(
        doc(db, 'users', resp?.data?.data?.id),
        {
          avatarUrl
        },
        { merge: true }
      );
      // --------------
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Facility Member Added Successfully',
        showConfirmButton: false,
        timer: 2000
      });
    }
    // --------------
  } catch (err) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: err.message
    });
  }
};

// for image
const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`
}));

export default function FacilityMembersForm() {
  const location = useLocation();
  const { data } = location.state ?? { id: '', name: '' };

  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [colourOptions, setColourOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [openOrg, setOpenOrg] = useState(false);
  const [optionOrg, setOptionOrg] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [isLoadingOrg, setisLoadingOrg] = useState(false);

  const loading = open && options.length === 0;

  async function getOrganisation() {


    let userCollection;
    if(user?.role === 'admin'){
      userCollection = collection(db, 'organizations');
    }
    else{
      userCollection = query(collection(db, 'organizations'), where('id', 'in', typeof(user?.organizationId)==='string'? [user?.organizationId]:user?.organizationId));
    }

    setisLoadingOrg(true);
    const orgRef = collection(db, 'organizations');

    onSnapshot(userCollection, (snapshot) => {
      const users = [];
      snapshot.docs.forEach((result) =>
        users.push({ title: result.data().organizationName, value: result.data().id })
      );
      setOptionOrg(users);
      setisLoadingOrg(false);
    });
    
  }

  const filterColors = (inputValue) =>
    colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    // _this.setState({ inputValue: inputValue });
    return inputValue;
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (user?.role === 'Facility Member') {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', user?.facilityIds)
        );
      } else {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('organizationId', '==', selectedOrgId)
        );
      }
      // const facilitesCollection = collection(db, 'facilites');
      if (!selectedOrgId) return;
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Enter valid email address').required('Email is required'),
    // facility: Yup.string().required('Facilty is required'),
    facility: Yup.array()
      .min(1, selectedOrgId ? 'at least 1 group is required' : 'Select the organisation first')
      .required('required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    jobTitle: Yup.string().required('Job title is required'),
    organisation: Yup.object()
      .nullable()
      .test('title', 'Organisation is required', function (value) {
        return value !== null;
      }),
    subRole: Yup.number().required('Role is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      organisation: null,
      // facility: data?.id ?? '',
      facility: [],
      // facilityName: data?.name ?? '',
      photo: '',
      subRole: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      // if (!image) {
      //   setIsLoading(false);
      //   return NotificationManager.error("You didn't select profile");
      // }
      setIsLoading(true);
      const email = getFieldProps('email').value;
      const facility = getFieldProps('facility').value;
      // const facilityName = getFieldProps('facilityName').value;
      const firstname = getFieldProps('firstName').value;
      const lastname = getFieldProps('lastName').value;
      const jobTitle = getFieldProps('jobTitle').value;
      const organization = getFieldProps('organisation').value?.title;
      const organizationId = getFieldProps('organisation').value?.value;
      const subRole = getFieldProps('subRole').value;

      const facilityIds = [];
      const facilityNames = [];
      facility.forEach((element) => {
        facilityIds.push(element.value);
        facilityNames.push(element.title);
      });

      const residentRef = query(
        collection(db, 'residents'),
        where('facilityId', 'in', facilityIds)
      );
      const refData = await getDocs(residentRef);

      const residentIds = [];
      refData?.forEach((res) => {
        residentIds.push(res.data()?.id);
      });
      addFacilityMemberAuth(
        email,
        facilityIds,
        facilityNames,
        firstname,
        lastname,
        jobTitle,
        organization,
        image,
        subRole,
        residentIds,
        organizationId
      )
        .then((res) => {
          if (res?.data?.error) {
            setIsLoading(false);
            return NotificationManager.error(res.data.error);
          }
          // Swal.fire({
          //   position: 'center',
          //   icon: 'success',
          //   title: 'Facility Member Added Successfully',
          //   showConfirmButton: false,
          //   timer: 2000
          // });
          navigate('/home/staff-members', { replace: true });
        })
        .catch((err) => {
          setIsLoading(false);
          return NotificationManager.error(err?.message);
        });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              label="First Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Last Name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>
          {/* -------------------------------------------------------------------------------------------- */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Job Title"
              {...getFieldProps('jobTitle')}
              error={Boolean(touched.jobTitle && errors.jobTitle)}
              helperText={touched.jobTitle && errors.jobTitle}
            />
            {/* <TextField
              fullWidth
              label="Organisation"
              {...getFieldProps('organisation')}
              error={Boolean(touched.organisation && errors.organisation)}
              helperText={touched.organisation && errors.organisation}
            /> */}
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              open={openOrg}
              onOpen={() => {
                getOrganisation();
                setOpenOrg(true);
              }}
              onClose={() => {
                setOpenOrg(false);
              }}
              onChange={(e, value, reason) => {
                setFieldValue('organisation', value);
                setFieldValue('facility', []);
                if (reason === 'clear') return setSelectedOrgId('');
                setSelectedOrgId(value?.value);
              }}
              // defaultValue={{ title: getFieldProps('organisation').value ?? '', value: '0' }}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option?.title}
              options={optionOrg}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organisation"
                  {...getFieldProps('organisation')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingOrg ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={Boolean(touched.organisation && errors.organisation)}
                  helperText={touched.organisation && errors.organisation}
                />
              )}
            />
          </Stack>
          {/* -------------------------------------------------------------------------------------------- */}

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {/* <Autocomplete
              fullWidth
              // multiple
              // disableCloseOnSelect
              // limitTags={3}
              id="asynchronous-demo"
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(e, value) => {
                console.log(value);
                setFieldValue('facilityName', value?.title || '');
                setFieldValue('facility', value?.value || '');
              }}
              defaultValue={{ title: data?.name ?? '', value: data?.id ?? '' }}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option.title}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Select Facility"
                  {...getFieldProps('facility')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={Boolean(touched.facility && errors.facility)}
                  helperText={touched.facility && errors.facility}
                />
              )}
            /> */}

            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <div style={{ width: '100%' }}>
                <Autocomplete
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  limitTags={3}
                  id="asynchronous-demo"
                  open={open}
                  onOpen={() => {
                    selectedOrgId && setOpen(true);
                  }}
                  // onClose={() => {
                  //   setOpen(false);
                  // }}
                  onChange={(event, newValue, reason) => {
                    if (reason === 'selectOption') {
                      setFieldValue('facility', newValue ?? '');
                    } else if (reason === 'removeOption') {
                      setFieldValue('facility', newValue ?? '');
                      // setRoomIds(newValue);
                    } else if (reason === 'clear') {
                      // setCheckAll(false);
                      setFieldValue('facility', []);
                    }
                    // setFieldValue('residentsId', []);
                    // setresidentIDs([]);
                    // setCheckAllResi(false);
                  }}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  getOptionLabel={(option) => option.title}
                  options={options}
                  loading={loading}
                  value={getFieldProps('facility')?.value}
                  // PopperComponent={(param) => (
                  //   <PopperStyledComponent {...param}>
                  //     <Box {...param} />
                  //     <Divider />
                  //     <Box
                  //       sx={{
                  //         backgroundColor: 'white',
                  //         height: '45px',
                  //         textOverflow: 'ellipsis',
                  //         overflow: 'hidden',
                  //         whiteSpace: 'nowrap'
                  //       }}
                  //     >
                  //       <Checkbox
                  //         // checked={checkAll}
                  //         // onChange={checkAllChange}
                  //         id="check-all"
                  //         sx={{ marginRight: '8px' }}
                  //         onMouseDown={(e) => e.preventDefault()}
                  //       />
                  //       Select All
                  //     </Box>
                  //   </PopperStyledComponent>
                  // )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Group"
                      // {...getFieldProps('facility')}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                      error={Boolean(touched.facility && errors.facility)}
                      helperText={touched.facility && errors.facility}
                    />
                  )}
                />
              </div>
            </ClickAwayListener>

            {/* -------------- for Sub Role -------------- */}
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              onChange={(e, value) => {
                // console.log(value?.value);
                setFieldValue('subRole', value?.value);
              }}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option.title}
              // options={['Administrator', 'Coordinator', 'Team member']}
              options={subRoleFacility}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Select Role"
                  {...getFieldProps('subRole')}
                  InputProps={{
                    ...params.InputProps
                  }}
                  error={Boolean(touched.subRole && errors.subRole)}
                  helperText={touched.subRole && errors.subRole}
                />
              )}
            />
          </Stack>
          {/* ----------------For photo ------------------- */}
          <Grid container spacing={2}>
            {image && (
              <Grid item xs={4} sm={4} md={2}>
                <Icon
                  icon="ci:close-big"
                  onClick={() => {
                    setImage('');
                  }}
                  style={{ cursor: 'pointer' }}
                />
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle alt="Image" src={URL.createObjectURL(image)} />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label">
              Upload Profile
              <input
                fullWidth
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>
          {/* ----------------For photo ------------------- */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Add Staff
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
