import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
import Page from '../../components/Page';
import OrganizationForm from 'src/components/_dashboard/organization/OrganizationForm';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 0)
}));

// ----------------------------------------------------------------------

export default function AddOrganisation() {
  const location = useLocation();
  const { state } = location;
  return (
    <RootStyle title="Add Group | Rel8">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {state?.id ? 'Update Organisation' : 'Add Organisation'}
            </Typography>
          </Box>

          <OrganizationForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
