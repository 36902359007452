import { doc, getDoc } from 'firebase/firestore';
import React from 'react';
import { db } from 'src/App';

function FamilyOrFacilityFunc(users) {
  if (users?.length > 2) {
    return 'Group Message';
  }

  if (users[0]?.id !== JSON.parse(localStorage.getItem('Rel8User'))?.id) {
    return users[0]?.role;
  }

  if (users[1]?.id !== JSON.parse(localStorage.getItem('Rel8User'))?.id) {
    return users[1]?.role;
  }
}

export default FamilyOrFacilityFunc;
