import { useState } from 'react';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Members from './Members';
import Page from '../../Page';
import InfoCard from './InfoCard';
import Rooms from './Rooms';
import Residents from './Resident';

export default function MoreInfo() {
  const location = useLocation();
  const { row, deleted } = location.state;
  // console.log(row);

  // console.log(row?.id);
  return (
    <Grid container spacing={12}>
      <Grid
        item
        sx={{
          display: 'flex',
          position: 'relative'
        }}
      >
        <InfoCard row={row} deleted={deleted} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Members id={row.id} name={row.name} deleted={deleted} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Rooms id={row.id} name={row.name} deleted={deleted} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Residents id={row.id} name={row.name} deleted={deleted} />
      </Grid>
    </Grid>
  );
}
