import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
import duplicate from '@iconify/icons-eva/file-add-outline';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  where,
  query,
  deleteField,
  updateDoc
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import Swal from 'sweetalert2';
import axios from 'axios';
import { db } from '../../../App';
import { deleteUser } from '../../../pages/facilityMember/User';
import { cloudFuncBaseUrl } from 'src/utils/config';
// ----------------------------------------------------------------------

export default function WellnessMoreMenu(props) {
  const refs = useRef(null);
  const {
    id,
    description,
    commentsCount,
    createdAt,
    likes,
    medias,
    residentId,
    residentName,
    roomId,
    facilityId,
    facilityName,
    mood,
    object,
    dots,
    date
  } = props;
  // console.log(id);
  const user = JSON.parse(localStorage.getItem('Rel8User')).role;

  const [isOpen, setIsOpen] = useState(false);
  const userDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: `Delete forever`,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: 'gray',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        // ----------------------------------------------Loading-------------
        let timerInterval;
        Swal.fire({
          title: 'Processing...',
          html: 'I will close in few<b></b> Moments.',
          // timer: 2000,
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector('b');
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer');
          }
        });
        // ----------------------------------------------Loading---------------------
        try {
          await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
            data: {
              id,
              collectionName: 'wellness',
              role: user
            }
          });
          // -----------------------------------

          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Wellness has been deleted.',
            showConfirmButton: false,
            timer: 1500
          });

          window.location.replace('/content/wellness');
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message
          });
        }
        // Delete event permanently
      } else if (result.isDenied) {
        try {
          // ----------------------------------------------Loading-------------
          let timerInterval;
          Swal.fire({
            title: 'Deleting...',
            html: 'I will close in few<b></b> Moments.',
            // timer: 2000,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });
          // ----------------------------------------------Loading---------------------
          await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/DeleteAllDataPermanently`,
            data: {
              id,
              collection: 'wellness'
            }
          });
          // ----------------------------------
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Wellness has been deleted.',
            showConfirmButton: false,
            timer: 1500
          });
          // window.location.reload(false);
        } catch (err) {
          return Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.message
          });
        }
      }
    });
  };
  return (
    <>
      <IconButton ref={refs} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={refs.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: '1', marginTop: dots ? '30px' : '0px' }}
      >
        {user !== 'Family Member' && (
          <>
            <MenuItem
              sx={{ color: 'text.secondary' }}
              component={RouterLink}
              to="/content/wellness/update-wellness"
              state={{ props }}
            >
              <ListItemIcon>
                <Icon icon={editFill} width={24} height={24} />
              </ListItemIcon>
              
              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={userDelete}>
              <ListItemIcon>
                <Icon icon={trash2Outline} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
            <MenuItem
              sx={{ color: 'text.secondary' }}
              component={RouterLink}
              to="/content/wellness/add-wellness"
              state={{ props }}
            >
              <ListItemIcon>
                <Icon icon={duplicate} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </>
        )}
        {!dots && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            component={RouterLink}
            // to="/home/story-detail"
            to="/content/wellness/wellness-detail"
            state={{
              id,
              description,
              commentsCount,
              createdAt,
              likes,
              medias,
              residentId,
              residentName,
              roomId,
              facilityId,
              facilityName,
              object
            }}
          >
            <ListItemIcon>
              <Icon icon={moreHorizontalFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="More" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {/* <MenuItem
          sx={{ color: 'text.secondary' }}
          component={RouterLink}
          to="/administration/clients"
          // state={{ docId: id, email }}
        >
          <ListItemIcon>
            <Icon icon={moreHorizontalFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Show Residents" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
    </>
  );
}
