import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Residents from '../../facilities/Resident';
// import MainCard from './MoreInfo/MainCard';
import Facilitys from './Facilitys';
import MainCardFacility from './MainCardFacility';
// import Residents from '../facilities/Resident';

export default function FacilityMoreInfoDetail() {
  const location = useLocation();
  const { row, deleted } = location.state;
  // console.log(row?.residentIds ?? []);
  return (
    <Grid container spacing={12}>
      <Grid
        item
        sx={{
          display: 'flex',
          position: 'relative'
        }}
      >
        <MainCardFacility row={row} />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={12}>
        <Members id={row.id} name={row.name} />
      </Grid> */}
      {/* <Grid item xs={12} sm={12} md={12}>
        <Rooms id={row.id} name={row.name} />
      </Grid> */}
      <Grid item xs={12} sm={12} md={12}>
        <Facilitys id={row?.facilityId} name={row.firstname} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Residents id={row?.residentIds ?? []} name={row.name} deleted={deleted} />
      </Grid>
    </Grid>
  );
}
