// routes
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import React, { useState, useEffect } from 'react';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import axios from 'axios';
import { onBackgroundMessage } from 'firebase/messaging/sw';

import Router, { RouterFacility, RouterFamily, RouterFamilyAdmin } from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { firebaseConfig } from './utils/config';
import Login from './pages/Login';
import 'react-notifications/lib/notifications.css';
import Forgot from './pages/Forgot';
import SubscribeFCMToken from './components/fcmToken/SubscribeFCMToken';

// ----------------------------------------------------------------------
const app = initializeApp(firebaseConfig);
export const localUser = JSON.parse(localStorage.getItem('Rel8User'));
export const LoggedUserId = localUser?.id;
export const db = getFirestore(app);
console.log("localuser : ",localUser)
export default function App() {
  const isForgot = localStorage.getItem('forgot');
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      localStorage.removeItem('Rel8User');
    }
  });

  // testing : BBjrxW7lQdbMZd4B8R3f_ETjnSi6yHdtRoaV8MeTT4EERkAX9R1U7yN2KIvOi5BhGfgnV5qOT391cANjNbN5zYw
  //   production : BPZ5Na3ecOEKEMcCkYrMSDCUg8yX5cYGUHlBavo3G4I3hHf0WPFOQnrRRvMkEQsB89QxGZSsHQuhJk7PHIMlKyg
  // testing : BBjrxW7lQdbMZd4B8R3f_ETjnSi6yHdtRoaV8MeTT4EERkAX9R1U7yN2KIvOi5BhGfgnV5qOT391cANjNbN5zYw
  //   production : BPZ5Na3ecOEKEMcCkYrMSDCUg8yX5cYGUHlBavo3G4I3hHf0WPFOQnrRRvMkEQsB89QxGZSsHQuhJk7PHIMlKyg
  const messaging = getMessaging();
  // for recieve notification
  function requestPermission() {
    const oldFcmTokens = localUser?.fcm === undefined ? [] : localUser?.fcm;
    
    getToken(messaging, {
      vapidKey:
      'BPZ5Na3ecOEKEMcCkYrMSDCUg8yX5cYGUHlBavo3G4I3hHf0WPFOQnrRRvMkEQsB89QxGZSsHQuhJk7PHIMlKyg'
    })
    .then(async (currentToken) => {
      if (currentToken) {
          console.log('Hurraaa!!! we got the token.....');
          // console.log(currentToken);
          localStorage.setItem('Rel8_fcm_token', currentToken);
          // ---------------------------------------------------
          //      For subscribe fcm token for notification
          // ---------------------------------------------------
          
          const facilityIDsArray = [];
          if (localUser?.role === 'admin') {
            const facilityRef = collection(db, 'facilites');
            const facilityData = await getDocs(facilityRef);
            facilityData?.forEach((e) => {
              facilityIDsArray.push(e.data()?.id);
            });
          }
          
          // for comment
          SubscribeFCMToken(`${localUser?.id}_comment`, currentToken);
          
          let ids = [];
          if (localUser?.role === 'admin') {
            ids = facilityIDsArray;
            localStorage.setItem('Rel8_facility_ids', JSON.stringify(facilityIDsArray));
          } else {
            ids =
            localUser.role === 'Facility Member'
            ? localUser?.facilityIds
            : localUser?.residentIds;
          }
          
          for (const fid of ids) {
            // for wellness
            SubscribeFCMToken(`${fid}_wellness`, currentToken);
            // for event
            SubscribeFCMToken(`${fid}_event`, currentToken);
            // for story
            SubscribeFCMToken(`${fid}_story`, currentToken);
            // for noticeboard
            SubscribeFCMToken(`${fid}_notice`, currentToken);
          }
          // For anyThing
          SubscribeFCMToken(localUser?.id, currentToken);
          // For messages
          SubscribeFCMToken(`${localUser?.id}_message`, currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err.message);
      });
    }
    
    
    
    function UpdateLocalUser(id) {
      getDoc(doc(db, 'users', id))
      .then((snapshot) => {
        localStorage.setItem('Rel8User', JSON.stringify(snapshot.data()));
      })
      .catch((err) => {
        console.log(err.message);
      });
    }
    
    useEffect(() => {
      if (localUser) {
        if (!localStorage.getItem('Rel8_fcm_token')) {
          requestPermission();
        }
      }
      // Update local user
      LoggedUserId && UpdateLocalUser(LoggedUserId);
    }, []);
    
    // --------------------------------
    
    console.log("localuser : ",localUser)
    return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      {(() => {
        if (isForgot) return <Forgot />;
        if (localUser?.role === 'admin') return <Router />;
        if (localUser?.role === 'Facility Member') return <RouterFacility />;
        if (localUser?.role === 'Family Member'&& localUser?.subRole != 0) return <RouterFamily />;
        if (localUser?.role === 'Family Member'&& localUser?.subRole == 0) return <RouterFamilyAdmin />;
        return <Login path="/login" />;
      })()}
    </ThemeConfig>
  );
}
