import { Box, Card, Divider, Grid, Stack, Typography, Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import messageSquareOutline from '@iconify/icons-eva/message-square-outline';
import heartOutline from '@iconify/icons-eva/heart-outline';
import Linkify from 'react-linkify';
import BackButtonImage from '../../../assest/backButton.svg';
import { localUser } from '../../../App';
import ShopProductCard from '../products/ProductCard';
import { LightBox } from '../user/LightBox';
import Happyhdpi from '../../../assest/Wellness-Happyhdpi.png';
import Neutralhdpi from '../../../assest/Wellness-Neutralhdpi.png';
import Sadhdpi from '../../../assest/Wellness-Sadhdpi.png';
import { styled } from '@mui/material/styles';
import CalendarIcon from "../../../assest/calenderIcon.svg"
import ClockIcon from "../../../assest/clock.svg"
import LocationIcon from "../../../assest/location.svg"
function EventPreview({
  file,
  title,
  description,
  setPreview,
  mood,
  AddSubmitFunc,
  draftLoading,
  isLoading,
  head1,
  head2,
  type,
  location,
  sdate,
  edate,
  setSelectOption,
  setDraft,
  handleSubmit,
  residentName,
  date,
  section,
  buttonModal
}) {
  const ProductFileStyle = styled('div')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    cursor: 'pointer',
    backgroundColor: '#E4E4E4'
  });

  const images =
    file.length != 0
      ? file.filter((image) => {
          console.log('image :: ', image);
          const file =
            (image.type != 'image' && image.type != 'video') ||
            (image.name && image.name.endsWith('.avi')) ||
            (image.name && image.name.endsWith('.mpeg')) ||
            (image.name && image.name.endsWith('.heic'));
          const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
          if (type != 'file') {
            return image;
          }
        })
      : [];
  const docs =
    file.length != 0
      ? file.filter((image) => {
          console.log('image :: ', image);
          const file =
            (image.type != 'image' && image.type != 'video') ||
            (image.name && image.name.endsWith('.avi')) ||
            (image.name && image.name.endsWith('.mpeg')) ||
            (image.name && image.name.endsWith('.heic'));
          const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
          if (type == 'file') {
            return image;
          }
        })
      : [];

  console.log('docs : ', docs);

  const RenderText = ({ description }) => (
    <div>
      {description?.split('\n\n\n').map((block, blockIndex) => (
        <div key={blockIndex}>
          {block.split('\n\n').map((paragraph, paraIndex) => (
            <div key={paraIndex} style={{ marginBottom: '1em' }}>
              {paragraph.split('\n').map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          ))}
          <br /> {/* Add extra line break to distinguish between blocks */}
        </div>
      ))}
    </div>
  );

  return (
    <Card
      sx={{
        paddingY: '25px',
        paddingX: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}
    >
      <div
        onClick={() => {
          setSelectOption('schedule');
        }}
      >
        <img src={BackButtonImage} style={{ width: '35px', cursor: 'pointer' }} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: '',
          justifyContent: '',
          marginRight: '',
          gap: '6px'
        }}
      >
        <Box sx={{ fontSize: '22px', fontWeight: 500, color: '#9A76F5' }}>
          {title?.value ?? title}
        </Box>
        <Box sx={{ fontSize: '16px', fontWeight: 400, marginLeft: '', color: '#9A76F5' }}>
          {moment(date).format('DD MMMM YYYY')}
        </Box>
      </div>

      {type == 'wellness' && (
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          {residentName && (
            <Avatar src="" sx={{ backgroundColor: '#9A76F5' }}>
              {residentName?.split(' ')[0]?.slice(0, 1)?.toUpperCase()}
              {residentName?.split(' ')[1]?.slice(0, 1)?.toUpperCase()}
            </Avatar>
          )}
          <Box sx={{ fontSize: '16px', fontWeight: 600 }}>{residentName}</Box>
        </Box>
      )}

      {type == 'wellness' && <Box sx={{ fontSize: '18px', fontWeight: 600 }}>Today I am</Box>}

      {type == 'wellness' && mood ? (
        <div style={{ marginLeft: '0' }}>
          {mood === 1 && <img src={Happyhdpi} alt="M" width="65" height="65" />}
          {mood === 2 && <img src={Neutralhdpi} alt="M" width="65" height="65" />}
          {mood === 3 && <img src={Sadhdpi} alt="M" width="65" height="65" />}
        </div>
      ) : (
        <div></div>
      )}

      <Box sx={{ fontSize: '16px', fontWeight: 500, paddingTop: '20px' }}>
        <RenderText description={description.value} />
      </Box>

      {type === 'event' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', marginBottom:'15px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
            <Box sx={{ color: '#9A76F5', fontWeight: 500, fontSize: '24px' }}>Event date/time</Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
              <Box sx={{ color: '#9A76F5', fontWeight: 500, fontSize: '16px', display: 'flex' , gap:'15px' , alignItems:'center'}}>
              <Box sx={{width:"30px"}}><img src={CalendarIcon} style={{height:"20px"}}/> </Box>
                <Box sx={{ color: 'black', fontWeight: 500, fontSize: '16px' }}>
                  {moment(sdate).format('DD/MM/YYYY')} - {moment(edate).format('DD/MM/YYYY')}
                </Box>
              </Box>
              <Box sx={{ color: '#9A76F5', fontWeight: 500, fontSize: '16px', display: 'flex',  gap:'15px' , alignItems:'center' }}>
              <Box sx={{width:"30px"}}><img src={ClockIcon} style={{height:"22px"}}/> </Box>
              <Box sx={{ color: 'black', fontWeight: 500, fontSize: '16px' }}>
                  {moment(sdate).format(' hh:mm a')} - {moment(edate).format(' hh:mm a')}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
            <Box sx={{ color: '#9A76F5', fontWeight: 500, fontSize: '24px' }}>Location</Box>
            <Box sx={{  display: 'flex',  gap:'15px' , alignItems:'center' }}>
            <Box sx={{ width: '30px' }}>
                  <img src={LocationIcon} style={{ height: '22px' }} />{' '}
                </Box>
              <Box sx={{ color: 'black', fontWeight: 500, fontSize: '16px' }}>{location}</Box>
            </Box>
          </Box>
        </Box>
      )}

      <div className="grid">
        {images.map((image, index) => {
          const fileType =
            (image.type != 'image' && image.type != 'video') ||
            (image.name && image.name.endsWith('.avi')) ||
            (image.name && image.name.endsWith('.mpeg')) ||
            (image.name && image.name.endsWith('.heic'));
          const type = fileType ? 'file' : image.type === 'image' ? 'image' : 'video';

          return (
            <>
              {type == 'image' && (
                <div
                  key={image.id}
                  className={index == 0 && images.length > 7 ? 'firstIndex' : 'allIndex'}
                >
                  <ShopProductCard img={image} Index={index} images={images} />
                </div>
              )}

              {/* {type == 'file' && (
                <Grid
                  key={image.id}
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    position: 'relative'
                  }}
                >
                  <Card>
                    <Box sx={{ pt: '100%', position: 'relative' }}>
                      
                      <ProductFileStyle>
                        <button
                          sx={{ pt: '100%', position: 'relative' }}
                          style={{
                            backgroundColor: '',
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexDirection: 'column',
                            padding: '5px',
                            width: '100%',
                            height: '100%',
                            maxHeight: '100%'
                          }}
                          onClick={() => {
                           
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '95%',
                              maxHeight: '95%',
                              overflow: 'hidden',
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <img
                              src={image.thumbnail}
                              style={{ objectFit: 'contain', maxHeight: '100%' }}
                            />
                          </div>
                          <div
                            style={{
                              fontSize: '12px',
                              fontSize: '12px',
                              textAlign: 'center',
                              textDecoration: 'none',
                              width: '100%'
                            }}
                          >
                            <p style={{ textAlign: 'center' }}>
                              {image.name
                                ? image.name.length > 20
                                  ? image.name.slice(0, 18) + '...'
                                  : image.name
                                : image.name}
                            </p>
                          </div>
                        </button>
                      </ProductFileStyle>
                    </Box>
                  </Card>
                </Grid>
              )} */}
              {type == 'video' && (
                <div
                  key={image.id}
                  className={index == 0 && images.length > 7 ? 'firstIndex' : 'allIndex'}
                >
                  <ShopProductCard img={image} Index={index} images={file} />
                </div>
              )}
            </>
          );
        })}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
        {docs &&
          docs.map((item) => {
            return (
              <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                <div
                  style={{
                    width: '50px',
                    minWidth: '50px',
                    maxWidth: '50px',
                    height: '60px',
                    minHeight: '60px',
                    maxHeight: '60px',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <img src={item.thumbnail} style={{ objectFit: 'contain', maxHeight: '100%' }} />
                </div>
                <p>
                  {item.name.length > 35 ? item.name.slice(0, 30) + '...' : item.name} {' ('}
                  {item.size ?? ''}
                  {' KB)'}
                </p>
              </div>
            );
          })}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'start',
          gap: '1px',
          marginTop: '20px'
        }}
      >
        <Box variant="h6" sx={{ color: '#9A76F5', fontWeight: 500 }} style={{ paddingLeft: '0%' }}>
          Shared By : {localUser?.firstname} {localUser?.lastname}
        </Box>
        <Box variant="h6" sx={{ color: '#9A76F5', fontWeight: 500 }} style={{ paddingLeft: '0%' }}>
          {localUser?.organization}
        </Box>
        {/* {type === 'event' && (
          <div
            style={{
              paddingRight: '3%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontSize: '14px'
            }}
          >
            <Icon
              icon={heartOutline}
              style={{ fontSize: '25px', paddingRight: '2px', color: '#9a76f5' }}
            />
            0
            <Icon
              icon={messageSquareOutline}
              style={{
                fontSize: '25px',
                marginLeft: '10px',
                paddingRight: '2px',
                color: '#9a76f5'
              }}
            />
            0
          </div>
        )} */}
      </div>
      <div
        style={{ width: '100%', height: '2px', borderRadius: '2px', backgroundColor: '#9a76f5' }}
      ></div>
      
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
            marginTop: '15px',
            width: '100%'
          }}
        >
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={draftLoading}
            onClick={() => {
              setDraft(true);
              handleSubmit();
            }}
          >
            Save Draft
          </LoadingButton>

          <LoadingButton
            fullWidth
            size="large"
            // type="submit"
            variant="contained"
            // loading={isLoading}
            sx={{}}
            onClick={() => {
              setSelectOption('add');
            }}
          >
            Edit
          </LoadingButton>
        </div>
      
      {title === 'Wellness Check' || section == 'Noticeboard' || section == 'Story' || section == 'Event' ? (
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
          sx={{ marginTop: 1 }}
          onClick={() => {
            AddSubmitFunc();
          }}
        >
          {head1}
        </LoadingButton>
      ) : (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ marginTop: 10 }}>
          <LoadingButton
            fullWidth
            size="large"
            // type="submit"
            variant="outlined"
            loading={draftLoading}
            onClick={() => {
              AddSubmitFunc(1);
            }}
          >
            {head1}
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            // type="submit"
            variant="contained"
            loading={isLoading}
            onClick={() => {
              AddSubmitFunc(0);
            }}
          >
            {head2}
          </LoadingButton>
        </Stack>
      )}
    </Card>
  );
}

export default EventPreview;
