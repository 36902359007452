import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import refreshOutline from '@iconify/icons-eva/refresh-outline';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import flipFill from '@iconify/icons-eva/flip-fill';
import archiveOutline from '@iconify/icons-eva/archive-outline';
import axios from 'axios';
import { restoreEvent } from '../../../pages/facilityMember/DeletedUsers';
import { db } from '../../../App';
import { deleteUser } from '../../../pages/facilityMember/User';
import { deleteUserPermanently } from './FamilyMoreMenu';
import { cloudFuncBaseUrl } from 'src/utils/config';
// ----------------------------------------------------------------------
const localUser = JSON.parse(localStorage.getItem('Rel8User'));

export default function MemberMoreMenu(props) {
  const {
    id,
    facilityId,
    email,
    facilityName,
    firstname,
    lastname,
    restrictAt,
    jobTitle,
    organisation,
    organisationId,
    deletedAt,
    avatarUrl,
    subRole,
    restrictTime
  } = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const userDelete = () => {
    deleteUser(id, email);
  };

  const user = JSON.parse(localStorage.getItem('Rel8User')).role;

  const deleteFacility = () => {
    try {
      // ----------------------------------confirm box----------------------
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to restrict this Facility member!',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, restrict it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // //  --------------loading---------------------
          let timerInterval;
          Swal.fire({
            title: 'Processing...',
            html: 'I will close in few <b></b> Moments.',
            // timer: 2000,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });

          // -------------------------- end loading-------------------------
          // try {
          if (restrictTime === 3) {
            const resp = await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteUserAfter3Restriction`,
              data: {
                id,
                action: 'delete'
              }
            });
          } else {
            // -----------restricted------------------
            await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
              data: {
                id,
                collectionName: 'users',
                role: user
              }
            });
          }
          // -------------------
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Facility member has been restricted.',
            showConfirmButton: false,
            timer: 1500
          });
          // window.location.reload(false);
          // } catch (error) {
          //   Swal.fire({
          //     icon: 'error',
          //     title: 'Error',
          //     text: error.message
          //   });
          // }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message
      });
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: '1' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => (!deletedAt ? deleteFacility() : restoreEvent(props))}
        >
          <ListItemIcon>
            <Icon icon={!deletedAt ? archiveOutline : flipFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={!deletedAt ? 'Restrict' : 'Restore'}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        {/* -------------------------------- for Delete -------------------------------- */}
        {localUser.role === 'admin' && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => deleteUserPermanently(id, 'facility member')}
          >
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {/* -------------------------------- for Delete -------------------------------- */}

        <MenuItem
          sx={{ color: 'text.secondary' }}
          component={RouterLink}
          to="/administration/staff-members/update-facility-member"
          state={{
            docId: id,
            email,
            facilityId,
            facilityName,
            firstName: firstname,
            lastName: lastname,
            jobTitle,
            organisation,
            organisationId,
            avatarUrl,
            subRole
          }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* ********************Update password */}
        {localUser.role === 'admin' && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            component={RouterLink}
            to="/home/change-password"
            state={{ id, email, type: 'facility' }}
          >
            <ListItemIcon>
              <Icon icon={refreshOutline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Update password" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
