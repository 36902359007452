import { Icon } from '@iconify/react';
import { Button, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import edit2Fill from '@iconify/icons-eva/edit-2-outline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import Linkify from 'react-linkify';
import { localUser } from '../../../App';
import Happyhdpi from '../../../assest/Wellness-Happyhdpi.png';
import Neutralhdpi from '../../../assest/Wellness-Neutralhdpi.png';
import Sadhdpi from '../../../assest/Wellness-Sadhdpi.png';
import EventMoreMenu from '../user/EventMoreMenu';
import NoticeMoreMenu from '../user/NoticeMoreMenu';
import WellnessMoreMenu from '../user/WellnessMoreMenu';
import StoriesMoreMenu from '../stories/StoriesMoreMenu';
function DetailBforeImages({ createdAt, object, type, medias, deleted }) {
  // console.log(object);
  const { id, title, description } = object;
  let props;
  let route;
  let moodE;

  if (type === 'event') {
    route = '/content/events/update-event';
    const {
      location,
      medias,
      startDate,
      startTime,
      endTime,
      endDate,
      likes,
      commentsCount,
      startTimePeriod,
      endTimePeriod,
      residentsId,
      roomsId,
      facilitiesId,
      facilityName
    } = object;
    props = {
      id,
      title,
      location,
      description,
      medias,
      startDate,
      startTime,
      endTime,
      endDate,
      createdAt,
      likes,
      commentsCount,
      startTimePeriod,
      endTimePeriod,
      residentsId,
      roomsId,
      facilitiesId,
      facilityName,
      object
    };
  } else if (type === 'story') {
    route = '/content/stories/update-story';
    const {
      // medias,
      likes,
      residentsId,
      isPublished,
      // roomName,
      roomsId,
      facilitiesId,
      facilityName
    } = object;

    props = {
      id,
      description,
      medias,
      likes,
      title,
      createdAt,
      residentsId,
      isPublished,
      roomsId,
      facilitiesId,
      facilityName
    };
  } else if (type === 'notic') {
    route = '/content/notice-board/update-notice';
    const {
      medias,
      commentsCount,
      likes,
      residentsId,
      roomsId,
      facilitiesId,
      facilityName,
      featuredNotice
    } = object;

    props = {
      id,
      title,
      description,
      medias,
      commentsCount,
      likes,
      createdAt,
      residentsId,
      roomsId,
      facilitiesId,
      facilityName,
      object,
      featuredNotice
    };

    // id={id}
    // title={title}
    // description={description}
    // medias={medias}
    // commentsCount={comments}
    // likes={likes}
    // createdAt={createdAt}
    // residentsId={residentsId}
    // roomsId={roomsId}
    // facilitiesId={facilitiesId}
    // facilityName={facilityName}
    // object={row}
    // featuredNotice={featuredNotice}
  } else if (type === 'wellness') {
    route = '/content/wellness/update-wellness';
    const {
      likes,
      medias,
      residentId,
      residentName,
      commentsCount,
      roomId,
      facilityId,
      facilityName,
      mood
    } = object;
    if (mood.toLowerCase() === 'happy') {
      moodE = Happyhdpi;
    } else if (mood.toLowerCase() === 'neutral') {
      moodE = Neutralhdpi;
    } else {
      moodE = Sadhdpi;
    }
    props = {
      id,
      description,
      commentsCount,
      createdAt,
      likes,
      medias,
      residentId,
      residentName,
      roomId,
      facilityId,
      facilityName,
      mood
    };
  }

  return (
    <div style={{ paddingLeft: '2%', marginLeft: '-5px', paddingRight: '9%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/* {type !== 'wellness' && ( */}
        <Typography variant="h4" style={{ width: '70%', color: '#9A76F5' }}>
          {type !== 'wellness' ? title : 'Wellness Check'}
        </Typography>
        {/* // )} */}
        {/* {type === 'wellness' && <img src={moodE} alt="H" width="70" height="70" />} */}
        <div style={{ paddingRight: '3%' }}>
          {/* {localUser.role !== 'Family Member' &&  (
            <Button
              size="small"
              variant="contained"
              style={{ position: 'relative', minWidth:0, paddingLeft:"2px", paddingRight:"2px" }}
              component={RouterLink}
              to={route}
              state={{ props }}
              disabled={deleted === 'yes' && true}
            >
              <MoreVertIcon/>
            </Button>
          )} */}
          {localUser.role !== 'Family Member' && type === 'event' &&(
            <EventMoreMenu
            id={id}
            title={object.title}
            location={object.location}
            description={object.description}
            medias={object.medias}
            startDate={object.startDate}
            startTime={object.startTime}
            endTime={object.endTime}
            endDate={object.endDate}
            createdAt={createdAt}
            likes={object.likes}
            commentsCount={object.comments}
            startTimePeriod={object.startTimePeriod}
            endTimePeriod={object.endTimePeriod}
            residentsId={object.residentsId}
            roomsId={object.roomsId}
            facilitiesId={object.facilitiesId}
            facilityName={object.facilityName}
            object={object}
            dots={true}
          />
          )}
          {localUser.role !== 'Family Member' && type === 'notic' &&(
           <NoticeMoreMenu
           id={object.id}
           title={object.title}
           description={object.description}
           medias={object.medias}
           commentsCount={object.comments}
           likes={object.likes}
           createdAt={createdAt}
           residentsId={object.residentsId}
           roomsId={object.roomsId}
           facilitiesId={object.facilitiesId}
           facilityName={object.facilityName}
           object={object}
           featuredNotice={object.featuredNotice}
           dots={true}
         />
          )}
          {localUser.role !== 'Family Member' && type === 'wellness' &&(
           <WellnessMoreMenu
           id={object.id}
           description={object.description}
           commentsCount={object.commentsCount}
           createdAt={createdAt}
           likes={object.likes}
           medias={object.medias}
           residentId={object.residentId}
           residentName={object.residentName}
           roomId={object.roomId}
           facilityId={object.facilityId}
           facilityName={object.facilityName}
           mood={object.mood}
           object={object}
           dots={true}
         />
          )}
          {localUser.role !== 'Family Member' && type === 'story' &&(
          <StoriesMoreMenu
          id={object.id}
          medias={object.medias}
          description={object.description}
          likes={object.likes}
          commentsCount={object.commentsCount}
          title={object.title}
          createdAt={createdAt}
          residentsId={object.residentsId}
          isPublished={object.isPublished}
          roomName={object.roomName}
          roomsId={object.roomsId}
          facilitiesId={object.facilitiesId}
          facilityName={object.facilityName}
          object={object}
          dots={true}
        />
          )}
        </div>
      </div>
      <span style={{ color: 'gray' }}>
        {moment(createdAt.seconds * 1000).format('DD MMMM YYYY')}
      </span>
      <br />
      <br />
      {type === 'wellness' && (
        <>
          <Typography variant="h6" style={{ width: '70%', marginBottom: 10 }}>
            Today I am
          </Typography>
          <img src={moodE} alt="H" width="70" height="70" />
        </>
      )}

      {type === 'event' && (
        <Stack direction={{ xs: 'column', sm: 'row', marginRight: '3%' }} spacing={3}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 5px',
              padding: '10px 10px 10px 0px',
              borderRadius: '5px',
              width: '100%'
            }}
          >
            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>Location</div>
            {object?.location}
          </Card>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 5px',
              width: '100%',
              padding: '10px 0px 10px 0px',
              borderRadius: '5px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div style={{ width: '50%' }}>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Start Date</div>
                {moment(new Date(object?.startDate.seconds * 1000)).format('DD/MM/YYYY')} {object?.startTime} {object?.startTimePeriod}
              </div>
              <div style={{ width: '50%' }}>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>End Date</div>
                {moment(new Date(object?.endDate.seconds * 1000)).format('DD/MM/YYYY')} {object?.endTime} {object?.endTimePeriod}
              </div>
            </div>
          </Card>
        </Stack>
      )}
      <div
        style={{
          marginTop: '3%',
          paddingRight: '2%',
          marginLeft: '-2%',
          textAlign: 'justify',
          color: 'black'
        }}
      >
        <Linkify>
          <pre style={{ whiteSpace: 'break-spaces', fontFamily: 'Arial' }}>{description}</pre>
        </Linkify>
      </div>
    </div>
  );
}

export default DetailBforeImages;