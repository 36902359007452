import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  where,
  query,
  deleteField,
  updateDoc
} from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import Swal from 'sweetalert2';
import axios from 'axios';
import { db } from '../../../App';
import { deleteUser } from '../../../pages/facilityMember/User';
import { cloudFuncBaseUrl } from 'src/utils/config';

// ----------------------------------------------------------------------

export default function RoomMoreMenu(props) {
  const { id, name, heading, facilityName, facilityId, avatarUrl, createdAt, type } = props;
  const refs = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // console.log(props);
  const user = JSON.parse(localStorage.getItem('Rel8User')).role;

  const roomDelete = () => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // ------------------------------------Loading--------------------
          let timerInterval;
          Swal.fire({
            title: 'Processing...',
            html: 'I will close in <b></b> Moments.',
            // timer: 2000,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });
          // ------------------------------------Loading--------------------
          try {
            await axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
              data: {
                id,
                collectionName: 'rooms',
                role: user
              }
            });

            // --------------------------
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: `Room has been deleted.`,
              showConfirmButton: false,
              timer: 1500
            });
            window.location.reload(false);
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message
            });
          }
        }
      });
    } catch (errr) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errr.message
      });
    }
  };
  return (
    <>
      <IconButton ref={refs} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={refs.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: '1' }}
      >
        {user !== 'Family Member' && (
          <>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={roomDelete}>
              <ListItemIcon>
                <Icon icon={trash2Outline} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>

            <MenuItem
              sx={{ color: 'text.secondary' }}
              component={RouterLink}
              to="/organisation/locations/update-room"
              state={{ roomId: id, name, facilityName, facilityId, avatarUrl }}
            >
              <ListItemIcon>
                <Icon icon={editFill} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </>
        )}
        <MenuItem
          sx={{ color: 'text.secondary' }}
          component={RouterLink}
          to="/administration/clients"
          state={{
            roomId: id,
            name,
            facilityId: type === 'room' ? null : facilityId
          }}
        >
          <ListItemIcon>
            <Icon icon={moreHorizontalFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Show Residents" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
