import moment from 'moment';
import React from 'react';

function lastMessageFunc(lastMessDate) {
  const date = new Date();
  // console.log(moment(new Date(date)).diff(lastMessDate, 'days'));
  // if (new Date().getDate() === new Date(lastMessDate).getDate()) {
  //   return 'Today';
  // }

  // for today
  if (moment(new Date(date)).diff(lastMessDate, 'days') === 0) {
    return 'Today';
  }

  // for Yesterday
  if (moment(new Date(date)).diff(lastMessDate, 'days') === 1) {
    return 'Yesterday';
  }
  // for other
  return moment(lastMessDate).format('DD MMM YYYY');
}

export default lastMessageFunc;
