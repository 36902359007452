import React from 'react';

function ApplyFilterMessageUser(getUser, searchTextarea) {
  let filteredItems = getUser;

  if (searchTextarea) {
    filteredItems = filteredItems.filter(
      (item) => item.text && item.text.toLowerCase().includes(searchTextarea.toLowerCase())
    );
  }

  // Sort the filtered items by time
  filteredItems.sort((a, b) => {
    // Assuming item.time is a timestamp or a sortable format
    return a.time - b.time;
  });

  return filteredItems;
}

export default ApplyFilterMessageUser;
