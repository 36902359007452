// for facility member subRole
export const subRoleFacility = [
  { title: 'Administrator', value: 0 },
  { title: 'Coordinator', value: 1 },
  { title: 'Team Member', value: 2 }
];

// for family member subRole
export const subRoleFamily = [
  { title: 'Family administrator', value: 0 },
  { title: 'Family member', value: 1 }
];
