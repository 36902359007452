import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import { Button, Grid, Box, styled, Card, Stack, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { collection, getDocs, setDoc, doc, query, where } from 'firebase/firestore';
import Autocomplete from '@mui/material/Autocomplete';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import CircularProgress from '@mui/material/CircularProgress';
import { db } from '../../../App';
import SortByTitle from '../event/SortByTitle';

const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------
const addRoom = async (name, facility, facilityName, image) => {
  let heading = name
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), '')
    .toUpperCase();
  heading = heading.substring(0, 2);

  const roomRef = doc(collection(db, 'rooms'));

  let avatarUrl = null;
  if (image) {
    const storage = getStorage();
    const storageRef = ref(storage, `rooms/${roomRef.id}/roomImage`);
    const metadata = {
      contentType: 'image/jpeg'
    };
    const uploadTask = await uploadBytesResumable(storageRef, image, metadata);

    avatarUrl = await getDownloadURL(uploadTask.ref);
  }
  return setDoc(roomRef, {
    id: roomRef.id,
    name,
    heading,
    facilityId: facility,
    facilityName,
    avatarUrl,
    createdAt: new Date()
  });
};

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function RoomForm() {
  const location = useLocation();
  const { data } = location.state ?? { id: '', name: '' };

  // console.log(data);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        facilitesCollection = collection(db, 'facilites');
      }
      // const facilitesCollection = collection(db, 'facilites');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise.forEach((result) => {
        console.log(result.data());
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    facility: Yup.string().required('Group is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      facility: data?.id ?? '',
      facilityName: data?.name ?? ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      // if (!image) {
      //   return NotificationManager.error('Image is required');
      // }
      setIsLoading(true);
      const { name, facility, facilityName } = values;
      addRoom(name, facility, facilityName, image)
        .then((res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Room Added Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          navigate('/home/locations', { replace: true });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          return NotificationManager.error(err.message);
        });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <Autocomplete
            fullWidth
            id="asynchronous-demo"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(e, value) => {
              console.log(value);
              setFieldValue('facilityName', value?.title || '');
              setFieldValue('facility', value?.value || '');
            }}
            // ---------------from facility rom add------------------
            defaultValue={{ title: data?.name ?? '', value: data?.id ?? '' }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            options={options}
            loading={loading}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label="Select Group"
                {...getFieldProps('facility')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
                error={Boolean(touched.facility && errors.facility)}
                helperText={touched.facility && errors.facility}
              />
            )}
          />

          <Grid container spacing={2}>
            {image && (
              <Grid item xs={4} sm={4} md={2}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle alt="Image" src={URL.createObjectURL(image)} />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label">
              Upload Image
              <input
                fullWidth
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Add Location
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

const topFilms = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  {
    title: 'The Lord of the Rings: The Return of the King',
    year: 2003
  },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  {
    title: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001
  },
  {
    title: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980
  },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  {
    title: 'The Lord of the Rings: The Two Towers',
    year: 2002
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  {
    title: 'Star Wars: Episode IV - A New Hope',
    year: 1977
  },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 }
];
