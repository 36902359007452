import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// components
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  query,
  where,
  onSnapshot
} from 'firebase/firestore';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead } from '../../components/_dashboard/user';
//
import { db } from '../../App';
import DraftListToolbar from '../../components/_dashboard/user/DraftListToolbar';
import DraftMoreMenu from '../../components/_dashboard/user/DraftMoreMenu';
import DeletedTimeResi from '../../components/_dashboard/resident/DeletedTimeResi';
import Label from '../../components/Label';

import repeatOutline from '@iconify/icons-eva/repeat-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { DeleteEvent } from '../Wellbeing/DeletedWellness';

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'createdAt', label: 'Date', alignRight: false },
  { id: 'deletedAt', label: 'Delete', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
  // { id: 'download', label: 'Download', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//* **************Delete Users From Firebase**********************
export const restoreEvent = (data) => {
  console.log(data.id);
  console.log(data);
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to be restore this draft?',
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, restore it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      // ----------------------------------------------Loading-------------
      let timerInterval;
      Swal.fire({
        title: 'Processing...',
        html: 'I will close in few<b></b> Moments.',
        // timer: 2000,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer');
        }
      });
      // ----------------------------------------------Loading---------------------
      await deleteDoc(doc(db, 'recycleBin', 'drafts', 'drafts', data.id));
      // .then(async (res) => {
      await setDoc(doc(db, 'drafts', data.id), {
        ...data
      });
      //   window.location.reload(false);
      // })
      // .catch((err) => console.log(err.message));
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Draft has been restored.',
        showConfirmButton: false,
        timer: 1500
      });
      window.location.reload(false);
    }
  });
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, type) {
  if (type && !query) {
    return array.filter((d) => d.type === type);
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DeletedDrafts() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);
  const [value, setValue] = useState();
  const [filterByType, setFilterByType] = useState('');

  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Noticeboard From Firebase**********************
  // const userCollection = collection(db, 'recycleBin', 'drafts', 'drafts');

  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'recycleBin', 'drafts', 'drafts'),
      where('object.facilitiesId', 'in', user?.facilityIds)
    );
  } else if (user?.role === 'admin') {
    userCollection = collection(db, 'recycleBin', 'drafts', 'drafts');
  } else if (user?.role === 'Family Member') {
    userCollection = query(
      collection(db, 'recycleBin', 'drafts', 'drafts'),
      where('object.residentsId', 'array-contains-any', user?.residentIds)
    );
  }

  const noticeboard = [];
  const getUsers = async () => {
    // getDocs(userCollection).then((res) => {
    onSnapshot(userCollection, (res) => {
      res.forEach((result) => {
        noticeboard.push({
          id: result.data().id,
          type: result.data().type,
          title: result.data().title,
          object: result.data().object,
          creatorId: result.data().creatorId,
          createdAt: result.data().createdAt,
          deletedAt: result.data().deletedAt
        });
      });
      if (!noticeboard.length) {
        return setNotFound(true);
      }
      setUSERLIST(noticeboard);
    });
  };

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  const handleFilterByDate = (event) => {
    console.log(event);
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setValue(event);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName,
    filterByType
  );

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Drafts | Rel8">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Deleted drafts
          </Typography>
        </Stack>

        <Card>
          <DraftListToolbar
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            value={value}
            filterByDate={handleFilterByDate}
            setFilterByType={setFilterByType}
            filterByType={filterByType}
            setSearch={setSearch}
            setSearchSet={setSearchSet}
            Search={Search}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                USERLIST={[]}
                localuser={{}}
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  deletedTab={true}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, title, object, type, createdAt, creatorId, deletedAt } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          description="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox" />
                          <TableCell component="th" scope="row" padding="2px">
                            <Typography variant="subtitle2" width="60%">
                              {title?.slice(0, 100)}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            {type?.replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase())}
                          </TableCell>
                          {/* <TableCell align="left">{createdAt.toDate().toDateString()}</TableCell> */}
                          <TableCell align="left">
                            {moment(createdAt?.toDate()).format('DD MMM YYYY')}
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color="error">
                              {DeletedTimeResi(deletedAt)}-remaining
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <Stack spacing={2} direction={{ xs: 'row', sm: 'row' }}>
                              <Tooltip title="Restore Draft" placement="top">
                                <LoadingButton
                                  size="small"
                                  variant="contained"
                                  //   loading={isLoading}
                                  onClick={() => {
                                    restoreEvent(row);
                                  }}
                                  disabled={user?.role === 'Family Member' ?? false}
                                >
                                  {/* Restore */}
                                  <Icon icon={repeatOutline} style={{ fontSize: '20px' }} />
                                </LoadingButton>
                              </Tooltip>
                              <Tooltip title="Delete Draft" placement="top">
                                <LoadingButton
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    DeleteEvent(
                                      row?.id,
                                      'drafts',
                                      row?.type === 'noticeboard' ? 'noticeboards' : row?.type
                                    );
                                  }}
                                  disabled={
                                    JSON.parse(localStorage.getItem('Rel8User')).role ===
                                      'Family Member' ?? false
                                  }
                                >
                                  <Icon icon={trash2Outline} style={{ fontSize: '20px' }} />
                                </LoadingButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound &&
                  (NotFound ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <UserNotFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
