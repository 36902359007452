import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Members from './Members';
import InfoCard from './InfoCard';
import Facilitys from './Facilitys';
// import Rooms from '../facilities/Rooms';
// import Residents from './Residents';

export default function MoreInfo() {
  const location = useLocation();
  const { row, deleted } = location.state;

  console.log(row?.id);
  return (
    <Grid container spacing={12}>
      <Grid
        item
        sx={{
          display: 'flex',
          position: 'relative'
        }}
      >
        <InfoCard row={row} deleted={deleted} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Facilitys id={row.id} name={row.name} deleted={deleted} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Members id={row.id} name={row.organizationName} deleted={deleted} />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={12}>
        <Residents id={row.id} name={row.name} deleted={deleted} />
      </Grid> */}
    </Grid>
  );
}
