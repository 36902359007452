import React from 'react';

function ApplyFilterMessages(getUser, searchTextarea) {
  if (searchTextarea) {
    return getUser?.filter(
      (item) => item?.text && item?.text.toLowerCase().includes(searchTextarea.toLowerCase())
    );
  }
  return getUser;
}

export default ApplyFilterMessages;
