import { useState, useEffect } from 'react';
import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { formatDistance } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// material
import Linkify from 'react-linkify';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';

import messageSquareOutline from '@iconify/icons-eva/message-square-outline';
import heartOutline from '@iconify/icons-eva/heart-outline';
import heartFill from '@iconify/icons-eva/heart-fill';

import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
// utils
import { mockImgCover } from '../../../utils/mockImages';
//
import Scrollbar from '../../Scrollbar';
// mood
import Happyhdpi from '../../../assest/Wellness-Happyhdpi.png';
import Neutralhdpi from '../../../assest/Wellness-Neutralhdpi.png';
import Sadhdpi from '../../../assest/Wellness-Sadhdpi.png';
import { fShortenNumber } from '../../../utils/formatNumber';
import { db } from '../../../App';

// ----------------------------------------------------------------------

const NEWS = [...Array(1)].map((_, index) => {
  const setIndex = index + 1;
  return {
    title: faker.name.title(),
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    image: mockImgCover(setIndex),
    postedAt: faker.date.soon()
  };
});

// ----------------------------------------------------------------------
export const likeFunction = async (storyId, object, collections, likes) => {
  try {
    const like = likes ?? [];
    const userLike = [];
    like?.forEach((e) => {
      if (e === user?.id) {
        userLike.push(e);
      }
    });
    if (userLike.length > 0) {
      const index = like.indexOf(user?.id);
      like.splice(index, 1);
      setlikeColor(false);
    } else {
      setlikeColor(true);
      like.push(user?.id);
    }

    await updateDoc(doc(db, collections, storyId), {
      likes: like
    });


  } catch (err) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: err.message
    });
  }
  
};


export default function AppNewsUpdate({
  UserName,
  comments,
  likes,
  storyId,
  collections,
  object,
  deleted
}) {
  const user = JSON.parse(localStorage.getItem('Rel8User'));

  const indx = likes?.find((e) => e === user?.id);

  const [likeColor, setlikeColor] = useState(false);

  useEffect(() => {
    if (indx) {
      return setlikeColor(true);
    }
    return setlikeColor(false);
  }, [indx]);

  // -----------------------------------------------
  //               Like function
  // -----------------------------------------------
   const likeFunction = async (storyId, object, collections, likes) => {
    try {
      const like = likes ?? [];
      const userLike = [];
      like?.forEach((e) => {
        if (e === user?.id) {
          userLike.push(e);
        }
      });
      if (userLike.length > 0) {
        const index = like.indexOf(user?.id);
        like.splice(index, 1);
        setlikeColor(false);
      } else {
        setlikeColor(true);
        like.push(user?.id);
      }

      await updateDoc(doc(db, collections, storyId), {
        likes: like
      });


    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.message
      });
    }
    
  };

  return (
    <div style={{ paddingRight: '9%', paddingLeft: '1%', marginTop: '2%' }}>
      {/* <br /> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h6" sx={{ color: '#9A76F5' }} style={{ paddingLeft: '0.5%' }}>
          {/* Shared by: {UserName ?? '---'} */}
          Shared by: {UserName}
        </Typography>
        <div style={{ paddingRight: '3%', fontSize: '14px' }}>
          <Icon
            icon={likeColor === true ? heartFill : heartOutline}
            style={{ fontSize: '25px', paddingRight: '2px', color: '#9a76f5', cursor: 'pointer' }}
            onClick={() => {
              deleted !== 'yes' && likeFunction(storyId, object, collections, likes);
            }}
          />
          {fShortenNumber(likes?.length)}
          <Icon
            icon={messageSquareOutline}
            style={{ fontSize: '25px', marginLeft: '10px', paddingRight: '2px', color: '#9a76f5' }}
          />
          {fShortenNumber(comments)}
        </div>
      </div>
      {/* <CardHeader title="Description" />
      <Scrollbar style={{ paddingRight: '2%' }}>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {NEWS.map((news) => (
            <NewsItem key={news.title} description={description} />
          ))}
        </Stack>
      </Scrollbar> */}
      {/* <Divider />
      <Box sx={{ p: 2, textAlign: 'right' }} /> */}
    </div>
  );
}
