import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  styled,
  Card
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import { collection, getDocs, doc, setDoc, query, where } from 'firebase/firestore';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { db } from '../../../App';
import SortByTitle from '../event/SortByTitle';

const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');
export default function UpdateResidentForm({ props }) {
  console.log(props);

  // const { profileImgUrl } = props;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState(props?.profileImgUrl);
  const [stateImage, setStateImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  // for facility capicity
  const [facilityFC, setFacilityFC] = useState(props?.facilityId);
  const [facilityError, setfacilityError] = useState(false);

  const [openRoom, setOpenRoom] = useState(false);
  const [roomOptions, setRoomOptions] = useState([]);
  const loadingRooms = openRoom && roomOptions.length === 0;

  // Selected facility check capicity
  useEffect(async () => {
    const facilitesCollection = query(collection(db, 'facilites'), where('id', '==', facilityFC));
    const facilitesPromise = await getDocs(facilitesCollection);
    const fac = [];
    facilitesPromise.forEach((e) => {
      fac.push(e.data());
    });
    // console.log(parseInt(fac[0]?.capacity, 10));

    const residentsCollection = query(
      collection(db, 'residents'),
      where('facilityId', '==', facilityFC)
    );
    const residentsPromise = await getDocs(residentsCollection);
    const opt = [];
    residentsPromise.forEach((result) => {
      opt.push(result.data());
    });
    // console.log(opt.length);
    if (opt.length >= parseInt(fac[0]?.capacity, 10)) {
      setfacilityError(true);
      return Swal.fire({
        allowOutsideClick: false,
        icon: 'error',
        title: 'Oops...',
        text: 'Client capacity has been reached.'
      });
    }
    setfacilityError(false);
  }, [facilityFC]);

  // Facility
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        facilitesCollection = collection(db, 'facilites');
      }
      // const facilitesCollection = collection(db, 'facilites');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise.forEach((result) => {
        console.log(result.data());
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // -------------Rooms Autocomplete--------------------------//
  useEffect(() => {
    let active = true;

    if (!loadingRooms) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const roomsCollection = query(collection(db, 'rooms'), where('facilityId', '==', facilityFC));
      const roomsPromise = await getDocs(roomsCollection);
      const opt = [];
      roomsPromise.forEach((result) => {
        console.log(result.data());
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setRoomOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingRooms]);

  useEffect(() => {
    if (!openRoom) {
      setOptions([]);
    }
  }, [openRoom]);

  const phoneRegExp = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/;
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('first name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('last name required'),
    // aboutMe: Yup.string().min(2, 'Too Short!').required('About me required'),
    aboutMe: Yup.string().min(2, 'Too Short!'),
    // like: Yup.string().min(2, 'Too Short!').required('Likes required'),
    like: Yup.string().min(2, 'Too Short!'),
    // dislike: Yup.string().min(2, 'Too Short!').required('Dislikes required'),
    dislike: Yup.string().min(2, 'Too Short!'),
    facility: Yup.string().required('Group is required.').nullable(),
    DOB: Yup.date().required('DOB is required'),
    room: Yup.string().required('Select another location.').nullable()
  });
  const formik = useFormik({
    initialValues: {
      firstName: props?.firstName,
      lastName: props?.lastName,
      DOB: new Date(props?.dateOfBirth.seconds * 1000),
      adminStory: props?.includedInAdminStories,
      facility: props?.facilityId,
      facilityName: props?.facilityName,
      room: props?.roomId,
      roomName: props?.roomName,
      photo: '',
      like: props?.likes,
      dislike: props?.dislikes,
      aboutMe: props?.aboutMe
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      setIsLoading(true);
      // if (!image) {
      //   setIsLoading(false);
      //   return NotificationManager.error("You didn't select image");
      // }
      const firstName = getFieldProps('firstName').value;
      const lastName = getFieldProps('lastName').value;
      const likes = getFieldProps('like').value;
      const dislikes = getFieldProps('dislike').value;
      const aboutMe = getFieldProps('aboutMe').value;
      const dateOfBirth = getFieldProps('DOB').value;
      const facilityId = getFieldProps('facility').value;
      const facilityName = getFieldProps('facilityName').value;
      const includedInAdminStories = getFieldProps('adminStory').value;
      const roomId = getFieldProps('room').value;
      const roomName = getFieldProps('roomName').value;
      const storage = getStorage();
      const residentsRef = doc(collection(db, 'residents'));

      if (facilityError === true) {
        setIsLoading(false);
        return Swal.fire({
          allowOutsideClick: false,
          icon: 'error',
          title: 'Error',
          text: 'Please select another facility!'
        });
      }

      const storageRef = ref(storage, `residents/${props?.id}/profile`);

      // if (RgExp.test(image)) {
      if (!stateImage) {
        return setDoc(
          doc(db, 'residents', props?.id),
          {
            firstName,
            likes: likes ?? '',
            dislikes: dislikes ?? '',
            aboutMe: aboutMe ?? '',
            lastName,
            dateOfBirth,
            facilityId,
            facilityName,
            roomId,
            roomName,
            includedInAdminStories
          },
          { merge: true }
        )
          .then((res) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Resident Updated Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            navigate('/administration/clients', { replace: true });
          })
          .catch((err) => console.log(err));
      }
      const metadata = {
        contentType: 'image/jpeg'
      };
      const uploadTask = uploadBytesResumable(storageRef, stateImage, metadata);
      // const uploadTask = uploadBytesResumable(storageRef, image, metadata);
      setTimeout(() => {
        NotificationManager.warning('Please wait! File is being upload');
      }, 10000);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => NotificationManager.error(error.message),
        async () => {
          const profileImgUrl = await getDownloadURL(uploadTask.snapshot.ref);
          setDoc(
            doc(db, 'residents', props?.id),
            {
              firstName,
              likes,
              dislikes,
              aboutMe,
              lastName,
              dateOfBirth,
              facilityId,
              facilityName,
              roomId,
              profileImgUrl,
              roomName,
              includedInAdminStories
            },
            { merge: true }
          )
            .then((res) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Resident Updated Successfully',
                showConfirmButton: false,
                timer: 2000
              });
              navigate('/administration/clients', { replace: true });
            })
            .catch((err) => console.log(err));
        }
      );
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <TextField
              fullWidth
              label="Last Name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(e, value) => {
                setFacilityFC('');
                setFieldValue('facilityName', value?.title || '');
                setFieldValue('facility', value?.value || '');
                setFacilityFC(value?.value);
                setRoomOptions([]);

                // for empty room
                setFieldValue('roomName', '');
                setFieldValue('room', '');
              }}
              defaultValue={{ title: props?.facilityName ?? '', value: props?.facilityId ?? '' }}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option.title}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Select Group"
                  {...getFieldProps('facility')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={Boolean(touched.facility && errors.facility)}
                  helperText={touched.facility && errors.facility}
                />
              )}
            />
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              open={openRoom}
              disabled={!facilityFC ?? true}
              onOpen={() => {
                setOpenRoom(true);
              }}
              onClose={() => {
                setOpenRoom(false);
              }}
              onChange={(e, value) => {
                setFieldValue('roomName', value?.title || '');
                setFieldValue('room', value?.value || '');
              }}
              defaultValue={{ title: props?.roomName ?? '', value: props?.roomId ?? '' }}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option.title}
              options={roomOptions}
              loading={loadingRooms}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Location"
                  {...getFieldProps('room')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingRooms ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={Boolean(touched.room && errors.room)}
                  helperText={touched.room && errors.room}
                />
              )}
            /> 
            <Stack sx={{ width: '100%', '& > *': { flex: 1 } }}>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date Of Birth"
                format='dd/MM/yyyy'
                inputFormat="dd/MM/yyyy"
                {...getFieldProps('DOB')}
                onChange={(e, value) => setFieldValue('DOB', e || '')}
                onChangeRaw={(e) => {
                  setFieldTouched('DOB', true, true);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={Boolean(touched.DOB && errors.DOB)}
                    helperText={touched.DOB && errors.DOB}
                  />
                )}
              />
            </LocalizationProvider>
            </Stack>
          </Stack>

          {/* ******************** Like and dislike  */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Likes"
              {...getFieldProps('like')}
              error={Boolean(touched.like && errors.like)}
              helperText={touched.like && errors.like}
            />
            <TextField
              fullWidth
              label="Dislikes"
              {...getFieldProps('dislike')}
              error={Boolean(touched.dislike && errors.dislike)}
              helperText={touched.dislike && errors.dislike}
            />
          </Stack>
          <TextField
            fullWidth
            multiline
            minRows={3}
            maxRows={20}
            label="About Me"
            {...getFieldProps('aboutMe')}
            error={Boolean(touched.aboutMe && errors.aboutMe)}
            helperText={touched.aboutMe && errors.aboutMe}
          />

          <FormControlLabel
            control={<Checkbox defaultChecked={!!props?.includedInAdminStories} />}
            label="Include in group story"
            {...getFieldProps('adminStory')}
            onChange={(e, value) => setFieldValue('adminStory', value)}
          />
          <Grid container spacing={2}>
            {image && (
              <Grid item xs={4} sm={4} md={2}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle
                      alt="Image"
                      src={image && RgExp.test(image) ? image : URL.createObjectURL(image)}
                    />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label">
              Upload Image
              <input
                fullWidth
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setStateImage(e.target.files[0]);
                }}
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>

          {/* <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          /> */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Update Client
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
