import {
  Avatar,
  Card,
  Container,
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  Tooltip
} from '@mui/material';
import { useState, useEffect } from 'react';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import copyOutline from '@iconify/icons-eva/copy-outline';

// import Members from './Members';
// import Page from '../../Page';
// import { LightBox } from '../user/LightBox';
import noImage from '../../../assest/no-image.png';

export default function InfoCard({ row, deleted }) {
  const [copied, setcopied] = useState(false);

  // ------for lightbox
  const [image, setImage] = useState('');

  // ---------------------Copy text to clip board------------------------------------
  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setcopied(true);
      return navigator.clipboard.writeText(str);
      // return Promise.reject('The Clipboard API is not available.');
    }
  };
  // ---------------------------------------------------------

  return (
    <Card sx={{ margin: 2, width: '100%', zIndex: 1 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} p={2} spacing={2}>
        <img
          src={row?.image ?? noImage}
          alt={row?.organizationName?.slice(0, 1)?.toUpperCase()}
          style={{
            width: 220,
            height: 220,
            objectFit: 'contain',
            borderRadius: '10px',
            cursor: 'pointer',
            backgroundColor: '#9A76F5',
            color: 'white'
          }}
          onClick={() => {
            setImage(row.avatarUrl);
          }}
        />
        <div>
          <Box m={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" gutterBottom>
              {row?.organizationName?.length > 130 ? (
                <>{row?.organizationName?.slice(0, 130)}...</>
              ) : (
                row?.organizationName
              )}
            </Typography>
            {/* <TextTypo text={row.contact} icon={<Icon icon="ant-design:phone-filled" />} /> */}
            <TextTypo text={row.contact} icon={<Icon icon="ant-design:phone-filled" />} />
            <TextTypo text={row.location} icon={<Icon icon="carbon:location-filled" />} />
          </Box>
          {/* <Box m={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
              Number of Clients
            </Typography>
            {row.capacity}
          </Box> */}
          {JSON.parse(localStorage.getItem('Rel8User')).role == 'admin' && (
            <Button
              variant="contained"
              size="small"
              disabled={deleted === 'yes' && true}
              style={{
                position: 'relative',
                float: 'right',
                position: 'absolute',
                right: '20px',
                bottom: '20px'
              }}
              component={RouterLink}
              to="/organisation/organisation/add_organisation"
              state={{
                ...row
              }}
            >
              Edit
              <Icon icon={edit2Fill} style={{ fontSize: '20px', paddingLeft: 5 }} />
            </Button>
          )}
        </div>
      </Stack>
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Card>
  );
}

const TextTypo = ({ text, icon }) => (
  <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
    {icon}
    &nbsp;
    {text}
  </Typography>
);
