// for testing if true
const isTest = true;
export const firebaseConfig = isTest
  ? // for testing
    {
      apiKey: 'AIzaSyATKjX56VFlR8W_tQcTizBvGh7PbpvVS4Y',
      authDomain: 'rel8app-services-test.firebaseapp.com',
      projectId: 'rel8app-services-test',
      storageBucket: 'rel8app-services-test.appspot.com',
      messagingSenderId: '1062026882677',
      appId: '1:1062026882677:web:09842d698782755756923c',
      measurementId: 'G-QYHQ43SK00'
    }
  : // for production
    {
      apiKey: 'AIzaSyDV6KojBtmjLdCE--tYd0QSWDa8B8T3skE',
      authDomain: 'rel8app-services.firebaseapp.com',
      projectId: 'rel8app-services',
      storageBucket: 'rel8app-services.appspot.com',
      messagingSenderId: '41546599359',
      appId: '1:41546599359:web:5347c188eeea9195306f0a',
      measurementId: 'G-JGKS3GTE58'
    };

export const cloudFuncBaseUrl = isTest
  ? 'https://us-central1-rel8app-services-test'
  : 'https://us-central1-rel8app-services';

export const GOOGLE_PLACES_API_KEY = 'AIzaSyDV6KojBtmjLdCE--tYd0QSWDa8B8T3skE';
