import { Link as RouterLink, useLocation } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent } from '@mui/material';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
import { Icon } from '@iconify/react';
import { CommentOutlined, LikeOutlined } from '@ant-design/icons/lib/icons';
import messageSquareOutline from '@iconify/icons-eva/message-square-outline';
import heartOutline from '@iconify/icons-eva/heart-outline';
import Popover from '@mui/material/Popover';
import NoticeMoreMenu from '../../components/_dashboard/user/NoticeMoreMenu';
import './comman.css';
import React, { useState, useEffect } from 'react';
import Spinner from 'react-spinner-material';
import ReactPlayer from 'react-player/lazy';
import { collection, doc, getDoc, onSnapshot, query, where, setDoc } from 'firebase/firestore';
import moment from 'moment';
import Swal from 'sweetalert2';
import heartFill from '@iconify/icons-eva/heart-fill';
import NewCommentBox from '../../components/_dashboard/app/NewCommentBox';

import SvgIconStyle from '../../components/SvgIconStyle';
import ShopProductCard from '../../components/_dashboard/products/ProductCard';
import { fShortenNumber } from '../../utils/formatNumber';
import { AppNewsUpdate } from '../../components/_dashboard/app';
import CommentBox from '../../components/_dashboard/app/CommentBox';
import { db } from '../../App';
import DetailBforeImages from '../../components/_dashboard/app/DetailBforeImages';
import { localUser } from '../../App';
import useDownloader from 'react-use-downloader';
const ProductFileStyle = styled('div')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  cursor: 'pointer',
  backgroundColor: '#E4E4E4'
});
const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});

const card = {
  '@media (min-width: 720px)': {
    width: '48px'
  }
};

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));
const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});
const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
const user = JSON.parse(localStorage.getItem('Rel8User'));

export default function NoticeDetail() {
  const [isLoading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOpen, setPopOpen] = useState(false);
  const [getComment, setComments] = useState([]);
  const [getLikes, setgetLikes] = useState([]);
  const [likeColor, setlikeColor] = useState(false);
  const [likesUsers, setLikesUsers] = useState([]);
  const [likesUsersLoading, setLikesUsersLoading] = useState(true);
  const [likesUsersNotfound, setLikesUsersNotFound] = useState(false);
  const [getCommentsCount, setGetCommentsCount] = useState();
  const location = useLocation();
  const [downloadURL, setDownloadURL] = useState('');
  const { size, percentage, download, isInProgress } = useDownloader();
  const { id, commentsCount, title, createdAt, object, deleted } = location?.state ?? '';
  // const images = location.state.images ?? location.state.props.medias;
  const media = location.state.medias;

  const images =
    media.length != 0
      ? media.filter((image) => {
          console.log('image :: ', image);
          const file =
            (image.type != 'image' && image.type != 'video') ||
            (image.name && image.name.endsWith('.avi')) ||
            (image.name && image.name.endsWith('.mpeg')) ||
            (image.name && image.name.endsWith('.heic'));
          const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
          if (type != 'file') {
            return image;
          }
        })
      : [];
  const docs =
    media.length != 0
      ? media.filter((image) => {
          console.log('image :: ', image);
          const file =
            (image.type != 'image' && image.type != 'video') ||
            (image.name && image.name.endsWith('.avi')) ||
            (image.name && image.name.endsWith('.mpeg')) ||
            (image.name && image.name.endsWith('.heic'));
          const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
          if (type == 'file') {
            return image;
          }
        })
      : [];



  useEffect(() => {
    setLoading(false);
    const script = document.createElement('link');
    script.href =
      'https://cdn.jsdelivr.net/gh/StudioKonKon/bootstrap-konkon@1.3.0-beta/dist/css/studio-konkon.min.css';
    script.rel = 'stylesheet';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const updateDocument = async () => {
      const docRef = doc(db, 'noticeboards', id);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log('Document data:', docSnap.data());
          if (docSnap.data().views) {
            console.log('run');
            await setDoc(
              docRef,
              { views: [...docSnap.data().views, localUser.id] },
              { merge: true }
            );
          } else {
            console.log('data ');
            const res = await setDoc(docRef, { views: [localUser.id] }, { merge: true });
            console.log('set data ', res);
          }
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };

    updateDocument();
  }, []);

  // ------------------------------
  useEffect(async () => {
    const q = query(collection(db, 'comments'), where('storyId', '==', id));
    let comments = [];
    onSnapshot(q, async (querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        comments.push(doc.data());
      });
      setComments(comments);
      comments = [];
    });

    // -------------likes-----------
    if (getLikes.length == 0) {
      await onSnapshot(doc(db, 'noticeboards', id), (doc) => {
        doc.data()?.likes.map((item) => {
          if (item == user.id) {
            setlikeColor(true);
          }
        });
        setGetCommentsCount(doc.data().commentsCount);
        setgetLikes(doc.data()?.likes);
      });
    }
  }, []);

  // ------------------------------
  // console.log(getComment);

  function CheckFileType(image) {
    console.log('images :: ', image.index);
    const file =
      (image.image.type != 'image' && image.image.type != 'video') ||
      (image.image.name && image.image.name.endsWith('.avi')) ||
      (image.image.name && image.image.name.endsWith('.mpeg')) ||
      (image.image.name && image.image.name.endsWith('.heic'));
    const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
    const condition = image.isInProgress && image.image.downloadUrl === image.downloadURL;
    switch (type) {
      case 'image':
        return (
          <div
            key={image.image.id}
            className={image.index == 0 && image.length > 7 ? 'firstIndex' : 'allIndex'}
          >
            <ShopProductCard img={image.image} Index={image.index} images={image.images} />
          </div>
        );

      case 'video':
        return (
          <div
            key={image.image.id}
            className={image.index == 0 && image.length > 7 ? 'firstIndex' : 'allIndex'}
          >
            <ShopProductCard img={image.image} Index={image.index} images={image.images} />
          </div>
        );
      default:
        return <p></p>;
    }
  }

  function CheckFileTypeDocument(image, images, index, percentage, isInProgress, downloadURL) {
    const file =
      (image.image.type != 'image' && image.image.type != 'video') ||
      (image.image.name && image.image.name.endsWith('.avi')) ||
      (image.image.name && image.image.name.endsWith('.mpeg')) ||
      (image.image.name && image.image.name.endsWith('.heic'));
    const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
    console.log('image ::: ', image);
    const condition = image.isInProgress && image.image.downloadUrl === image.downloadURL;
    switch (type) {
      case 'file':
        return (
          <button
            key={image.image.id}
            style={{
              border: 'none',
              cursor: 'pointer',
              gap: '10px',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row',
              backgroundColor: condition ? '#F2EEFE' : 'transparent',
              maxWidth: '250px'
            }}
            onClick={() => {
              if (image.isInProgress) {
                if (image.image.downloadUrl === image.downloadURL) {
                  return;
                }
                PopupError();
                return;
              }
              image.setDownloadURL(image.image.downloadUrl);
              image.download(image.image.downloadUrl, image.image.name);
            }}
          >
            <div
              style={{
                width: '50px',
                minWidth: '50px',
                maxWidth: '50px',
                height: '60px',
                minHeight: '60px',
                maxHeight: '60px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img
                src={image.image.thumbnail}
                style={{ objectFit: 'contain', maxHeight: '100%' }}
              />
            </div>
            <div
              style={{
                fontSize: '12px',
                fontSize: '12px',
                textDecoration: 'none'
              }}
            >
              <div style={{ textAlign: 'center', fontWeight: 600 }}>
                {image.image.name
                  ? image.image.name.length > 20
                    ? image.image.name.slice(0, 18) + '...'
                    : image.image.name
                  : image.image.name}
                {' ('}
                {image.image.size ?? ''}
                {' KB)'}
              </div>
            </div>
          </button>
        );

      default:
        return <p></p>;
    }
  }

  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );

  const handleClick = (event) => {
    setPopOpen(true);
    setAnchorEl(event.currentTarget);
  };
  function LikesList(event) {
    handleClick(event);
    console.log('get likes ', getLikes);
    if (getLikes.length > 0) {
      setLikesUsersNotFound(false);
      setLikesUsersLoading(true);

      const q = query(collection(db, 'users'), where('id', 'in', getLikes));
      let likes = [];
      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          likes.push(doc.data());
        });
        setLikesUsersLoading(false);
        setLikesUsers(likes);
        likes = [];
      });
      return;
    }
    console.log('runing');
    setLikesUsersLoading(false);
    setLikesUsersNotFound(true);
  }

  const handleClose = () => {
    setPopOpen(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const likePopId = open ? 'simple-popover' : undefined;


  function formatDate(date) {
    if (date.seconds) {
      const milliseconds = date.seconds * 1000;
      const dateObject = new Date(milliseconds);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const dateString = dateObject.toLocaleString('en-US', options);
      return dateString;
    } else {
      console.log('date before ', date);

      // Parse the date string to create a Date object
      const parsedDate = new Date(date);
      console.log('date after ', parsedDate);

      // Format the date as desired (e.g., "June 22, 2024")
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      const formattedDate = parsedDate.toLocaleString('en-US', options);
      return formattedDate;
    }
  }

  console.log("object :: ",object)
  const RenderText = ({ description }) => (
    <div>
      {description?.split('\n\n\n').map((block, blockIndex) => (
        <div key={blockIndex}>
          {block.split('\n\n').map((paragraph, paraIndex) => (
            <div key={paraIndex} style={{ marginBottom: '1em' }}>
              {paragraph.split('\n').map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          ))}
          <br />  {/* Add extra line break to distinguish between blocks */}
        </div>
      ))}
    </div>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {/* <DetailBforeImages createdAt={createdAt} object={object} type="notic" deleted={deleted} /> */}

      <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
        <Typography variant="h4" style={{ color: '#9A76F5', fontWeight: 400 }}>
          {title}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <div style={{ paddingRight: '0', fontSize: '14px', display: 'flex', gap: '10px' }}>
            <div style={{ display: 'flex' }}>
              <Icon
                icon={getLikes.length > 0 ? heartFill : heartOutline}
                style={{
                  fontSize: '25px',
                  paddingRight: 'px',
                  color: '#9a76f5',
                  cursor: 'pointer'
                }}
                onClick={LikesList}
              />
              {fShortenNumber(getLikes?.length)}
              <Popover
                id={likePopId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ marginTop: '10px' }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  horizontal: 'right'
                }}
              >
                <div
                  style={{
                    maxHeight: '250px',
                    maxWidth: '225px',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    padding: '7px'
                  }}
                >
                  <div
                    style={{ color: '#9a76f5', fontSize: '20px', fontWeight: 500, width: '100%' }}
                  >
                    Likes
                  </div>
                  {!likesUsersLoading && likesUsersNotfound && (
                    <div style={{ fontSize: '14px', minWidth: '210px' }}>No Like Found</div>
                  )}
                  {likesUsersLoading && !likesUsersNotfound && (
                    <div style={{ fontSize: '14px', minWidth: '210px' }}>Loading</div>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {!likesUsersLoading &&
                      !likesUsersNotfound &&
                      likesUsers.map((item, index) => {
                        console.log('likeUsers ', item);
                        const name = item.firstname + ' ' + item.lastname;
                        return (
                          <div
                            style={{
                              paddingBottom: '5px',
                              paddingTop: '5px',
                              cursor: 'pointer',
                              width: '210px',
                              display: 'flex',
                              gap: '5px',
                              alignItems: 'center',
                              borderBottom:
                                index == likesUsers.length - 1 ? '' : '0.5px solid #E7E8EB'
                            }}
                          >
                            {item.avatarUrl ? (
                              <Avatar src={item.avatarUrl} alt="photoURL" />
                            ) : (
                              <Avatar src="" sx={{ backgroundColor: '#9A76F5' }}>
                                {item.firstname?.split(' ')[0]?.slice(0, 1)?.toUpperCase()}
                                {item.lastname?.split(' ')[1]?.slice(0, 1)?.toUpperCase()}
                              </Avatar>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                              <div style={{ fontSize: '14px', lineHeight: '16px' }}>
                                {name.length > 18 ? name.slice(0, 16) + '..' : name}
                              </div>
                              {item.organization && (
                                <div style={{ fontSize: '12px', lineHeight: '13px' }}>
                                  {item.organization.length > 25
                                    ? item.organization.slice(0, 23) + '..'
                                    : item.organization}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Popover>
            </div>
            <div
              onClick={() => {
                let commentElement = document.getElementById('commentBoxId');
                commentElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest'
                });
              }}
              style={{ display: 'flex' }}
            >
              <Icon
                icon={messageSquareOutline}
                style={{
                  fontSize: '25px',
                  marginLeft: '0px',
                  paddingRight: 'px',
                  color: '#9a76f5'
                }}
              />
              {getCommentsCount}
            </div>
          </div>

          <NoticeMoreMenu
            id={id}
            title={title}
            description={object.description}
            medias={object.medias}
            commentsCount={object.comments}
            likes={object.likes}
            createdAt={createdAt}
            residentsId={object.residentsId}
            roomsId={object.roomsId}
            facilitiesId={object.facilitiesId}
            facilityName={object.facilityName}
            object={object}
            featuredNotice={object.featuredNotice}
          />
        </Box>
      </Box>
      <Card
        sx={{
          boxShadow: 20,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          paddingY: '20px',
          paddingX: '20px'
        }}
      >
        <Box sx={{ fontSize: '16px', fontWeight: 400, marginLeft: '', color: '#9A76F5' }}>
         {formatDate(createdAt )}
        </Box>
        <Box sx={{ fontSize: '16px', fontWeight: 400, paddingTop: '25px' }}>
         <RenderText description={object.description} />
        </Box>

        <div className="grid">
          {images &&
            images.slice(0, images.length).map((item, index) => {
              const length = images.length;
              return <CheckFileType image={item} index={index} length={length} images={images} />;
            })}
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {docs &&
            docs.map((item, index) => {
              return (
                <CheckFileTypeDocument
                  image={item}
                  index={index}
                  images={docs}
                  isInProgress={isInProgress}
                  downloadURL={downloadURL}
                  setDownloadURL={setDownloadURL}
                  download={download}
                />
              );
            })}
        </Box>
        <Box sx={{ display: 'flex', gap: '1px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px', fontWeight: 600, color: '#9A76F5' }}>
            Shared by: {object.authorName ?? ''}
          </div>
          <div style={{ fontSize: '16px', fontWeight: 600, color: '#9A76F5' }}>
            {object.facilityName ?? ''}
          </div>
        </Box>
        <Box
          id="commentBoxId"
          sx={{ width: '100%', height: '3px', borderRadius: '10px', backgroundColor: '#9A76F5' }}
        ></Box>
    
      <NewCommentBox
          storyId={id}
          commentsCount={getComment.length}
          collections="noticeboards"
          object={object}
          deleted={deleted}
          likeColor={likeColor}
          getLikes={getLikes}
          setlikeColor={setlikeColor}
        />
      </Card>
    </Box>
  );
}

function Image({ images, likes, comments, title, date }) {
  return (
    <>
      {/* <Card
        style={card}
        sx={{
          width: '50%',
          position: 'relative',
          left: '25%'
        }}
      >
        <CardMediaStyle
          sx={{
            ...((1 || 1) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            }),
            ...(1 && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              ...((1 || 1) && { display: 'none' })
            }}
          />
          <AvatarStyle
            alt="image"
            src={images[0].downloadUrl}
            sx={{
              ...((1 || 1) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40
              })
            }}
          />

          <CoverImgStyle alt="title" src={images[0].downloadUrl} />
        </CardMediaStyle>
        <CardContent
          sx={{
            pt: 4,
            ...((1 || 1) && {
              bottom: 0,
              width: '100%',
              position: 'absolute'
            })
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'text.disabled', display: 'block' }}
          >
            {date}
          </Typography>
          <TitleStyle
            to={images[0]}
            color="inherit"
            variant="subtitle2"
            underline="hover"
            sx={{
              ...(1 && { typography: 'h5', height: 60 }),
              ...((1 || 1) && {
                color: 'common.white'
              })
            }}
          >
            {title}
          </TitleStyle>
          <InfoStyle>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: 1 === 0 ? 0 : 1.5,
                ...((1 || 1) && {
                  color: 'grey.500'
                })
              }}
            >
              <Icon icon={heartOutline} />
              <Typography variant="caption" sx={{ ml: 1, mr: 1 }}>
                {fShortenNumber(likes.length)}
              </Typography>
              <Icon icon={messageSquareOutline} />
              <Typography variant="caption" sx={{ ml: 1, mr: 1 }}>
                {fShortenNumber(comments)}
              </Typography>
            </Box>
          </InfoStyle>
        </CardContent>
      </Card> */}
      <br />
      <br />
      <Grid container spacing={3} padding={4} sx={{ paddingRight: '9.5%' }}>
        {images.map((img, index) => (
          <Grid key={img.id} item xs={12} sm={6} md={2}>
            <ShopProductCard img={img} Index={index} images={images} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function Video({ images, likes, comments, title, date }) {
  console.log(images[0].thumbnail);
  return (
    <>
      {/* <Card
        style={card}
        sx={{
          width: '50%',
          position: 'relative',
          left: '25%'
        }}
        sm={{ width: '100%', position: 'relative' }}
      >
        <CardMediaStyle
          sx={{
            ...((1 || 1) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            }),
            ...(1 && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              ...((1 || 1) && { display: 'none' })
            }}
          />
          <AvatarStyle
            alt="image"
            src={images[0].thumbnail}
            sx={{
              ...((1 || 1) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40
              })
            }}
          />

          <CoverImgStyle alt="title" src={images[0].thumbnail} />
        </CardMediaStyle>
        <CardContent
          sx={{
            pt: 4,
            ...((1 || 1) && {
              bottom: 0,
              width: '100%',
              position: 'absolute'
            })
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'text.disabled', display: 'block' }}
          >
            {date}
          </Typography>
          <TitleStyle
            to={images[0]}
            color="inherit"
            variant="subtitle2"
            underline="hover"
            sx={{
              ...(1 && { typography: 'h5', height: 60 }),
              ...((1 || 1) && {
                color: 'common.white'
              })
            }}
          >
            {title}
          </TitleStyle>
          <InfoStyle>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: 1 === 0 ? 0 : 1.5,
                ...((1 || 1) && {
                  color: 'grey.500'
                })
              }}
            >
              <Icon icon={heartOutline} />
              <Typography variant="caption" sx={{ ml: 1, mr: 1 }}>
                {fShortenNumber(likes.length)}
              </Typography>
              <Icon icon={messageSquareOutline} />
              <Typography variant="caption" sx={{ ml: 1, mr: 1 }}>
                {fShortenNumber(comments)}
              </Typography>
            </Box>
          </InfoStyle>
        </CardContent>
      </Card> */}
      <br />
      <br />
      <Grid container spacing={3} padding={4} sx={{ paddingRight: '9.5%' }}>
        {images.map((img, index) => (
          <Grid key={img.id} item xs={12} sm={6} md={2}>
            <ShopProductCard img={img} images={images} Index={index} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
